var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { EditOutlinedIcon } from '../Icon';
import DescriptionList from '../DescriptionList';
import { Heading, Text } from '../Typography';
import Avatar from '../Avatar';
import Button from '../Button';
import './UserCard.scss';
var UserCard = /** @class */ (function (_super) {
    __extends(UserCard, _super);
    function UserCard() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleAvatarClick = function () {
            var onAvatarClick = _this.props.onAvatarClick;
            if (onAvatarClick) {
                onAvatarClick();
            }
        };
        _this.getFieldValue = function (value) {
            // TODO: fix me. Why we only check for null or false?
            if (value === false) {
                return React.createElement(Text, null);
            }
            return value !== null ? value : '-';
        };
        return _this;
    }
    UserCard.prototype.render = function () {
        var _this = this;
        var _a = this.props, id = _a.id, fullName = _a.fullName, avatar = _a.avatar, information = _a.information, editButton = _a.editButton;
        return (React.createElement("div", { id: id, "data-testid": "elmo-usercard-".concat(id || 'default'), className: "elmo-usercard__avatar" },
            React.createElement("div", { className: "elmo-usercard__avatar--wrapper" },
                React.createElement(Avatar, { label: fullName, size: 'xl', src: avatar }),
                editButton && (React.createElement(Button, { icon: React.createElement(EditOutlinedIcon, null), isCircular: true, onClick: this.handleAvatarClick, ariaLabel: "Edit" }))),
            React.createElement("div", { className: "elmo-usercard__profile-data" },
                React.createElement(Heading, { isBold: true, htmlTag: 'h2' }, fullName),
                information && (React.createElement(DescriptionList, { style: "align-icons" }, information.map(function (info, key) { return (React.createElement(DescriptionList.Item, { label: info.icon, key: key }, _this.getFieldValue(info.data))); }))))));
    };
    return UserCard;
}(Component));
export default UserCard;
