var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { uid } from '../api';
function Svg(_a) {
    var rtl = _a.rtl, speed = _a.speed, interval = _a.interval, style = _a.style, width = _a.width, height = _a.height, viewBoxWidth = _a.viewBoxWidth, viewBoxHeight = _a.viewBoxHeight, baseUrl = _a.baseUrl, gradientRatio = _a.gradientRatio, animate = _a.animate, ariaLabel = _a.ariaLabel, children = _a.children, className = _a.className, uniquekey = _a.uniquekey, primaryColor = _a.primaryColor, primaryOpacity = _a.primaryOpacity, secondaryColor = _a.secondaryColor, secondaryOpacity = _a.secondaryOpacity, preserveAspectRatio = _a.preserveAspectRatio, props = __rest(_a, ["rtl", "speed", "interval", "style", "width", "height", "viewBoxWidth", "viewBoxHeight", "baseUrl", "gradientRatio", "animate", "ariaLabel", "children", "className", "uniquekey", "primaryColor", "primaryOpacity", "secondaryColor", "secondaryOpacity", "preserveAspectRatio"]);
    var idClip = uniquekey ? "".concat(uniquekey, "-idClip") : uid();
    var idGradient = uniquekey ? "".concat(uniquekey, "-idGradient") : uid();
    var rtlStyle = rtl ? { transform: 'scaleX(-1)' } : {};
    var keyTimes = "0; ".concat(interval, "; 1");
    var dur = "".concat(speed, "s");
    gradientRatio = gradientRatio ? gradientRatio : 0;
    return (React.createElement("svg", __assign({ role: "img", style: __assign(__assign({}, style), rtlStyle), className: className, "aria-label": ariaLabel ? ariaLabel : undefined, viewBox: "0 0 ".concat(viewBoxWidth, " ").concat(viewBoxHeight), preserveAspectRatio: preserveAspectRatio, width: width }, (height ? { height: height } : {})),
        ariaLabel ? React.createElement("title", null, ariaLabel) : null,
        React.createElement("rect", { x: "0", y: "0", width: viewBoxWidth, height: viewBoxHeight, clipPath: "url(".concat(baseUrl, "#").concat(idClip, ")"), style: { fill: "url(".concat(baseUrl, "#").concat(idGradient, ")") } }),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: idClip }, children),
            React.createElement("linearGradient", { id: idGradient },
                React.createElement("stop", { offset: "0%", stopColor: primaryColor, stopOpacity: primaryOpacity }, animate && (React.createElement("animate", { attributeName: "offset", values: "".concat(-gradientRatio, "; ").concat(-gradientRatio, "; 1"), keyTimes: keyTimes, dur: dur, repeatCount: "indefinite" }))),
                React.createElement("stop", { offset: "50%", stopColor: secondaryColor, stopOpacity: secondaryOpacity }, animate && (React.createElement("animate", { attributeName: "offset", values: "".concat(-gradientRatio / 2, "; ").concat(-gradientRatio / 2, "; ").concat(1 + gradientRatio / 2), keyTimes: keyTimes, dur: dur, repeatCount: "indefinite" }))),
                React.createElement("stop", { offset: "100%", stopColor: primaryColor, stopOpacity: primaryOpacity }, animate && (React.createElement("animate", { attributeName: "offset", values: "0; 0; ".concat(1 + gradientRatio), keyTimes: keyTimes, dur: dur, repeatCount: "indefinite" })))))));
}
export default Svg;
