var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Children, Component } from 'react';
import ListTableColumn from './component/ListTableColumn';
import ListTableHeaderWrapper from './component/ListTableHeaderWrapper';
import { TOTAL_GRID_COLS } from '../_lib/const';
import ListTableTr from './component/ListTableTr';
import { getClass, isElementOfType, noop } from '../_lib/helper';
import './ListTable.scss';
import ListTableContext from './ListTableContext';
import ListTableHeader from './component/ListTableHeader';
import ListTableBody from './component/ListTableBody';
import ListTableTd from './component/ListTableTd';
import ListTablePagination from './component/ListTablePagination';
import Sticky from '../Sticky';
import { EmptyState } from '../EmptyState';
var maxColsForBreakpoints = {
    xs: 2,
    sm: 4,
    md: 6,
    lg: 6,
    xl: 6,
    xxl: 8,
};
var ListTable = /** @class */ (function (_super) {
    __extends(ListTable, _super);
    function ListTable(props) {
        var _this = _super.call(this, props) || this;
        /**
         * Exposes setState to DataTableContext consumers.
         *
         * @param state
         */
        _this.setContextState = function (state) {
            _this.setState(state);
        };
        _this.updateSelectAllCheckboxState = function (isSelect) {
            var _a = _this.props, data = _a.data, isItemSelected = _a.isItemSelected, isItemDisabled = _a.isItemDisabled;
            var countVisibleSelected = isSelect ? 1 : -1;
            var countVisibleSelectable = 0;
            // For the case where data is passed in through the ListTable.
            if (data) {
                data.forEach(function (rowData) {
                    var isSelected = !!(isItemSelected && isItemSelected(rowData));
                    var isDisabled = !!(isItemDisabled && isItemDisabled(rowData));
                    countVisibleSelected += isSelected ? 1 : 0;
                    countVisibleSelectable += !isDisabled || isSelected ? 1 : 0;
                });
            }
            // For the case where the rows are rendered with <ListTableTr>
            if (_this.subcomponents.body) {
                var bodyProps = _this.subcomponents.body.props;
                var rows = Children.toArray(bodyProps.children);
                rows.forEach(function (row) {
                    if (!React.isValidElement(row) || !isElementOfType(row, ListTableTr)) {
                        return;
                    }
                    var rowProps = row.props;
                    countVisibleSelected += rowProps.isSelected ? 1 : 0;
                    countVisibleSelectable +=
                        !rowProps.isDisabled || rowProps.isSelected ? 1 : 0;
                });
            }
            var isIndeterminate = !(countVisibleSelected === countVisibleSelectable) &&
                countVisibleSelected !== 0;
            var isChecked = countVisibleSelected !== 0;
            _this.setContextState({
                selectAllIsChecked: isChecked,
                selectAllIsIndeterminate: isIndeterminate,
                showSelectAllAvailableHeader: isChecked && !isIndeterminate,
            });
        };
        /**
         * Get the next context state with the values from props
         * Does not update the state
         *
         * @param prevProps
         */
        _this.getContextStateUpdate = function (prevProps) {
            var props = _this.props;
            var propsToUpdate = [
                'hasBulkActionsButton',
                'countSelectedItems',
                'countItemsAvailable',
                'isAllAvailableSelected',
                'onSort',
                'onSelectAllToggle',
                'onSelectAllAvailableToggle',
                'countSelectable',
                'hasLayout',
            ];
            var newState = {};
            // Check if the props have changed, if they have, add it to the new state to be updated
            propsToUpdate.forEach(function (key) {
                if (prevProps[key] !== props[key]) {
                    newState[key] = props[key];
                }
            });
            return newState;
        };
        /**
         * Called when a row is clicked.
         * Wraps the function passed into ListTable to provide the row information
         *
         * @param rowData
         * @param rowIndex
         */
        _this.onRowClick = function (rowData, rowIndex) { return function () {
            var onRowClick = _this.props.onRowClick;
            if (!onRowClick) {
                return false;
            }
            return onRowClick(rowData, rowIndex);
        }; };
        /**
         * Called when item selected/deselected
         * Wraps the function passed into ListTable to provide the row information
         *
         * @param rowData
         */
        _this.onItemToggle = function (rowData) { return function (isSelect) {
            var onItemToggle = _this.props.onItemToggle;
            if (!onItemToggle) {
                return false;
            }
            return onItemToggle(rowData, isSelect);
        }; };
        /**
         * Called when the page is changed or page size is changed.
         */
        _this.onPageUpdate = function () {
            _this.setContextState({
                showSelectAllAvailableHeader: false,
                selectAllIsChecked: false,
                selectAllIsIndeterminate: false,
            });
        };
        // run this in the constructor so we save on rendering
        _this.init();
        return _this;
    }
    ListTable.prototype.componentDidUpdate = function (prevProps) {
        var nextContextState = this.getContextStateUpdate(prevProps);
        if (Object.keys(nextContextState).length !== 0) {
            this.setContextState(nextContextState);
        }
        var _a = this.props, sortColumn = _a.sortColumn, sortDirection = _a.sortDirection, countSelectedItems = _a.countSelectedItems, toggleBulkActionDisabled = _a.toggleBulkActionDisabled, data = _a.data, isAllAvailableSelected = _a.isAllAvailableSelected, isBulkActionOpen = _a.isBulkActionOpen;
        /**
         * Update the sort column and direction
         */
        if (prevProps.sortColumn !== sortColumn ||
            prevProps.sortDirection !== sortDirection) {
            this.setState({
                sort: {
                    column: sortColumn,
                    direction: sortDirection,
                },
            });
        }
        /**
         * When the data in the list table changes, uncheck the "Select all" checkbox
         */
        if (prevProps.data !== data) {
            this.setState({
                selectAllIsChecked: !!isAllAvailableSelected,
                selectAllIsIndeterminate: false,
                showSelectAllAvailableHeader: false,
            });
        }
        /**
         * If the bulk actions has been closed, hide the select all component in the header, and uncheck the Select All
         * checkbox.
         */
        if (!isBulkActionOpen && isBulkActionOpen !== prevProps.isBulkActionOpen) {
            this.setContextState({
                showSelectAllAvailableHeader: false,
                selectAllIsChecked: false,
                selectAllIsIndeterminate: false,
            });
        }
        /**
         * Enable or disable the bulk actions buttons depending if there are any selected
         * items
         */
        if (countSelectedItems !== undefined &&
            prevProps.countSelectedItems !== undefined &&
            prevProps.countSelectedItems !== countSelectedItems) {
            var firstSelected = countSelectedItems > 0 && prevProps.countSelectedItems === 0;
            var lastDeselected = countSelectedItems === 0 && prevProps.countSelectedItems > 0;
            if (firstSelected || lastDeselected) {
                toggleBulkActionDisabled();
            }
        }
    };
    ListTable.prototype.init = function () {
        var _a = this.props, sortColumn = _a.sortColumn, sortDirection = _a.sortDirection, actions = _a.actions, avatarType = _a.avatarType, icon = _a.icon;
        // get subcomponents from children
        this.subcomponents = this.getSubComponents();
        // update ListTable context state from props
        var contextState = this.getContextStateUpdate({});
        var rowsHasActions = this.checkRowsHaveActions(this.subcomponents);
        this.state = __assign({ selectAllIsChecked: false, selectAllIsIndeterminate: false, showSelectAllAvailableHeader: false, countSelectable: 0, setContextState: this.setContextState, hasBulkActionsButton: false, countSelectedItems: 0, countItemsAvailable: 0, isAllAvailableSelected: false, onSort: noop, avatarType: avatarType, classNamesForCols: this.calculateClassNames(this.subcomponents.columns.length), colSpanForCols: this.calculateColSpan(this.subcomponents.columns.length), sort: {
                column: sortColumn,
                direction: sortDirection,
            }, columns: this.subcomponents.columns, hasActions: actions || icon || rowsHasActions, updateSelectAllCheckboxState: this.updateSelectAllCheckboxState }, contextState);
    };
    /**
     * Calculate the subcomponents in the ListTable children. Used to render in a specific order
     */
    ListTable.prototype.getSubComponents = function () {
        var children = this.props.children;
        var subComponents = {
            columns: [],
            body: null,
            pagination: null,
        };
        Children.map(children, function (child) {
            if (!React.isValidElement(child)) {
                return false;
            }
            if (isElementOfType(child, ListTableColumn)) {
                subComponents.columns.push(child);
            }
            else if (isElementOfType(child, ListTableHeader)) {
                var headerProps = child.props;
                subComponents.columns = Children.toArray(headerProps.children).filter(function (column) {
                    return isElementOfType(column, ListTableColumn);
                });
            }
            else if (isElementOfType(child, ListTableBody)) {
                subComponents.body = child;
            }
            else if (isElementOfType(child, ListTablePagination)) {
                subComponents.pagination = child;
            }
        });
        return subComponents;
    };
    /**
     * Checks if the ListTableTr components contain actions
     *
     * @param subComponents
     */
    ListTable.prototype.checkRowsHaveActions = function (subComponents) {
        if (!subComponents.body) {
            return false;
        }
        var bodyProps = subComponents.body.props;
        var rows = Children.toArray(bodyProps.children);
        for (var i = 0; i < rows.length; i++) {
            var row = rows[i];
            if (!React.isValidElement(row) || !isElementOfType(row, ListTableTr)) {
                continue;
            }
            var rowProps = row.props;
            // If the row has actions or icon
            if (!!rowProps.actions || !!rowProps.icon) {
                return true; // stop looping!
            }
        }
    };
    ListTable.prototype.renderBody = function (subcomponents) {
        var _this = this;
        var _a = this.props, id = _a.id, data = _a.data, isItemDisabled = _a.isItemDisabled, isItemSelected = _a.isItemSelected, actions = _a.actions, icon = _a.icon, href = _a.href, avatarType = _a.avatarType, testId = _a.testId, hideLabel = _a.hideLabel, useCompactLayout = _a.useCompactLayout, isBulkActionOpen = _a.isBulkActionOpen, isSelectable = _a.isSelectable, truncateData = _a.truncateData;
        var hasActions = this.state.hasActions;
        if (data && data.length > 0) {
            return data.map(function (rowData, rowIndex) {
                var isSelected = !!(isItemSelected && isItemSelected(rowData));
                var isDisabled = !!(isItemDisabled && isItemDisabled(rowData));
                var rowActions = actions ? actions(rowData, rowIndex) : undefined;
                var rowIcon = icon ? icon(rowData, rowIndex) : undefined;
                var rowHref = href ? href(rowData, rowIndex) : undefined;
                var rowTestId = testId ? testId(rowData, rowIndex) : undefined;
                return (React.createElement(ListTableTr, { testId: rowTestId, rowData: rowData, rowIndex: rowIndex, onRowClick: _this.onRowClick(rowData, rowIndex), onItemToggle: _this.onItemToggle(rowData), isSelected: isSelected, isSelectable: isSelectable, isDisabled: isDisabled, actions: rowActions, icon: rowIcon, key: rowIndex, id: (id || 'default') + '-' + rowIndex, href: rowHref, avatarType: avatarType, hasActions: hasActions, hideLabel: hideLabel, useCompactLayout: useCompactLayout, isBulkActionOpen: isBulkActionOpen, truncateData: truncateData }));
            });
        }
        return subcomponents.body;
    };
    ListTable.prototype.render = function () {
        var _a;
        var _b = this.props, isBulkActionOpen = _b.isBulkActionOpen, ariaLabel = _b.ariaLabel, id = _b.id, className = _b.className, avatarType = _b.avatarType, isSticky = _b.isSticky, useCompactLayout = _b.useCompactLayout, onSelectAllToggle = _b.onSelectAllToggle, isSelectable = _b.isSelectable, countSelectedItems = _b.countSelectedItems, hideCardLayout = _b.hideCardLayout, _c = _b.style, style = _c === void 0 ? {} : _c, data = _b.data, emptyView = _b.emptyView, error = _b.error, bodyCanScroll = _b.bodyCanScroll;
        var _d = this.state, sort = _d.sort, hasActions = _d.hasActions;
        /** Store the re-rendered subcomponents as it gets used in other functions */
        this.subcomponents = this.getSubComponents();
        var renderTable = (React.createElement(ListTableContext.Provider, { value: this.state },
            React.createElement("div", { id: id, className: getClass(useCompactLayout ? 'elmo-compactlisttable' : 'elmo-listtable', className, (_a = {
                        'bulkactions-open': isBulkActionOpen
                    },
                    _a["avatar-type--".concat(avatarType)] = avatarType,
                    _a['has-row-actions'] = hasActions,
                    _a['hide-card-layout'] = hideCardLayout,
                    _a)), role: "table", "aria-label": ariaLabel, "data-testid": "elmo-listtable-".concat(id || 'default'), "data-sort-order": sort.direction, "data-sort-by": sort.column, style: style },
                React.createElement(ListTableHeaderWrapper, { id: id, isSticky: isSticky, useCompactLayout: useCompactLayout, hasActions: hasActions, onSelectAllToggle: onSelectAllToggle, isSelectable: isSelectable, countSelectedItems: countSelectedItems }),
                ((!data || (data === null || data === void 0 ? void 0 : data.length) === 0) && !error && emptyView) || React.createElement(React.Fragment, null),
                error ? (React.createElement("div", { style: { margin: 'auto', padding: '18px' } },
                    React.createElement(EmptyState, { header: "Error encountered", description: error.message }))) : (React.createElement("div", { style: bodyCanScroll ? { overflow: 'scroll' } : {} }, this.renderBody(this.subcomponents))),
                this.subcomponents.pagination &&
                    React.cloneElement(this.subcomponents.pagination, {
                        onPageUpdate: this.onPageUpdate,
                    }))));
        if (id && isSticky) {
            return React.createElement(Sticky.Target, { id: id + '-Sticky' }, renderTable);
        }
        return renderTable;
    };
    /**
     * Calculate the width of each column and whether an offset needs to be applied.
     *
     * @param numColumns
     */
    ListTable.prototype.calculateColSpan = function (numColumns) {
        var result = [];
        Object.entries(maxColsForBreakpoints).forEach(function (_a, sizeIndex) {
            var size = _a[0], maxCols = _a[1];
            var numCols = Math.min(numColumns, maxCols);
            var remainder = TOTAL_GRID_COLS % numCols;
            var currentColCount = 0; // index of the column in the current row
            var isFirstRow = true;
            Array(numColumns)
                .fill(0)
                .forEach(function (child, colIndex) {
                // initialise the results array
                if (sizeIndex === 0) {
                    result[colIndex] = {
                        xs: {},
                        sm: {},
                        md: {},
                        lg: {},
                        xl: {},
                        xxl: {},
                    };
                }
                // calculate how many cols each column takes up.
                var span = Math.floor(TOTAL_GRID_COLS / numCols) +
                    (colIndex < remainder ? 1 : 0);
                // Offset required because in the design from the second row onwards, the columns start
                // from the second column.
                var offset = 0;
                if (!isFirstRow && currentColCount === 0) {
                    offset = span;
                }
                currentColCount++;
                if ((isFirstRow && currentColCount === maxCols) ||
                    (!isFirstRow && currentColCount === maxCols - 1)) {
                    currentColCount = 0;
                    if (isFirstRow) {
                        isFirstRow = false;
                    }
                }
                result[colIndex][size] = {
                    span: span,
                    offset: offset,
                };
            });
        });
        return result;
    };
    /**
     * Decides whether or not the header should be displayed on specific breakpoints
     *
     * @param numColumns
     */
    ListTable.prototype.calculateClassNames = function (numColumns) {
        return Array(numColumns)
            .fill(0)
            .map(function (data, colIndex) {
            var headerClassNames = 'd-none '; // don't display the headers by default
            var labelClassNames = colIndex === 0 ? 'd-none' : ''; // don't display the label for the first column by default
            Object.entries(maxColsForBreakpoints).forEach(function (_a) {
                var size = _a[0], maxCols = _a[1];
                if (colIndex < maxCols) {
                    labelClassNames += ' d-' + size + '-none';
                    headerClassNames += ' d-' + size + '-block';
                    return;
                }
                labelClassNames += ' d-' + size + '-block';
                headerClassNames += ' d-' + size + '-none';
            });
            return {
                header: headerClassNames,
                label: labelClassNames,
            };
        });
    };
    ListTable.Column = ListTableColumn;
    ListTable.Header = ListTableHeader;
    ListTable.Body = ListTableBody;
    ListTable.Tr = ListTableTr;
    ListTable.Td = ListTableTd;
    ListTable.Pagination = ListTablePagination;
    return ListTable;
}(Component));
export default ListTable;
