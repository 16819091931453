import React, { useState } from 'react';
import { AssignAppraisalItem, AssignAppraisalListViewProps } from '../../type';
import { t, messages } from '../../../../lib/translation';
import { Flex } from '@eds/flex';
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '@eds/table';
import { Pagination, PaginationState } from '@eds/pagination';
import { Button, CustomButton } from '@eds/button';
import { Radio } from '@eds/radio';
import { Box } from '@eds/box';
import { EmptyState } from '@eds/empty-state';
import history from '../../../../lib/history';
import { TeamPerformancePath } from '../../../TeamPerformance/routes';
import { getRoute, getEmptyStateMessage } from '../../../../lib/util';
import Routes from '../../../../routes';
import { Text } from '@eds/text';
import AssigneeListModal from './AssigneeListModal';
import './index.scss';

const ListView = ({
  limit,
  page,
  total,
  data,
  columnSort,
  onTableSort,
  onPageChange,
  onPageSizeChange,
}: AssignAppraisalListViewProps): React.ReactElement => {
  const [selectedAppraisal, setSelectedAppraisal] = useState<number | null>(null);
  const [appraisal, setAppraisal] = useState<AssignAppraisalItem | null>(null);

  const onPageUpdate = (paginationState: PaginationState): void => {
    if (paginationState.currentPage !== Number(page)) {
      onPageChange(paginationState.currentPage);
    }

    if (paginationState.currentItemsPerPage !== Number(limit)) {
      onPageSizeChange(paginationState.currentItemsPerPage);
    }
  };

  const getEmptyState = (): Record<string, string> => getEmptyStateMessage();

  return (
    <Flex flexDirection="column" gap="medium">
      <Table tableBorder>
        <TableHead>
          <TableRow>
            <TableHeaderCell
              columnName="title"
              key="title"
              onSortChange={onTableSort}
              sort={columnSort}
              data-testid="appraisalTitle"
              width="70%"
            >
              {t(messages.assignAppraisal.listHeader.appraisalTitle)}
            </TableHeaderCell>
            <TableHeaderCell
              columnName="assignees"
              key="assignees"
              data-testid="assignees"
              horizontalAlign="middle"
            >
              {t(messages.assignAppraisal.listHeader.assignees)}
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item: AssignAppraisalItem) => (
            <TableRow key={item.id}>
              <TableCell>
                <Radio
                  name="appraisal"
                  size="small"
                  value={item.id.toString()}
                  id={`appraisal-${item.id.toString()}`}
                  onClick={(): void => setSelectedAppraisal(item.id)}
                >
                  {item.title}
                </Radio>
              </TableCell>
              <TableCell horizontalAlign="middle">
                <CustomButton
                  size="small"
                  disabled={!item.totalAssignee}
                  tone="ghost"
                  onClick={(e): void => {
                    e.stopPropagation();
                    setAppraisal(item);
                  }}
                  data-testid={`assigneeBtn-${item.id.toString()}`}
                >
                  <Text
                    className={!item.totalAssignee ? '' : 'open-modal-text'}
                    fontSize="small"
                    lineHeight="loose"
                    color={!item.totalAssignee ? 'neutralSubtle' : 'themePrimary400'}
                    fontWeight="medium"
                  >
                    {item.totalAssignee.toString()}
                  </Text>
                </CustomButton>
              </TableCell>
            </TableRow>
          ))}
          {!data.length && (
            <TableRow>
              <TableCell colSpan="100%">
                <Box marginTop="large" marginBottom="large">
                  <EmptyState title={getEmptyState().title} description={getEmptyState().content} />
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        indexBased={1}
        itemsCount={total}
        currentPage={Number(page)}
        currentItemsPerPage={Number(limit)}
        itemsLabelSingular="item"
        itemsLabelPlural="items"
        onPageUpdate={onPageUpdate}
      />
      <Flex gap="xxsmall" flexWrap="wrap">
        <Button
          tone="neutral"
          label="Cancel"
          data-testid="select-appraisal-cancel"
          onClick={(): void => {
            history.push(TeamPerformancePath);
          }}
        />
        <Button
          label="Next"
          data-testid="select-appraisal-next"
          disabled={!selectedAppraisal}
          onClick={(): void =>
            history.push(
              getRoute(Routes.assignAppraisal.routes.assign.path, {
                params: { id: selectedAppraisal },
              })
            )
          }
        />
      </Flex>
      <AssigneeListModal
        isOpen={!!appraisal}
        appraisalId={appraisal ? appraisal.id : 0}
        appraisalTitle={appraisal ? appraisal.title : ''}
        onClose={(): void => {
          setAppraisal(null);
        }}
      />
    </Flex>
  );
};

export default ListView;
