import { createAction } from 'redux-act';
import { AssignAppraisalDefaultQuery } from './state';
import {
  AssignAppraisalQuery,
  AssignAppraisalData,
  AssigneeData,
  AssigneeQuery,
  EmployeeListQuery,
  EmployeeListData,
} from '../type';

export const getAppraisals: any = createAction(
  'PERFORMANCE_GET_APPRAISALS',
  (payload: AssignAppraisalQuery) => ({
    ...AssignAppraisalDefaultQuery,
    ...payload,
  })
);

export const getAppraisalsSuccess: any = createAction(
  'PERFORMANCE_GET_APPRAISALS_SUCCESS',
  (data: AssignAppraisalData) => data
);

export const getAppraisalsFailure: any = createAction(
  'PERFORMANCE_GET_APPRAISALS_FAILURE',
  (error: any) => error
);

export const getAppraisal: any = createAction('PERFORMANCE_GET_APPRAISAL', (id: number) => id);

export const getAppraisalSuccess: any = createAction(
  'PERFORMANCE_GET_APPRAISAL_SUCCESS',
  (data: any) => data
);

export const getAppraisalFailure: any = createAction(
  'PERFORMANCE_GET_APPRAISAL_FAILURE',
  (error: any) => error
);

/** Assignee Actions */
export const getAssignees: any = createAction(
  'PERFORMANCE_GET_APPRAISAL_ASSIGNEES',
  (appraisalId: number, query: AssigneeQuery) => ({ appraisalId, query })
);
export const getAssigneesSuccess: any = createAction(
  'PERFORMANCE_GET_APPRAISAL_ASSIGNEES_SUCCESS',
  (data: AssigneeData) => data
);
export const getAssigneesFailure: any = createAction(
  'PERFORMANCE_GET_APPRAISAL_ASSIGNEES_FAILURE',
  (error: any) => error
);

/** get pending assignment employee Actions */
export const getEmployees: any = createAction(
  'PERFORMANCE_GET_APPRAISAL_PENDING_ASSIGNMENT_EMPLOYEES',
  (appraisalId: number, query: EmployeeListQuery) => ({ appraisalId, query })
);
export const getEmployeesSuccess: any = createAction(
  'PERFORMANCE_GET_APPRAISAL_PENDING_ASSIGNMENT_EMPLOYEES_SUCCESS',
  (data: EmployeeListData) => data
);
export const getEmployeesFailure: any = createAction(
  'PERFORMANCE_GET_APPRAISAL_PENDING_ASSIGNMENT_EMPLOYEES_FAILURE',
  (error: any) => error
);
/** End get pending assignment employee Actions */

export const assignAppraisal: any = createAction('PERFORMANCE_ASSIGN_APPRAISAL');
export const assignAppraisalAccepted: any = createAction('PERFORMANCE_ASSIGN_APPRAISAL_ACCEPTED');
export const assignAppraisalFailure: any = createAction(
  'PERFORMANCE_ASSIGN_APPRAISAL_FAILURE',
  (error: any) => error
);
