import React, { ReactElement, useCallback, useState } from 'react';
import { NotificationContextType, NotificationObject } from './type';

export const NotificationsContext = React.createContext<Partial<NotificationContextType>>({
  notifications: [],
});

export const NotificationProvider = (props: { children: ReactElement }): ReactElement => {
  const [notifications, setNotifications] = useState<NotificationObject[]>([]);

  const add = useCallback(
    (notification: NotificationObject): void => {
      setNotifications((prevNotifications: NotificationObject[]) => [
        ...prevNotifications,
        notification,
      ]);
    },
    [setNotifications]
  );

  const clear = useCallback(
    (index: number): void => {
      setNotifications((prevNotifications: NotificationObject[]) => {
        const reducedNotifications = [...prevNotifications];
        reducedNotifications.splice(index, 1);
        return reducedNotifications;
      });
    },
    [setNotifications]
  );

  const clearByTypeJobId = useCallback(
    (typeJobId: string): void => {
      setNotifications((prevNotifications: NotificationObject[]) =>
        prevNotifications.filter(
          (notification: NotificationObject) => notification.typeJobId !== typeJobId
        )
      );
    },
    [setNotifications]
  );

  const reset = useCallback((): void => {
    setNotifications([]);
  }, [setNotifications]);

  return (
    <NotificationsContext.Provider value={{ notifications, add, clear, clearByTypeJobId, reset }}>
      {props.children}
    </NotificationsContext.Provider>
  );
};

export default { NotificationsContext, NotificationProvider };
