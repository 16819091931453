var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { getClass } from '../_lib/helper';
import './TextArea.scss';
var TextArea = /** @class */ (function (_super) {
    __extends(TextArea, _super);
    function TextArea() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleOnChange = function (e) {
            var _a = _this.props, isDisabled = _a.isDisabled, onChange = _a.onChange;
            if (e === undefined) {
                return;
            }
            if (isDisabled) {
                e.stopPropagation();
                return;
            }
            if (onChange) {
                onChange(e);
            }
        };
        _this.handleOnKeyPress = function (e) {
            var _a = _this.props, isDisabled = _a.isDisabled, onEnterPress = _a.onEnterPress, onKeyPress = _a.onKeyPress;
            if (e === undefined) {
                return;
            }
            if (isDisabled) {
                e.stopPropagation();
                return;
            }
            if (e.charCode === 13 && onEnterPress) {
                onEnterPress(e);
            }
            if (onKeyPress) {
                onKeyPress(e);
            }
        };
        _this.handleOnBlur = function (e) {
            var onBlur = _this.props.onBlur;
            if (onBlur) {
                onBlur(e);
            }
        };
        _this.handleOnFocus = function (e) {
            var onFocus = _this.props.onFocus;
            if (onFocus) {
                onFocus(e);
            }
        };
        return _this;
    }
    TextArea.prototype.render = function () {
        var _a = this.props, className = _a.className, name = _a.name, placeholder = _a.placeholder, isDisabled = _a.isDisabled, isHidden = _a.isHidden, id = _a.id, rows = _a.rows, value = _a.value, ariaLabel = _a.ariaLabel, isRequired = _a.isRequired;
        return (React.createElement("textarea", { id: id, "data-testid": "elmo-textarea-".concat(id || 'default'), className: getClass('elmo-textarea', [className]), name: name, placeholder: placeholder, onChange: this.handleOnChange, onKeyPress: this.handleOnKeyPress, onBlur: this.handleOnBlur, onFocus: this.handleOnFocus, disabled: isDisabled, hidden: isHidden, rows: rows, value: value, "aria-label": ariaLabel, required: isRequired }));
    };
    return TextArea;
}(Component));
export default TextArea;
