import React from 'react';
import { getClass } from '../_lib/helper';
import { ExpandMoreIcon as KeyboardArrowDownIcon } from '../Icon';
import './Badge.scss';
function Badge(_a) {
    var _b;
    var id = _a.id, icon = _a.icon, role = _a.role, type = _a.type, children = _a.children, className = _a.className, hasPopoverIcon = _a.hasPopoverIcon, isCircular = _a.isCircular, isSharp = _a.isSharp;
    return (React.createElement("span", { id: id, className: getClass('elmo-badge', className, (_b = {},
            _b[type] = type,
            _b.lighter = children === '0' || children === 0,
            _b['is-circular'] = isCircular,
            _b['is-sharp'] = isSharp,
            _b["role-".concat(role)] = role,
            _b)), "data-testid": "elmo-badge-".concat(id || 'default') },
        icon && React.createElement("span", { className: "elmo-badge__icon" }, icon),
        children && React.createElement("span", { className: "elmo-badge__label" }, children),
        hasPopoverIcon && (React.createElement("span", { className: "elmo-badge__popover-icon" },
            React.createElement(KeyboardArrowDownIcon, null)))));
}
Badge.defaultProps = {
    role: 'status',
    type: 'grey',
};
export default Badge;
