import React from 'react';
import { ThemeContextProvider } from './ThemeContext';
import CollapseButton from './CollapseButton';
import MobileHeader from './MobileHeader';
import { getClass } from '../../_lib/helper';
import { MobileHeaderStyled } from '.././Styles';
function MenuError(_a) {
    var id = _a.id, theme = _a.theme, alert = _a.alert, isMenuCollapsed = _a.isMenuCollapsed, isMenuOpenedMobile = _a.isMenuOpenedMobile, isMobile = _a.isMobile, handleLayoutToggle = _a.handleLayoutToggle, handleMenuOpenedMobile = _a.handleMenuOpenedMobile, drawMenu = _a.drawMenu;
    return (React.createElement(ThemeContextProvider, { value: theme },
        React.createElement("div", { className: getClass('elmo-nav-layout', {}, {
                'is-collapsed': isMenuCollapsed,
                'is-mobile': isMobile,
                'is-default': !isMobile,
            }), "data-testid": "elmo-left-navigation-".concat(id || 'default') },
            React.createElement(CollapseButton, { onLayoutToggle: handleLayoutToggle, isMenuCollapsed: isMenuCollapsed }),
            React.createElement(MobileHeaderStyled, null,
                React.createElement(MobileHeader, { onClick: handleMenuOpenedMobile, drawMenu: drawMenu })),
            React.createElement("div", { className: getClass('elmo-nav-drawer-toggle', {}, { 'is-opened': isMenuOpenedMobile }), onClick: handleMenuOpenedMobile }),
            React.createElement("div", { className: getClass('elmo-nav-drawer', {}, { 'is-opened': isMenuOpenedMobile, 'is-error': true }) }, alert))));
}
export default MenuError;
