import React from 'react';
import { ArrowBackIcon as HeaderBackIcon } from '../../Icon';
import Button from '../../Button';
import Loader from '../../Loader';
import { getClass } from '../../_lib/helper';
import './PlaceholderHeader.scss';
/**
 * Header back button
 */
function HeaderBack() {
    return (React.createElement("div", { className: "elmo-header__back-btn-wrapper" },
        React.createElement(Button, { isText: true, icon: React.createElement(HeaderBackIcon, null), isDisabled: true, ariaLabel: "Back" })));
}
/**
 * Header buttons
 */
function HeaderButtons() {
    return (React.createElement("div", { className: "elmo-header-btns elmo-header-btns--placeholder" },
        React.createElement(Loader, { type: "buttons", height: "90px" })));
}
/**
 * Get tabs
 *
 * @param numberOfTabs
 */
function getTabs(numberOfTabs) {
    var elements = [];
    for (var i = 0; i < numberOfTabs; i++) {
        elements.push(React.createElement("li", { className: "elmo-tabs__item elmo-tabs__item--placeholder", key: i },
            React.createElement(Loader, { type: "line", width: "80px", height: "11px" })));
    }
    return elements;
}
/**
 * Placeholder header
 */
function PlaceholderHeader(_a) {
    var hasButtons = _a.hasButtons, hasBackButton = _a.hasBackButton, hasSubHeading = _a.hasSubHeading, hasTabs = _a.hasTabs, numberOfTabs = _a.numberOfTabs, id = _a.id;
    var wrapperClassName = getClass('elmo-header-wrapper', {}, {
        'has-buttons': hasButtons,
        'has-back': hasBackButton,
        'has-tabs': hasTabs,
        'has-subheading': hasSubHeading,
    });
    var headerClassName = getClass('elmo-header', {}, {
        subpage: hasBackButton,
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: id, "data-testid": "elmo-header-wrapper-".concat(id || 'default'), className: wrapperClassName },
            React.createElement("div", { className: headerClassName },
                React.createElement("div", { className: "elmo-header__base" },
                    React.createElement("div", { className: "elmo-header__top" },
                        hasButtons && React.createElement(HeaderButtons, null),
                        React.createElement("div", { className: "elmo-header__title-wrapper" },
                            hasBackButton && React.createElement(HeaderBack, null),
                            React.createElement("div", { className: "elmo-header__title-block-wrapper" },
                                React.createElement("div", { className: "elmo-header__title-block elmo-header__title-block--placeholder" },
                                    React.createElement(Loader, { type: "text", width: "500px", height: "28px" })),
                                hasSubHeading && (React.createElement("h2", { className: "elmo-header__sub-heading elmo-header__sub-heading--placeholder" },
                                    React.createElement(Loader, { type: "text", width: "300px", height: "21px" })))))),
                    hasTabs && (React.createElement("div", { className: "elmo-tabs", id: "ElmoTab" },
                        React.createElement("nav", { className: "elmo-tabs__nav", id: "ElmoTabNav" },
                            React.createElement("ul", null, getTabs(numberOfTabs)))))))),
        React.createElement("div", { className: "elmo-header-wrapper__spacing" })));
}
PlaceholderHeader.defaultProps = {
    hasSubHeading: true,
    hasBackButton: true,
    hasButtons: true,
    hasTabs: true,
    numberOfTabs: 3,
};
export default PlaceholderHeader;
