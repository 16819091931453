import React from 'react';
import './AlertTitle.scss';
function AlertTitle(_a) {
    var title = _a.title, id = _a.id;
    // TODO: we need to change h5 to Title, also we need to have testId
    // props for Title component so we can pass any test ID to it
    // data-testid={testId || `elmo-alert-title-${id || 'default'}`}
    return (React.createElement("h3", { className: "elmo-alert__title", "data-testid": "elmo-alert-title-".concat(id || 'default') }, title));
}
export default AlertTitle;
