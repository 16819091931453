import { setPerformanceBasePath } from '../../lib/route';
import AssignAppraisal from '.';
import ListContainer from './components/List';
import AssignmentContainer from './components/AssignmentContainer';

export const AppraisalAssignmentListPath = setPerformanceBasePath('/assign-appraisal');
export const AppraisalAssignmentPath = setPerformanceBasePath('/assign-appraisal/:id');
const AssignAppraisalRoutes: any = {
  path: AppraisalAssignmentListPath,
  component: AssignAppraisal,
  routes: {
    assign: {
      path: AppraisalAssignmentPath,
      paramRules: {
        id: '(\\d+)',
      },
      component: AssignmentContainer,
    },
    list: {
      path: AppraisalAssignmentListPath,
      component: ListContainer,
    },
  },
};

export type AssignAppraisalRoutesType = typeof AssignAppraisalRoutes;

export default AssignAppraisalRoutes;
