var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component, createRef } from 'react';
import FocusLock from 'react-focus-lock';
import { getClass } from '../../_lib/helper';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import ModalBody from './ModalBody';
import '../Modal.scss';
var ModalWrapper = /** @class */ (function (_super) {
    __extends(ModalWrapper, _super);
    function ModalWrapper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.contentRef = createRef();
        /**
         * Handle close modal event
         */
        _this.handleCloseModal = function () {
            var closeModal = _this.props.closeModal;
            if (closeModal) {
                closeModal();
            }
        };
        /**
         * Handle press escape key and close modal
         */
        _this.escFunction = function (event) {
            if (event.keyCode === 27) {
                _this.handleCloseModal();
            }
        };
        return _this;
    }
    /**
     * Update Layout Context (isModalOpen) by triggering toggleModalOpen
     * if modal is opened on loading
     */
    ModalWrapper.prototype.componentDidMount = function () {
        if (this.props.toggleModalOpen && this.props.isOpened) {
            this.props.toggleModalOpen();
        }
    };
    ModalWrapper.prototype.componentWillUnmount = function () {
        if (this.props.toggleModalOpen && this.props.isOpened) {
            this.props.toggleModalOpen();
        }
    };
    /**
     * Update Layout Context (isModalOpen) by triggering toggleModalOpen
     * if it was closed before and is updated to True on isOpened
     */
    ModalWrapper.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.isOpened === false &&
            this.props.isOpened === true &&
            this.props.toggleModalOpen) {
            this.props.toggleModalOpen();
        }
        if (prevProps.isOpened === true &&
            this.props.isOpened === false &&
            this.props.toggleModalOpen) {
            this.props.toggleModalOpen();
        }
    };
    /**
     * Render Modal
     */
    ModalWrapper.prototype.render = function () {
        var _a;
        var _b = this.props, id = _b.id, icon = _b.icon, title = _b.title, primaryButton = _b.primaryButton, type = _b.type, children = _b.children, closeLabel = _b.closeLabel, hasCloseFooterButton = _b.hasCloseFooterButton, className = _b.className, isOpened = _b.isOpened, backButton = _b.backButton, ariaLabel = _b.ariaLabel, isBackgroundGrey = _b.isBackgroundGrey;
        return (isOpened && (React.createElement(FocusLock, { disabled: !isOpened, returnFocus: true, noFocusGuards: true, className: "d-inline", whiteList: function (node) {
                var root = document.getElementById('root');
                return root ? root.contains(node) : false;
            } },
            React.createElement("div", { className: getClass('elmo-modal', className, (_a = {
                        open: !backButton ? isOpened : true
                    },
                    _a["".concat(type)] = type,
                    _a)), id: this.props.id, "data-testid": "elmo-modal-".concat(id || 'default'), tabIndex: -1 },
                !backButton && React.createElement("div", { className: "elmo-modal__bg", tabIndex: -1 }),
                React.createElement("div", { className: "elmo-modal__content", ref: this.contentRef, role: "dialog", tabIndex: 0, "aria-modal": true, "aria-label": ariaLabel, onKeyDown: this.escFunction },
                    React.createElement(ModalHeader, { icon: icon, title: title, titleSize: "sm", closeModal: this.handleCloseModal, backButton: backButton, primaryButton: type !== 'dialog' ? primaryButton : '' }),
                    React.createElement(ModalBody, { isBackgroundGrey: isBackgroundGrey, modalContent: this.contentRef }, children),
                    React.createElement(ModalFooter, { hasCloseFooterButton: hasCloseFooterButton, closeModal: this.handleCloseModal, primaryButton: primaryButton, closeLabel: closeLabel }))))));
    };
    ModalWrapper.Header = ModalHeader;
    ModalWrapper.Body = ModalBody;
    ModalWrapper.Footer = ModalFooter;
    ModalWrapper.defaultProps = {
        type: 'medium',
        isOpened: false,
        hasCloseFooterButton: true,
    };
    return ModalWrapper;
}(Component));
export default ModalWrapper;
