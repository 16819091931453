// tslint:disable max-line-length
import React from 'react';

function Goal(): React.ReactElement {
  return (
    <svg className="elmo-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
      <path d="M2 11.9979C2 6.45277 6.45801 2 12.0021 2C17.5463 2 22.0021 6.4592 22 12.0021C21.9979 17.5472 17.5398 22 11.9979 22C6.45373 21.9979 2 17.5408 2 11.9979ZM5.02057 12.0171C5.03556 15.8466 8.16538 18.9715 11.9914 18.9801C15.8282 18.9886 18.9944 15.8167 18.9794 11.9829C18.9644 8.15335 15.8346 5.02849 12.0086 5.01992C8.16967 5.01135 5.00557 8.1812 5.02057 12.0171ZM8.74904 12.0128C8.74262 10.2287 10.2186 8.74658 12.001 8.74658V8.74872C13.7833 8.74658 15.2593 10.2287 15.2529 12.0128C15.2465 13.7948 13.7598 15.2662 11.9753 15.2512C10.2122 15.2362 8.75761 13.7755 8.74904 12.0128Z" />
    </svg>
  );
}

export default Goal;
