import MyContributions from '.';
import { setPerformanceBasePath } from '../../lib/route';

export const MyContributionsPath = setPerformanceBasePath('/my-contributions');
const MyContributionsRoutes: any = {
  path: MyContributionsPath,
  component: MyContributions,
  exact: true,
};

export type MyContributionsType = typeof MyContributionsRoutes;

export default MyContributionsRoutes;
