import React from 'react';
import './Divider.scss';
function Divider(_a) {
    var id = _a.id, role = _a.role, ariaOrientation = _a.ariaOrientation;
    return (React.createElement("div", { id: id, "data-testid": "elmo-divider-".concat(id || 'default'), className: "elmo-divider", role: role, "aria-orientation": ariaOrientation }));
}
Divider.defaultProps = {
    role: 'separator',
    ariaOrientation: 'horizontal',
};
export default Divider;
