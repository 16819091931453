import React from 'react';
import { Layout as ElmoLayout } from 'elmo-elements';

/**
 * <Dev.Note>
 *   Do not extend from a component. In react we mainly use a composition pattern.
 *   For example in this case we are creating a new Layout component that is composed of the
 *   main ELMO Layout. By doing this we are creating a wrapper around ELMO layout so if later
 *   we want to customize the entire app layout the process is easier.
 *
 *   Note that you dont need to make wrapper around all ELMO UI components, for example you
 *   can still use a Button component directly inside your components
 * </Dev.Note>
 * @param param
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Layout = ({ children, ...rest }: any): React.ReactElement => (
  <ElmoLayout {...rest}>{children}</ElmoLayout>
);

Layout.Header = ({ children }: any): React.ReactElement => (
  <ElmoLayout.Header>{children}</ElmoLayout.Header>
);

Layout.Content = ({ children }: any): React.ReactElement => (
  <ElmoLayout.Content>{children}</ElmoLayout.Content>
);

/**
 * <Dev.Note>
 *   You can even add some extra functionality to your own custom Layout UI component
 * </Dev.Note>
 *
 * @param param
 */
Layout.Footer = ({ children }: any): React.ReactElement => <div>{children}</div>;

export default Layout;
