import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest, cancelled } from 'redux-saga/effects';
import apis from './api';
import {
  getAppraisals,
  getAppraisalsSuccess,
  getAppraisalsFailure,
  getAppraisalSuccess,
  getAppraisalFailure,
  getAppraisal,
  getAssignees,
  getAssigneesSuccess,
  getAssigneesFailure,
  assignAppraisal,
  assignAppraisalAccepted,
  assignAppraisalFailure,
  getEmployees,
  getEmployeesSuccess,
  getEmployeesFailure,
} from './actions';

export const getAppraisalsSaga = function* (action: Record<string, any>): SagaIterator {
  const { get, cancel } = apis.getAppraisals(action.payload);
  try {
    const data = yield call(() => get);
    yield put(getAppraisalsSuccess(data));
  } catch (error) {
    yield put(getAppraisalsFailure(error));
  } finally {
    if (yield cancelled()) {
      // if saga task is cancelled, we cancel the api request
      cancel && cancel('Request cancelled');
    }
  }
};

export const getAppraisalSaga = function* (action: Record<string, any>): SagaIterator {
  const { get, cancel } = apis.getAppraisal(action.payload);
  try {
    const data = yield call(() => get);
    yield put(getAppraisalSuccess(data));
  } catch (error) {
    yield put(getAppraisalFailure(error));
  } finally {
    if (yield cancelled()) {
      // if saga task is cancelled, we cancel the api request
      cancel && cancel('Request cancelled');
    }
  }
};

export const getAssigneesSaga = function* (action: Record<string, any>): SagaIterator {
  const { appraisalId, query } = action.payload;
  const { get, cancel } = apis.getAppraisalPerformances(appraisalId, query);
  try {
    const data = yield call(() => get);
    yield put(getAssigneesSuccess(data));
  } catch (error) {
    yield put(getAssigneesFailure(error));
  } finally {
    if (yield cancelled()) {
      // if saga task is cancelled, we cancel the api request
      cancel && cancel('Request cancelled');
    }
  }
};

export const assignAppraisalSaga = function* (action: Record<string, any>): SagaIterator {
  const { payload } = action;
  try {
    const data = yield call(apis.assign, payload);
    yield put(assignAppraisalAccepted(data));
  } catch (error) {
    yield put(assignAppraisalFailure(error));
  }
};

export const getEmployeesSaga = function* (action: Record<string, any>): SagaIterator {
  const { appraisalId, query } = action.payload;
  const { get, cancel } = apis.getEmployees(appraisalId, query);
  try {
    const data = yield call(() => get);
    yield put(getEmployeesSuccess(data));
  } catch (error) {
    yield put(getEmployeesFailure(error));
  } finally {
    if (yield cancelled()) {
      // if saga task is cancelled, we cancel the api request
      cancel && cancel('Request cancelled');
    }
  }
};

/**
 * AssignAppraisal Sagas
 */
export default function* root(): SagaIterator {
  yield all([
    takeLatest(getAppraisals, getAppraisalsSaga),
    takeLatest(getAppraisal, getAppraisalSaga),
    takeLatest(getAssignees, getAssigneesSaga),
    takeLatest(assignAppraisal, assignAppraisalSaga),
    takeLatest(getEmployees, getEmployeesSaga),
  ]);
}
