import { FilterStatus } from 'elmo-elements/dist/Filter';
import { messages, t } from '../../../lib/translation';
import {
  AbstractItemPaginator,
  ConversationsQuery,
  ConversationsState,
  DiscussionItemsQueryType,
  NoteQuery,
  UserSearchQueryType,
} from '../type';

export const defaultConversationQuery: ConversationsQuery = {
  page: 1,
  itemsPerPage: 30,
  visibility: true,
};

export const defaultDataPaginator: AbstractItemPaginator = {
  data: [],
  meta: {
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 30,
  },
};

export const defaultUserSearchQueryState: UserSearchQueryType = {
  text: '',
  page: 1,
  itemsPerPage: 10,
};
export const defaultDiscussionItemsQuery: DiscussionItemsQueryType = {
  conversationId: null,
  isCompleted: false,
  page: 1,
  itemsPerPage: 10,
};

export const defaultCompletedItemsQuery: DiscussionItemsQueryType = {
  conversationId: null,
  isCompleted: true,
  page: 1,
  itemsPerPage: 10,
  order: {
    modifiedAt: 'desc',
  },
};

export const defaultNoteQuery: NoteQuery = {
  discussionItemId: null,
  order: {
    createdAt: 'asc',
  },
  pagination: false,
};

export const defaultConversationsState: ConversationsState = {
  list: {
    isLoaded: false,
    isLoading: false,
    isVisibilityUpdated: false,
    paginator: defaultDataPaginator,
    error: undefined,
    query: { ...defaultConversationQuery },
  },
  detail: {
    isLoaded: false,
    isLoading: false,
    data: undefined,
    error: undefined,
  },
  userSearch: {
    isLoaded: false,
    isLoading: false,
    data: undefined,
    error: undefined,
  },
  conversationCreation: {
    isLoaded: false,
    isLoading: false,
    data: undefined,
    error: undefined,
  },
  discussionItems: {
    isLoaded: false,
    isLoading: false,
    hasCompletedItem: false,
    hasDeletedItem: false,
    paginator: defaultDataPaginator,
    error: undefined,
    query: { ...defaultDiscussionItemsQuery },
  },
  discussionItemCreation: {
    isLoaded: false,
    isLoading: false,
    data: undefined,
    error: undefined,
  },
  linkedDiscussionItemCreations: [],
  discussionItemUpdate: {
    isLoaded: false,
    isLoading: false,
    error: undefined,
  },
  discussionItemsNotes: [],
  completedItems: {
    isLoaded: false,
    isLoading: false,
    hasDeletedItem: false,
    paginator: defaultDataPaginator,
    error: undefined,
    query: { ...defaultCompletedItemsQuery },
    hasRestoredItem: false,
  },
  discussionItemNoteUpdate: {
    isLoaded: false,
    isLoading: false,
    error: undefined,
  },
};

export const ConversationVisibilityFilterOptions: FilterStatus[] = [
  {
    value: 'true',
    label: t(messages.conversations.visibilityFilter.active),
  },
  {
    value: 'false',
    label: t(messages.conversations.visibilityFilter.closed),
  },
];

export default { ...defaultConversationsState };
