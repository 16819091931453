var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import classNames from 'classnames';
import { css } from 'styled-components';
import { breakpoints, BreakpointNames } from '../style/config';
import logger from '../logger';
import { Children } from 'react';
/**
 * Make a BEM string based on props
 *
 * @param  {string} name Base class.
 * @param  {Object<string, any>} classes Component classes.
 * @param  {Object<string, any>} modifiers Component extra class modifiers passing as props.
 * @return {string} BEM class string.
 */
export function getClass(name, classes, modifiers) {
    var modifierClasses = {};
    for (var key in modifiers) {
        if (key === 'className') {
            modifierClasses["".concat(modifiers[key])] = true;
        }
        else if (modifiers[key]) {
            modifierClasses["".concat(name, "--").concat(key)] = modifiers[key];
        }
    }
    return classNames(name, classes, modifierClasses);
}
/**
 * Get a maped value from map object
 *
 * @param {*} key
 * @param {*} defaultKey
 * @param {*} map
 */
export function mapProp(map, key, defaultKey) {
    return map[key] || map[defaultKey];
}
/**
 * @param size
 * @api
 */
export function getSize(size) {
    return function (arg) { return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    @media (min-width: ", "px) {\n      ", "\n    }\n  "], ["\n    @media (min-width: ", "px) {\n      ", "\n    }\n  "])), size, arg); };
}
/**
 *
 * Media query function for styled-components
 */
export var mediaBreakpointUp = {
    xs: getSize(breakpoints.xs.size),
    sm: getSize(breakpoints.sm.size),
    md: getSize(breakpoints.md.size),
    lg: getSize(breakpoints.lg.size),
    xl: getSize(breakpoints.xl.size),
    xxl: getSize(breakpoints.xxl.size),
};
/**
 * TODO: this function only delete the first item that has been found in the list
 *
 * Remove an item from array by searching string
 *
 * @param Array<string> items
 * @param {string} value
 */
export function removeArrayItem(items, value) {
    var index = items.indexOf(value);
    if (index === -1) {
        return;
    }
    items.splice(index, 1);
}
/**
 * Remove a classname from an element
 *
 * @param {HTMLElement} element
 * @param {string} classname
 */
export function removeClassToElement(element, className) {
    // eslint-disable-next-line  no-shadow,@typescript-eslint/no-shadow
    var classNames = '';
    var classNameArray = [];
    classNames = element.getAttribute('class');
    if (classNames !== null && classNames.search(' ') >= 0) {
        classNameArray = classNames.split(' ');
    }
    removeArrayItem(classNameArray, className);
    element.setAttribute('class', classNameArray.join(' '));
}
/**
 * Add a classname to an element
 *
 * @param {HTMLElement} element
 * @param {string} classname
 */
export function addClassToElement(element, className) {
    // eslint-disable-next-line  no-shadow, @typescript-eslint/no-shadow
    var classNames = '';
    var classNameArray = [];
    classNames = element.getAttribute('class');
    if (classNames !== null && classNames.search(' ') >= 0) {
        classNameArray = classNames.split(' ');
    }
    classNameArray.push(className);
    Array.from(new Set(classNameArray.map(function (item) { return item.id; })));
    element.setAttribute('class', classNameArray.join(' '));
}
/**
 * Default noop function
 *
 * @param e
 */
export function noop(e) {
    return;
}
/** Used to generate unique IDs. */
var idCounter = {};
/**
 * Source: Lodash
 *
 * Generates a unique ID. If `prefix` is given, the ID is appended to it.
 *
 * @since 0.1.0
 * @category Util
 * @param {string} [prefix=''] The value to prefix the ID with.
 * @returns {string} Returns the unique ID.
 * @see random
 * @example
 *
 * uniqueId('contact_')
 * // => 'contact_104'
 *
 * uniqueId()
 * // => '105'
 */
export function uniqueId(prefix) {
    if (prefix === void 0) { prefix = '$elmo$'; }
    if (!idCounter[prefix]) {
        idCounter[prefix] = 0;
    }
    var id = ++idCounter[prefix];
    if (prefix === '$elmo$') {
        return "".concat(id);
    }
    return "".concat(prefix + id);
}
/**
 * Source: https://medium.com/@TCAS3/debounce-deep-dive-javascript-es6-e6f8d983b7a1
 *
 * @param delay
 * @param fn should be only an sync function
 */
export function debounce(fn, delay) {
    var timerId;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(function () {
            fn.apply(void 0, args);
            timerId = null;
        }, delay);
    };
}
export function isElementOfType(element, component) {
    return (element &&
        element.type &&
        component &&
        element.type.displayName === component.displayName);
}
export function screensizeToBreakpoint() {
    // Please check all components that is related to this function
    // before making any change.
    var screenSize = window.innerWidth;
    if (screenSize < breakpoints.xs.size) {
        return BreakpointNames.xs;
    }
    else if (screenSize < breakpoints.sm.size) {
        return BreakpointNames.sm;
    }
    else if (screenSize < breakpoints.md.size) {
        return BreakpointNames.md;
    }
    else if (screenSize < breakpoints.lg.size) {
        return BreakpointNames.lg;
    }
    return BreakpointNames.xxl;
}
export function isScreenDesktop() {
    var screenSize = window.innerWidth;
    if (screenSize < breakpoints.lg.size) {
        return false;
    }
    return true;
}
/** function that locks scroll by adding a classname in HTML tag */
export function scrollLock(isLock) {
    var htmlTag = document.querySelector('html');
    if (htmlTag) {
        if (isLock) {
            htmlTag.classList.add('elmo-noscroll');
        }
        else {
            htmlTag.classList.remove('elmo-noscroll');
        }
    }
}
/** function that returns current scroll position */
export function getScrollY() {
    return window.pageYOffset;
}
/** function that sets scroll position manually */
export function setScrollY(scrollY) {
    if (document.scrollingElement) {
        document.scrollingElement.scrollTop = scrollY !== undefined ? scrollY : 0;
    }
}
/** function that adds a classname ”isIOS” in the HTML tag */
export function classOSinHTML() {
    if (navigator &&
        navigator.userAgent &&
        navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        var htmlTag = document.querySelector('html');
        if (htmlTag) {
            htmlTag.classList.add('isIOS');
        }
    }
}
/**
 * Get a random item from the given array
 *
 * @param items
 */
export function getRandomItem(items) {
    var index = Math.floor(Math.random() * items.length);
    return items[index];
}
/**
 * Get data from Localstorage
 */
export function getFromStorage(key, format) {
    if (format === void 0) { format = 'string'; }
    try {
        var localStorageData = localStorage.getItem(key);
        if (localStorageData) {
            if (format === 'string') {
                return localStorageData;
            }
            if (format === 'object') {
                try {
                    var localStorageDataObject = JSON.parse(localStorageData);
                    return localStorageDataObject;
                }
                catch (e) {
                    return false;
                }
            }
        }
        return false;
    }
    catch (error) {
        logger.error(error);
    }
    return false;
}
/**
 * Set data to Localstorage
 */
export function setToStorage(key, data, format) {
    if (format === void 0) { format = 'string'; }
    try {
        if (localStorage) {
            if (format === 'string') {
                localStorage.setItem(key, data);
            }
            if (format === 'object') {
                localStorage.setItem(key, JSON.stringify(data));
            }
        }
    }
    catch (error) {
        logger.error(error);
    }
}
export function isMobileDevice() {
    try {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    catch (e) {
        return false;
    }
}
/**
 * Number formatter for Badge on the Button
 */
export function abbreviateNumber(badgeNumber) {
    var SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
    // eslint-disable-next-line  no-bitwise
    var tier = (Math.log10(badgeNumber) / 3) | 0;
    if (badgeNumber < 10000) {
        return badgeNumber.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);
    var scaled = badgeNumber / scale;
    var str = scaled.toString().substr(0, 3);
    if (str.substr(str.length - 1, 1) === '.') {
        str = str.substr(0, str.length - 1);
    }
    return str + suffix + '+';
}
/**
 * Check if two arrays are equal based on key comparison
 *
 * @param children1
 * @param children2
 */
export function childrenIsEqual(children1, children2) {
    if (Children.count(children1) !== Children.count(children2)) {
        return false;
    }
    var children1Arr = Children.toArray(children1);
    var children2Arr = Children.toArray(children2);
    for (var i = 0; i < children1Arr.length; i++) {
        if (children1Arr[i].key !== children2Arr[i].key) {
            return false;
        }
    }
    return true;
}
/**
 * Find Closest Element by ID
 */
export function closestParentById(el, id) {
    var element = el;
    while (element.id !== id) {
        element = element.parentNode;
        if (!element) {
            return null;
        }
    }
    return element;
}
export function waitFor(ms) {
    return new Promise(function (resolve) {
        setTimeout(resolve, ms);
    });
}
var templateObject_1;
