var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { Component } from 'react';
import { ButtonUpload } from './component/ButtonUpload';
import { FileSuccess } from './component/FileSuccess';
import { FileReject } from './component/FileReject';
import { DropArea } from './component/DropArea';
import Dropzone from 'react-dropzone';
import './FileUpload.scss';
var FileUpload = /** @class */ (function (_super) {
    __extends(FileUpload, _super);
    function FileUpload(props) {
        var _this = _super.call(this, props) || this;
        _this.fileMaxSize = 2;
        _this.onDrop = function (acceptedFiles, rejectedFiles) {
            var isMulti = _this.props.isMulti;
            var data = {};
            var files = [];
            var rejected = [];
            if (isMulti) {
                files = __spreadArray([], _this.state.files, true);
                rejected = __spreadArray([], _this.state.rejectFiles, true);
            }
            if (acceptedFiles && acceptedFiles.length) {
                Object.assign(data, {
                    files: acceptedFiles.concat(files),
                });
            }
            if (rejectedFiles && rejectedFiles.length) {
                Object.assign(data, {
                    rejectFiles: rejectedFiles.concat(rejected),
                });
            }
            _this.setState(data);
            var onUploadStart = _this.props.onUploadStart;
            if (onUploadStart) {
                onUploadStart(acceptedFiles);
            }
        };
        _this.onDragEnter = function () {
            var onDragEnter = _this.props.onDragEnter;
            if (onDragEnter) {
                onDragEnter();
            }
        };
        _this.onDragLeave = function () {
            var onDragLeave = _this.props.onDragLeave;
            if (onDragLeave) {
                onDragLeave();
            }
        };
        _this.onDragOver = function () {
            var onDragOver = _this.props.onDragOver;
            if (onDragOver) {
                onDragOver();
            }
        };
        _this.onFileDialogCancel = function () {
            var onFileDialogCancel = _this.props.onFileDialogCancel;
            if (onFileDialogCancel) {
                onFileDialogCancel();
            }
        };
        _this.onDropAccepted = function (files) {
            var onDropAccepted = _this.props.onDropAccepted;
            if (onDropAccepted) {
                onDropAccepted(files);
            }
        };
        _this.onDropRejected = function (files) {
            var onDropRejected = _this.props.onDropRejected;
            if (onDropRejected) {
                onDropRejected(files);
            }
            _this.setState({
                displayError: true,
            });
        };
        // onKeyDown = (): void => {
        //   const { onKeyDown } = this.props;
        //   if (onKeyDown) {
        //     onKeyDown();
        //   }
        // };
        _this.preventDropOnDocument = function () {
            var preventDropOnDocument = _this.props.preventDropOnDocument;
            if (preventDropOnDocument) {
                preventDropOnDocument();
            }
        };
        var fileMaxSize = _this.props.fileMaxSize;
        if (fileMaxSize) {
            _this.maxSize = fileMaxSize;
        }
        _this.state = {
            files: [],
            rejectFiles: [],
            displayError: false,
        };
        return _this;
    }
    Object.defineProperty(FileUpload.prototype, "maxSize", {
        get: function () {
            return 1024 * 1024 * this.fileMaxSize;
        },
        set: function (value) {
            this.fileMaxSize = value;
        },
        enumerable: false,
        configurable: true
    });
    FileUpload.prototype.onCancel = function () {
        this.setState({
            files: [],
        });
    };
    FileUpload.prototype.handleDeleteFile = function (file) {
        var files = __spreadArray([], this.state.files, true);
        this.setState({
            files: files.filter(function (f) { return f.name !== file.name; }),
        });
        var handleFileDelete = this.props.handleFileDelete;
        if (handleFileDelete) {
            handleFileDelete(file);
        }
    };
    FileUpload.prototype.hadnleDeleteRejectedFile = function (file) {
        var rejectFiles = __spreadArray([], this.state.rejectFiles, true);
        this.setState({
            rejectFiles: rejectFiles.filter(function (f) { return f.name !== file.name; }),
        });
        var handleFileDelete = this.props.handleFileDelete;
        if (handleFileDelete) {
            handleFileDelete(file);
        }
    };
    FileUpload.prototype.render = function () {
        var _this = this;
        var _a = this.state, files = _a.files, rejectFiles = _a.rejectFiles;
        var _b = this.props, isMulti = _b.isMulti, displayFiles = _b.displayFiles, displayRejectedFiles = _b.displayRejectedFiles, displayDropArea = _b.displayDropArea, acceptedFiles = _b.acceptedFiles, dropareaText = _b.dropareaText, draggingText = _b.draggingText, id = _b.id;
        return (React.createElement("div", { id: id, "data-testid": "elmo-file-upload-".concat(id || 'default'), className: "elmo-file-upload-wrapper" },
            React.createElement(Dropzone, { onDrop: this.onDrop, maxSize: this.maxSize, multiple: isMulti, accept: acceptedFiles.join(', '), onDragEnter: this.onDragEnter, onDragLeave: this.onDragLeave, onDragOver: this.onDragOver, onFileDialogCancel: this.onFileDialogCancel, onDropAccepted: this.onDropAccepted, onDropRejected: this.onDropRejected }, function (_a) {
                var getRootProps = _a.getRootProps, getInputProps = _a.getInputProps, isDragActive = _a.isDragActive, open = _a.open;
                return (React.createElement(React.Fragment, null,
                    rejectFiles && displayFiles && displayRejectedFiles && (React.createElement("div", { className: "elmo-fileupload-files" }, rejectFiles.map(function (file, i) { return (React.createElement(FileReject, { key: "reject-file-".concat(i), name: file.name, handleDelete: _this.hadnleDeleteRejectedFile.bind(_this, file) })); }))),
                    files && displayFiles && (React.createElement("div", { className: "elmo-fileupload-files" }, files.map(function (file, i) { return (React.createElement(FileSuccess, { key: "file-".concat(i), name: file.name, handleDelete: _this.handleDeleteFile.bind(_this, file) })); }))),
                    displayDropArea && (React.createElement(DropArea, { isDragActive: isDragActive, getInputProps: getInputProps, getRootProps: getRootProps, dropareaText: dropareaText, draggingText: draggingText })),
                    !displayDropArea && (React.createElement(ButtonUpload, { getInputProps: getInputProps, getRootProps: getRootProps, open: open }))));
            })));
    };
    FileUpload.defaultProps = {
        displayDropArea: true,
        displayFiles: true,
        displayRejectedFiles: true,
        isMulti: true,
        disabled: false,
        fileMaxSize: 2,
        acceptedFiles: ['image/*', '.pdf'],
        dropareaText: React.createElement("p", null, "Drop files here, or click to select files to upload."),
        draggingText: 'Drop files here',
    };
    return FileUpload;
}(Component));
export default FileUpload;
