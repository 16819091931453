/* eslint-disable camelcase */
import jwt_decode from 'jwt-decode';

export const setToken = (token: string): void => {
  sessionStorage.setItem('token', token);
};

export const removeToken = (): void => {
  sessionStorage.removeItem('token');
};

export const decodeToken = (token: string): any => jwt_decode(token);

export const isTokenValid = (token: string): boolean => {
  try {
    jwt_decode(token);
  } catch (e) {
    removeToken();
    return false;
  }

  return true;
};

export const getToken = (): string | null => {
  const token = sessionStorage.getItem('token');

  if (token !== null && isTokenValid(token)) {
    return token;
  }

  return null;
};

export const isTokenExpired = (token: string): boolean => {
  const currentTime = Date.now() / 1000;
  const jwt = decodeToken(token);

  return jwt.exp < currentTime;
};
