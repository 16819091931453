import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest, cancelled } from 'redux-saga/effects';
import { getMyPerformances, getMyPerformancesSuccess, getMyPerformancesFailure } from './actions';
import apis from './api';

/**
 * Get Performances
 * <Dev.Note>
 *  Here you should just orchestrate the redux actions. It is better to abstract any API call or
 *  any side effect action in a separate function such as api.getPerformances
 * </Dev.Note>
 */
export const getMyPerformancesSaga = function* (action: Record<string, any>): SagaIterator {
  // the get my performance will return a get promise and a api cancellation function
  const { get, cancel } = apis.getMyPerformances(action.payload);
  try {
    const data = yield call(() => get);
    yield put(getMyPerformancesSuccess(data));
  } catch (error) {
    yield put(getMyPerformancesFailure(error));
  } finally {
    if (yield cancelled()) {
      // if saga task is cancelled, we cancel the api request
      cancel && cancel('Request cancelled');
    }
  }
};
/**
 * Performances Sagas
 */
export default function* root(): SagaIterator {
  yield all([takeLatest(getMyPerformances, getMyPerformancesSaga)]);
}
