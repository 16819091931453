import httpClient from '../../../lib/api';
import { ApprovalPerformancesQuery, ApprovalPerformancesApiInterface } from '../type';
import { CancellableGetType } from '../../../lib/api/type';

export const resources: any = {
  performances: {
    url: '/performance-api/v1/approval-performances',
  },
};
export class ApprovalPerformancesApi implements ApprovalPerformancesApiInterface {
  getApprovalPerformances(
    approvalPerformancesQuery: ApprovalPerformancesQuery
  ): CancellableGetType {
    const { get, cancel } = httpClient.cancellableGet(resources.performances.url, {
      params: {
        page: approvalPerformancesQuery.page,
        limit: approvalPerformancesQuery.limit,
        sort: approvalPerformancesQuery.sort || undefined,
        keyword: approvalPerformancesQuery.keyword || undefined,
      },
    });
    const promise = get
      .then(result =>
        Promise.resolve({
          total: result.headers['x-pagination-count'] || 0,
          page: result.headers['x-pagination-page'] || approvalPerformancesQuery.page,
          limit: result.headers['x-pagination-limit'] || approvalPerformancesQuery.limit,
          data: result.data || [],
        })
      )
      .catch((error: Error) => {
        // if the error is cancelled request, we don't need to handle it
        if (error.message === 'Request cancelled') {
          return undefined;
        }
        return Promise.reject(error);
      });
    return {
      get: promise,
      cancel,
    };
  }
}

export default new ApprovalPerformancesApi();
