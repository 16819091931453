import React from 'react';
import MessageLayoutView from './view';

const MessageLayoutContainer = ({
  title,
  content,
}: {
  title: string;
  content: string;
}): React.ReactElement => <MessageLayoutView title={title} content={content} />;

export default MessageLayoutContainer;
