import moment, { unitOfTime } from 'moment';
import { DATE_FORMAT_D_MMM_YYYY } from '../const/index';

export function dateFormat(
  date: moment.MomentInput,
  format = DATE_FORMAT_D_MMM_YYYY,
  isUnixTimestamp = false
): string {
  const dateToFormat = isUnixTimestamp && typeof date === 'number' ? moment.unix(date) : date;
  return moment(dateToFormat).format(format);
}

export function dateDiff(
  date1: moment.MomentInput,
  date2: moment.MomentInput,
  format?: unitOfTime.Diff
): number {
  const date1Moment = moment(date1);
  const date2Moment = moment(date2);
  return date1Moment.diff(date2Moment, format);
}
