var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import MenuIcon from './MenuIcon';
import { StyledLink, EmptyIconPlaceholder } from '../Styles';
var MenuLink = /** @class */ (function (_super) {
    __extends(MenuLink, _super);
    function MenuLink() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleLinkClick = function (event, menuItem) {
            var _a = _this.props, handleClick = _a.handleClick, onCloseMenuMobile = _a.onCloseMenuMobile;
            if (onCloseMenuMobile) {
                onCloseMenuMobile();
            }
            if (handleClick) {
                event.preventDefault();
                handleClick(event, menuItem);
            }
            return;
        };
        return _this;
    }
    MenuLink.prototype.render = function () {
        var _this = this;
        var _a = this.props, title = _a.title, url = _a.url, icon = _a.icon, isSelected = _a.isSelected, isSubMenu = _a.isSubMenu, menuFontColor = _a.menuFontColor, menuHoverFontColor = _a.menuHoverFontColor;
        // create test id based on the menu title
        var menuItemTestId = title
            .toLocaleLowerCase()
            .replace(/ /g, '-')
            .replace(/,/, '');
        return (React.createElement("li", __assign({}, (isSelected === true ? { className: 'is-selected' } : {})),
            React.createElement(StyledLink, { onClick: function (event) {
                    _this.handleLinkClick(event, { title: title, url: url, icon: icon, isSelected: isSelected });
                }, href: url, "data-testid": "elmo-menu-".concat(menuItemTestId), className: "menuitem-link", hoverFontColor: menuHoverFontColor, fontColor: menuFontColor, isSubMenu: isSubMenu, isSelected: isSelected, title: title },
                icon ? React.createElement(MenuIcon, { icon: icon }) : React.createElement(EmptyIconPlaceholder, null),
                React.createElement("span", { className: "menuitem-link__label" }, title))));
    };
    return MenuLink;
}(Component));
export default MenuLink;
