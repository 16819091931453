import { createReducer } from 'redux-act';
import cloneDeep from 'lodash/cloneDeep';
import * as actions from './actions';
import defaultState, { defaultConversationsState, defaultDataPaginator } from './state';
import {
  CompletedItemsPaginator,
  CompletedItemType,
  ConversationCompletedItemsState,
  ConversationDiscussionItemsState,
  ConversationsPaginator,
  ConversationsState,
  ConversationType,
  LinkedDiscussionItemCreationState,
  DiscussionItemNotesData,
  DiscussionItemNotesError,
  DiscussionItemNotesState,
  DiscussionItemPayload,
  DiscussionItemsPaginator,
  DiscussionItemsQueryType,
  DiscussionItemType,
  NoteCreationError,
  NoteDeletionError,
  NoteDeletionPayload,
  NotePayload,
  NoteQuery,
} from '../type';
import { UsersPaginator } from '../../../lib/user/type';
import NoteControls from '../../../lib/notes';

export function getConversationsReducer(state: ConversationsState): ConversationsState {
  const list = { ...state.list };
  list.isLoading = true;
  list.isLoaded = false;

  return { ...state, list };
}

export function getConversationsSuccessReducer(
  state: ConversationsState,
  paginator: ConversationsPaginator
): ConversationsState {
  const list = { ...state.list };
  list.isLoaded = true;
  list.isLoading = false;
  list.paginator = paginator;
  list.error = undefined;

  return { ...state, list };
}

export function getConversationsFailureReducer(
  state: ConversationsState,
  error: Error
): ConversationsState {
  const list = { ...state.list };
  list.isLoaded = true;
  list.isLoading = false;
  list.error = error;

  return { ...state, list };
}

export function searchUsersReducer(state: ConversationsState): ConversationsState {
  const userSearch = { ...state.userSearch };
  userSearch.isLoading = true;
  userSearch.isLoaded = false;

  return { ...state, userSearch };
}

export function searchUsersSuccessReducer(
  state: ConversationsState,
  data: UsersPaginator
): ConversationsState {
  const userSearch = { ...state.userSearch };
  userSearch.isLoaded = true;
  userSearch.isLoading = false;
  if (data.meta.currentPage > 1 && userSearch.data) {
    userSearch.data.data = [...userSearch.data.data, ...data.data];
    userSearch.data.meta = data.meta;
  } else {
    userSearch.data = data;
  }
  userSearch.error = undefined;

  return { ...state, userSearch };
}

export function searchUsersFailureReducer(
  state: ConversationsState,
  error: Error
): ConversationsState {
  const userSearch = { ...state.userSearch };
  userSearch.isLoaded = true;
  userSearch.isLoading = false;
  userSearch.error = error;

  return { ...state, userSearch };
}

export function resetSearchUsersReducer(state: ConversationsState): ConversationsState {
  return { ...state, userSearch: defaultConversationsState.userSearch };
}

export function createConversationReducer(state: ConversationsState): ConversationsState {
  state.conversationCreation.isLoading = true;

  return state;
}

export function createConversationSuccessReducer(
  state: ConversationsState,
  data: ConversationType
): ConversationsState {
  const conversationCreation = { ...state.conversationCreation };
  conversationCreation.isLoading = false;
  conversationCreation.isLoaded = true;
  conversationCreation.data = data;

  return { ...state, conversationCreation };
}

export function createConversationFailureReducer(
  state: ConversationsState,
  error: Error
): ConversationsState {
  const conversationCreation = { ...state.conversationCreation };
  conversationCreation.isLoaded = true;
  conversationCreation.isLoading = false;
  conversationCreation.error = error;

  return { ...state, conversationCreation };
}

export function updateConversationVisibilityReducer(state: ConversationsState): ConversationsState {
  const list = { ...state.list };
  list.isLoading = true;
  list.isLoaded = false;
  list.isVisibilityUpdated = false;

  return { ...state, list };
}

export function updateConversationVisibilitySuccessReducer(
  state: ConversationsState
): ConversationsState {
  const list = { ...state.list };
  list.isLoading = false;
  list.isLoaded = true;
  list.isVisibilityUpdated = true;

  return { ...state, list };
}

export function updateConversationVisibilityFailureReducer(
  state: ConversationsState,
  error: Error
): ConversationsState {
  const list = { ...state.list };
  list.isLoading = false;
  list.isLoaded = true;
  list.isVisibilityUpdated = false;
  list.error = error;

  return { ...state, list };
}

export function getConversationDetailsReducer(state: ConversationsState): ConversationsState {
  const detail = { ...state.detail };
  detail.isLoading = true;
  detail.isLoaded = false;

  return { ...state, detail };
}

export function getConversationDetailsSuccessReducer(
  state: ConversationsState,
  data: ConversationType
): ConversationsState {
  const detail = { ...state.detail };
  detail.isLoading = false;
  detail.isLoaded = true;
  detail.data = data;

  return { ...state, detail };
}

export function getConversationDetailsFailureReducer(
  state: ConversationsState,
  error: Error
): ConversationsState {
  const detail = { ...state.detail };
  detail.isLoaded = true;
  detail.isLoading = false;
  detail.error = error;

  return { ...state, detail };
}

export function getDiscussionItemsReducer(
  state: ConversationsState,
  query: DiscussionItemsQueryType
): ConversationsState {
  const list = { ...state.discussionItems };
  list.isLoading = true;
  list.isLoaded = false;
  list.hasDeletedItem = false;
  list.query = {
    ...list.query,
    conversationId: query.conversationId,
  };

  return { ...state, discussionItems: list };
}

export function getDiscussionItemsSuccessReducer(
  state: ConversationsState,
  paginator: DiscussionItemsPaginator
): ConversationsState {
  const list = { ...state.discussionItems };
  list.isLoaded = true;
  list.isLoading = false;
  list.hasDeletedItem = false;
  list.paginator = paginator;
  list.error = undefined;

  return { ...state, discussionItems: list };
}

export function getDiscussionItemsFailureReducer(
  state: ConversationsState,
  error: Error
): ConversationsState {
  const list = { ...state.discussionItems };
  list.isLoaded = true;
  list.isLoading = false;
  list.hasDeletedItem = false;
  list.error = error;

  return { ...state, discussionItems: list };
}

export function createDiscussionItemReducer(
  state: ConversationsState,
  payload: DiscussionItemPayload
): ConversationsState {
  const { performanceItem } = payload;
  if (performanceItem !== undefined) {
    const linkedDiscussionItemCreations = [
      ...state.linkedDiscussionItemCreations,
      {
        isLoading: true,
        isLoaded: false,
        performanceId: performanceItem.performanceId,
        sectionId: performanceItem.sectionId,
        sectionItemId: performanceItem.sectionItemId,
      },
    ];
    return { ...state, linkedDiscussionItemCreations };
  }

  return {
    ...state,
    discussionItemCreation: {
      ...state.discussionItemCreation,
      isLoading: true,
      isLoaded: false,
    },
  };
}

export function createDiscussionItemSuccessReducer(
  state: ConversationsState,
  { payload, data }: { payload: DiscussionItemPayload; data: DiscussionItemType }
): ConversationsState {
  const { performanceItem } = payload;
  if (performanceItem !== undefined) {
    let linkedDiscussionItemCreations: LinkedDiscussionItemCreationState[] = [];

    if (performanceItem.type === 'performance') {
      linkedDiscussionItemCreations = state.linkedDiscussionItemCreations.map(
        (item): LinkedDiscussionItemCreationState =>
          item.performanceId === performanceItem.performanceId &&
          !item.sectionId &&
          !item.sectionItemId
            ? { ...item, isLoading: false, isLoaded: true }
            : item
      );
    } else if (performanceItem.type === 'section') {
      linkedDiscussionItemCreations = state.linkedDiscussionItemCreations.map(
        (item): LinkedDiscussionItemCreationState =>
          item.performanceId === performanceItem.performanceId &&
          item.sectionId === performanceItem.sectionId &&
          !item.sectionItemId
            ? { ...item, isLoading: false, isLoaded: true }
            : item
      );
    } else if (performanceItem.type === 'section_item') {
      linkedDiscussionItemCreations = state.linkedDiscussionItemCreations.map(
        (item): LinkedDiscussionItemCreationState =>
          item.performanceId === performanceItem.performanceId &&
          item.sectionId === performanceItem.sectionId &&
          item.sectionItemId === performanceItem.sectionItemId
            ? { ...item, isLoading: false, isLoaded: true }
            : item
      );
    }

    return { ...state, linkedDiscussionItemCreations };
  }

  return {
    ...state,
    discussionItemCreation: {
      ...state.discussionItemCreation,
      isLoading: false,
      isLoaded: true,
      data,
    },
  };
}

export function createDiscussionItemFailureReducer(
  state: ConversationsState,
  { payload, error }: { payload: DiscussionItemPayload; error: Error }
): ConversationsState {
  const { performanceItem } = payload;
  if (performanceItem !== undefined) {
    let linkedDiscussionItemCreations: LinkedDiscussionItemCreationState[] = [];

    if (performanceItem.type === 'performance') {
      linkedDiscussionItemCreations = state.linkedDiscussionItemCreations.map(
        (item): LinkedDiscussionItemCreationState =>
          item.performanceId === performanceItem.performanceId &&
          !item.sectionId &&
          !item.sectionItemId
            ? { ...item, isLoading: false, isLoaded: true, error }
            : item
      );
    } else if (performanceItem.type === 'section') {
      linkedDiscussionItemCreations = state.linkedDiscussionItemCreations.map(
        (item): LinkedDiscussionItemCreationState =>
          item.performanceId === performanceItem.performanceId &&
          item.sectionId === performanceItem.sectionId &&
          !item.sectionItemId
            ? { ...item, isLoading: false, isLoaded: true, error }
            : item
      );
    } else if (performanceItem.type === 'section_item') {
      linkedDiscussionItemCreations = state.linkedDiscussionItemCreations.map(
        (item): LinkedDiscussionItemCreationState =>
          item.performanceId === performanceItem.performanceId &&
          item.sectionId === performanceItem.sectionId &&
          item.sectionItemId === performanceItem.sectionItemId
            ? { ...item, isLoading: false, isLoaded: true, error }
            : item
      );
    }

    return { ...state, linkedDiscussionItemCreations };
  }

  return {
    ...state,
    discussionItemCreation: {
      ...state.discussionItemCreation,
      isLoading: false,
      isLoaded: true,
      error,
    },
  };
}

export function updateDiscussionItemReducer(state: ConversationsState): ConversationsState {
  const discussionItemUpdate = { ...state.discussionItemUpdate };
  discussionItemUpdate.isLoading = true;
  discussionItemUpdate.isLoaded = false;

  return { ...state, discussionItemUpdate };
}

export function updateDiscussionItemSuccessReducer(state: ConversationsState): ConversationsState {
  const discussionItemUpdate = { ...state.discussionItemUpdate };
  discussionItemUpdate.isLoading = false;
  discussionItemUpdate.isLoaded = true;

  return { ...state, discussionItemUpdate };
}

export function updateDiscussionItemFailureReducer(
  state: ConversationsState,
  error: Error
): ConversationsState {
  const discussionItemUpdate = { ...state.discussionItemUpdate };
  discussionItemUpdate.isLoading = false;
  discussionItemUpdate.isLoaded = true;
  discussionItemUpdate.error = error;

  return { ...state, discussionItemUpdate };
}

export function completeDiscussionItemReducer(state: ConversationsState): ConversationsState {
  return {
    ...state,
    discussionItems: {
      ...state.discussionItems,
      isLoading: true,
      isLoaded: false,
    },
  };
}

export function completeDiscussionItemSuccessReducer(
  state: ConversationsState
): ConversationsState {
  return {
    ...state,
    discussionItems: {
      ...state.discussionItems,
      hasCompletedItem: true,
      isLoading: false,
      isLoaded: true,
      error: undefined,
    },
  };
}

export function completeDiscussionItemFailureReducer(
  state: ConversationsState,
  error: Error
): ConversationsState {
  return {
    ...state,
    discussionItems: {
      ...state.discussionItems,
      hasCompletedItem: false,
      isLoading: false,
      isLoaded: true,
      error,
    },
  };
}

export function deleteDiscussionItemReducer(state: ConversationsState): ConversationsState {
  const discussionItems = { ...state.discussionItems };
  discussionItems.isLoading = true;
  discussionItems.isLoaded = false;
  discussionItems.hasDeletedItem = false;

  return { ...state, discussionItems };
}

export function deleteDiscussionItemSuccessReducer(state: ConversationsState): ConversationsState {
  const discussionItems = { ...state.discussionItems };
  discussionItems.isLoading = false;
  discussionItems.isLoaded = true;
  discussionItems.hasDeletedItem = true;

  return { ...state, discussionItems };
}

export function deleteDiscussionItemFailureReducer(
  state: ConversationsState,
  error: Error
): ConversationsState {
  const discussionItems = { ...state.discussionItems };
  discussionItems.isLoading = false;
  discussionItems.isLoaded = true;
  discussionItems.hasDeletedItem = false;
  discussionItems.error = error;

  return { ...state, discussionItems };
}

export function getCompletedItemsReducer(
  state: ConversationsState,
  query: DiscussionItemsQueryType
): ConversationsState {
  const list = { ...state.completedItems };
  list.isLoading = true;
  list.isLoaded = false;
  list.query = {
    ...list.query,
    conversationId: query.conversationId,
  };

  return { ...state, completedItems: list };
}

export function getCompletedItemsSuccessReducer(
  state: ConversationsState,
  paginator: CompletedItemsPaginator
): ConversationsState {
  const list = { ...state.completedItems };
  list.isLoaded = true;
  list.isLoading = false;
  list.paginator = paginator;
  list.error = undefined;

  return { ...state, completedItems: list };
}

export function getCompletedItemsFailureReducer(
  state: ConversationsState,
  error: Error
): ConversationsState {
  const list = { ...state.completedItems };
  list.isLoaded = true;
  list.isLoading = false;
  list.error = error;

  return { ...state, completedItems: list };
}

export function deleteCompletedItemReducer(state: ConversationsState): ConversationsState {
  const completedItems = { ...state.completedItems };
  completedItems.isLoading = true;
  completedItems.isLoaded = false;

  return { ...state, completedItems };
}

export function deleteCompletedItemSuccessReducer(state: ConversationsState): ConversationsState {
  const completedItems = { ...state.completedItems };
  completedItems.isLoading = false;
  completedItems.isLoaded = true;
  completedItems.hasDeletedItem = true;

  return { ...state, completedItems };
}

export function deleteCompletedItemFailureReducer(
  state: ConversationsState,
  error: Error
): ConversationsState {
  const completedItems = { ...state.completedItems };
  completedItems.isLoading = false;
  completedItems.isLoaded = true;
  completedItems.hasDeletedItem = false;
  completedItems.error = error;

  return { ...state, completedItems };
}

export function resetConversationCreationReducer(state: ConversationsState): ConversationsState {
  return {
    ...state,
    conversationCreation: {
      ...state.conversationCreation,
      isLoaded: false,
      isLoading: false,
      data: undefined,
      error: undefined,
    },
  };
}

export function resetConversationListReducer(state: ConversationsState): ConversationsState {
  return { ...state, list: defaultConversationsState.list };
}

export function resetConversationDetailsReducer(state: ConversationsState): ConversationsState {
  return { ...state, detail: defaultConversationsState.detail };
}

export function resetDiscussionItemsReducer(state: ConversationsState): ConversationsState {
  return {
    ...state,
    discussionItems: {
      ...state.discussionItems,
      isLoaded: false,
      isLoading: false,
      hasCompletedItem: false,
      error: undefined,
      paginator: defaultDataPaginator,
    },
  };
}

export function resetDiscussionItemUpdateReducer(state: ConversationsState): ConversationsState {
  return { ...state, discussionItemUpdate: defaultConversationsState.discussionItemUpdate };
}

export function resetDiscussionItemCreationReducer(state: ConversationsState): ConversationsState {
  return {
    ...state,
    discussionItemCreation: defaultConversationsState.discussionItemCreation,
    linkedDiscussionItemCreations: defaultConversationsState.linkedDiscussionItemCreations,
  };
}

export function resetDiscussionItemCompletionReducer(
  state: ConversationsState
): ConversationsState {
  return {
    ...state,
    discussionItems: {
      ...state.discussionItems,
      hasCompletedItem: false,
    },
  };
}

export function resetDiscussionItemDeletionReducer(state: ConversationsState): ConversationsState {
  return {
    ...state,
    discussionItems: {
      ...state.discussionItems,
      hasDeletedItem: false,
    },
  };
}

export function getDiscussionItemNotesReducer(
  state: ConversationsState,
  { discussionItemId }: NoteQuery
): ConversationsState {
  const itemsNotes = [...state.discussionItemsNotes];
  const defaultNoteState = {
    isLoading: true,
    isLoaded: false,
    discussionItemId,
    paginator: {
      data: [],
      meta: {
        totalItems: 0,
      },
    },
  };
  let foundMatch = false;
  itemsNotes.forEach((x: DiscussionItemNotesState, index: number) => {
    if (x.discussionItemId === discussionItemId) {
      itemsNotes[index] = {
        ...itemsNotes[index],
        isLoading: true,
        isLoaded: false,
      };
      foundMatch = true;
    }
  });
  if (!foundMatch) {
    itemsNotes.push(defaultNoteState);
  }
  return { ...state, discussionItemsNotes: itemsNotes };
}

export function getDiscussionItemNotesSuccessReducer(
  state: ConversationsState,
  notesData: DiscussionItemNotesData
): ConversationsState {
  const notes = cloneDeep(state.discussionItemsNotes);
  notes.forEach((x: DiscussionItemNotesState, index: number) => {
    if (x.discussionItemId === notesData.discussionItemId) {
      notes[index] = {
        isLoading: false,
        isLoaded: true,
        discussionItemId: notesData.discussionItemId,
        paginator: notesData.notes,
      };
    }
  });
  const discussionItems: ConversationDiscussionItemsState = cloneDeep(state.discussionItems);
  discussionItems.paginator.data.forEach((dItem: DiscussionItemType, key: number) => {
    const dItemId = Number(dItem.id.split('/').pop());
    if (dItemId === notesData.discussionItemId && discussionItems.paginator.data[key]) {
      (discussionItems.paginator.data[key] as DiscussionItemType) = {
        ...(discussionItems.paginator.data[key] as DiscussionItemType),
        totalNote: notesData.notes.meta.totalItems,
      };
    }
  });

  const completedItems: ConversationCompletedItemsState = cloneDeep(state.completedItems);
  completedItems.paginator.data.forEach((dItem: CompletedItemType, j: number) => {
    const cItemId = Number(dItem.id.split('/').pop());
    if (cItemId === notesData.discussionItemId && completedItems.paginator.data[j]) {
      (completedItems.paginator.data[j] as CompletedItemType) = {
        ...(completedItems.paginator.data[j] as CompletedItemType),
        totalNote: notesData.notes.meta.totalItems,
      };
    }
  });

  return { ...state, discussionItemsNotes: notes, discussionItems, completedItems };
}

export function getDiscussionItemNotesFailureReducer(
  state: ConversationsState,
  notesError: DiscussionItemNotesError
): ConversationsState {
  const itemsNotes = [...state.discussionItemsNotes];
  itemsNotes.forEach((x: DiscussionItemNotesState, index: number) => {
    if (x.discussionItemId === notesError.discussionItemId) {
      itemsNotes[index] = {
        isLoading: false,
        isLoaded: true,
        discussionItemId: notesError.discussionItemId,
        error: notesError.error,
        paginator: itemsNotes[index].paginator ?? {
          data: [],
          meta: {
            totalItems: 0,
          },
        },
      };
    }
  });

  return { ...state, discussionItemsNotes: itemsNotes };
}

export function resetDiscussionItemNotesReducer(
  state: ConversationsState,
  discussionItemId: number
): ConversationsState {
  const itemsNotes = [...state.discussionItemsNotes];
  itemsNotes.forEach((x: DiscussionItemNotesState, index: number) => {
    if (x.discussionItemId === discussionItemId) {
      itemsNotes.splice(index, 1);
    }
  });
  return { ...state, discussionItemsNotes: itemsNotes };
}

export function resetCompletedItemsReducer(state: ConversationsState): ConversationsState {
  return {
    ...state,
    completedItems: {
      ...state.completedItems,
      isLoaded: false,
      isLoading: false,
      error: undefined,
      paginator: defaultDataPaginator,
    },
  };
}

export function resetCompletedItemDeletionReducer(state: ConversationsState): ConversationsState {
  return {
    ...state,
    completedItems: {
      ...state.completedItems,
      hasDeletedItem: false,
    },
  };
}

export function createConversationNoteReducer(
  state: ConversationsState,
  payload: NotePayload
): ConversationsState {
  if (!NoteControls.isItemTypeSupported(payload.itemType)) {
    return state;
  }
  const payloadItemId = Number(payload.discussionItem.split('/').pop());
  const itemsNotes: DiscussionItemNotesState[] = Object.assign([], state.discussionItemsNotes);
  itemsNotes.forEach((x: DiscussionItemNotesState, index: number) => {
    if (x.discussionItemId === payloadItemId) {
      itemsNotes[index] = {
        ...itemsNotes[index],
        isLoading: true,
        isLoaded: false,
      };
    }
  });

  return { ...state, discussionItemsNotes: itemsNotes };
}

export function createConversationNoteFailureReducer(
  state: ConversationsState,
  error: NoteCreationError
): ConversationsState {
  if (!NoteControls.isItemTypeSupported(error.itemType)) {
    return state;
  }
  const itemsNotes = [...state.discussionItemsNotes];
  itemsNotes.forEach((x: DiscussionItemNotesState, index: number) => {
    if (x.discussionItemId === error.itemId) {
      itemsNotes[index] = {
        ...itemsNotes[index],
        isLoading: false,
        isLoaded: true,
        error: error.error,
      };
    }
  });

  return { ...state, discussionItemsNotes: itemsNotes };
}

export function deleteConversationNoteReducer(
  state: ConversationsState,
  noteQuery: NoteDeletionPayload
): ConversationsState {
  if (!NoteControls.isItemTypeSupported(noteQuery.itemType)) {
    return state;
  }
  const itemsNotes = [...state.discussionItemsNotes];
  itemsNotes.forEach((x: DiscussionItemNotesState, index: number) => {
    if (x.discussionItemId === noteQuery.itemId) {
      itemsNotes[index] = {
        ...itemsNotes[index],
        isLoading: true,
        isLoaded: false,
      };
    }
  });

  return { ...state, discussionItemsNotes: itemsNotes };
}

export function deleteConversationNoteFailureReducer(
  state: ConversationsState,
  noteDeletionError: NoteDeletionError
): ConversationsState {
  if (!NoteControls.isItemTypeSupported(noteDeletionError.itemType)) {
    return state;
  }
  const itemsNotes = [...state.discussionItemsNotes];
  itemsNotes.forEach((x: DiscussionItemNotesState, index: number) => {
    if (x.discussionItemId === noteDeletionError.itemId) {
      itemsNotes[index] = {
        ...itemsNotes[index],
        isLoading: false,
        isLoaded: true,
        error: noteDeletionError.error,
      };
    }
  });

  return { ...state, discussionItemsNotes: itemsNotes };
}

export function restoreCompletedItemReducer(state: ConversationsState): ConversationsState {
  const completedItems = { ...state.completedItems };
  completedItems.isLoading = true;
  completedItems.isLoaded = false;

  return { ...state, completedItems };
}

export function restoreCompletedItemSuccessReducer(state: ConversationsState): ConversationsState {
  const completedItems = { ...state.completedItems };
  completedItems.isLoading = false;
  completedItems.isLoaded = true;
  completedItems.hasRestoredItem = true;

  return { ...state, completedItems };
}

export function restoreCompletedItemFailureReducer(
  state: ConversationsState,
  error: Error
): ConversationsState {
  const completedItems = { ...state.completedItems };
  completedItems.isLoading = false;
  completedItems.isLoaded = true;
  completedItems.hasRestoredItem = false;
  completedItems.error = error;

  return { ...state, completedItems };
}

export function resetCompletedItemRestoringReducer(state: ConversationsState): ConversationsState {
  const completedItems = { ...state.completedItems };
  completedItems.hasRestoredItem = false;

  return { ...state, completedItems };
}

export function updateDiscussionItemNoteReducer(state: ConversationsState): ConversationsState {
  const discussionItemNoteUpdate = { ...state.discussionItemNoteUpdate };
  discussionItemNoteUpdate.isLoading = true;
  discussionItemNoteUpdate.isLoaded = false;

  return { ...state, discussionItemNoteUpdate };
}

export function updateDiscussionItemNoteSuccessReducer(
  state: ConversationsState
): ConversationsState {
  const discussionItemNoteUpdate = { ...state.discussionItemNoteUpdate };
  discussionItemNoteUpdate.isLoading = false;
  discussionItemNoteUpdate.isLoaded = true;

  return { ...state, discussionItemNoteUpdate };
}

export function updateDiscussionItemNoteFailureReducer(
  state: ConversationsState,
  error: Error
): ConversationsState {
  const discussionItemNoteUpdate = { ...state.discussionItemNoteUpdate };
  discussionItemNoteUpdate.isLoading = false;
  discussionItemNoteUpdate.isLoaded = true;
  discussionItemNoteUpdate.error = error;

  return { ...state, discussionItemNoteUpdate };
}

export function resetUpdateDiscussionItemNoteReducer(
  state: ConversationsState
): ConversationsState {
  return { ...state, discussionItemNoteUpdate: defaultConversationsState.discussionItemNoteUpdate };
}

export default createReducer(
  {
    [`${actions.getConversations}`]: getConversationsReducer,
    [`${actions.getConversationsSuccess}`]: getConversationsSuccessReducer,
    [`${actions.getConversationsFailure}`]: getConversationsFailureReducer,
    [`${actions.searchUsers}`]: searchUsersReducer,
    [`${actions.searchUsersSuccess}`]: searchUsersSuccessReducer,
    [`${actions.searchUsersFailure}`]: searchUsersFailureReducer,
    [`${actions.resetSearchUsers}`]: resetSearchUsersReducer,
    [`${actions.createConversation}`]: createConversationReducer,
    [`${actions.createConversationSuccess}`]: createConversationSuccessReducer,
    [`${actions.createConversationFailure}`]: createConversationFailureReducer,
    [`${actions.updateConversationVisibility}`]: updateConversationVisibilityReducer,
    [`${actions.updateConversationVisibilitySuccess}`]: updateConversationVisibilitySuccessReducer,
    [`${actions.updateConversationVisibilityFailure}`]: updateConversationVisibilityFailureReducer,
    [`${actions.getConversationDetails}`]: getConversationDetailsReducer,
    [`${actions.getConversationDetailsSuccess}`]: getConversationDetailsSuccessReducer,
    [`${actions.getConversationDetailsFailure}`]: getConversationDetailsFailureReducer,
    [`${actions.resetConversationCreation}`]: resetConversationCreationReducer,
    [`${actions.resetConversationList}`]: resetConversationListReducer,
    [`${actions.resetConversationDetails}`]: resetConversationDetailsReducer,
    [`${actions.getDiscussionItems}`]: getDiscussionItemsReducer,
    [`${actions.getDiscussionItemsSuccess}`]: getDiscussionItemsSuccessReducer,
    [`${actions.getDiscussionItemsFailure}`]: getDiscussionItemsFailureReducer,
    [`${actions.resetDiscussionItems}`]: resetDiscussionItemsReducer,
    [`${actions.createDiscussionItem}`]: createDiscussionItemReducer,
    [`${actions.createDiscussionItemSuccess}`]: createDiscussionItemSuccessReducer,
    [`${actions.createDiscussionItemFailure}`]: createDiscussionItemFailureReducer,
    [`${actions.resetDiscussionItemCreation}`]: resetDiscussionItemCreationReducer,
    [`${actions.updateDiscussionItem}`]: updateDiscussionItemReducer,
    [`${actions.updateDiscussionItemSuccess}`]: updateDiscussionItemSuccessReducer,
    [`${actions.updateDiscussionItemFailure}`]: updateDiscussionItemFailureReducer,
    [`${actions.resetDiscussionItemUpdate}`]: resetDiscussionItemUpdateReducer,
    [`${actions.completeDiscussionItem}`]: completeDiscussionItemReducer,
    [`${actions.completeDiscussionItemSuccess}`]: completeDiscussionItemSuccessReducer,
    [`${actions.completeDiscussionItemFailure}`]: completeDiscussionItemFailureReducer,
    [`${actions.resetDiscussionItemCompletion}`]: resetDiscussionItemCompletionReducer,
    [`${actions.deleteDiscussionItem}`]: deleteDiscussionItemReducer,
    [`${actions.deleteDiscussionItemSuccess}`]: deleteDiscussionItemSuccessReducer,
    [`${actions.deleteDiscussionItemFailure}`]: deleteDiscussionItemFailureReducer,
    [`${actions.resetDiscussionItemDeletion}`]: resetDiscussionItemDeletionReducer,
    [`${actions.getCompletedItems}`]: getCompletedItemsReducer,
    [`${actions.getCompletedItemsSuccess}`]: getCompletedItemsSuccessReducer,
    [`${actions.getCompletedItemsFailure}`]: getCompletedItemsFailureReducer,
    [`${actions.resetCompletedItems}`]: resetCompletedItemsReducer,
    [`${actions.deleteCompletedItem}`]: deleteCompletedItemReducer,
    [`${actions.deleteCompletedItemSuccess}`]: deleteCompletedItemSuccessReducer,
    [`${actions.deleteCompletedItemFailure}`]: deleteCompletedItemFailureReducer,
    [`${actions.resetCompletedItemDeletion}`]: resetCompletedItemDeletionReducer,
    [`${actions.restoreCompletedItem}`]: restoreCompletedItemReducer,
    [`${actions.restoreCompletedItemSuccess}`]: restoreCompletedItemSuccessReducer,
    [`${actions.restoreCompletedItemFailure}`]: restoreCompletedItemFailureReducer,
    [`${actions.resetCompletedItemRestoring}`]: resetCompletedItemRestoringReducer,
    [`${actions.getDiscussionItemNotes}`]: getDiscussionItemNotesReducer,
    [`${actions.getDiscussionItemNotesSuccess}`]: getDiscussionItemNotesSuccessReducer,
    [`${actions.getDiscussionItemNotesFailure}`]: getDiscussionItemNotesFailureReducer,
    [`${actions.resetDiscussionItemNotes}`]: resetDiscussionItemNotesReducer,
    [`${actions.createConversationNote}`]: createConversationNoteReducer,
    [`${actions.createConversationNoteFailure}`]: createConversationNoteFailureReducer,
    [`${actions.deleteConversationNote}`]: deleteConversationNoteReducer,
    [`${actions.deleteConversationNoteFailure}`]: deleteConversationNoteFailureReducer,
    [`${actions.updateDiscussionItemNote}`]: updateDiscussionItemNoteReducer,
    [`${actions.updateDiscussionItemNoteSuccess}`]: updateDiscussionItemNoteSuccessReducer,
    [`${actions.updateDiscussionItemNoteFailure}`]: updateDiscussionItemNoteFailureReducer,
    [`${actions.resetUpdateDiscussionItemNote}`]: resetUpdateDiscussionItemNoteReducer,
  },
  defaultState
);
