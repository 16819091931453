import { createAction } from 'redux-act';
import { Review360PerformanceDefaultQuery } from './state';
import { Review360PerformanceQuery, Review360PerformanceData } from '../type';

export const getReview360Performance: any = createAction(
  'PERFORMANCE_GET_REVIEW_360_PERFORMANCE',
  (payload: Review360PerformanceQuery) => ({
    ...Review360PerformanceDefaultQuery,
    ...payload,
  })
);

export const getReview360PerformanceSuccess: any = createAction(
  'PERFORMANCE_GET_REVIEW_360_PERFORMANCE_SUCCESS',
  (data: Review360PerformanceData) => data
);

export const getReview360PerformanceFailure: any = createAction(
  'PERFORMANCE_GET_PERFORMANCE_REVIEW_360_FAILURE',
  (error: any) => error
);
