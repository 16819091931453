import React from 'react';
import DropdownButtonIcon from './DropdownButtonIcon';
import { getClass } from '../../_lib/helper';
import './DropdownButton.scss';
import Button from '../../Button';
import Tooltip from '../../Tooltip';
function DropdownButton(_a) {
    var toggleMenu = _a.toggleMenu, ariaLabel = _a.ariaLabel, text = _a.text, icon = _a.icon, isDisabled = _a.isDisabled, id = _a.id, isBlock = _a.isBlock, isFullWidth = _a.isFullWidth, isBordered = _a.isBordered, isOpenDropdown = _a.isOpenDropdown, hasTooltip = _a.hasTooltip, type = _a.type, size = _a.size;
    var button = (React.createElement(Button, { type: type, icon: text ? null : React.createElement(DropdownButtonIcon, { icon: icon }), className: getClass('elmo-dropdown__button', '', {
            disabled: isDisabled,
            'full-width': isFullWidth,
            'has-border': isBordered,
        }), onClick: toggleMenu, isDisabled: isDisabled, isUppercase: false, "data-testid": "elmo-dropdown-button-".concat(id || 'default'), isBlock: isBlock, ariaHaspopup: true, ariaExpanded: isOpenDropdown, ariaLabel: ariaLabel, size: size }, text ? (React.createElement(React.Fragment, null,
        text,
        React.createElement("span", { className: 'elmo-dropdown__button__icon' },
            React.createElement(DropdownButtonIcon, { icon: icon })))) : null));
    if (hasTooltip) {
        return (React.createElement(Tooltip, { title: ariaLabel ? ariaLabel : '', placement: "bottom" }, button));
    }
    return button;
}
export default DropdownButton;
