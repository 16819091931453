var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component, createRef } from 'react';
import RadioButtons from '../RadioButtons';
import Radio from '../Radio';
import Dropdown from '../Dropdown';
import './Switcher.scss';
import { getClass } from '../_lib/helper';
var Switcher = /** @class */ (function (_super) {
    __extends(Switcher, _super);
    function Switcher() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.switcherRef = createRef();
        _this.getSelectedButtonKey = function () {
            var buttons = _this.props.buttons;
            var selectedButton = {
                icon: '',
                onClick: function () { return void 0; },
                isActive: true,
            };
            var selectedKey = 0;
            // TODO: map for single value, check if safe to replace with find?
            buttons.map(function (button, key) {
                if (button.isActive) {
                    selectedButton = button;
                    selectedKey = key;
                }
            });
            return {
                selectedButton: selectedButton,
                selectedKey: selectedKey,
            };
        };
        _this.onHandleChange = function (v) {
            var buttons = _this.props.buttons;
            var key;
            if (typeof v === 'number') {
                key = v;
            }
            else {
                key = parseInt(v, 10);
            }
            _this.onHandleClick('', buttons[key]);
        };
        _this.onHandleClick = function (e, button) {
            button.onClick();
            _this.switchToDefaultState(button);
        };
        _this.switchToDefaultState = function (button) {
            var switcher = _this.switcherRef.current;
            if (switcher !== null) {
                if (!button.isActive) {
                    switcher.classList.remove('expanded');
                }
                else {
                    switcher.classList.add('expanded');
                }
            }
        };
        _this.closeExpander = function (e, switcher) {
            if (!e.target.closest('.elmo-switcher')) {
                switcher.classList.remove('expanded');
            }
        };
        // remove expanded className if click/touch outside of the component
        _this.bindMouseEvents = function () {
            _this.rootWrapper = document.querySelector('html, body');
            var switcher = _this.switcherRef.current;
            if (_this.rootWrapper !== null && switcher !== null) {
                _this.handleClickListener = _this.rootWrapper.addEventListener('click', function (e) { return _this.closeExpander(e, switcher); });
                _this.handleTouchListener = _this.rootWrapper.addEventListener('touchstart', function (e) { return _this.closeExpander(e, switcher); });
            }
        };
        _this.removeEventListeners = function () {
            if (_this.rootWrapper !== null) {
                _this.rootWrapper.removeEventListener('click', _this.handleClickListener);
                _this.rootWrapper.removeEventListener('touchstart', _this.handleTouchListener);
            }
        };
        return _this;
    }
    Switcher.prototype.render = function () {
        var _this = this;
        var _a = this.props, buttons = _a.buttons, id = _a.id, className = _a.className;
        var _b = this.getSelectedButtonKey(), selectedButton = _b.selectedButton, selectedKey = _b.selectedKey;
        return (React.createElement("div", { className: "elmo-switcher__wrapper" },
            React.createElement("div", { id: id, "data-testid": "elmo-switcher-".concat(id || 'default'), className: getClass('elmo-switcher', className), ref: this.switcherRef },
                React.createElement("div", { className: "elmo-switcher__inner", role: "radiogroup" },
                    React.createElement(RadioButtons, { id: id + '-radio-buttons', "data-testid": "elmo-switcher-radio-buttons-".concat(id || 'default'), selected: selectedKey, onChange: this.onHandleChange, type: "solid", className: "elmo-switcher--radio-buttons", ariaLabelledBy: "Switcher" }, buttons &&
                        buttons.map(function (button, key) { return (React.createElement(Radio, { value: key, key: key, tooltip: button.ariaLabel && button.ariaLabel }, button.icon)); })),
                    React.createElement(Dropdown, { id: id + '-dropdown', "data-testid": "elmo-switcher-dropdown-".concat(id || 'default'), icon: selectedButton.icon, ariaLabel: "Switcher", className: "elmo-switcher--dropdown" }, buttons &&
                        buttons.map(function (button, key) { return (React.createElement(Dropdown.Item, { ariaLabel: button.ariaLabel, value: key, key: key, onClick: function (e) { return _this.onHandleClick(e, button); } },
                            button.icon,
                            " ",
                            button.ariaLabel)); }))))));
    };
    Switcher.prototype.componentDidMount = function () {
        this.bindMouseEvents();
    };
    Switcher.prototype.componentWillUnmount = function () {
        this.removeEventListeners();
    };
    return Switcher;
}(Component));
export default Switcher;
