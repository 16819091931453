import React from 'react';
import { t, messages } from '../../lib/translation';
import MessageLayout from '../../element/MessageLayout';
import { Layout } from 'elmo-elements';

function ForbiddenContainer(): React.ReactElement {
  return (
    <Layout.Content>
      <MessageLayout
        title={t(messages.app.forbidden.title)}
        content={t(messages.app.forbidden.content)}
      />
    </Layout.Content>
  );
}

export default ForbiddenContainer;
