import { createAction } from 'redux-act';
import {
  PerformancePaginator,
  SectionItemType,
  UserPerformanceErrorPayload,
  UserPerformanceQuery,
  UserPerformanceSuccessPayload,
} from '../type';
import { defaultUserPerformanceQuery } from './state';

export const getUserPerformances: any = createAction(
  'GET_USER_PERFORMANCES',
  (query: UserPerformanceQuery) => ({ ...defaultUserPerformanceQuery, ...query })
);

export const getUserPerformancesSuccess: any = createAction(
  'GET_USER_PERFORMANCES_SUCCESS',
  (data: PerformancePaginator, userId: string): UserPerformanceSuccessPayload => ({ data, userId })
);

export const getUserPerformancesFailure: any = createAction(
  'GET_USER_PERFORMANCES_FAILURE',
  (error: Error, userId: string): UserPerformanceErrorPayload => ({ error, userId })
);

export const resetUserPerformances: any = createAction('RESET_USER_PERFORMANCES');

export const getSectionList: any = createAction('GET_SECTION_LIST', (id: number) => id);

export const getSectionListSuccess: any = createAction(
  'GET_SECTION_LIST_SUCCESS',
  (data: any) => data
);

export const getSectionListFailure: any = createAction(
  'GET_SECTION_LIST_FAILURE',
  (error: any) => error
);

export const getSectionItemList: any = createAction(
  'GET_SECTION_ITEM_LIST',
  (id: number, module: string) => ({ id, module })
);

export const getSectionItemListSuccess: any = createAction(
  'GET_SECTION_ITEM_LIST_SUCCESS',
  (data: SectionItemType[]) => data
);

export const getSectionItemListFailure: any = createAction(
  'GET_SECTION_ITEM_LIST_FAILURE',
  (error: Error) => error
);

export const resetSectionList: any = createAction('RESET_SECTION_LIST');

export const resetSectionItemList: any = createAction('RESET_SECTION_ITEM_LIST');
