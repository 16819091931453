import { createAction } from 'redux-act';
import { ApprovalPerformanceDefaultQuery } from './state';
import { ApprovalPerformancesQuery, ApprovalPerformancesData } from '../type';

export const getApprovalPerformances: any = createAction(
  'PERFORMANCE_GET_APPROVAL_PERFORMANCES',
  (payload: ApprovalPerformancesQuery) => ({
    ...ApprovalPerformanceDefaultQuery,
    ...payload,
  })
);

export const getApprovalPerformancesSuccess: any = createAction(
  'PERFORMANCE_GET_APPROVAL_PERFORMANCES_SUCCESS',
  (data: ApprovalPerformancesData) => data
);

export const getApprovalPerformancesFailure: any = createAction(
  'PERFORMANCE_GET_APPROVAL_PERFORMANCES_FAILURE',
  (error: any) => error
);
