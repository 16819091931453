import {
  PerformanceState,
  UserPerformanceErrorPayload,
  UserPerformanceQuery,
  UserPerformanceSuccessPayload,
  SectionListType,
  SectionItemType,
} from '../type';
import { createReducer } from 'redux-act';
import * as actions from './actions';
import defaultState from './state';

export function getUserPerformancesReducer(
  state: PerformanceState,
  query: UserPerformanceQuery
): PerformanceState {
  const userList = { ...state.userList };

  userList[query.employeeId] = {
    userId: query.employeeId,
    isLoading: true,
    isLoaded: false,
    error: undefined,
    paginator: {
      meta: {
        totalItems: 0,
        itemsPerPage: query.itemsPerPage,
        currentPage: query.page,
      },
      data: [],
    },
  };

  return { ...state, userList };
}

export function getUserPerformancesSuccessReducer(
  state: PerformanceState,
  { data, userId }: UserPerformanceSuccessPayload
): PerformanceState {
  const userList = { ...state.userList };

  if (userList[userId] !== undefined) {
    userList[userId].isLoading = false;
    userList[userId].isLoaded = true;
    userList[userId].paginator = data;
  }

  return { ...state, userList };
}

export function getUserPerformancesFailureReducer(
  state: PerformanceState,
  { error, userId }: UserPerformanceErrorPayload
): PerformanceState {
  const userList = { ...state.userList };

  if (userList[userId] !== undefined) {
    userList[userId].isLoading = false;
    userList[userId].isLoaded = true;
    userList[userId].error = error;
  }

  return { ...state, userList };
}

export function resetUserPerformancesReducer(state: PerformanceState): PerformanceState {
  return { ...state, userList: defaultState.userList };
}

export function getSectionListReducer(state: PerformanceState): PerformanceState {
  const sectionList = { ...state.sectionList };
  sectionList.isLoading = true;
  sectionList.isLoaded = false;

  return { ...state, sectionList };
}

export function getSectionListSuccessReducer(
  state: PerformanceState,
  data: SectionListType
): PerformanceState {
  const sectionList = { ...state.sectionList };
  sectionList.isLoading = false;
  sectionList.isLoaded = true;
  sectionList.data = data;
  sectionList.error = undefined;

  return { ...state, sectionList };
}

export function getSectionListFailureReducer(
  state: PerformanceState,
  error: Error
): PerformanceState {
  const sectionList = { ...state.sectionList };
  sectionList.isLoading = false;
  sectionList.isLoaded = true;
  sectionList.error = error;

  return { ...state, sectionList };
}

export function getSectionItemListReducer(state: PerformanceState): PerformanceState {
  const sectionItemList = { ...state.sectionItemList };
  sectionItemList.isLoading = true;
  sectionItemList.isLoaded = false;

  return { ...state, sectionItemList };
}

export function getSectionItemListSuccessReducer(
  state: PerformanceState,
  data: SectionItemType[]
): PerformanceState {
  const sectionItemList = { ...state.sectionItemList };
  sectionItemList.isLoading = false;
  sectionItemList.isLoaded = true;
  sectionItemList.data = data;

  return { ...state, sectionItemList };
}

export function getSectionItemListFailureReducer(
  state: PerformanceState,
  error: Error
): PerformanceState {
  const sectionItemList = { ...state.sectionItemList };
  sectionItemList.isLoading = false;
  sectionItemList.isLoaded = true;
  sectionItemList.error = error;

  return { ...state, sectionItemList };
}

export function resetSectionListReducer(state: PerformanceState): PerformanceState {
  return { ...state, sectionList: defaultState.sectionList };
}

export function resetSectionItemListReducer(state: PerformanceState): PerformanceState {
  return { ...state, sectionItemList: defaultState.sectionItemList };
}

export default createReducer(
  {
    [`${actions.getUserPerformances}`]: getUserPerformancesReducer,
    [`${actions.getUserPerformancesSuccess}`]: getUserPerformancesSuccessReducer,
    [`${actions.getUserPerformancesFailure}`]: getUserPerformancesFailureReducer,
    [`${actions.resetUserPerformances}`]: resetUserPerformancesReducer,
    [`${actions.getSectionList}`]: getSectionListReducer,
    [`${actions.getSectionListSuccess}`]: getSectionListSuccessReducer,
    [`${actions.getSectionListFailure}`]: getSectionListFailureReducer,
    [`${actions.resetSectionList}`]: resetSectionListReducer,
    [`${actions.getSectionItemList}`]: getSectionItemListReducer,
    [`${actions.getSectionItemListSuccess}`]: getSectionItemListSuccessReducer,
    [`${actions.getSectionItemListFailure}`]: getSectionItemListFailureReducer,
    [`${actions.resetSectionItemList}`]: resetSectionItemListReducer,
  },
  defaultState
);
