import { all, fork } from 'redux-saga/effects';
import app from './App/sagas';
import conversations from '../page/Conversations/state/sagas';
import performance from '../page/Performance/state/sagas';
import teamPerformance from '../page/TeamPerformance/state/sagas';
import myPerformance from '../page/MyPerformance/state/sagas';
import review360Performance from '../page/Review360Performance/state/sagas';
import assignAppraisal from '../page/AssignAppraisal/state/sagas';
import { SagaIterator } from 'redux-saga';
import approvalPerformance from '../page/ApprovalPerformance/state/sagas';

/**
 * rootSaga
 */
export default function* root(): SagaIterator {
  yield all([
    fork(app),
    fork(conversations),
    fork(approvalPerformance),
    fork(performance),
    fork(teamPerformance),
    fork(myPerformance),
    fork(review360Performance),
    fork(assignAppraisal),
  ]);
}
