// needed to support customEvent method used in EDS
import './lib/polyfills/index';
import 'proxy-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './state/store';
import App from './App';
import './lib/translation';
import 'elmo-elements/dist/main.css';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
