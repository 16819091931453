import {
  PollingJobInterface,
  NotificationsMapInterface,
  NavigationUserType,
  NavigationBrandingType,
  NavigationBrandingThemeType,
  NavigationMenuUserType,
  NavigationMenuModulesType,
  NavigationStatusType,
  AppStateType,
  NavigationType,
} from './type';
import { SystemConfiguration } from '../../lib/systemConfiguration/type';
import { defaultTheme } from 'elmo-elements';
import {
  PERFORMANCE_EMPLOYEE_SEARCH_SCALE_DIRECT,
  PERFORMANCE_VISIBILITY_DEFAULT,
} from '../../lib/const';

export const defaultSystemConfiguration: SystemConfiguration = {
  companySystemName: '',
  companySubDomain: '',
  performanceTMSDomain: '',
  featureFlags: {},
  employeeSearchScale: PERFORMANCE_EMPLOYEE_SEARCH_SCALE_DIRECT,
  performanceConfig: {
    performanceVisibility: PERFORMANCE_VISIBILITY_DEFAULT,
    allowManagerAssignAppraisal: true,
    allowManagerDeleteAppraisal: true,
  },
};

export const defaultPollingJobs: PollingJobInterface = {};

export const defaultNotificationsMap: NotificationsMapInterface = {};

const defaultNavigationUser: NavigationUserType = {
  id: 0,
  name: '',
  img: '',
  isElmo: false,
};

const defaultNavigationTheme: NavigationBrandingThemeType = {
  profile: {
    color: '',
    backgroundColor: '',
  },
  admin: {
    color: '',
    backgroundColor: '',
  },
  menu: {
    backgroundColor: '',
    fontColor: '',
    hoverFontColor: '',
    font: '',
  },
};

const defaultNavigationBranding: NavigationBrandingType = {
  logo: '',
  theme: defaultNavigationTheme,
};

const defaultNavigationMenuUser: NavigationMenuUserType[] = [];

const defaultNavigationMenuModules: NavigationMenuModulesType[] = [];

const defaultNavigationMenu = {
  user: defaultNavigationMenuUser,
  modules: defaultNavigationMenuModules,
};

const defaultNavigationStatus: NavigationStatusType = {
  key: '',
  title: '',
};

export const defaultNavigation: NavigationType = {
  isLoading: true,
  isLoaded: false,
  menu: defaultNavigationMenu,
  user: defaultNavigationUser,
  branding: defaultNavigationBranding,
  status: defaultNavigationStatus,
  error: null,
  footerMessage: '',
};

/**
 * Document about what this state is about
 */
export default {
  isLoaded: false,
  isLoading: true,
  systemConfiguration: defaultSystemConfiguration,
  error: null,
  theme: defaultTheme,
  pollingJobs: defaultPollingJobs,
  notificationsMap: defaultNotificationsMap,
  navigation: defaultNavigation,
} as AppStateType;
