var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component, createRef } from 'react';
import ResizeObserver from '@juggle/resize-observer';
import { getClass } from '../_lib/helper';
import { FormContainerBreakpoints, } from './type';
import './FormContainer.scss';
var FormContainer = /** @class */ (function (_super) {
    __extends(FormContainer, _super);
    function FormContainer(props) {
        var _this = _super.call(this, props) || this;
        _this.formContainerRef = createRef();
        _this.formContainerObserver = new ResizeObserver(function (entries) {
            _this.updateMaxColumns();
        });
        _this.updateMaxColumns = function () {
            var maxColumns = _this.props.maxColumns;
            var formContainerRef = _this.formContainerRef;
            if (formContainerRef && formContainerRef.current) {
                var width = formContainerRef.current.clientWidth;
                var columns = 1;
                if (width < FormContainerBreakpoints['2']) {
                    // minimum columns is 2 because width:100% is expected
                    // for less than 2 columns unless maxColumns props set 1
                    columns = 2;
                }
                else if (width >= FormContainerBreakpoints['2'] &&
                    width < FormContainerBreakpoints['3']) {
                    columns = 2;
                }
                else {
                    columns = 3;
                }
                if (columns > maxColumns) {
                    columns = maxColumns;
                }
                _this.setState({
                    maxColumns: columns,
                });
            }
        };
        var maxColumns = _this.props.maxColumns;
        _this.state = {
            maxColumns: maxColumns,
        };
        return _this;
    }
    FormContainer.prototype.componentDidMount = function () {
        var formContainerRef = this.formContainerRef;
        if (formContainerRef && formContainerRef.current) {
            this.formContainerObserver.observe(formContainerRef.current);
            this.updateMaxColumns();
        }
    };
    FormContainer.prototype.componentWillUnmount = function () {
        this.formContainerObserver.disconnect();
    };
    FormContainer.prototype.render = function () {
        var _a;
        var _b = this.props, children = _b.children, className = _b.className, id = _b.id;
        var maxColumns = this.state.maxColumns;
        return (React.createElement("div", { id: id, "data-testid": "elmo-form-container-".concat(id || 'default'), ref: this.formContainerRef, className: getClass('elmo-form-container', className, (_a = {},
                _a["max-columns-".concat(maxColumns)] = maxColumns,
                _a)) },
            React.createElement("div", { className: "elmo-form-container__inner" }, children)));
    };
    FormContainer.defaultProps = {
        maxColumns: 3,
    };
    return FormContainer;
}(Component));
export default FormContainer;
