import {
  PerformancePaginator,
  PerformanceType,
  UserPerformanceQuery,
  SectionListType,
  SectionItemType,
  PerformanceMainConfigurationsType,
  WorkflowConfigurationType,
  PerformanceSectionConfigurationsType,
} from '../type';
import httpClient from '../../../lib/api';

export const resources = {
  performances: {
    url: '/performances',
  },
  section: {
    url: '/performances/:id',
  },
  goal: {
    url: '/performance-goal-sections/:id',
  },
  competency: {
    url: '/performance-competency-sections/:id',
  },
  development: {
    url: '/performance-development-sections/:id',
  },
  performanceDetail: {
    url: '/performance-api/v1/performances/:id',
  },
  performanceWorkflowConfigurations: {
    url: '/performance-api/v1/performances/:id/workflow-configurations',
  },
  bulkPerformanceSync: {
    url: '/performance-api/v1/performances/sync',
  },
  mainConfigurations: {
    url: '/performance-api/v1/performances/:id/main-configurations',
  },
  sectionsConfigurations: {
    url: '/performance-api/v1/performances/:id/section-configurations',
  },
};

export class PerformanceApi {
  async getUserPerformances(query: UserPerformanceQuery): Promise<any> {
    try {
      const response = await httpClient.get(resources.performances.url, {
        params: { ...query },
        headers: { 'Content-Type': 'application/json', Accept: 'application/vnd.api+json' },
      });
      const data = response?.data;
      const result: PerformancePaginator = {
        data: [],
        meta: {
          totalItems: 0,
          currentPage: query.page,
          itemsPerPage: query.itemsPerPage,
        },
      };

      if (data === null) {
        return result;
      }

      result.data = data.data.map(
        (item: any): PerformanceType => ({
          id: item.attributes._id,
          title: item.attributes.title,
        })
      );
      result.meta = data.meta;

      return result;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getSectionList(performanceId: number): Promise<any> {
    try {
      const response = await httpClient.get(
        resources.section.url.replace(':id', String(performanceId)),
        {
          headers: { 'Content-Type': 'application/json', Accept: 'application/vnd.api+json' },
        }
      );
      const { data } = response.data;
      let result: SectionListType = {
        performanceId: 0,
        sections: [],
      };

      if (data) {
        result = {
          performanceId: data.attributes._id,
          sections: data.attributes.sections,
        };
      }

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  getSection(id: number, module: string): Promise<any> {
    const { url } = resources[module as keyof typeof resources];

    return httpClient
      .get(url.replace(':id', String(id)), {
        headers: { 'Content-Type': 'application/json', Accept: 'application/vnd.api+json' },
      })
      .then(response => {
        const { data } = response.data;
        if (!data) {
          return null;
        }

        return {
          id: data.attributes._id,
          title: data.attributes.title,
          items: data.attributes.items.map(
            (item: any): SectionItemType => ({ id: item.id, title: item.title })
          ),
        };
      })
      .catch(error => Promise.reject(error));
  }

  getPerformanceById(performanceId: string): Promise<PerformanceType> {
    return httpClient
      .get(resources.performanceDetail.url.replace(':id', String(performanceId)))
      .then(res => res.data);
    // .catch(err => ({ error: err.data }));
  }

  bulkPerformanceSync(performanceIds: string[], actions: string[]): Promise<boolean> {
    return httpClient
      .post(resources.bulkPerformanceSync.url, {
        id: performanceIds,
        actions,
      })
      .then((response: any) => response.data);
  }

  getPerformanceMainConfigurationsById(
    performanceId: string
  ): Promise<PerformanceMainConfigurationsType> {
    return httpClient
      .get(resources.mainConfigurations.url.replace(':id', String(performanceId)))
      .then(res => res.data);
  }

  getPerformanceSectionsConfigurationsById(
    performanceId: string
  ): Promise<PerformanceSectionConfigurationsType[]> {
    return httpClient
      .get(resources.sectionsConfigurations.url.replace(':id', String(performanceId)))
      .then(res => res.data);
  }

  getPerformanceWorkflowConfigurationsById(
    performanceId: string
  ): Promise<WorkflowConfigurationType> {
    return httpClient
      .get(resources.performanceWorkflowConfigurations.url.replace(':id', String(performanceId)))
      .then(res => res.data);
  }
}

export default new PerformanceApi();
