import { SagaIterator } from 'redux-saga';
import { takeLatest, all, call, put, cancelled, debounce, takeEvery } from 'redux-saga/effects';
import api from './api';
import * as actions from './actions';
import { DiscussionItemType, NotePayload } from '../type';
import NoteControls from '../../../lib/notes';

export const getConversationsSaga = function* (action: Record<string, any>): SagaIterator {
  try {
    const data = yield call(api.getConversations, action.payload);
    yield put(actions.getConversationsSuccess(data));
  } catch (error) {
    yield put(actions.getConversationsFailure(error));
  }
};

export const searchUsersSaga = function* (action: Record<string, any>): SagaIterator {
  const { get, cancel } = api.searchUsers(action.payload);
  try {
    const data = yield call(() => get);
    yield put(actions.searchUsersSuccess(data));
  } catch (error) {
    yield put(actions.searchUsersFailure(error));
  } finally {
    if (yield cancelled()) {
      // if saga task is cancelled, we cancel the api request
      cancel && cancel('Request cancelled');
    }
  }
};

export const createConversationSaga = function* (action: Record<string, any>): SagaIterator {
  try {
    const data = yield call(api.conversationCreation, action.payload);
    yield put(actions.createConversationSuccess(data));
  } catch (error) {
    yield put(actions.createConversationFailure(error));
  }
};

export const updateConversationVisibilitySaga = function* (
  action: Record<string, any>
): SagaIterator {
  try {
    const { id, payload } = action.payload;
    yield call(api.updateConversationVisibility, id, payload);
    yield put(actions.updateConversationVisibilitySuccess());
  } catch (error) {
    yield put(actions.updateConversationVisibilityFailure(error));
  }
};

export const getConversationDetailsSaga = function* (action: Record<string, any>): SagaIterator {
  const { get, cancel } = api.getConversationDetail(action.payload);
  try {
    const data = yield call(() => get);
    yield put(actions.getConversationDetailsSuccess(data));
  } catch (error) {
    yield put(actions.getConversationDetailsFailure(error));
  } finally {
    if (yield cancelled()) {
      // if saga task is cancelled, we cancel the api request
      cancel && cancel('Request cancelled');
    }
  }
};

export const getDiscussionItemsSaga = function* (action: Record<string, any>): SagaIterator {
  const { get, cancel } = api.getDiscussionItems(action.payload);
  try {
    const data = yield call(() => get);
    yield put(actions.getDiscussionItemsSuccess(data));
  } catch (error) {
    yield put(actions.getDiscussionItemsFailure(error));
  } finally {
    if (yield cancelled()) {
      // if saga task is cancelled, we cancel the api request
      cancel && cancel('Request cancelled');
    }
  }
};

export const createDiscussionItemSaga = function* (action: Record<string, any>): SagaIterator {
  try {
    const data: DiscussionItemType = yield call(api.createDiscussionItem, action.payload);
    yield put(actions.createDiscussionItemSuccess(action.payload, data));
  } catch (error) {
    yield put(actions.createDiscussionItemFailure(action.payload, error));
  }
};

export const updateDiscussionItemSaga = function* (action: Record<string, any>): SagaIterator {
  try {
    const { id, payload } = action.payload;

    yield call(api.updateDiscussionItem, id, payload);
    yield put(actions.updateDiscussionItemSuccess());
  } catch (error) {
    yield put(actions.updateDiscussionItemFailure(error));
  }
};

export const completeDiscussionItemSaga = function* (action: Record<string, any>): SagaIterator {
  try {
    yield call(api.changeDiscussionItemCompletion, action.payload, true);
    yield put(actions.completeDiscussionItemSuccess());
  } catch (error) {
    yield put(actions.completeDiscussionItemFailure(error));
  }
};

export const restoreCompletedItemSaga = function* (action: Record<string, any>): SagaIterator {
  try {
    yield call(api.changeDiscussionItemCompletion, action.payload, false);
    yield put(actions.restoreCompletedItemSuccess());
  } catch (error) {
    yield put(actions.restoreCompletedItemFailure(error));
  }
};

export const deleteDiscussionItemSaga = function* (action: Record<string, any>): SagaIterator {
  try {
    yield call(api.deleteDiscussionItem, action.payload);
    yield put(actions.deleteDiscussionItemSuccess());
  } catch (error) {
    yield put(actions.deleteDiscussionItemFailure(error));
  }
};

export const getDiscussionItemNotesSaga = function* (action: Record<string, any>): SagaIterator {
  const { discussionItemId } = action.payload;
  const { get, cancel } = api.getDiscussionItemNotes(action.payload);
  try {
    const data = yield call(() => get);
    yield put(actions.getDiscussionItemNotesSuccess({ discussionItemId, notes: data }));
  } catch (error) {
    yield put(actions.getDiscussionItemNotesFailure({ discussionItemId, error }));
  } finally {
    if (yield cancelled()) {
      // if saga task is cancelled, we cancel the api request
      cancel && cancel('Request cancelled');
    }
  }
};

export const getCompletedItemsSaga = function* (action: Record<string, any>): SagaIterator {
  const { get, cancel } = api.getDiscussionItems(action.payload);
  try {
    const data = yield call(() => get);
    yield put(actions.getCompletedItemsSuccess(data));
  } catch (error) {
    yield put(actions.getCompletedItemsFailure(error));
  } finally {
    if (yield cancelled()) {
      // if saga task is cancelled, we cancel the api request
      cancel && cancel('Request cancelled');
    }
  }
};

export const deleteCompletedItemSaga = function* (action: Record<string, any>): SagaIterator {
  try {
    yield call(api.deleteDiscussionItem, action.payload);
    yield put(actions.deleteCompletedItemSuccess());
  } catch (error) {
    yield put(actions.deleteCompletedItemFailure(error));
  }
};

export const createConversationNoteSaga = function* (action: Record<string, any>): SagaIterator {
  const { discussionItem, itemType } = action.payload as NotePayload;
  const itemId = Number(discussionItem.split('/').pop());

  try {
    yield call(api.createConversationNote, action.payload);
    if (NoteControls.isItemTypeSupported(itemType)) {
      yield put(actions.getDiscussionItemNotes({ discussionItemId: itemId }));
    }
  } catch (error) {
    yield put(actions.createConversationNoteFailure({ itemId, itemType, error }));
  }
};

export const deleteConversationNoteSaga = function* (action: Record<string, any>): SagaIterator {
  const { id, itemId, itemType } = action.payload;

  try {
    yield call(api.deleteConversationNote, id);
    if (NoteControls.isItemTypeSupported(itemType)) {
      yield put(actions.getDiscussionItemNotes({ discussionItemId: itemId }));
    }
  } catch (error) {
    yield put(actions.deleteConversationNoteFailure({ id, itemId, itemType, error }));
  }
};

export const updateDiscussionItemNoteSaga = function* (action: Record<string, any>): SagaIterator {
  try {
    const { id, payload } = action.payload;

    yield call(api.updateDiscussionItemNote, id, payload);
    yield put(actions.updateDiscussionItemNoteSuccess());
  } catch (error) {
    yield put(actions.updateDiscussionItemNoteFailure(error));
  }
};

export default function* root(): SagaIterator {
  yield all([
    takeLatest(actions.getConversations, getConversationsSaga),
    takeLatest(actions.createConversation, createConversationSaga),
    takeLatest(actions.getConversationDetails, getConversationDetailsSaga),
    debounce(1000, actions.searchUsers, searchUsersSaga),
    takeLatest(actions.getDiscussionItems, getDiscussionItemsSaga),
    takeEvery(actions.createDiscussionItem, createDiscussionItemSaga),
    takeLatest(actions.updateDiscussionItem, updateDiscussionItemSaga),
    takeLatest(actions.completeDiscussionItem, completeDiscussionItemSaga),
    takeLatest(actions.deleteDiscussionItem, deleteDiscussionItemSaga),
    takeLatest(actions.getDiscussionItemNotes, getDiscussionItemNotesSaga),
    takeLatest(actions.getCompletedItems, getCompletedItemsSaga),
    takeLatest(actions.deleteCompletedItem, deleteCompletedItemSaga),
    takeLatest(actions.deleteConversationNote, deleteConversationNoteSaga),
    takeLatest(actions.restoreCompletedItem, restoreCompletedItemSaga),
    takeLatest(actions.createConversationNote, createConversationNoteSaga),
    takeLatest(actions.updateDiscussionItemNote, updateDiscussionItemNoteSaga),
    takeLatest(actions.updateConversationVisibility, updateConversationVisibilitySaga),
  ]);
}
