import { createReducer } from 'redux-act';
import defaultState from './state';
import { getMyPerformances, getMyPerformancesSuccess, getMyPerformancesFailure } from './actions';
import { MyPerformancesQuery, MyPerformancesState, MyPerformancesData } from '../type';
import { resetState } from '../../../state/App';

/**
 * Get performances
 */
export function getMyPerformancesReducer(
  state: MyPerformancesState,
  myPerformancesQuery: MyPerformancesQuery
): MyPerformancesState {
  return {
    ...state,
    query: myPerformancesQuery,
    isLoaded: false,
    isLoading: true,
    error: undefined,
  };
}

export function getMyPerformancesSuccessReducer(
  state: MyPerformancesState,
  data: MyPerformancesData
): MyPerformancesState {
  return {
    ...state,
    performances: { ...data },
    isLoading: false,
    isLoaded: true,
    error: undefined,
  };
}

export function getMyPerformancesFailureReducer(
  state: MyPerformancesState,
  error: Error
): MyPerformancesState {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error,
  };
}

export function resetStateReducer(state: MyPerformancesState): MyPerformancesState {
  return {
    ...state,
    isDeleted: false,
    isLoading: false,
    isLoaded: false,
    error: undefined,
  };
}

export default createReducer(
  {
    [`${getMyPerformances}`]: getMyPerformancesReducer,
    [`${getMyPerformancesSuccess}`]: getMyPerformancesSuccessReducer,
    [`${getMyPerformancesFailure}`]: getMyPerformancesFailureReducer,
    [`${resetState}`]: resetStateReducer,
  },
  defaultState
);
