import React from 'react';
import PageLoading from '../element/PageLoading';
import Error from '../page/Error';
import { ERROR_PAGE_CODES_MAP } from '../lib/const';

interface AppLoadingProps {
  isLoaded: boolean;
  children: JSX.Element;
  error?: any;
}

// This is the loading of the entire APP.
//
// Dev note: Keep this as dumb as possible. In htis component we should
// only be concerned about what we should display when the app is in isLoading
// or isLoaded status also what we should display if we have an error
function AppLoading({ children, isLoaded, error }: AppLoadingProps): React.ReactElement {
  if (error) {
    if (error.response && error.response.status in ERROR_PAGE_CODES_MAP) {
      return <Error error={error} />;
    }
    if (error.response && error.response.status === 401) {
      return <div>redirecting...</div>;
    }
  }

  if (isLoaded) {
    return children;
  }

  return <PageLoading />;
}

export default AppLoading;
