import { CancellableGetType } from '../../../lib/api/type';
import httpClient from '../../../lib/api';
import { UserProfileType } from '../../../lib/user/type';
import { TYPE_DISCUSSION } from '../DetailsPage/components/ItemList/type';
import * as qs from 'qs';
import {
  AbstractItemPaginator,
  CompletedItemType,
  ConversationsQuery,
  ConversationType,
  DiscussionItemPayload,
  DiscussionItemsQueryType,
  DiscussionItemType,
  DiscussionItemNoteUpdatePayload,
  NotePayload,
  NoteQuery,
  NotesPaginator,
  NoteType,
  UserSearchQueryType,
  ConversationVisibilityPayload,
} from '../type';

export const resources: any = {
  conversations: {
    url: '/conversations',
  },
  users: {
    url: '/users',
  },
  conversation: {
    url: '/conversations/:id',
  },
  conversationVisibility: {
    url: '/conversations/:id/visibility',
  },
  discussionItems: {
    url: '/conversations/:id/discussion-items',
  },
  createDiscussionItem: {
    url: '/conversation-discussion-items',
  },
  discussionItem: {
    url: '/conversation-discussion-items/:id',
  },
  discussionItemNotes: {
    url: '/conversation-discussion-items/:id/notes',
  },
  conversationNotes: {
    url: '/conversation-notes',
  },
  conversationNote: {
    url: '/conversation-notes/:id',
  },
};

export class ConversationsApi {
  searchUsers(userSearchQuery: UserSearchQueryType): CancellableGetType {
    const { get, cancel } = httpClient.cancellableGet(resources.users.url, {
      params: { ...userSearchQuery },
      headers: { 'Content-Type': 'application/vnd.api+json', Accept: 'application/vnd.api+json' },
    });
    const getPromise = get
      .then(response => {
        const { data } = response;
        const result = {
          data: [],
          meta: undefined,
        };
        if (data?.data) {
          result.data = data.data.map(
            (item: any): UserProfileType => ({
              id: item.id,
              firstName: item.attributes.firstName,
              lastName: item.attributes.lastName,
              email: item.attributes.email,
              profileImage: item.attributes.profileImage,
            })
          );
          result.meta = data.meta;
        }
        return Promise.resolve(result);
      })
      .catch((error: Error) => {
        // if the error is cancelled request, we don't need to handle it
        if (error.message === 'Request cancelled') {
          return undefined;
        }
        return Promise.reject(error);
      });
    return {
      get: getPromise,
      cancel,
    };
  }

  async getConversations(query: ConversationsQuery): Promise<any> {
    try {
      const response = await httpClient.get(resources.conversations.url, {
        params: { ...query },
        headers: { 'Content-Type': 'application/json', Accept: 'application/vnd.api+json' },
      });
      const { data } = response;
      const result = {
        data: [],
        meta: undefined,
      };

      if (data?.data) {
        result.data = data.data.map((item: any): ConversationType => {
          let user;
          if (item.attributes.user) {
            const userItem = item.attributes.user.data;
            user = {
              id: userItem.id,
              firstName: userItem.attributes.firstName,
              lastName: userItem.attributes.lastName,
              email: userItem.attributes.email,
              profileImage: userItem.attributes.profileImage,
              position: userItem.attributes.position,
              isActive: userItem.attributes.isActive,
            };
          } else {
            const member = item.attributes.members[0].data;
            user = {
              id: member.id,
              firstName: member.attributes.firstName,
              lastName: member.attributes.lastName,
              email: member.attributes.email,
              profileImage: member.attributes.profileImage,
              position: member.attributes.position,
              isActive: member.attributes.isActive,
            };
          }

          return {
            id: item.id,
            user,
            totalDiscussionItem: item.attributes.totalDiscussionItem,
            totalCompletedItem: item.attributes.totalCompletedItem,
            _id: item.attributes._id,
          };
        });
        result.meta = data.meta;
      }

      return result;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  conversationCreation(userId: string): Promise<any> {
    const payload = { users: [userId] };
    return httpClient
      .post(resources.conversations.url, payload)
      .then((response: { data: any }) => response.data)
      .catch((error: any) => Promise.reject(error.response.data));
  }

  updateConversationVisibility(id: number, payload: ConversationVisibilityPayload): Promise<any> {
    return httpClient
      .patch(resources.conversationVisibility.url.replace(':id', id), payload, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
          Accept: 'application/vnd.api+json',
        },
      })
      .then(() => Promise.resolve(true))
      .catch((error: any) => Promise.reject(error));
  }

  getConversationDetail(id: number): CancellableGetType {
    const { get, cancel } = httpClient.cancellableGet(
      resources.conversation.url.replace(':id', id),
      {
        headers: { 'Content-Type': 'application/vnd.api+json', Accept: 'application/vnd.api+json' },
      }
    );
    const promise = get
      .then(result => {
        const { data } = result;
        let user;
        if (data?.data) {
          if (data.data.attributes.user) {
            user = {
              id: data.data.attributes.user?.data.id,
              firstName: data.data.attributes.user?.data.attributes.firstName,
              lastName: data.data.attributes.user?.data.attributes.lastName,
              email: data.data.attributes.user?.data.attributes.email,
              profileImage: data.data.attributes.user?.data.attributes.profileImage,
              isActive: data.data.attributes.user?.data.attributes.isActive,
            };
          } else {
            user = {
              id: data.data.attributes.members[0]?.data.id,
              firstName: data.data.attributes.members[0]?.data.attributes.firstName,
              lastName: data.data.attributes.members[0]?.data.attributes.lastName,
              email: data.data.attributes.members[0]?.data.attributes.email,
              profileImage: data.data.attributes.members[0]?.data.attributes.profileImage,
              isActive: data.data.attributes.members[0]?.data.attributes.isActive,
            };
          }
        }

        const members = data.data.attributes.members.map(
          (item: any): UserProfileType => ({
            id: item.data.id,
            firstName: item.data.attributes.firstName,
            lastName: item.data.attributes.lastName,
            email: item.data.attributes.email,
            profileImage: item.data.attributes.profileImage,
          })
        );

        return {
          id: data?.data.id,
          user,
          members,
          totalDiscussionItem: data?.data.attributes.totalDiscussionItem,
          totalCompletedItem: data?.data.attributes.totalCompletedItem,
        };
      })
      .catch((error: Error) => {
        if (error.message === 'Request cancelled') {
          return undefined;
        }
        return Promise.reject(error);
      });
    return {
      get: promise,
      cancel,
    };
  }

  getDiscussionItems(payload: DiscussionItemsQueryType): CancellableGetType {
    const { get, cancel } = httpClient.cancellableGet(
      resources.discussionItems.url.replace(':id', payload.conversationId),
      {
        params: {
          page: payload.page,
          isCompleted: payload.isCompleted,
          itemsPerPage: payload.itemsPerPage,
          order: payload.order,
        },
        paramsSerializer: (params: any) => qs.stringify(params),
        headers: { 'Content-Type': 'application/vnd.api+json', Accept: 'application/vnd.api+json' },
      }
    );
    const promise = get
      .then(response => {
        const { data, meta, included } = response.data;
        const result: AbstractItemPaginator = {
          data: [],
          meta: {
            totalItems: 0,
          },
        };
        if (data) {
          result.data = data.map((item: any): DiscussionItemType | CompletedItemType => {
            const createdBy = included.find(
              (includedItem: any): any =>
                item.relationships.createdBy &&
                includedItem.id === item.relationships.createdBy.data.id
            );
            const performanceItem = included.find(
              (includedItem: any): any =>
                item.relationships.performanceItem &&
                includedItem.id === item.relationships.performanceItem.data.id
            );

            const discussionItem: DiscussionItemType | CompletedItemType = {
              id: item.id,
              title: item.attributes.title,
              isCompleted: item.attributes.isCompleted,
              conversation: item.relationships.conversation.data.id,
              user: {
                id: createdBy.id,
                firstName: createdBy.attributes.firstName,
                lastName: createdBy.attributes.lastName,
                email: createdBy.attributes.email,
                profileImage: createdBy.attributes.profileImage,
              },
              totalNote: item.attributes.totalNote,
              createdAt: item.attributes.createdAt,
              modifiedAt: item.attributes.modifiedAt,
              type: TYPE_DISCUSSION,
            };

            if (performanceItem) {
              discussionItem.performanceItem = {
                text: performanceItem.attributes.text,
                performanceId: performanceItem.attributes.performanceId,
                sectionId: performanceItem.attributes.sectionId,
                sectionItemId: performanceItem.attributes.sectionItemId,
                module: performanceItem.attributes.module,
              };
            }

            return discussionItem;
          });
          result.meta = meta;
        }
        return Promise.resolve(result);
      })
      .catch((error: Error) => {
        if (error.message === 'Request cancelled') {
          return undefined;
        }
        return Promise.reject(error);
      });
    return {
      get: promise,
      cancel,
    };
  }

  createDiscussionItem(payload: DiscussionItemPayload): Promise<any> {
    return httpClient
      .post(resources.createDiscussionItem.url, payload, {
        headers: { 'Content-Type': 'application/json', Accept: 'application/vnd.api+json' },
      })
      .then((response: { data: any }) => {
        const { data, included } = response.data;
        const createdUserId = data.relationships.createdBy.data.id;
        const createdBy = included.find(
          (includedItem: any): any => includedItem.id === createdUserId
        );
        const result: DiscussionItemType = {
          id: data.id,
          title: data.attributes.title,
          isCompleted: data.attributes.isCompleted,
          conversation: data.relationships.conversation.data.id,
          user: {
            id: createdBy.id,
            firstName: createdBy.attributes.firstName,
            lastName: createdBy.attributes.lastName,
            email: createdBy.attributes.email,
            profileImage: createdBy.attributes.profileImage,
          },
          totalNote: data.attributes.totalNote,
          createdAt: data.attributes.createdAt,
          modifiedAt: data.attributes.modifiedAt,
          type: TYPE_DISCUSSION,
        };

        return Promise.resolve(result);
      })
      .catch((error: any) => Promise.reject(error));
  }

  updateDiscussionItem(id: number, payload: DiscussionItemPayload): Promise<any> {
    return httpClient
      .put(resources.discussionItem.url.replace(':id', id), payload, {
        headers: { 'Content-Type': 'application/json', Accept: 'application/vnd.api+json' },
      })
      .then((response: { data: any }) => Promise.resolve(true))
      .catch((error: any) => Promise.reject(error));
  }

  changeDiscussionItemCompletion(discussionItemId: number, isCompleted: boolean): Promise<any> {
    return httpClient
      .patch(
        resources.discussionItem.url.replace(':id', discussionItemId),
        {
          isCompleted,
        },
        {
          headers: {
            'Content-Type': 'application/merge-patch+json',
            Accept: 'application/vnd.api+json',
          },
        }
      )
      .then((response: { data: any }) => Promise.resolve(true))
      .catch((error: any) => Promise.reject(error));
  }

  deleteDiscussionItem(discussionItemId: number): Promise<any> {
    return httpClient
      .delete(resources.discussionItem.url.replace(':id', discussionItemId), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/vnd.api+json',
        },
      })
      .then((response: { data: any }) => Promise.resolve(true))
      .catch((error: any) => Promise.reject(error));
  }

  getDiscussionItemNotes(query: NoteQuery): CancellableGetType {
    const { discussionItemId, ...queryParams } = query;
    const { get, cancel } = httpClient.cancellableGet(
      resources.discussionItemNotes.url.replace(':id', discussionItemId),
      {
        params: { ...queryParams },
        paramsSerializer: (params: any) => qs.stringify(params),
        headers: { 'Content-Type': 'application/vnd.api+json', Accept: 'application/vnd.api+json' },
      }
    );
    const promise = get
      .then(response => {
        const { data } = response;
        const result: NotesPaginator = {
          data: [],
          meta: {
            totalItems: 0,
          },
        };
        if (data?.data) {
          result.data = data.data.map((item: any): NoteType => {
            const createdUserId = item.relationships.createdBy.data.id;
            const createdBy = data.included.find(
              (includedItem: any): any => includedItem.id === createdUserId
            );
            return {
              id: item.id,
              discussionItem: data.discussionItem,
              text: item.attributes.text,
              isDisplayInPerformance: item.attributes.isDisplayInPerformance,
              user: {
                id: createdBy.id,
                firstName: createdBy.attributes.firstName,
                lastName: createdBy.attributes.lastName,
                email: createdBy.attributes.email,
                profileImage: createdBy.attributes.profileImage,
              },
              createdAt: item.attributes.createdAt,
              modifiedAt: item.attributes.modifiedAt,
            };
          });
          result.meta.totalItems = data.meta.totalItems;
        }
        return Promise.resolve(result);
      })
      .catch((error: Error) => {
        if (error.message === 'Request cancelled') {
          return undefined;
        }
        return Promise.reject(error);
      });
    return {
      get: promise,
      cancel,
    };
  }

  deleteConversationNote(noteId: number): Promise<any> {
    return httpClient
      .delete(resources.conversationNote.url.replace(':id', noteId), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/vnd.api+json',
        },
      })
      .then((response: { data: any }) => Promise.resolve(true))
      .catch((error: any) => Promise.reject(error));
  }

  createConversationNote(payload: NotePayload): Promise<any> {
    return httpClient
      .post(
        resources.conversationNotes.url,
        {
          discussionItem: payload.discussionItem,
          text: payload.text,
          isDisplayInPerformance: payload.isDisplayingInPerformance,
        },
        {
          headers: { 'Content-Type': 'application/json', Accept: 'application/vnd.api+json' },
        }
      )
      .then((response: { data: any }) => {
        const { data, included } = response.data;
        const createdUserId = data.relationships.createdBy.data.id;
        const createdBy = included.find(
          (includedItem: any): any => includedItem.id === createdUserId
        );
        const result: NoteType = {
          id: data.id,
          discussionItem: data.relationships.discussionItem.data.id,
          user: {
            id: createdBy.id,
            firstName: createdBy.attributes.firstName,
            lastName: createdBy.attributes.lastName,
            email: createdBy.attributes.email,
            profileImage: createdBy.attributes.profileImage,
          },
          text: data.attributes.text,
          createdAt: data.attributes.createdAt,
          modifiedAt: data.attributes.modifiedAt,
        };

        return Promise.resolve(result);
      })
      .catch((error: any) => Promise.reject(error));
  }

  updateDiscussionItemNote(id: number, payload: DiscussionItemNoteUpdatePayload): Promise<any> {
    return httpClient
      .put(resources.conversationNote.url.replace(':id', id), payload)
      .then(() => Promise.resolve(true))
      .catch((error: any) => Promise.reject(error));
  }
}

export default new ConversationsApi();
