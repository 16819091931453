import React from 'react';
import { t, messages } from '../../lib/translation';
import MessageLayout from '../../element/MessageLayout';
import { Layout } from 'elmo-elements';

const NotFoundContainer = (): React.ReactElement => (
  <Layout.Content>
    <MessageLayout
      title={t(messages.app.notfound.title)}
      content={t(messages.app.notfound.content)}
    />
  </Layout.Content>
);

export default NotFoundContainer;
