var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import './MultiLevelModal.scss';
import { getClass } from '../_lib/helper';
var SubModalWrapper = /** @class */ (function (_super) {
    __extends(SubModalWrapper, _super);
    function SubModalWrapper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isEnable: false,
            isActive: false,
        };
        _this.handleOnAnimationEnd = function () {
            if (_this.state.isEnable && !_this.state.isActive) {
                _this.setState({ isEnable: false });
            }
        };
        return _this;
    }
    SubModalWrapper.prototype.componentDidUpdate = function (prevProps, prevState) {
        var _a = this.props, modalKey = _a.modalKey, active = _a.active;
        var _b = this.state, isEnable = _b.isEnable, isActive = _b.isActive;
        if (modalKey === active) {
            if (!isEnable) {
                this.setState({ isEnable: true });
            }
            else {
                if (!prevState.isEnable) {
                    this.setState({ isActive: true });
                }
            }
        }
        else {
            if (isEnable && isActive) {
                this.setState({ isActive: false });
            }
        }
    };
    SubModalWrapper.prototype.render = function () {
        var _a = this.props, modalKey = _a.modalKey, subModals = _a.subModals;
        var _b = this.state, isActive = _b.isActive, isEnable = _b.isEnable;
        return (isEnable && (React.createElement("div", { className: getClass('elmo-multilevel-modal__sub', {
                'is-active': isActive,
            }), key: modalKey, onAnimationEnd: this.handleOnAnimationEnd }, subModals[modalKey])));
    };
    return SubModalWrapper;
}(Component));
var MultiLevelModal = /** @class */ (function (_super) {
    __extends(MultiLevelModal, _super);
    function MultiLevelModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getClassNames = function () {
            var _a = _this.props, className = _a.className, active = _a.active;
            var activeModal = active !== '' ? ' is-sub-modal' : ' is-main-modal';
            var additionalClassNames = className ? className : '';
            return "".concat(activeModal, " ").concat(additionalClassNames);
        };
        return _this;
    }
    MultiLevelModal.prototype.render = function () {
        var _a = this.props, mainModal = _a.mainModal, subModals = _a.subModals, active = _a.active, id = _a.id;
        return (React.createElement("div", { id: id, "data-testid": "elmo-multilevel-modal-".concat(id || 'default'), className: getClass('elmo-multilevel-modal', this.getClassNames()) },
            React.createElement("div", { className: 'elmo-multilevel-modal__main' }, typeof mainModal !== 'undefined' && mainModal),
            typeof subModals !== 'undefined' &&
                Object.keys(subModals).map(function (key) { return (React.createElement(SubModalWrapper, { key: key, modalKey: key, active: active, subModals: subModals })); })));
    };
    return MultiLevelModal;
}(Component));
export default MultiLevelModal;
