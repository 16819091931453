/**
 *  <Dev.Note>
 *    In this file only define the parameters that are not subjected to change based on the environment.
 *    For example list of pagination page sizes (10, 20, 30) does not need to be changed in dev or staging env
 *    so you can define it here. In contrast an API base url is subjected to change from the staging or dev
 *    enviroement so you should define that in the config servise
 *  </Dev.Note>
 */

export const ENV: any = {
  names: {
    development: 'development',
    staging: 'staging',
    production: 'production',
  },
};

export const PAGINATION: any = {
  SIZE_OPTIONS: [10, 20, 30],
};

// date format like 24/11/2018
export const DATE_FORMAT_DD_MM_YYYY = 'DD/MM/YYYY';

// date formate like 24/11/2023 10:36pm
export const DATE_FORMAT_DD_MM_YYYY_H_MM_A = 'DD/MM/YYYY h:mm a';

// date format like May 24, 2018
export const DATE_FORMAT_MMM_DD_YYYY = 'MMM DD, YYYY';

// date format like 1 Jan 2019
export const DATE_FORMAT_D_MMM_YYYY = 'D MMM YYYY';

export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';

export const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const NOTICE_TYPES = {
  DARK: 'dark',
  INFO: 'info',
  SUCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
};

/**
 *  appraisal STATUS consts
 */
export const STATUS_ALL = '0';
export const STATUS_PLANNING = '1';
export const STATUS_INPROGRESS = '2';
export const STATUS_FINALISE = '3';
export const STATUS_COMPLETED = '4';
export const STATUS_ACTIVE = '1,2,3';
export const DEFAULT_PARTICIPANT = '2';

export const PERFORMANCE_MENU_KEY = 'app.menu.performance';

export const ASSIGN_APPRAISAL_TO_SELECTED_REPORTS = '1';
export const ASSIGN_APPRAISAL_TO_DIRECT_REPORTS = '2';
export const ASSIGN_APPRAISAL_TO_ALL_REPORTS = '3';

export const DEFAULT_SORT_COLUMN = 3;

/**
 * Filter breakpoint props
 * This props is use for Quick Filter
 */
export const breakpointButtons = {
  xs: 1,
  sm: 3,
  md: 3,
  lg: 3,
  xl: 3,
  xxl: 3,
};

export const HTTP_RESPONSE_CODE = {
  NO_CONTENT_SUCCESS: 204,
};

export const ERROR_PAGE_CODES_MAP = {
  502: true,
  504: true,
};

export const POLLING_CONTINUE_POLL_STATUSES = {
  Waiting: true,
  'In progress': true,
  Paused: true,
};

export const POLLING_ERROR_POLL_STATUSES = {
  Unsuccessful: true,
  Suspend: true,
};

export const POLLING_FINISHED_POLL_STATUSES = {
  Finished: true,
};

export const POLLING_APPRAISAL_ASSIGNMENT_TYPE = 'appraisalAssignment';

export const MODULE = {
  GOAL: 'goal',
  DEVELOPMENT: 'development',
  COMPETENCY: 'competency',
};

export const ROUTE_PREFIX_MYPERFORMANCE = 'my-performance';
export const ROUTE_PREFIX_TEAMPERFORMANCE = 'team-performance';
export const ROUTE_PREFIX_SIGNOFFPERFORMANCE = 'team-signoff-performance';
export const ROUTE_PREFIX_REVIEW360PERFORMANCE = 'review-360-performance';
export const ROUTE_PREFIX_APPROVALPERFORMANCE = 'approval-performance';
export const ROUTE_PREFIX_ASSIGNAPPRAISAL = 'assign-appraisal';

export const PERFORMANCE_VISIBILITY_DEFAULT = 0;
export const PERFORMANCE_VISIBILITY_MANAGER = 1;
export const PERFORMANCE_VISIBILITY_ALL_MANAGER = 2;

export const PERFORMANCE_EMPLOYEE_SEARCH_SCALE_DIRECT = 0;
export const PERFORMANCE_EMPLOYEE_SEARCH_SCALE_INDIRECT = 1;
export const PERFORMANCE_EMPLOYEE_SEARCH_SCALE_ORGANISATION = 2;
