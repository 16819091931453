var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Loader from '../../Loader';
import { Row, Col } from '../../Grid';
import Card from '../../Card';
import { getRandomItem } from '../../_lib/helper';
import './PlaceholderDashboard.scss';
/**
 * Card title
 */
var title = React.createElement(Loader, { type: "short-text", width: 200, height: 24 });
var randomTypes = ['code', 'list', 'content', 'bullet'];
/**
 * Get cols
 *
 * @param cols
 */
function getCols(cols, colResponsiveOptions, rowIndex, types) {
    var elements = [];
    var size = 24 / cols;
    for (var i = 0; i < cols; i++) {
        var currentIndex = rowIndex * cols + i;
        var type = types && types[currentIndex]
            ? types[currentIndex]
            : getRandomItem(randomTypes);
        elements.push(React.createElement(Col, __assign({ key: 'elmo-placeholder-col-' + i, md: size, sm: size, lg: size }, colResponsiveOptions, { role: "presentation" }),
            React.createElement(Card, { heading: title },
                React.createElement(Loader, { type: type, className: "elmo-dashboard-placeholder__loader" }))));
    }
    return elements;
}
/**
 * Get rows and cols
 *
 * @param rows
 * @param cols
 * @param colResponsiveOptions
 * @param types
 */
function getRows(rows, cols, colResponsiveOptions, types) {
    var elements = [];
    for (var i = 0; i < rows; i++) {
        elements.push(React.createElement(Row, { isNoGutters: false, key: 'elmo-placeholder-row-' + i, role: "presentation" }, getCols(cols, colResponsiveOptions, i, types)));
    }
    return elements;
}
/**
 * Placeholder for a dashboard
 */
function PlaceholderDashboard(_a) {
    var id = _a.id, cols = _a.cols, rows = _a.rows, types = _a.types, colResponsiveOptions = __rest(_a, ["id", "cols", "rows", "types"]);
    return (React.createElement("div", { id: id, "data-testid": "elmo-dashboard-placeholder-".concat(id || 'default'), className: "elmo-dashboard-placeholder" }, getRows(rows, cols, colResponsiveOptions, types)));
}
PlaceholderDashboard.defaultProps = {
    cols: 3,
    rows: 3,
};
export default PlaceholderDashboard;
