import httpClient from '../../../lib/api';
import { Review360PerformanceQuery, Review360PerformanceApiInterface } from '../type';

export const resources: any = {
  myReview: {
    url: '/performance-api/v1/review-360-performances',
  },
};
export class Review360PerformanceApi implements Review360PerformanceApiInterface {
  getReview360Performance(query: Review360PerformanceQuery): Promise<any> {
    return httpClient
      .get(resources.myReview.url, {
        params: {
          // params mapping
          page: query.page,
          limit: query.limit,
          sort: query.sort ? query.sort : undefined,
          keyword: query.keyword ? query.keyword : undefined,
        },
      })
      .then(result => {
        const total =
          typeof result.headers !== 'undefined' &&
          typeof result.headers['x-pagination-count'] !== 'undefined'
            ? result.headers['x-pagination-count']
            : 0;
        const pageNum =
          typeof result.headers !== 'undefined' &&
          typeof result.headers['x-pagination-page'] !== 'undefined'
            ? result.headers['x-pagination-page']
            : query.page;
        const limitNum =
          typeof result.headers !== 'undefined' &&
          typeof result.headers['x-pagination-limit'] !== 'undefined'
            ? result.headers['x-pagination-limit']
            : query.limit;
        return Promise.resolve({
          total,
          page: pageNum,
          limit: limitNum,
          data: typeof result.data !== 'undefined' ? result.data : [],
        });
      })
      .catch((error: Error) => Promise.reject(error));
  }
}

export default new Review360PerformanceApi();
