import SignOffPerformance from '.';
import { setPerformanceBasePath } from '../../lib/route';

export const SignOffPerformancePath = setPerformanceBasePath('/team-signoff-performance');

const SignOffPerformancesRoutes: any = {
  path: SignOffPerformancePath,
  component: SignOffPerformance,
  exact: true,
};

export type SignOffPerformancesRoutesType = typeof SignOffPerformancesRoutes;

export default SignOffPerformancesRoutes;
