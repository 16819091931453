var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { MoreHorizIcon as ActionsIcon } from '../../Icon';
import Dropdown from '../../Dropdown';
import Button from '../../Button';
var ListTableActions = /** @class */ (function (_super) {
    __extends(ListTableActions, _super);
    function ListTableActions() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ListTableActions.prototype.render = function () {
        return (React.createElement("div", { className: "elmo-listtable__row-actions", role: "cell" }, this.renderContent()));
    };
    ListTableActions.prototype.renderContent = function () {
        var _a = this.props, actions = _a.actions, icon = _a.icon, isPlaceholderRendered = _a.isPlaceholderRendered;
        if (actions && actions.length) {
            return this.renderDropdown(actions, icon || React.createElement(ActionsIcon, null));
        }
        if (icon) {
            return this.renderIcon(icon);
        }
        if (isPlaceholderRendered) {
            return this.renderPlaceholder();
        }
        return null;
    };
    ListTableActions.prototype.renderDropdown = function (actions, icon) {
        var rowIndex = this.props.rowIndex;
        return (React.createElement(Dropdown, { icon: icon, position: 'bottom-end', ariaLabel: "Actions" }, actions.reduce(function (result, action, index) {
            var dropdownItem = (React.createElement(Dropdown.Item, { ariaLabel: action.ariaLabel, onClick: action.onClick, value: rowIndex, isDisabled: action.isDisabled, key: 'dropdown-item-' + index },
                action.icon,
                " ",
                action.label));
            var divider = (React.createElement(Dropdown.Divider, { type: "short", key: 'dropdown-divider-' + index }));
            return action.withDivider
                ? __spreadArray(__spreadArray([], result, true), [dropdownItem, divider], false) : __spreadArray(__spreadArray([], result, true), [dropdownItem], false);
        }, [])));
    };
    ListTableActions.prototype.renderIcon = function (icon) {
        return React.createElement("div", { className: "elmo-listtable__actions-icon" }, icon);
    };
    ListTableActions.prototype.renderPlaceholder = function () {
        return (React.createElement(Button, { icon: React.createElement(ActionsIcon, null), className: "elmo-listtable__row-actions-placeholder" }));
    };
    return ListTableActions;
}(React.Component));
export default ListTableActions;
