import { FilterStatus } from 'elmo-elements/dist/Filter';
import { STATUS_ACTIVE, STATUS_COMPLETED } from '../../../lib/const';
import { messages, t } from '../../../lib/translation';
import {
  PerformanceListState,
  PerformancePaginator,
  PerformanceState,
  UserPerformanceQuery,
  SectionListState,
  SectionListType,
  SectionItemListState,
} from '../type';

export const defaultSectionList: SectionListType = {
  performanceId: 0,
  sections: [],
};

export const defaultSectionState: SectionListState = {
  isLoading: false,
  isLoaded: false,
  data: defaultSectionList,
  error: undefined,
};

export const defaultSectionItemState: SectionItemListState = {
  isLoading: false,
  isLoaded: false,
  data: [],
  error: undefined,
};

export const defaultUserPerformanceQuery: UserPerformanceQuery = {
  page: 1,
  itemsPerPage: 30,
  employeeId: '',
  phases: [1, 2, 3],
};

export const defaultPerformanceState: PerformanceState = {
  userList: {},
  sectionList: defaultSectionState,
  sectionItemList: defaultSectionItemState,
};

export const defaultPerformancePaginator: PerformancePaginator = {
  meta: {
    totalItems: 0,
    itemsPerPage: 10,
    currentPage: 1,
  },
  data: [],
};

export const defaultPerformanceListState: PerformanceListState = {
  userId: '',
  isLoading: false,
  isLoaded: false,
  paginator: defaultPerformancePaginator,
  error: undefined,
};

export const UserPerformancePhaseFilterOptions: FilterStatus[] = [
  {
    value: STATUS_ACTIVE,
    label: t(messages.performance.phaseFilter.active),
  },
  {
    value: STATUS_COMPLETED,
    label: t(messages.performance.phaseFilter.completed),
  },
];

export default { ...defaultPerformanceState };
