import { createReducer } from 'redux-act';
import defaultState from './state';
import {
  appInit,
  appInitSuccess,
  appInitFailure,
  startPoll,
  pausePolls,
  cleanNotification,
  stopPoll,
  fetchNavigation,
  fetchNavigationSuccess,
  fetchNavigationFailure,
} from './actions';
import { StopPollPayloadType, StartPollPayloadType, AppStateType, NavigationType } from './type';
import { SystemConfiguration } from '../../lib/systemConfiguration/type';
import { ThemeType } from 'elmo-elements/dist/_lib/style/theme';

export function appInitReducer(state: AppStateType): AppStateType {
  return {
    ...state,
    isLoaded: false,
    isLoading: true,
  };
}

export function appInitSuccessReducer(
  state: AppStateType,
  { systemConfiguration, theme }: { systemConfiguration: SystemConfiguration; theme: ThemeType }
): AppStateType {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    systemConfiguration,
    theme,
  };
}

export function appInitFailureReducer(state: AppStateType, error: Error | null): AppStateType {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error,
  };
}

export function startPollReducer(
  state: AppStateType,
  { jobId, id, type, pushNotification }: StartPollPayloadType
): AppStateType {
  if (state.pollingJobs[jobId as number]) {
    return state;
  }
  const jobs = { ...state.pollingJobs };
  jobs[jobId as number] = {
    polling: true,
    id,
    type,
    pushNotification,
  };
  return {
    ...state,
    pollingJobs: jobs,
  };
}

export function pausePollsReducer(state: AppStateType): AppStateType {
  const jobs = { ...state.pollingJobs };
  Object.keys(jobs).forEach(job => {
    jobs[job as unknown as number].polling = false;
  });
  return {
    ...state,
    pollingJobs: jobs,
  };
}

/**
 * we only put data or error when job is finished or unsuccessful
 */
export function stopPollReducer(
  state: AppStateType,
  { jobId, data, error }: StopPollPayloadType
): AppStateType {
  let result = state;
  if (state.pollingJobs[jobId]) {
    const job = { ...state.pollingJobs[jobId] };
    job.data = data;
    job.error = error;
    job.polling = false;
    result = {
      ...state,
      pollingJobs: {
        ...state.pollingJobs,
        [jobId]: job,
      },
    };

    if (job.pushNotification) {
      let notification;
      if (error) {
        notification = {
          success: false,
          type: job.type,
          error,
        };
      } else {
        notification = {
          success: true,
          type: job.type,
          error: undefined,
        };
      }
      result = {
        ...result,
        notificationsMap: {
          ...state.notificationsMap,
          [jobId]: notification,
        },
      };
    }
  }

  return result;
}

export function cleanNotificationReducer(
  state: AppStateType,
  { id }: { id: number }
): AppStateType {
  const notificationsMap = { ...state.notificationsMap };
  delete notificationsMap[id];

  return { ...state, notificationsMap };
}

export function fetchNavigationReducer(state: AppStateType): AppStateType {
  const navigation = { ...state.navigation };
  navigation.isLoading = true;

  return {
    ...state,
    navigation,
  };
}

export function fetchNavigationSuccessReducer(
  state: AppStateType,
  data: NavigationType
): AppStateType {
  const navigation = { ...state.navigation };
  navigation.isLoaded = true;
  navigation.isLoading = false;
  navigation.menu = { ...data.menu };
  navigation.user = { ...data.user };
  navigation.branding = { ...data.branding };
  navigation.status = { ...data.status };
  navigation.footerMessage = data.footerMessage;

  return {
    ...state,
    navigation,
  };
}

export function fetchNavigationFailureReducer(state: AppStateType, error: Error): AppStateType {
  const navigation = { ...state.navigation };
  navigation.isLoaded = false;
  navigation.isLoading = true;
  navigation.error = error;

  return {
    ...state,
    navigation,
  };
}

export default createReducer(
  {
    [`${appInit}`]: appInitReducer,
    [`${appInitSuccess}`]: appInitSuccessReducer,
    [`${appInitFailure}`]: appInitFailureReducer,
    [`${startPoll}`]: startPollReducer,
    [`${stopPoll}`]: stopPollReducer,
    [`${cleanNotification}`]: cleanNotificationReducer,
    [`${pausePolls}`]: pausePollsReducer,
    [`${fetchNavigation}`]: fetchNavigationReducer,
    [`${fetchNavigationSuccess}`]: fetchNavigationSuccessReducer,
    [`${fetchNavigationFailure}`]: fetchNavigationFailureReducer,
  },
  defaultState
);
