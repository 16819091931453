import React from 'react';
import './Icon.scss';
import { getClass } from '../_lib/helper';
export * from './type';
export var IconDefaultProps = {
    size: 'md',
};
/**
 *
 * @param param
 */
function Icon(_a) {
    var _b;
    var children = _a.children, size = _a.size, title = _a.title, className = _a.className;
    return (React.createElement("span", { "data-testid": "elmo-icon", title: title, className: getClass('elmo-icon', className, (_b = {}, _b["size-".concat(size)] = size, _b)) },
        React.createElement("svg", { focusable: "false", viewBox: "0 0 24 24", "aria-hidden": "true", role: "presentation", className: "elmo-icon__svg" }, children)));
}
export function withIcon(iconComponent) {
    return function (_a) {
        var size = _a.size, title = _a.title, className = _a.className;
        return (React.createElement(Icon, { size: size, title: title, className: className }, React.cloneElement(iconComponent, {})));
    };
}
Icon.defaultProps = IconDefaultProps;
export default Icon;
