import React, { lazy } from 'react';
import Suspense from '../../../element/Suspense';

const View = lazy(() => import('./view'));
const PerformanceViewPage = ({ routes }: Record<string, any>): React.ReactElement => (
  <Suspense>
    <View routes={routes} />
  </Suspense>
);
export default PerformanceViewPage;
