import React, { lazy, useEffect, useContext } from 'react';
import Suspense from '../../element/Suspense';
import { SubmenuContext } from '../../element/Menu/submenuContext';
import SubMenuItems from '../../element/Menu/SubMenuItems';

const Review360PerformanceView = lazy(() => import('./view'));

const Review360Performance = ({ routes }: Record<any, any>): React.ReactElement => {
  const { setSubmenu } = useContext(SubmenuContext);
  useEffect(() => {
    if (setSubmenu) {
      setSubmenu(SubMenuItems.review360Performance.key);
    }
  }, [setSubmenu]);

  return (
    <Suspense>
      <Review360PerformanceView routes={routes} />
    </Suspense>
  );
};

export default Review360Performance;
