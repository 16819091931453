import { loadTranslations } from '../translation';
import { loadAssets } from '../assets';
import { ErrorAppInitUndefinedError } from '../error';
import { loadTheme } from '../theme';
import { loadSystemConfiguration } from '../config/loadder';

/**
 * App setup and initialisations
 *
 * <Dev.Note>
 *  Any async/sync activities that need to be done first in order to load the app can be impelemnted here.
 *  As you can see we dont implement the logic related to each action in this file so we keep this setup as
 *  minimal and abstract as posible. In otherword the job of this `setup` function is to orchestrate some
 *  individual set up activities.
 * </Dev.Note>
 */
function setup(): Promise<any> {
  const promises: Promise<any>[] = [
    loadSystemConfiguration(),
    loadTheme(),
    loadTranslations(),
    loadAssets(),
  ];

  return (
    Promise.all(promises)
      .then(([systemConfiguration, theme]: any) => ({
        systemConfiguration,
        theme,
      }))
      // <Dev.Note>
      // The following condition should happen very rarely. It means you have an exception but there
      // is no valid error for that. For example you return a Promise.reject() isnide a function.
      // </Dev.Note>
      .catch((error: any) => Promise.reject(error || new ErrorAppInitUndefinedError()))
  );
}

export default setup;
