var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import LayoutContext from './LayoutContext';
function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
function withLayoutAware(WrappedComponent) {
    var LayoutAware = function (props) {
        return (React.createElement(LayoutContext.Consumer, null, function (layoutProps) { return React.createElement(WrappedComponent, __assign({}, props, layoutProps)); }));
    };
    LayoutAware.displayName = "LayoutAware(".concat(getDisplayName(WrappedComponent), ")");
    return LayoutAware;
}
export default withLayoutAware;
