import React, { useEffect } from 'react';
import { AlertModal } from '@eds/modal';
import {
  ASSIGN_APPRAISAL_TO_ALL_REPORTS,
  ASSIGN_APPRAISAL_TO_DIRECT_REPORTS,
} from '../../../../lib/const';
import { messages, t } from '../../../../lib/translation';
import { useCore } from '../../../../element/Core/CoreContext';

const AssignmentConfirmationModal = ({
  isModalOpen,
  appraisalTitle,
  selectedEmployeeNumber,
  assigneeType,
  onAssignConfirm,
  onClose,
}: {
  isModalOpen: boolean;
  appraisalTitle: string;
  selectedEmployeeNumber: number;
  assigneeType: string;
  onAssignConfirm: () => void;
  onClose: () => void;
}): React.ReactElement => {
  const { setIsModalOpen } = useCore();
  useEffect(() => {
    setIsModalOpen(isModalOpen);
  }, [isModalOpen, setIsModalOpen]);

  const description = {
    [ASSIGN_APPRAISAL_TO_DIRECT_REPORTS]: t(
      messages.assignAppraisal.assignAppraisalConfirmationLabel.direct,
      {
        title: appraisalTitle,
      }
    ),
    [ASSIGN_APPRAISAL_TO_ALL_REPORTS]: t(
      messages.assignAppraisal.assignAppraisalConfirmationLabel.all,
      {
        title: appraisalTitle,
      }
    ),
    default: t(messages.assignAppraisal.assignAppraisalConfirmationLabel.selectedMultiple, {
      title: appraisalTitle,
      number: selectedEmployeeNumber,
    }),
  }[assigneeType || 'default'];

  return (
    <AlertModal
      isOpen={isModalOpen}
      onClose={onClose}
      title="Confirm assignees"
      description={description}
      controls={{
        confirm: {
          label: 'Confirm',
          onClick: onAssignConfirm,
        },
        cancel: {
          label: 'Cancel',
          onClick: onClose,
          tone: 'ghost',
        },
      }}
    />
  );
};
export default AssignmentConfirmationModal;
