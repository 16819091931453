import React, { FC } from 'react';
import { Box } from '@eds/box';
import { css } from '@emotion/css';
import { CoreContextProvider, useCore } from './CoreContext';
import { content, modal } from './modal';

// This core component is created to fix the modal z-index issue with the main-header z-index
const CoreComponent: FC = ({ children }) => {
  const { isModalOpen } = useCore();

  // toggle the overrides
  const className = isModalOpen ? css(modal()) : css(content());

  return <Box className={className}>{children}</Box>;
};

export const Core: FC = ({ children }) => (
  <CoreContextProvider>
    <CoreComponent>{children}</CoreComponent>
  </CoreContextProvider>
);
