var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component, Children, cloneElement, } from 'react';
import Radio from '../Radio';
import { DIRECTION_HORIZONTAL, DIRECTION_VERTICAL } from '../_lib/const';
import { getClass, isElementOfType, uniqueId } from '../_lib/helper';
import PropTypes from 'prop-types';
import './RadioGroup.scss';
export var RadioGroupPropTypes = {
    selected: PropTypes.any,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    direction: PropTypes.oneOf([DIRECTION_VERTICAL, DIRECTION_HORIZONTAL]),
    ariaLabelledBy: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
};
var RadioGroup = /** @class */ (function (_super) {
    __extends(RadioGroup, _super);
    function RadioGroup(props) {
        var _this = _super.call(this, props) || this;
        _this.radioInputName = uniqueId('elmo-radio-group');
        _this.onRadioButtonClick = function (value) {
            _this.setState({
                selectedValue: value,
            });
            if (_this.props.onChange) {
                _this.props.onChange(value);
            }
        };
        _this.state = {
            selectedValue: props.selected,
        };
        return _this;
    }
    RadioGroup.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.selected !== prevProps.selected) {
            this.setState({
                selectedValue: this.props.selected,
            });
        }
    };
    RadioGroup.prototype.isSelected = function (value) {
        return this.state.selectedValue === value;
    };
    RadioGroup.prototype.renderRadioButtons = function () {
        var _this = this;
        var _a = this.props, id = _a.id, disabled = _a.disabled;
        return Children.map(this.props.children, function (child, index) {
            if (!isElementOfType(child, Radio)) {
                return null;
            }
            var radioChild = child;
            var childId;
            if (id) {
                childId = "".concat(id, "-").concat(index);
            }
            return cloneElement(radioChild, {
                onChange: _this.onRadioButtonClick,
                checked: _this.isSelected(radioChild.props.value),
                disabled: disabled || radioChild.props.disabled,
                id: childId,
                name: _this.radioInputName,
            });
        });
    };
    RadioGroup.prototype.render = function () {
        var _a = this.props, className = _a.className, id = _a.id, ariaLabelledBy = _a.ariaLabelledBy, direction = _a.direction;
        var classes = getClass('elmo-radio-group', [className, 'row flex-inline no-gutters'], {
            col: direction === DIRECTION_VERTICAL,
        });
        return (React.createElement("div", { id: id, className: classes, role: "radiogroup", "aria-labelledby": ariaLabelledBy, "data-testid": "elmo-radio-group-".concat(id || 'default') }, this.renderRadioButtons()));
    };
    RadioGroup.propTypes = RadioGroupPropTypes;
    return RadioGroup;
}(Component));
export default RadioGroup;
