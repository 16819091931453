import React, { useEffect } from 'react';
import { ContentModal, ControlsProps } from '@eds/modal';
import { useCore } from '../../../../element/Core/CoreContext';
import { messages, t } from '../../../../lib/translation';
import { Box } from '@eds/box';
import { Flex } from '@eds/flex';
import { Heading } from '@eds/heading';
import { Text } from '@eds/text';
import AssigneeListView from '../AssigneeList';

const AssigneeListModal = ({
  appraisalId,
  appraisalTitle,
  onClose,
  isOpen,
}: {
  appraisalId: number;
  appraisalTitle: string;
  onClose: () => void;
  isOpen: boolean;
}): React.ReactElement => {
  const { setIsModalOpen } = useCore();
  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen, setIsModalOpen]);

  const controls: ControlsProps = {
    confirm: { label: 'Close', onClick: onClose, tone: 'neutral' },
  };

  return (
    <ContentModal
      isOpen={isOpen}
      onClose={onClose}
      header={t(messages.assignAppraisal.assigneeList.title)}
      controls={controls}
    >
      <Box marginTop="medium" marginBottom="large">
        <Flex alignItems="center">
          <Heading level={4}>{appraisalTitle}</Heading>
        </Flex>
        <Text fontSize="small" lineHeight="tight" color="neutralSubtle">
          {t(messages.assignAppraisal.assigneeList.assignedEmployees)}
        </Text>
      </Box>
      <AssigneeListView appraisalId={appraisalId} />
    </ContentModal>
  );
};

export default AssigneeListModal;
