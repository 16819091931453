var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { Component } from 'react';
import FilterButtons from './component/FilterButtons';
import FilterDropdown from './component/FilterDropdown';
import { debounce, getClass, screensizeToBreakpoint } from '../_lib/helper';
import './Filter.scss';
import FilterCustomModal from './component/FilterCustomModal';
import CustomFilterSummary from './component/CustomFilterSummary';
import { BreakpointNames } from '../_lib/style/config';
/** How often to call the callback for the window resize event */
var WINDOW_RESIZE_DELAY = 100;
/** Number of buttons for each breakpoint. Preset by design team. */
var NUM_BUTTONS_BREAKPOINTS = {
    xs: 1,
    sm: 3,
    md: 5,
    lg: 5,
    xl: 5,
    xxl: 5,
};
var Filter = /** @class */ (function (_super) {
    __extends(Filter, _super);
    function Filter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentBreakpoint = BreakpointNames.xxl;
        _this.state = {
            numStatusesToShow: 1,
            buttonStatuses: [],
            dropdownStatuses: [],
            selectedIndex: null,
            isModalOpen: false,
            isCustomFilterActive: false,
        };
        /**
         * When the window is resized, recalculate the list of items in buttons and in the dropdown
         */
        _this.handleResize = function () {
            var oldBreakpoint = _this.currentBreakpoint;
            _this.currentBreakpoint = screensizeToBreakpoint();
            if (_this.currentBreakpoint !== oldBreakpoint) {
                _this.updateButtonsAndDropdown(_this.state.selectedIndex);
            }
        };
        /**
         * Get the index of the selected value in the array of statuses, and update the selectedIndex
         * @param value
         */
        _this.updateSelectedIndex = function (value) {
            var statuses = _this.props.statuses;
            // look for the index of the selected item
            var selectedIndex = statuses.findIndex(function (s) {
                return s.value === value;
            });
            var indexNotFound = selectedIndex === -1;
            _this.setState({
                selectedIndex: indexNotFound ? null : selectedIndex,
                // if the selected status is not in the list of statuses, then custom filters are enabled:
                isCustomFilterActive: indexNotFound,
            });
            return selectedIndex;
        };
        /**
         * Called when the selected status is changed
         * @param status
         */
        _this.handleChange = function (status) {
            var onStatusChange = _this.props.onStatusChange;
            var selectedIndex = _this.updateSelectedIndex(status);
            onStatusChange(status);
            return selectedIndex;
        };
        /**
         * Called when a dropdown item is clicked.
         */
        _this.handleDropdownItemClick = function (status) {
            var selectedIndex = _this.handleChange(status);
            _this.updateButtonsAndDropdown(selectedIndex);
        };
        /**
         * Toggles the modal
         */
        _this.toggleModal = function () {
            _this.setState(function (_a) {
                var isModalOpen = _a.isModalOpen;
                return ({ isModalOpen: !isModalOpen });
            }, function () {
                if (_this.props.onToggleCustomFilters) {
                    _this.props.onToggleCustomFilters(_this.state.isModalOpen);
                }
            });
        };
        /**
         * Called when Apply is clicked in the modal.
         */
        _this.handleModalApply = function () {
            var onApplyCustomFilters = _this.props.onApplyCustomFilters;
            _this.toggleModal();
            _this.setState({
                selectedIndex: null,
                isCustomFilterActive: true,
            });
            if (onApplyCustomFilters) {
                onApplyCustomFilters();
            }
        };
        return _this;
    }
    Filter.prototype.componentDidUpdate = function (prevProps) {
        var _a = this.props, statuses = _a.statuses, selectedValue = _a.selectedValue;
        var prevStatuses = prevProps.statuses, prevSelectedValue = prevProps.selectedValue;
        if (statuses !== prevStatuses) {
            this.updateButtonsAndDropdown(this.state.selectedIndex);
        }
        if (selectedValue !== prevSelectedValue) {
            this.updateSelectedIndex(selectedValue);
        }
    };
    Filter.prototype.componentDidMount = function () {
        var selectedValue = this.props.selectedValue;
        var selectedIndex = this.updateSelectedIndex(selectedValue);
        this.currentBreakpoint = screensizeToBreakpoint();
        this.updateButtonsAndDropdown(selectedIndex);
        this.windowResizeEventListener = debounce(this.handleResize, WINDOW_RESIZE_DELAY);
        window.addEventListener('resize', this.windowResizeEventListener);
    };
    Filter.prototype.componentWillUnmount = function () {
        window.removeEventListener('resize', this.windowResizeEventListener);
    };
    /**
     * Split the statuses among the radio button bar and the dropdown
     * @param selectedIndex
     */
    Filter.prototype.updateButtonsAndDropdown = function (selectedIndex) {
        var statuses = this.props.statuses;
        statuses = statuses.slice(); // required so that the array itself is not modified
        var currButtonStatuses = [];
        var currDropdownStatuses = [];
        var mergeBreakpoints = __assign(__assign({}, NUM_BUTTONS_BREAKPOINTS), this.props.breakpoint);
        var numButtons = mergeBreakpoints[this.currentBreakpoint];
        // if there is no selected status or if the selected status is within the number of required buttons to show
        // just grab the first <numButtons> buttons
        if (selectedIndex === null || selectedIndex < numButtons) {
            currButtonStatuses = statuses.splice(0, numButtons);
            currDropdownStatuses = statuses;
        }
        else {
            // if there is a selected status, show the first <numButtons> - 1 buttons, then the selected one
            // the rest go to the dropdown.
            var selected = statuses.splice(selectedIndex, 1);
            currButtonStatuses = __spreadArray(__spreadArray([], statuses.splice(0, numButtons - 1), true), selected, true);
            currDropdownStatuses = statuses;
        }
        this.setState({
            buttonStatuses: currButtonStatuses,
            dropdownStatuses: currDropdownStatuses,
        });
    };
    Filter.prototype.render = function () {
        var _a = this.state, buttonStatuses = _a.buttonStatuses, dropdownStatuses = _a.dropdownStatuses, selectedIndex = _a.selectedIndex, isModalOpen = _a.isModalOpen, isCustomFilterActive = _a.isCustomFilterActive;
        var _b = this.props, id = _b.id, statuses = _b.statuses, renderCustomFilters = _b.renderCustomFilters, customFilterSummary = _b.customFilterSummary, onCustomFilterSave = _b.onCustomFilterSave, onCustomFilterClear = _b.onCustomFilterClear, className = _b.className, isApplyBtnDisabled = _b.isApplyBtnDisabled, customFilterTooltip = _b.customFilterTooltip;
        var selected = selectedIndex !== null ? statuses[selectedIndex].value : null;
        return (React.createElement("div", { id: id, "data-testid": "elmo-filter-".concat(id || 'default'), className: getClass('elmo-filter-wrapper', className) },
            React.createElement("div", { className: "elmo-filter" },
                React.createElement(FilterButtons, { statuses: buttonStatuses, selected: selected, onChange: this.handleChange }),
                dropdownStatuses.length > 0 && (React.createElement(FilterDropdown, { statuses: dropdownStatuses, onChange: this.handleDropdownItemClick })),
                renderCustomFilters && (React.createElement(FilterCustomModal, { isOpen: isModalOpen, toggle: this.toggleModal, onApply: this.handleModalApply, isButtonActive: isCustomFilterActive, isApplyBtnDisabled: isApplyBtnDisabled, tooltip: customFilterTooltip }, renderCustomFilters()))),
            customFilterSummary && customFilterSummary.length > 0 && (React.createElement(CustomFilterSummary, { items: customFilterSummary, onSaveClick: onCustomFilterSave, onClearClick: onCustomFilterClear }))));
    };
    Filter.defaultProps = {
        isApplyBtnDisabled: false,
        customFilterTooltip: 'Filter',
    };
    return Filter;
}(Component));
export default Filter;
