// tslint:disable max-line-length
import React from 'react';

function ChatOutlined(): React.ReactElement {
  return (
    <svg className="elmo-icon__svg" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M4 4h16v12H5.17L4 17.17V4m0-2c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H4z"
      />
      <path xmlns="http://www.w3.org/2000/svg" d="M6 12h8v2H6v-2zM6 9h12v2H6V9zM6 6h12v2H6V6z" />
    </svg>
  );
}

export default ChatOutlined;
