import log from 'loglevel';

/**
 * Log levels
 */
export interface LogLevel {
  TRACE: 0;
  DEBUG: 1;
  INFO: 2;
  WARN: 3;
  ERROR: 4;
  SILENT: 5;
}

export type LoggerPostHandler = (...msg: any[]) => void;

/**
 * Possible log level descriptors, may be string, lower or upper case, or number.
 */
export type LogLevelDesc =
  | LogLevel[keyof LogLevel]
  | 'trace'
  | 'debug'
  | 'info'
  | 'warn'
  | 'error'
  | 'silent'
  | keyof LogLevel;

class Logger {
  private driver: any;

  private options: any;

  private postHandlers: any = {
    debug: null,
    info: null,
    warn: null,
    error: null,
  };

  constructor(options?: any) {
    this.options = options;
    this.driver = log;
  }

  /**
   * This disables all logging below the given level, so that after a log.setLevel("warn") call log.warn("something")
   * or log.error("something") will output messages, but log.info("something") will not.
   *
   * @param level as a string, like 'error' (case-insensitive) or as a number from 0 to 5 (or as log.levels. values)
   * @param persist Where possible the log level will be persisted. LocalStorage will be used if available, falling
   *     back to cookies if not. If neither is available in the current environment (i.e. in Node), or if you pass
   *     false as the optional 'persist' second argument, persistence will be skipped.
   */
  setLevel(level: LogLevelDesc, persist?: boolean): void {
    this.driver.setLevel(level, persist);
  }

  /**
   * Output debug message to console including appropriate icons
   *
   * @param msg any data to log to the console
   */
  debug(...msg: any[]): void {
    this.driver.debug(msg);
    if (typeof this.postHandlers.debug === 'function') {
      this.postHandlers.debug(...msg);
    }
  }

  /**
   * Set a post handler
   * @param callback
   */
  setPostDebugLog(callback: LoggerPostHandler): void {
    this.postHandlers.debug = callback;
  }

  /**
   * Output info message to console including appropriate icons
   *
   * @param msg any data to log to the console
   */
  info(...msg: any[]): void {
    this.driver.info(msg);
    if (typeof this.postHandlers.info === 'function') {
      this.postHandlers.info(...msg);
    }
  }

  /**
   * Set a post handler
   * @param callback
   */
  setPostInfoLog(callback: LoggerPostHandler): void {
    this.postHandlers.info = callback;
  }

  /**
   * Output warn message to console including appropriate icons
   *
   * @param msg any data to log to the console
   */
  warn(...msg: any[]): void {
    this.driver.warn(msg);
    if (typeof this.postHandlers.warn === 'function') {
      this.postHandlers.warn(...msg);
    }
  }

  /**
   * Set a post handler
   * @param callback
   */
  setPostWarnLog(callback: LoggerPostHandler): void {
    this.postHandlers.warn = callback;
  }

  /**
   * Output error message to console including appropriate icons
   *
   * @param msg any data to log to the console
   */
  error(...msg: any[]): void {
    this.driver.error(msg);
    if (typeof this.postHandlers.error === 'function') {
      this.postHandlers.error(...msg);
    }
  }

  /**
   * Set a post handler
   * @param callback
   */
  setPostErrorLog(callback: LoggerPostHandler): void {
    this.postHandlers.error = callback;
  }
}

export default Logger;
