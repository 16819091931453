import React, { ReactElement } from 'react';
import { Redirect, Switch } from 'react-router';
import { Route, RedirectRoute, DeafultRoute } from '.';
import PrivateRoute from './PrivateRoute';

// get path with regex rule
function getPathWithRule(route: Route): string {
  let pathWithRule = route.path;
  if (typeof route.paramRules === 'object') {
    Object.keys(route.paramRules).forEach((key: string) => {
      pathWithRule = pathWithRule.replace(`:${key}`, `:${key}${route.paramRules[key]}`);
    });
  }
  return pathWithRule;
}

function RouteWithSubRoutes({
  path,
  routes,
  component: Component,
  routeComponent: RouteComponent = PrivateRoute,
}: DeafultRoute): React.ReactElement {
  return (
    <RouteComponent
      path={path}
      render={(props: any): React.ReactElement => <Component {...props} routes={routes} />}
    />
  );
}

function NestedRoutes({ routes }: { routes: any }): ReactElement | null {
  if (!routes) {
    return null;
  }

  return (
    <Switch>
      {Object.keys(routes).map((key: string, index) => {
        const route: Route = routes[key];
        if ((route as RedirectRoute).redirect) {
          const { redirect, exact = true } = route as RedirectRoute;
          const pathWithRule = getPathWithRule(route);
          return <Redirect exact={exact} path={pathWithRule} to={redirect} key={index} />;
        }
        const pathWithRule = getPathWithRule(route);
        const test = {
          ...route,
          path: pathWithRule,
        };
        return <RouteWithSubRoutes key={index} {...(test as DeafultRoute)} />;
      })}
    </Switch>
  );
}

export default NestedRoutes;
