var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component } from 'react';
import RcRangeCalendar from 'rc-calendar/lib/RangeCalendar';
import { getClass } from '../_lib/helper';
var RangeCalendar = /** @class */ (function (_super) {
    __extends(RangeCalendar, _super);
    function RangeCalendar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onSelect = function (_a) {
            var from = _a[0], to = _a[1];
            var onSelect = _this.props.onSelect;
            if (onSelect) {
                onSelect({
                    from: from,
                    to: to,
                });
            }
        };
        return _this;
    }
    RangeCalendar.prototype.render = function () {
        var _a = this.props, id = _a.id, className = _a.className, value = _a.value, onSelect = _a.onSelect, restProps = __rest(_a, ["id", "className", "value", "onSelect"]);
        return (React.createElement("div", { id: id, "data-testid": "elmo-range-calendar-".concat(id || 'default'), className: getClass('elmo-calendar elmo-calendar-range', className) },
            React.createElement(RcRangeCalendar, __assign({ selectedValue: this.selectedValue, onSelect: this.onSelect }, restProps))));
    };
    Object.defineProperty(RangeCalendar.prototype, "selectedValue", {
        get: function () {
            var value = this.props.value;
            if (!value) {
                return [];
            }
            var from = value.from, to = value.to;
            return [from, to];
        },
        enumerable: false,
        configurable: true
    });
    return RangeCalendar;
}(Component));
export default RangeCalendar;
