import { createReducer } from 'redux-act';
import defaultState from './state';
import {
  getAppraisals,
  getAppraisalsSuccess,
  getAppraisalsFailure,
  getAppraisalSuccess,
  getAppraisal,
  getAppraisalFailure,
  getAssignees,
  getAssigneesSuccess,
  getAssigneesFailure,
  assignAppraisalFailure,
  assignAppraisalAccepted,
  getEmployees,
  getEmployeesSuccess,
  getEmployeesFailure,
} from './actions';
import {
  AssignAppraisalQuery,
  AssignAppraisalState,
  AssignAppraisalData,
  AssignAppraisalItem,
  AssigneeData,
  EmployeeListData,
} from '../type';
import { resetState } from '../../../state/App';
/**
 * Get appraisals list
 */
export function getAppraisalsReducer(
  state: AssignAppraisalState,
  appraisalQuery: AssignAppraisalQuery
): AssignAppraisalState {
  return {
    ...state,
    appraisalId: undefined,
    selectedAppraisal: undefined,
    query: appraisalQuery,
    isLoaded: false,
    isLoading: true,
  };
}

export function getAppraisalsSuccessReducer(
  state: AssignAppraisalState,
  data: AssignAppraisalData
): AssignAppraisalState {
  return {
    ...state,
    appraisals: { ...data },
    isLoading: false,
    isLoaded: true,
  };
}

export function getAppraisalsFailureReducer(
  state: AssignAppraisalState,
  error: Error
): AssignAppraisalState {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error,
  };
}

export function getAppraisalReducer(state: AssignAppraisalState, id: number): AssignAppraisalState {
  return {
    ...state,
    appraisalId: id,
    isLoaded: false,
    isLoading: true,
  };
}

export function getAppraisalSuccessReducer(
  state: AssignAppraisalState,
  data: AssignAppraisalItem
): AssignAppraisalState {
  return {
    ...state,
    selectedAppraisal: { ...data },
    isLoading: false,
    isLoaded: true,
  };
}

export function getAppraisalFailureReducer(
  state: AssignAppraisalState,
  error: Error
): AssignAppraisalState {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error,
  };
}

/**
 * Update appraisal assignees state
 */
export function getAssigneeReducer(
  state: AssignAppraisalState,
  payload: Record<string, any>
): AssignAppraisalState {
  const assigneeList = { ...state.assigneeList, ...{ isLoading: true } };
  return {
    ...state,
    appraisalId: payload.appraisalId,
    assigneeList,
  };
}

export function getAssigneeSuccessReducer(
  state: AssignAppraisalState,
  data: AssigneeData
): AssignAppraisalState {
  return {
    ...state,
    assigneeList: {
      assignees: { ...data },
      isLoading: false,
    },
  };
}

export function getAssigneeFailureReducer(
  state: AssignAppraisalState,
  error: Error
): AssignAppraisalState {
  const assigneeList = { ...state.assigneeList, ...{ isLoading: false, error } };
  return {
    ...state,
    assigneeList,
  };
}

export function assignAppraisalAcceptedReducer(
  state: AssignAppraisalState,
  data: Record<string, any>
): AssignAppraisalState {
  if (!state.selectedAppraisal) {
    return state;
  }

  const selectedAppraisal = { ...state.selectedAppraisal, ...{ activeJobId: data.id } };
  return {
    ...state,
    selectedAppraisal,
  };
}

export function assignAppraisalFailureReducer(
  state: AssignAppraisalState,
  error: Error
): AssignAppraisalState {
  return {
    ...state,
    error,
  };
}
/**
 * Update appraisal assignees state
 */
export function getEmployeesReducer(
  state: AssignAppraisalState,
  payload: Record<string, any>
): AssignAppraisalState {
  const employeeList = { ...state.employeeList, ...{ isLoading: true } };
  return {
    ...state,
    appraisalId: payload.appraisalId,
    employeeList,
  };
}

export function getEmployeesSuccessReducer(
  state: AssignAppraisalState,
  data: EmployeeListData
): AssignAppraisalState {
  return {
    ...state,
    employeeList: {
      employees: { ...data },
      isLoading: false,
    },
  };
}

export function getEmployeesFailureReducer(
  state: AssignAppraisalState,
  error: Error
): AssignAppraisalState {
  const employeeList = { ...state.employeeList, ...{ isLoading: false, error } };
  return {
    ...state,
    employeeList,
  };
}

export function resetStateReducer(state: AssignAppraisalState): AssignAppraisalState {
  return {
    ...state,
    selectedAppraisal: undefined,
    isLoaded: false,
    error: undefined,
  };
}

export default createReducer(
  {
    [`${getAppraisals}`]: getAppraisalsReducer,
    [`${getAppraisalsSuccess}`]: getAppraisalsSuccessReducer,
    [`${getAppraisalsFailure}`]: getAppraisalsFailureReducer,
    [`${getAppraisal}`]: getAppraisalReducer,
    [`${getAppraisalSuccess}`]: getAppraisalSuccessReducer,
    [`${getAppraisalFailure}`]: getAppraisalFailureReducer,
    [`${getAssignees}`]: getAssigneeReducer,
    [`${getAssigneesSuccess}`]: getAssigneeSuccessReducer,
    [`${getAssigneesFailure}`]: getAssigneeFailureReducer,
    [`${assignAppraisalAccepted}`]: assignAppraisalAcceptedReducer,
    [`${assignAppraisalFailure}`]: assignAppraisalFailureReducer,
    [`${getEmployees}`]: getEmployeesReducer,
    [`${getEmployeesSuccess}`]: getEmployeesSuccessReducer,
    [`${getEmployeesFailure}`]: getEmployeesFailureReducer,
    [`${resetState}`]: resetStateReducer,
  },
  defaultState
);
