import { messages, t } from '../translation';
import { getToken, isTokenExpired } from '../token';
import httpClient from '../api';
import { completeLogin } from './index';

const isLoggedIn = (): boolean => {
  const token = getToken();

  if (token === null) {
    return false;
  }

  return !isTokenExpired(token);
};

export const isAuthenticated = async (): Promise<any> => {
  if (isLoggedIn()) {
    return Promise.resolve(true);
  }

  try {
    const response: any = await httpClient
      .get('/auth/check-token', {
        baseURL: '/oauth2-client',
      })
      .then((result: any) => result.data);

    completeLogin(response.token);

    return Promise.resolve(true);
  } catch (err) {
    return Promise.reject(t(messages.app.auth.redirectingToLogin));
  }
};
