import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { t, messages } from '../../../../lib/translation';
import { EmployeeListQuery, EmployeeItem } from '../../type';
import { getEmployees } from '../../state/actions';
import { getEmptyStateMessage, onPerformanceViewClick } from '../../../../lib/util';
import AuthAccess from '../../../../lib/auth/access';
import { Flex } from '@eds/flex';
import { Box } from '@eds/box';
import { EmptyState } from '@eds/empty-state';
import {
  ColumnSort,
  SortDirection,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from '@eds/table';
import { Pagination, PaginationState } from '@eds/pagination';
import { Avatar } from '@eds/avatar';
import { ExternalLinkIcon } from '@eds/icon';
import { Button } from '@eds/button';
import { FilterBar } from '@eds/filtering';
import { Text } from '@eds/text';

export type EmployeeListProps = {
  appraisalId: number;
  selectedUserIds: string[];
  startDate: string;
  setSelectedUserIds: (selectedUserIds: string[]) => void;
  completedJobId: number | undefined;
};

export const EmployeeListView = ({
  appraisalId,
  selectedUserIds,
  startDate,
  setSelectedUserIds,
  completedJobId,
}: EmployeeListProps): React.ReactElement => {
  const { currentUser } = AuthAccess;
  const [employeeListQuery, setEmployeeListQuery] = useState<EmployeeListQuery>({
    page: 1,
    limit: 10,
    sort: 'firstName',
    startDate: '',
  });
  const [selectAll, setSelectAll] = useState<boolean | 'indeterminate'>();
  const { isLoading, employees } = useSelector((state: any) => state.assignAppraisal.employeeList);
  const emptyState = getEmptyStateMessage('employeeList', employeeListQuery.keyword);

  const dispatch = useDispatch();
  // execute search
  useEffect(() => {
    dispatch(
      getEmployees(appraisalId, {
        ...employeeListQuery,
        startDate,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appraisalId, startDate, employeeListQuery]);

  // initially this completedJobId null, only after we poll the server to confirm the assignment job is done,
  // we will re-fetch the get employee list
  useEffect(() => {
    if (completedJobId) {
      dispatch(
        getEmployees(appraisalId, {
          ...employeeListQuery,
          startDate,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedJobId]);

  // An array list of the values that should be selected or unselected when onSelectAllChange is called
  // NOTE: If some checkboxes should be rendered but disabled, you need to exclude them from this array so that "Select all" doesn't check them.
  const selectAllValues = employees.data
    .filter((employee: EmployeeItem) => !employee.performance)
    .map((employee: EmployeeItem) => employee.id.toString());

  const onSelectAllChange = (): void => {
    if (!selectAll || selectAll === 'indeterminate') {
      // If the "Select all" checkbox is false or indeterminate,
      // add the `selectAllValues` to the `selectedValues` state (filtering out any duplicate values)
      const addedValues = selectedUserIds
        .concat(selectAllValues)
        .filter((value, index, array) => array.indexOf(value) === index);
      setSelectedUserIds(addedValues);
    } else {
      // Otherwise when `selectAll` is true, filter out the `selectAllValues` from the `selectedValues` state
      const remainingValues = selectedUserIds.filter(value => !selectAllValues?.includes(value));
      setSelectedUserIds(remainingValues);
    }
  };

  // Setting the state of the "Select all" checkbox (true, false or indeterminate)
  const isAllSelected =
    // eslint-disable-next-line no-nested-ternary
    selectAllValues.length &&
    selectAllValues.every((value: string) => selectedUserIds.includes(value))
      ? true
      : selectAllValues.some((value: string) => selectedUserIds.includes(value))
      ? 'indeterminate'
      : false;

  if (isAllSelected !== selectAll) {
    setSelectAll(isAllSelected);
  }

  /**
   * Handle search box submission.
   *
   * @param {string} value The search value
   */
  const handleSearch = (keyword: string): void => {
    keyword = keyword.trim();
    setEmployeeListQuery({
      ...employeeListQuery,
      keyword,
      page: 1,
    });
  };

  const onTableSort = ({ columnName, sortDirection }: ColumnSort): void => {
    switch (sortDirection) {
      case 'ascending': {
        setEmployeeListQuery({ ...employeeListQuery, page: 1, sort: columnName });
        break;
      }
      case 'descending': {
        setEmployeeListQuery({ ...employeeListQuery, page: 1, sort: `-${columnName}` });
        break;
      }
      default: {
        setEmployeeListQuery({ ...employeeListQuery, page: 1, sort: '' });
      }
    }
  };

  const onPageUpdate = (paginationState: PaginationState): void => {
    if (paginationState.currentPage !== Number(employeeListQuery.page)) {
      setEmployeeListQuery({ ...employeeListQuery, page: paginationState.currentPage });
    }

    if (paginationState.currentItemsPerPage !== Number(employeeListQuery.limit)) {
      setEmployeeListQuery({ ...employeeListQuery, limit: paginationState.currentItemsPerPage });
    }
  };

  const getColumnSort = (): ColumnSort => {
    let sortDirection: SortDirection = 'default';
    let columnName = '';
    if (employeeListQuery.sort) {
      const sortSplit = employeeListQuery.sort.split('-');
      columnName = sortSplit.length === 1 ? sortSplit[0] : sortSplit[1];
      sortDirection = sortSplit.length === 1 ? 'ascending' : 'descending';
    }
    return {
      sortDirection,
      columnName,
    };
  };

  const [searchValue, setSearchValue] = useState<string>(employeeListQuery.keyword || '');

  const handleSearchClear = (): void => {
    setSearchValue('');
    handleSearch('');
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value.trim();
    setSearchValue(event.target.value);
    /* istanbul ignore else */
    if (value && value.length >= 2) {
      handleSearch(value);
    }

    /* istanbul ignore else */
    if (value.length === 0) {
      handleSearchClear();
    }
  };

  return (
    <Flex flexDirection="column" gap="medium">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginTop="small"
        marginBottom="large"
        flexWrap="wrap"
        gap="small"
      >
        <FilterBar
          loading={isLoading}
          keywordFilter={{
            placeholder: 'Search by template name...',
            value: searchValue,
            onChange: handleSearchChange,
            onClear: handleSearchClear,
            width: '30rem',
          }}
        >
          <Text marginLeft="small" marginRight="small" fontSize="standard">
            {selectedUserIds.length ? `${selectedUserIds.length} selected` : ''}
          </Text>
        </FilterBar>
      </Flex>
      <Flex flexDirection="column" gap="medium">
        <Table
          tableBorder
          selectType="checkbox"
          selectedValues={selectedUserIds}
          onSelectedValuesChange={setSelectedUserIds}
          data-testid="employee-list"
        >
          <TableHead>
            <TableRow selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
              <TableHeaderCell
                columnName="firstName"
                key="firstName"
                onSortChange={onTableSort}
                sort={getColumnSort()}
                data-testid="firstName"
              >
                {t(messages.assignAppraisal.employeeList.listHeader.employeeName)}
              </TableHeaderCell>
              <TableHeaderCell columnName="position" key="position">
                {t(messages.assignAppraisal.employeeList.listHeader.position)}
              </TableHeaderCell>
              <TableHeaderCell columnName="department" key="department">
                {t(messages.assignAppraisal.employeeList.listHeader.department)}
              </TableHeaderCell>
              <TableHeaderCell columnName="managerName" key="managerName">
                {t(messages.assignAppraisal.employeeList.listHeader.managerName)}
              </TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.data.map((employee: EmployeeItem) => (
              <TableRow
                key={employee.id}
                selectLabel={employee.firstName}
                selectValue={employee.id.toString()}
                selectDisabled={!!employee.performance?.id}
              >
                <TableCell>
                  <Flex alignItems="center" gap="xxsmall">
                    {employee.firstName && employee.lastName && (
                      <Avatar
                        src={employee.profileImage}
                        name={`${employee.firstName} ${employee.lastName}`}
                      />
                    )}
                    {`${employee.firstName} ${employee.lastName}`}
                    {!!employee.performance?.id && (
                      <Button
                        iconOnly
                        icon={ExternalLinkIcon}
                        size="small"
                        tone="ghost"
                        label="View Appraisal"
                        onClick={(): void => {
                          onPerformanceViewClick(
                            currentUser.host,
                            employee.performance.id.toString()
                          );
                        }}
                        data-testid={`performance-view-${employee.id}`}
                      />
                    )}
                  </Flex>
                </TableCell>
                <TableCell>{employee.position}</TableCell>
                <TableCell>{employee.department}</TableCell>
                <TableCell>
                  <Flex alignItems="center" gap="xxsmall">
                    {employee.manager.firstName && employee.manager.lastName && (
                      <Avatar
                        src={employee.manager.profileImage}
                        name={`${employee.manager.firstName} ${employee.manager.lastName}`}
                      />
                    )}
                    {`${employee.manager.firstName} ${employee.manager.lastName}`}
                  </Flex>
                </TableCell>
              </TableRow>
            ))}
            {!employees.data.length && (
              <TableRow>
                <TableCell colSpan="100%">
                  <Box marginTop="large" marginBottom="large">
                    <EmptyState title={emptyState.title} description={emptyState.content} />
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          indexBased={1}
          itemsCount={employees.total}
          currentPage={Number(employeeListQuery.page)}
          currentItemsPerPage={Number(employeeListQuery.limit)}
          itemsLabelSingular="item"
          itemsLabelPlural="items"
          onPageUpdate={onPageUpdate}
        />
      </Flex>
    </Flex>
  );
};

export default EmployeeListView;
