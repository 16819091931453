import React, { lazy, useEffect, useContext } from 'react';
import Suspense from '../../element/Suspense';
import { SubmenuContext } from '../../element/Menu/submenuContext';
import SubMenuItems from '../../element/Menu/SubMenuItems';

const SignOffPerformanceView = lazy(() => import('./view'));

export const SignOffPerformances = ({ routes }: Record<string, any>): React.ReactElement => {
  const { setSubmenu } = useContext(SubmenuContext);
  useEffect(() => {
    /* istanbul ignore else */
    if (setSubmenu) {
      setSubmenu(SubMenuItems.signOffPerformance.key);
    }
  }, [setSubmenu]);

  return (
    <Suspense>
      <SignOffPerformanceView routes={routes} />
    </Suspense>
  );
};

export default SignOffPerformances;
