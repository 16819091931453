import { createAction } from 'redux-act';
import { TeamPerformanceDefaultQuery } from './state';
import { TeamPerformanceQuery, TeamPerformanceData } from '../type';

/**
 * Some short document about what this action is about
 */
export const getTeamPerformance: any = createAction(
  'TEAM_GET_PERFORMANCE',
  (payload: TeamPerformanceQuery) => ({
    ...TeamPerformanceDefaultQuery,
    ...payload,
  })
);

export const getTeamPerformanceSuccess: any = createAction(
  'TEAM_GET_PERFORMANCE_SUCCESS',
  (data: TeamPerformanceData) => data
);

export const getTeamPerformanceFailure: any = createAction(
  'TEAM_GET_TEAM_PERFORMANCE_FAILURE',
  (error: any) => error
);

export const deleteTeamPerformance: any = createAction(
  'TEAM_DELETE_PERFORMANCE',
  (performanceId: number) => ({
    performanceId,
  })
);

export const deleteTeamPerformanceSuccess: any = createAction(
  'TEAM_DELETE_PERFORMANCE_SUCCESS',
  () => ({})
);

export const deleteTeamPerformanceFailure: any = createAction(
  'TEAM_DELETE_PERFORMANCE_FAILURE',
  (error: any) => error
);
