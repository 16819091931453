import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ListView from './listView';
import { withRouter } from 'react-router-dom';
import { Flex } from '@eds/flex';
import { AppraisalAssignmentListPath } from '../../routes';
import usePerformanceListControl from '../../../../lib/hooks/usePerformanceListControl';
import { AssignAppraisalDefaultQuery } from '../../state/state';
import { getAppraisals } from '../../state';
import { useDebouncedCallback } from 'use-debounce';
import { FilterBar } from '@eds/filtering';

export const ListContainer = (): React.ReactElement => {
  const currentPath = AppraisalAssignmentListPath;
  const dispatch = useDispatch();
  const {
    appraisals: { total, data },
    isLoaded,
    isLoading,
  } = useSelector((state: any) => state.assignAppraisal);
  const { query, onTableSort, onSearch, onPageChange, onPageSizeChange, getColumnSort } =
    usePerformanceListControl(currentPath, AssignAppraisalDefaultQuery);

  useEffect(() => {
    dispatch(getAppraisals({ ...query }));
  }, [query, dispatch]);

  useEffect(() => {
    dispatch(getAppraisals({ ...AssignAppraisalDefaultQuery }));
  }, [dispatch]);

  const onDebouncedSearch = useDebouncedCallback((searchValue?: string): void => {
    onSearch(searchValue);
  }, 300);

  const [searchValue, setSearchValue] = useState<string>(query.keyword || '');

  const handleSearchClear = (): void => {
    setSearchValue('');
    onDebouncedSearch();
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value.trim();
    setSearchValue(event.target.value);
    /* istanbul ignore else */
    if (value && value.length >= 2) {
      onDebouncedSearch(value);
    }

    /* istanbul ignore else */
    if (value.length === 0) {
      handleSearchClear();
    }
  };

  return (
    <>
      <Flex flexDirection="column">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          marginTop="small"
          marginBottom="large"
          flexWrap="wrap"
          gap="small"
        >
          <FilterBar
            loading={isLoading}
            keywordFilter={{
              placeholder: 'Search by template name...',
              value: searchValue,
              onChange: handleSearchChange,
              onClear: handleSearchClear,
              width: '30rem',
            }}
          />
        </Flex>
        <ListView
          data={data}
          total={total}
          page={query.page}
          limit={query.limit}
          onTableSort={onTableSort}
          columnSort={getColumnSort(query.sort)}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
      </Flex>
    </>
  );
};

export default withRouter(ListContainer);
