import httpClient from '../../../lib/api';
import { HTTP_RESPONSE_CODE } from '../../../lib/const';
import { TeamPerformanceQuery, TeamPerformanceApiInterface } from '../type';

export const resources: any = {
  teamPerformances: {
    url: '/performance-api/v1/team-performances',
  },
  performance: {
    url: '/performance-api/v1/performances',
  },
};
export class TeamPerformanceApi implements TeamPerformanceApiInterface {
  constructor() {
    this.getTeamPerformance = this.getTeamPerformance.bind(this);
    this.deleteTeamPerformance = this.deleteTeamPerformance.bind(this);
  }

  httpClientGet(teamPerformanceQuery: TeamPerformanceQuery): Promise<any> {
    return httpClient.get(resources.teamPerformances.url, {
      params: {
        // params mapping
        page: teamPerformanceQuery.page,
        limit: teamPerformanceQuery.limit,
        phase: teamPerformanceQuery.phase ? teamPerformanceQuery.phase : undefined,
        sort: teamPerformanceQuery.sort ? teamPerformanceQuery.sort : undefined,
        keyword: teamPerformanceQuery.keyword ? teamPerformanceQuery.keyword : undefined,
        indirectManager: teamPerformanceQuery.indirectManager
          ? teamPerformanceQuery.indirectManager
          : undefined,
        signOffEnabled: teamPerformanceQuery.isSignOff,
      },
    });
  }

  httpClientDelete(apiPath: string): Promise<any> {
    return httpClient.delete(apiPath);
  }

  getTeamPerformance(teamPerformanceQuery: TeamPerformanceQuery): Promise<any> {
    return this.httpClientGet(teamPerformanceQuery)
      .then(result => {
        const total =
          typeof result.headers !== 'undefined' &&
          typeof result.headers['x-pagination-count'] !== 'undefined'
            ? result.headers['x-pagination-count']
            : 0;
        const pageNum =
          typeof result.headers !== 'undefined' &&
          typeof result.headers['x-pagination-page'] !== 'undefined'
            ? result.headers['x-pagination-page']
            : teamPerformanceQuery.page;
        const limitNum =
          typeof result.headers !== 'undefined' &&
          typeof result.headers['x-pagination-limit'] !== 'undefined'
            ? result.headers['x-pagination-limit']
            : teamPerformanceQuery.limit;
        return Promise.resolve({
          total,
          page: pageNum,
          limit: limitNum,
          data: typeof result.data !== 'undefined' ? result.data : [],
        });
      })
      .catch((error: Error) => Promise.reject(error));
  }

  /**
   * Deletes a single performance from team performance page
   */
  deleteTeamPerformance(performanceId: number): Promise<any> {
    return this.httpClientDelete(`${resources.performance.url}/${performanceId}`).then(result => {
      if (result.status === HTTP_RESPONSE_CODE.NO_CONTENT_SUCCESS) {
        return Promise.resolve({ status: true, result });
      }
      return Promise.reject();
    });
  }
}

export default new TeamPerformanceApi();
