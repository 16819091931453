var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from 'react';
import { AdministratorIcon, HomeIcon } from '../Icon';
import MobileHeader from './components/MobileHeader';
import SubMenuHeader from './components/SubMenuHeader';
import CompanyLogo from './components/CompanyLogo';
import CollapseButton from './components/CollapseButton';
import MainMenu from './components/MainMenu';
import SubMenu from './components/SubMenu';
import MenuFooter from './components/MenuFooter';
import UserDetails from './components/UserDetails';
import { ThemeContextProvider } from './components/ThemeContext';
import MenuError from './components/MenuError';
import PlaceholderMenu from '../Placeholder/PlaceholderMenu';
import { classOSinHTML, getClass, getFromStorage, getScrollY, screensizeToBreakpoint, scrollLock, setScrollY, setToStorage, } from '../_lib/helper';
import { menuDefaultTheme } from '../_lib/style/theme';
import { Header, MobileHeaderStyled, SubMenuStyled } from './Styles';
import logger from '../_lib/logger';
import './Menu.scss';
import PlaceholderMobileMenu from '../Placeholder/PlaceholderMobileMenu';
import { ELMO_SVG_EXPIRY_TIME, ELMO_SVG_ICONS_S3_PRODUCTION, ELMO_SVG_ICONS_S3_STAGING, ELMO_SVG_ICONS_STORAGE_KEY, } from '../_lib/const/menu';
var Menu = /** @class */ (function (_super) {
    __extends(Menu, _super);
    function Menu(props) {
        var _this = _super.call(this, props) || this;
        _this.handleMenuOpenedMobile = function () {
            var isMobile = _this.props.isMobile;
            // run function if the screen size is smaller than 1280,
            // Which means the menu starts floating
            if (screensizeToBreakpoint() !== 'xxl' || isMobile) {
                var _a = _this.state, isMenuOpenedMobile = _a.isMenuOpenedMobile, bodyScrollTop = _a.bodyScrollTop;
                _this.setState({
                    isMenuOpenedMobile: !isMenuOpenedMobile,
                });
                if (!isMenuOpenedMobile) {
                    _this.setState({
                        bodyScrollTop: getScrollY(),
                    });
                    scrollLock(true);
                }
                else {
                    scrollLock(false);
                    setScrollY(bodyScrollTop);
                }
            }
        };
        _this.handleSubMenuToggle = function () {
            _this.setState({
                asSubMenu: !_this.state.asSubMenu,
            });
        };
        _this.handleLayoutToggle = function () {
            _this.setState({
                isMenuCollapsed: !_this.state.isMenuCollapsed,
            }, function () {
                _this.updateBodyClassname(_this.state.isMenuCollapsed);
            });
        };
        _this.getCurrentMainMenu = function () {
            var _a = _this.props, menuItems = _a.menuItems, userDetails = _a.userDetails;
            var returnItem;
            returnItem = menuItems && menuItems.filter(function (n) { return n.isSelected; }).shift();
            if (returnItem) {
                return returnItem;
            }
            if (userDetails && userDetails.isAdminSelected) {
                return {
                    title: 'Administration',
                    icon: AdministratorIcon,
                };
            }
            return null;
        };
        var asSubMenu = _this.props.asSubMenu;
        _this.state = {
            asSubMenu: asSubMenu,
            isMenuCollapsed: false,
            isMenuOpenedMobile: false,
            bodyScrollTop: 0,
        };
        classOSinHTML();
        _this.currentTime = Date.now();
        return _this;
    }
    Menu.prototype.updateBodyClassname = function (isMenuCollapsed) {
        var isReact = this.props.isReact;
        var Body = document.querySelector('body');
        if (Body && isReact === false) {
            if (isMenuCollapsed) {
                Body.classList.add('elmo-body--menu-collapsed');
            }
            else {
                Body.classList.remove('elmo-body--menu-collapsed');
            }
        }
    };
    Menu.prototype.getIconUrl = function (hostname) {
        var isStaging = new RegExp(/.+\.elmodev\.com|^localhost.*/).test(hostname);
        return isStaging ? ELMO_SVG_ICONS_S3_STAGING : ELMO_SVG_ICONS_S3_PRODUCTION;
    };
    Menu.prototype.setIconsToStorage = function (data) {
        var localstorageData = {
            data: data,
            timestamp: this.currentTime,
        };
        setToStorage(ELMO_SVG_ICONS_STORAGE_KEY, localstorageData, 'object');
    };
    Menu.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var svgWrapper, localstorageData, s3IconsResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        svgWrapper = document.createElement('div');
                        svgWrapper.style.display = 'none';
                        document.body.insertBefore(svgWrapper, document.body.childNodes[0]);
                        localstorageData = getFromStorage(ELMO_SVG_ICONS_STORAGE_KEY, 'object');
                        if (localstorageData) {
                            if (localstorageData.timestamp &&
                                this.currentTime - localstorageData.timestamp < ELMO_SVG_EXPIRY_TIME) {
                                svgWrapper.innerHTML = localstorageData.data;
                                return [2 /*return*/];
                            }
                        }
                        return [4 /*yield*/, this.getIconsFromS3()];
                    case 1:
                        s3IconsResult = _a.sent();
                        svgWrapper.innerHTML = s3IconsResult;
                        this.setIconsToStorage(s3IconsResult);
                        return [2 /*return*/];
                }
            });
        });
    };
    Menu.prototype.componentDidUpdate = function (prevProps, prevState) {
        var _a = this.props, menuOpened = _a.menuOpened, menuClosed = _a.menuClosed, asSubMenu = _a.asSubMenu;
        if (asSubMenu !== prevProps.asSubMenu) {
            this.setState({ asSubMenu: asSubMenu });
        }
        if (prevState.isMenuCollapsed !== this.state.isMenuCollapsed) {
            if (menuOpened && !this.state.isMenuCollapsed) {
                setTimeout(function () {
                    menuOpened();
                }, 1);
            }
            if (menuClosed && this.state.isMenuCollapsed) {
                setTimeout(function () {
                    menuClosed();
                }, 1);
            }
        }
    };
    Menu.prototype.getIconsFromS3 = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                var iconUrl = _this.getIconUrl(window.location.hostname);
                var ajax_1 = new XMLHttpRequest();
                ajax_1.open('GET', iconUrl, true);
                ajax_1.send();
                ajax_1.onload = function () {
                    var responseText = ajax_1.responseText;
                    if (responseText) {
                        resolve(responseText);
                        // svgWrapper.innerHTML = responseText;
                        // this.setIconsToStorage(ajax.responseText);
                    }
                };
            }
            catch (error) {
                logger.error(error);
                reject(error);
            }
        });
    };
    Menu.prototype.render = function () {
        var _a = this.state, asSubMenu = _a.asSubMenu, isMenuCollapsed = _a.isMenuCollapsed, isMenuOpenedMobile = _a.isMenuOpenedMobile;
        var _b = this.props, menuItems = _b.menuItems, subMenuItems = _b.subMenuItems, userDetails = _b.userDetails, userMenu = _b.userMenu, logo = _b.logo, mobileLogo = _b.mobileLogo, logoHref = _b.logoHref, theme = _b.theme, isLoading = _b.isLoading, id = _b.id, isMobile = _b.isMobile, isError = _b.isError, alert = _b.alert, drawMenu = _b.drawMenu, footerMessage = _b.footerMessage;
        // Always just use the PlaceholderMobileMenu if it is loading and isMobile is set,
        // regardless of screen size.
        if (isLoading && isMobile) {
            return React.createElement(PlaceholderMobileMenu, null);
        }
        // Otherwise use either Placeholder, depending on the screensize.
        if (isLoading) {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "d-none d-lg-block" },
                    React.createElement(PlaceholderMenu, null)),
                React.createElement("div", { className: "d-lg-none" },
                    React.createElement(PlaceholderMobileMenu, null))));
        }
        if (isError) {
            return (React.createElement(MenuError, { id: id, theme: theme, alert: alert, isMenuCollapsed: isMenuCollapsed, isMenuOpenedMobile: isMenuOpenedMobile, isMobile: isMobile, handleLayoutToggle: this.handleLayoutToggle, handleMenuOpenedMobile: this.handleMenuOpenedMobile, drawMenu: drawMenu }));
        }
        var profileBackgroundColor;
        if (theme && theme.profile && theme.profile.backgroundColor) {
            profileBackgroundColor = theme.profile.backgroundColor;
        }
        var currentMainMenu = this.getCurrentMainMenu();
        var CurrentMenuIcon = HomeIcon;
        if (currentMainMenu && currentMainMenu['icon']) {
            CurrentMenuIcon = currentMainMenu['icon'];
        }
        return (React.createElement(ThemeContextProvider, { value: theme },
            React.createElement("div", { className: getClass('elmo-nav-layout', {}, {
                    'is-collapsed': isMenuCollapsed,
                    'is-mobile': isMobile,
                    'is-default': !isMobile,
                }), "data-testid": "elmo-left-navigation-".concat(id || 'default') },
                React.createElement(CollapseButton, { onLayoutToggle: this.handleLayoutToggle, isMenuCollapsed: isMenuCollapsed }),
                React.createElement(MobileHeaderStyled, { color: theme && theme.menu && theme.menu.fontColor, backgroundColor: theme && theme.menu && theme.menu.backgroundColor },
                    React.createElement(MobileHeader, { icon: CurrentMenuIcon, label: currentMainMenu && currentMainMenu['title'], mobileLogo: mobileLogo, logoHref: logoHref, onClick: this.handleMenuOpenedMobile, drawMenu: drawMenu })),
                React.createElement("div", { className: getClass('elmo-nav-drawer-toggle', {}, { 'is-opened': isMenuOpenedMobile }), onClick: this.handleMenuOpenedMobile }),
                React.createElement("div", { className: getClass('elmo-nav-drawer', {}, { 'is-opened': isMenuOpenedMobile }) },
                    React.createElement(Header, { className: "elmo-nav-header", profileBackgroundColor: profileBackgroundColor },
                        React.createElement(CompanyLogo, { logo: logo, mobileLogo: mobileLogo, logoHref: logoHref, hasDrawMenu: !!drawMenu, isCollapsed: !!isMenuCollapsed }),
                        !!drawMenu &&
                            drawMenu(isMenuCollapsed ? 'collapsed' : 'expanded')),
                    React.createElement("div", { className: getClass('elmo-nav-body', {}, { 'is-submenu': asSubMenu }) },
                        userDetails && (React.createElement(UserDetails, __assign({}, userDetails, { userMenu: userMenu }))),
                        React.createElement(MainMenu, { menuItems: menuItems, footerMessage: footerMessage, onOpenSubmenu: subMenuItems ? this.handleSubMenuToggle : undefined }),
                        subMenuItems && (React.createElement(SubMenuStyled, { className: "elmo-nav-submenu", menuFont: theme && theme.menu && theme.menu.font },
                            React.createElement(SubMenuHeader, { onSubMenuToggle: this.handleSubMenuToggle, icon: CurrentMenuIcon, title: currentMainMenu && currentMainMenu['title'] }),
                            React.createElement(SubMenu, { menuItems: subMenuItems, onCloseMenuMobile: this.handleMenuOpenedMobile })))),
                    React.createElement(MenuFooter, null)))));
    };
    Menu.defaultProps = {
        isReact: true,
        asSubMenu: true,
        theme: menuDefaultTheme,
    };
    return Menu;
}(Component));
export default Menu;
