import React, { lazy, useEffect, useContext } from 'react';
import Suspense from '../../element/Suspense';
import { SubmenuContext } from '../../element/Menu/submenuContext';
import SubMenuItems from '../../element/Menu/SubMenuItems';
import { SystemConfigurationContext } from '../../lib/systemConfiguration';
import ForbiddenContainer from '../Forbidden';

const AssignAppraisalView = lazy(() => import('./view'));

const AssignAppraisal = ({ routes }: Record<string, any>): React.ReactElement => {
  const { setSubmenu } = useContext(SubmenuContext);
  const { performanceConfig } = useContext(SystemConfigurationContext);

  useEffect(() => {
    if (setSubmenu) {
      setSubmenu(SubMenuItems.teamPerformance.key);
    }
  }, [setSubmenu]);

  if (!performanceConfig.allowManagerAssignAppraisal) {
    return <ForbiddenContainer />;
  }

  return (
    <Suspense>
      <AssignAppraisalView routes={routes} />
    </Suspense>
  );
};

export default AssignAppraisal;
