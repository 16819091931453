var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import StyledHeading from './style';
import { getClass } from '../../_lib/helper';
import '../Typography.scss';
function mapPropsToClass(block, props) {
    var _a;
    var isBold = props.isBold, isItalic = props.isItalic, isTruncate = props.isTruncate, align = props.align, decoration = props.decoration, transform = props.transform, color = props.color, size = props.size, type = props.type, className = props.className;
    return getClass(block, (_a = {},
        _a["".concat(className)] = className,
        _a["font-weight-bold"] = isBold,
        _a["font-italic"] = isItalic,
        _a["text-truncate"] = isTruncate,
        _a["text-".concat(align)] = align,
        _a["text-decoration-".concat(decoration)] = decoration,
        _a["text-transform-".concat(transform)] = transform,
        _a["text-color-".concat(color)] = color,
        _a["".concat(type, "-size-").concat(size)] = size,
        _a), []);
}
function Heading(_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var id = props.id, htmlTag = props.htmlTag;
    var StyledHeadingComponent = StyledHeading(htmlTag);
    return (React.createElement(StyledHeadingComponent, { id: id, "data-testid": "elmo-typography-".concat(id || 'default'), className: mapPropsToClass('elmo-typography', props) }, children));
}
Heading.defaultProps = {
    isBold: false,
    isItalic: false,
    isTruncate: false,
    type: 'heading',
    size: 'rg',
    htmlTag: 'h3',
};
export default Heading;
