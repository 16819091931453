import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Menu as ElmoMenu } from 'elmo-elements';
import { PERFORMANCE_MENU_KEY } from '../../lib/const';
import { fetchNavigation } from '../../state/App';
import history from '../../lib/history';
import SubMenuItems from './SubMenuItems';
import { InterfaceLink } from './model';
import { MenuItem, UserDetailsProps } from 'elmo-elements/dist/Menu/type';
import AuthAccess from '../../lib/auth/access';
import { SubmenuContext } from './submenuContext';

const Menu = (): React.ReactElement => {
  const navigation = useSelector((state: any) => state.app.navigation);
  const { submenu } = useContext(SubmenuContext);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchNavigation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToHost = (): void => {
    const { currentUser } = AuthAccess;
    window.location.href = currentUser?.host
      ? `//${currentUser.host}`
      : `//${window.location.host}`;
  };
  const userInfo = useMemo<UserDetailsProps>((): UserDetailsProps => {
    if (!navigation.menu.user.length) {
      return {
        name: '',
        avatar: '',
        isAdmin: false,
        adminUrl: '',
        isAdminSelected: false,
      };
    }

    const adminInfo = navigation.menu.user
      .filter((navigationLink: InterfaceLink) => navigationLink.key === 'app.menu.admin')
      .shift();

    return {
      name: navigation.user.name,
      avatar: navigation.user.img,
      isAdmin: adminInfo && 'url' in adminInfo,
      adminUrl: adminInfo && 'url' in adminInfo ? adminInfo.url : '',
      isAdminSelected:
        adminInfo && navigation.status.location && navigation.status.location.key === adminInfo.key,
    };
  }, [navigation]);

  const mainMenuItems = useMemo<[]>(
    () =>
      navigation.menu.modules.map(
        (item: { title: string; url: string; icon: string; key: string }) => ({
          title: item.title,
          url: item.url,
          icon: item.icon,
          isSelected: item.key === PERFORMANCE_MENU_KEY,
        })
      ),
    [navigation.menu.modules]
  );

  const subMenuItems = useMemo<MenuItem[]>(() => {
    if (!navigation.menu.modules.length) {
      return [];
    }

    const subMenu = navigation.menu.modules.find(
      (subItem: { key: string }) => subItem.key === 'app.menu.performance'
    );

    return subMenu.children.map(
      (item: { rel: string; title: string; url: string; totalItemNum: number }) => {
        const itemKey = item.rel.charAt(0).toLowerCase() + item.rel.slice(1);
        return {
          title: item.title,
          url: SubMenuItems[itemKey].isExternal ? item.url : SubMenuItems[itemKey].url,
          icon: SubMenuItems[itemKey].icon,
          isSelected: SubMenuItems[itemKey].key === submenu,
          handleClick: (): void => {
            if (SubMenuItems[itemKey].isExternal) {
              window.location.href = item.url;
            } else {
              history.push(SubMenuItems[itemKey].url);
            }
          },
        };
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation.menu.modules, submenu]);

  const userMenuItems = useMemo<MenuItem[]>(() => {
    const userMenu = navigation.menu.user.filter(
      (subItem: { key: string }) => subItem.key !== 'app.menu.admin'
    );

    return userMenu.map((item: { title: string; url: string; icon: string; children: any }) => ({
      title: item.title,
      url: item.url,
      icon: item.icon,
    }));
  }, [navigation.menu.user]);

  return (
    <ElmoMenu
      logo={navigation.branding.logo}
      asSubMenu={true}
      subMenuItems={subMenuItems}
      menuItems={mainMenuItems}
      userDetails={userInfo}
      userMenu={userMenuItems}
      theme={navigation.branding.theme}
      isReact={true}
      isLoading={navigation.isLoading}
      logoHref={redirectToHost}
      footerMessage={navigation.footerMessage}
      {...(navigation.branding.smallLogo !== ''
        ? { mobileLogo: navigation.branding.smallLogo }
        : {})}
    />
  );
};

export default Menu;
export const MenuWithRouter = withRouter(Menu);
