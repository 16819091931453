var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { ChevronLeftIcon, AdministratorIcon } from '../../Icon';
import MenuLink from './MenuLink';
import ThemeAware from './ThemeAware';
import { AdminLink, UserDetailsStyled, UserMenuStyled, UserAvatar, UserDetailsButton, UserMenuWrapperStyled, } from '../Styles';
import { getClass } from '../../_lib/helper';
var UserDetails = /** @class */ (function (_super) {
    __extends(UserDetails, _super);
    function UserDetails() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isUserMenuOpened: false,
        };
        _this.handleUserMenuOpen = function () {
            var isUserMenuOpened = _this.state.isUserMenuOpened;
            _this.setState({
                isUserMenuOpened: !isUserMenuOpened,
            });
        };
        return _this;
    }
    UserDetails.prototype.componentDidUpdate = function (prevProps, prevState) {
        var _a = this.props, userMenuOpened = _a.userMenuOpened, userMenuClosed = _a.userMenuClosed;
        if (prevState.isUserMenuOpened !== this.state.isUserMenuOpened) {
            if (userMenuOpened && this.state.isUserMenuOpened) {
                setTimeout(function () {
                    userMenuOpened();
                }, 1);
            }
            if (userMenuClosed && !this.state.isUserMenuOpened) {
                setTimeout(function () {
                    userMenuClosed();
                }, 1);
            }
        }
    };
    UserDetails.prototype.render = function () {
        var _this = this;
        var isUserMenuOpened = this.state.isUserMenuOpened;
        var _a = this.props, name = _a.name, avatar = _a.avatar, userMenu = _a.userMenu, isAdmin = _a.isAdmin, adminUrl = _a.adminUrl, userMeta = _a.userMeta;
        return (React.createElement(ThemeAware, { render: function (_a) {
                var adminColor = _a.adminColor, adminBackgroundColor = _a.adminBackgroundColor, profileColor = _a.profileColor, profileBackgroundColor = _a.profileBackgroundColor, menuFont = _a.menuFont;
                return (React.createElement(UserMenuWrapperStyled, { className: getClass('elmo-nav-user', {}, { 'is-opened': isUserMenuOpened }), menuFont: menuFont },
                    React.createElement(UserDetailsStyled, { profileColor: profileColor, profileBackgroundColor: profileBackgroundColor, className: "elmo-nav-user__header", onClick: _this.handleUserMenuOpen },
                        name && (React.createElement(UserAvatar, { label: name, isLabelVisible: true, size: 'xs', src: avatar })),
                        userMenu && (React.createElement("div", { className: "elmo-nav-user__header__btn-collapse" },
                            React.createElement(UserDetailsButton, { profileColor: profileColor, icon: React.createElement(ChevronLeftIcon, null), isText: true, ariaLabel: isUserMenuOpened ? 'Collapse' : 'Expand' })))),
                    React.createElement(UserMenuStyled, { profileColor: profileColor, profileBackgroundColor: profileBackgroundColor, className: "elmo-nav-user__menu" }, userMenu && (React.createElement("ul", null, userMenu.map(function (menuItem, i) { return (React.createElement(MenuLink, __assign({}, menuItem, { key: "user-menu-".concat(i), url: menuItem.url === '/logout' && (userMeta === null || userMeta === void 0 ? void 0 : userMeta.baseUrl)
                            ? "https://".concat(userMeta === null || userMeta === void 0 ? void 0 : userMeta.baseUrl).concat(menuItem.url)
                            : menuItem.url }))); })))),
                    isAdmin && adminUrl && (React.createElement(AdminLink, { adminColor: adminColor, adminBackgroundColor: adminBackgroundColor, className: "elmo-nav-user__admin-menu" },
                        React.createElement("a", { href: adminUrl },
                            React.createElement(AdministratorIcon, null),
                            React.createElement("span", { className: "elmo-nav-user__admin-menu__label" }, "Administration"))))));
            } }));
    };
    return UserDetails;
}(Component));
export default UserDetails;
