import React from 'react';
import MenuLink from './MenuLink';
import ThemeAware from './ThemeAware';
import { Nav } from '../Styles';
import './MainMenu.scss';
import MenuFootnote from './MenuFootnote';
function MainMenu(_a) {
    var menuItems = _a.menuItems, footerMessage = _a.footerMessage, onOpenSubmenu = _a.onOpenSubmenu;
    return (React.createElement(ThemeAware, { render: function (_a) {
            var menuBackgroundColor = _a.menuBackgroundColor, menuFont = _a.menuFont, menuFontColor = _a.menuFontColor, menuHoverFontColor = _a.menuHoverFontColor;
            return (React.createElement(Nav, { className: "elmo-nav-menu", menuBackgroundColor: menuBackgroundColor, menuFont: menuFont, "aria-label": "Elmo menu" }, menuItems && (React.createElement(React.Fragment, null,
                React.createElement("ul", null, menuItems.map(function (menuItem, i) { return (React.createElement(MenuLink, { key: "menu-item-".concat(i), title: menuItem.title, url: menuItem.url, icon: menuItem.icon, isSelected: menuItem.isSelected, menuFontColor: menuFontColor, menuHoverFontColor: menuHoverFontColor, handleClick: menuItem.isSelected && onOpenSubmenu
                        ? onOpenSubmenu
                        : menuItem.handleClick })); })),
                React.createElement(MenuFootnote, { menuFontColor: menuFontColor, menuFont: menuFont, footerMessage: footerMessage })))));
        } }));
}
export default MainMenu;
