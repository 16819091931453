import config from '../config';
import { removeToken, setToken } from '../token';

export const getOAuthRedirectionPath = (): string =>
  `${config.api.oauthClientSecureRedirectionPath}null?frontend-url=${window.location.href}`;

const redirectToTmsLogin = (): void => {
  window.location.href = `https://${window.location.hostname}${getOAuthRedirectionPath()}`;
};

export const removeJWTAndPromptForLogin = (): void => {
  removeToken();
  return redirectToTmsLogin();
};

export const completeLogin = (token: string): void => {
  setToken(token);
};

export default { getOAuthRedirectionPath, removeJWTAndPromptForLogin, completeLogin };
