import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getReview360Performance,
  getReview360PerformanceSuccess,
  getReview360PerformanceFailure,
} from './actions';
import apis from './api';

export const getReview360PerformanceSaga = function* (action: Record<string, any>): SagaIterator {
  try {
    const data = yield call(apis.getReview360Performance, action.payload);
    yield put(getReview360PerformanceSuccess(data));
  } catch (error) {
    yield put(getReview360PerformanceFailure(error));
  }
};

/**
 * Performance Review 360 Sagas
 */
export default function* root(): Record<string, any> {
  yield all([takeLatest(getReview360Performance, getReview360PerformanceSaga)]);
}
