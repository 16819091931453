import React, { lazy, useEffect, useContext } from 'react';
import Suspense from '../../element/Suspense';
import { SubmenuContext } from '../../element/Menu/submenuContext';
import SubMenuItems from '../../element/Menu/SubMenuItems';
import { Route } from 'react-router-dom';

const TeamPerformanceView = lazy(() => import('./view'));

const TeamPerformances = ({ routes }: { routes: Route[] }): React.ReactElement => {
  const { setSubmenu } = useContext(SubmenuContext);
  useEffect(() => {
    /* istanbul ignore else */
    if (setSubmenu) {
      setSubmenu(SubMenuItems.teamPerformance.key);
    }
  }, [setSubmenu]);

  return (
    <Suspense>
      <TeamPerformanceView routes={routes} />
    </Suspense>
  );
};

export default TeamPerformances;
