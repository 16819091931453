/* eslint-disable no-bitwise */
import logger from '../logger';
import {
  STATUS_PLANNING,
  STATUS_INPROGRESS,
  STATUS_FINALISE,
  STATUS_COMPLETED,
  MONTH_NAMES,
  NOTICE_TYPES,
} from '../const';
import { messages } from '../translation';
import classNames from 'classnames';
import React from 'react';
import { BadgeType } from 'elmo-elements/dist/Badge';
import appConfig from '../config';
import AuthAccess from '../auth/access';
import { RatingSystemConfigurationType, WorkflowItem } from '../../page/Performance/type';

/**
 * Define a very general simple functionality here that can be used accross
 * multiple services or components. A function you want to define here should be
 * very small, and it should be used in multiple places.
 */

/**
 * Extract the component name
 *
 * @param Component
 */
export function getComponentName(Component: React.FC): string {
  return Component.displayName || Component.name || 'Component';
}

/**
 * Note: Use this function only for testing when you want to have some delay
 *
 * @param path
 * @param time
 */
export function withDelay(callback: () => any, time = 1000): Promise<any> {
  return new Promise((resolve: any) => {
    setTimeout(() => {
      resolve(callback());
    }, time);
  });
}

/**
 * A simple queryString helper
 */
interface QueryStringInterface {
  parse: (query: string) => any;
  stringify: (params: any) => string;
}

/**
 * A simple queryString helper
 */
export const queryString: QueryStringInterface = {
  parse: (query: string) => {
    if (query) {
      const qs = new URLSearchParams(query);
      const params: any = {};
      qs.forEach((value: any, key: any) => {
        params[key] = qs.get(key);
      });

      return params;
    }
    return null;
  },
  stringify: (params: any) => {
    const qs = new URLSearchParams('');
    if (params) {
      const keys = Object.keys(params);
      if (keys && Array.isArray(keys)) {
        keys.forEach((key: any) => {
          qs.append(key, params[key]);
        });
      }
    }

    return qs.toString();
  },
};

/**
 * Remove falsey properties from Object
 * @param object
 */
export function clearObject(object: Record<any, any>): void {
  return Object.keys(object).forEach(
    key => (object[key] === '' || object[key] === null) && delete object[key]
  );
}

/**
 * A simple function to generate a route with placeholder
 * For example you pass ('/users/user/:id', {params: {id: 12}})
 * It gives you `/users/user/12`
 *
 * For example you pass ('/users/user/:id', {params: {id: 12}, query: {page: 12}})
 * It gives you `/users/user/12?page=12` *
 *
 * @param path
 * @param param
 */
export function getRoute(path: string, config?: Record<string, any>): string {
  let finalPath = path;
  if (!config) {
    return finalPath;
  }

  // If route param exist, then replace them, for example
  // replace '/users/user/:id' with {id: 12}
  if (config.params) {
    const keys: any = Object.keys(config.params);
    if (Array.isArray(keys)) {
      finalPath = keys.reduce(
        (cPath: string, key: string) => cPath.replace(`:${key}`, config.params[key]),
        path
      );
    }
  }

  // If route query string params exist
  if (config.query) {
    // tslint:disable-next-line: no-use-before-declare
    const searchOld = queryString.parse(document.location.search.split('?')[1]);
    const searchNew: any = {
      ...searchOld,
      ...config.query,
    };

    clearObject(searchNew);

    const search = queryString.stringify(searchNew);

    if (searchNew) {
      finalPath = `${finalPath}?${search}`;
    }
  }

  return finalPath;
}

/**
 * A document title(Head > Title) helper
 * @param title
 */
export function setPageTitle(title: string): void {
  document.title = title;
}

/**
 * Get data from Localstorage
 * @param key
 * @param format
 */
export function getLocalstorage(key: string, format: 'string' | 'object'): any {
  try {
    const localStorageData = localStorage.getItem(key);
    if (localStorageData) {
      if (format === 'string') {
        return localStorageData;
      }
      if (format === 'object') {
        try {
          return JSON.parse(localStorageData);
        } catch (e) {
          return false;
        }
      }
    }
    return false;
  } catch (error) {
    logger.error(error);
  }

  return false;
}

/**
 * Set data to Localstorage
 * @param key
 * @param data
 * @param format
 */
export function setLocalstorage(
  key: string,
  data: Record<any, any> | string,
  format: 'string' | 'object'
): void {
  try {
    if (localStorage) {
      if (format === 'string') {
        localStorage.setItem(key, data as string);
      }
      if (format === 'object') {
        localStorage.setItem(key, JSON.stringify(data));
      }
    }
  } catch (error) {
    logger.error(error);
  }
}

/**
 * Toggle Array
 * @param array
 * @param value
 */
export function toggleArray(array: number[], value: number): number[] {
  const index = array.indexOf(value);

  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }
  return array;
}

/**
 * Get current query string and merge with default
 * @param defaultQuery
 */
export function getQuery(defaultQuery?: Record<any, any>): Record<any, any> {
  const currentQuery = queryString.parse(document.location.search);
  const query = {
    ...defaultQuery,
    ...currentQuery,
  };
  query.page = parseInt(query.page ?? 1, 10);
  query.limit = parseInt(query.limit ?? 10, 10);
  return query;
}

/**
 * split sort query into the direction and columnIndex
 * @param sortableColumns
 * @param sort
 */
export function getSortData(
  sortableColumns: any[],
  sort?: string,
  defaultSortColumn?: number
): Record<any, any> {
  let sortColumn = defaultSortColumn || 3;
  let sortDirection = 'asc';
  let sortColumnKey = '';
  if (sort) {
    const sortSplit = sort.split('-');
    sortColumnKey = sortSplit.length === 1 ? sortSplit[0] : sortSplit[1];
    sortDirection = sortSplit.length === 1 ? 'asc' : 'desc';
    sortableColumns.forEach((item, index) => {
      if (item.id === sortColumnKey) {
        sortColumn = index;
      }
    });
  }

  return {
    sortDirection,
    sortColumn,
    sortColumnKey,
  };
}

/**
 * get the badge type (like red, blue color)
 * @param phaseId
 */
export function getPhaseBadgeType(phaseId?: string): BadgeType {
  switch (phaseId) {
    case STATUS_PLANNING: // planning
      return 'warning';
    case STATUS_INPROGRESS: // InProgress
      return 'info';
    case STATUS_FINALISE: // Finalise
      return 'info';
    case STATUS_COMPLETED: // Complete
      return 'success';
    default:
      return 'info';
  }
}

export enum BadgeTone {
  POSITIVE = 'positive',
  INFORMATIVE = 'informative',
  CAUTION = 'caution',
  CRITICAL = 'critical',
  NEUTRAL = 'neutral',
}

export function getPhaseBadgeTone(phaseId?: string): BadgeTone {
  switch (phaseId) {
    case STATUS_PLANNING: // planning
      return BadgeTone.NEUTRAL;
    case STATUS_INPROGRESS: // InProgress
      return BadgeTone.INFORMATIVE;
    case STATUS_FINALISE: // Finalise
      return BadgeTone.CAUTION;
    case STATUS_COMPLETED: // Complete
      return BadgeTone.POSITIVE;
    default:
      return BadgeTone.INFORMATIVE;
  }
}

export enum BadgeWeight {
  BOLD = 'bold',
  SUBTLE = 'subtle',
}

export function getPhaseBadgeWeight(phaseId?: string): BadgeWeight {
  switch (phaseId) {
    case STATUS_PLANNING: // planning
      return BadgeWeight.BOLD;
    case STATUS_INPROGRESS: // InProgress
    case STATUS_FINALISE: // Finalise
    case STATUS_COMPLETED: // Complete
      return BadgeWeight.SUBTLE;
    default:
      return BadgeWeight.SUBTLE;
  }
}

export enum NoticeTone {
  POSITIVE = 'positive',
  INFORMATIVE = 'informative',
  CAUTION = 'caution',
  CRITICAL = 'critical',
}

export function getNoticeTone(noticeType?: string): NoticeTone {
  switch (noticeType) {
    case NOTICE_TYPES.DANGER:
      return NoticeTone.CRITICAL;
    case NOTICE_TYPES.INFO:
      return NoticeTone.INFORMATIVE;
    case NOTICE_TYPES.SUCESS:
      return NoticeTone.POSITIVE;
    case NOTICE_TYPES.WARNING:
      return NoticeTone.CAUTION;
    default:
      return NoticeTone.INFORMATIVE;
  }
}

/**
 * Get Empty State message
 *
 * @param {string} key Empty state message key
 * @param {string} searchKeyword Search keyword
 */
export function getEmptyStateMessage(
  key = 'default',
  searchKeyword?: string
): { title: string; content: string } {
  const msgKey = searchKeyword ? 'search' : key;

  return {
    title: messages.app.emptyStateTitle[msgKey],
    content: messages.app.emptyStateContent[msgKey],
  };
}

export function getBrowserVisibilityProp(): string {
  if (typeof (document as any).msHidden !== 'undefined') {
    return 'msvisibilitychange';
  }
  if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
  return 'visibilitychange';
}

export function getBrowserDocumentHiddenProp(): string {
  if (typeof (document as any).msHidden !== 'undefined') {
    return 'msHidden';
  }
  if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
  return 'hidden';
}
export function getIsDocumentVisible(): boolean {
  return !(document as any)[getBrowserDocumentHiddenProp()];
}

/**
 * deduplicate the array elements
 * @param accumulator
 * @param currentValue
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function deduplicateReducer(accumulator: any[], currentValue: any): any[] {
  return accumulator.includes(currentValue) ? accumulator : [...accumulator, currentValue];
}

/**
 * Make a BEM string based on props
 * @param  {string} name Base class.
 * @param  {Object<string, any>} classes Component classes.
 * @param  {Object<string, any>} modifiers Component extra class modifiers passing as props.
 * @return {string} BEM class string.
 */
export function getClass(name: string, classes?: string, modifiers?: Record<any, any>): string {
  const modifierClasses: any = {};
  if (modifiers) {
    Object.keys(modifiers).forEach((key: string) => {
      if (key === 'className') {
        modifierClasses[`${modifiers[key]}`] = true;
      } else if (modifiers[key]) {
        modifierClasses[`${name}--${key}`] = modifiers[key];
      }
    });
  }

  return classNames(name, classes, modifierClasses);
}

export function adjustColour(colour: string, amount: number): string {
  let usePound = false;

  if (colour[0] === '#') {
    colour = colour.slice(1);
    usePound = true;
  }

  const num = parseInt(colour, 16);

  let r = (num >> 16) + amount;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amount;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export function isOnlyOneItemInLastPage(
  totalItems: number,
  currentPage?: number,
  itemsPerPage?: number
): boolean {
  if (!currentPage || !itemsPerPage) {
    return false;
  }
  if (totalItems % itemsPerPage === 1 && Math.ceil(totalItems / itemsPerPage) === currentPage) {
    return true;
  }
  return false;
}

// export time format from `2021-10-12T15:07:23+11:00` to `15:07 on 12 Oct 2021`
export function getHumanReadableDate(d: Date): string {
  const hours = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
  const mins = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
  return `${hours}:${mins} on ${d.getDate()} ${MONTH_NAMES[d.getMonth()]} ${d.getFullYear()}`;
}

export function getPerformanceViewUrl(host: string, id: string): string {
  return `https://${host}${appConfig.legacy.appraisalUrl}/${id}`;
}

export function getPerformanceExportUrl(host: string, id: number): string {
  return `https://${host}/dashboard/export-performance-detail/${id}`;
}

export function onPerformanceViewClick(host: string, id: string): void {
  window.location.href = getPerformanceViewUrl(host, id);
}

export function getAppraisalEditUrl(id: number): string {
  const { currentUser } = AuthAccess;
  return `https://${currentUser.host}/admin/add-edit-appraisal/${id}`;
}

export function getUserProfileUrl(id: string): string {
  const { currentUser } = AuthAccess;
  return `https://${currentUser.host}/controlpanel/user-profile/${id}`;
}

export function getRatingSystemUrl(id: number): string {
  const { currentUser } = AuthAccess;

  return `https://${currentUser.host}/admin/edit-rating/${id}`;
}

export function getActiveWorkflowsAsString(activeWorkflows: WorkflowItem[]): string {
  return activeWorkflows.map(worfkflow => worfkflow.title).join(', ');
}

export default {
  getQuery,
  onPerformanceViewClick,
  getPerformanceViewUrl,
  getPerformanceExportUrl,
  getHumanReadableDate,
  isOnlyOneItemInLastPage,
  adjustColour,
  getClass,
  deduplicateReducer,
  getEmptyStateMessage,
  getBrowserDocumentHiddenProp,
  getPhaseBadgeType,
  getPhaseBadgeTone,
  getPhaseBadgeWeight,
  BadgeTone,
  getSortData,
  queryString,
  getComponentName,
  withDelay,
  setPageTitle,
  getLocalstorage,
  toggleArray,
  setLocalstorage,
  getBrowserVisibilityProp,
  getIsDocumentVisible,
  getRoute,
  clearObject,
  getNoticeTone,
  NoticeTone,
  getAppraisalEditUrl,
  getUserProfileUrl,
  getRatingSystemUrl,
  getActiveWorkflowsAsString,
};
