var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component, Children, cloneElement, } from 'react';
import { RadioButtonsDirections, RadioButtonsTypes, } from './type';
import { getClass, isElementOfType, uniqueId } from '../_lib/helper';
import Radio from '../Radio';
import './RadioButtons.scss';
var RadioButtons = /** @class */ (function (_super) {
    __extends(RadioButtons, _super);
    function RadioButtons(props) {
        var _this = _super.call(this, props) || this;
        _this.radioInputName = uniqueId('elmo-radio-buttons');
        _this.onRadioButtonClick = function (value) {
            _this.setState({
                selectedValue: value,
            });
            if (_this.props.onChange) {
                _this.props.onChange(value);
            }
        };
        _this.state = {
            selectedValue: props.selected,
        };
        return _this;
    }
    RadioButtons.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.selected !== prevProps.selected) {
            this.setState({
                selectedValue: this.props.selected,
            });
        }
    };
    RadioButtons.prototype.isSelected = function (value) {
        return this.state.selectedValue === value;
    };
    RadioButtons.prototype.renderRadioButtons = function () {
        var _this = this;
        var id = this.props.id;
        return Children.map(this.props.children, function (child, index) {
            if (!isElementOfType(child, Radio)) {
                return null;
            }
            var radioChild = child;
            var childId;
            if (id) {
                childId = "".concat(id, "-").concat(index);
            }
            return cloneElement(radioChild, {
                onChange: _this.onRadioButtonClick,
                checked: _this.isSelected(radioChild.props.value),
                disabled: _this.props.disabled || radioChild.props.disabled,
                id: childId,
                name: _this.radioInputName,
            });
        });
    };
    RadioButtons.prototype.render = function () {
        var _a = this.props, id = _a.id, className = _a.className, type = _a.type, direction = _a.direction, ariaLabelledBy = _a.ariaLabelledBy;
        var classes = getClass('elmo-radio-buttons', [className, 'row flex-inline no-gutters'], {
            col: direction === RadioButtonsDirections.vertical,
            solid: type === RadioButtonsTypes.solid,
            outline: type === RadioButtonsTypes.outlined,
            grouped: type === RadioButtonsTypes.grouped,
        });
        return (React.createElement("div", { id: id, className: classes, role: "radiogroup", "aria-labelledby": ariaLabelledBy, "data-testid": "elmo-radio-bar-".concat(id || 'default') }, this.renderRadioButtons()));
    };
    return RadioButtons;
}(Component));
export default RadioButtons;
