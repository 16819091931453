import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { t, messages } from '../../../../lib/translation';
import { getEmptyStateMessage, getPhaseBadgeTone, getPhaseBadgeWeight } from '../../../../lib/util';
import { dateFormat } from '../../../../lib/date';
import { AssigneeItem, AssigneeQuery } from '../../type';
import { getAssignees } from '../../state';
import { STATUS_ALL } from '../../../../lib/const';
import './index.scss';
import { Pagination, PaginationState } from '@eds/pagination';
import { Flex } from '@eds/flex';
import {
  ColumnSort,
  SortDirection,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from '@eds/table';
import { Avatar } from '@eds/avatar';
import { Badge } from '@eds/badge';
import { Box } from '@eds/box';
import { EmptyState } from '@eds/empty-state';
import { DropdownOption } from '../../../TeamPerformance/type';
import { useDebouncedCallback } from 'use-debounce';
import { FilterBar } from '@eds/filtering';
import { AppraisalStatusOptions } from '../../../TeamPerformance/state/state';
import { SelectDropdown } from '@eds/select-dropdown';

type AssigneeListViewProps = {
  appraisalId: number;
};

const AssigneeListView = ({ appraisalId }: AssigneeListViewProps): React.ReactElement => {
  const { isLoading, assignees } = useSelector((state: any) => state.assignAppraisal.assigneeList);
  const [query, setQuery] = useState<AssigneeQuery>({
    sort: 'startDate',
    limit: 10,
    page: 1,
    phase: `${STATUS_ALL}`,
  });
  const emptyState = useMemo(
    () => getEmptyStateMessage('assigneeList', query.keyword),
    [query.keyword]
  );
  const dispatch = useDispatch();
  const [phaseItems, setPhaseItems] = useState<DropdownOption[]>([]);
  const getColumnSort = (): ColumnSort => {
    let sortDirection: SortDirection = 'default';
    let columnName = '';
    if (query.sort) {
      const sortSplit = query.sort.split('-');
      columnName = sortSplit.length === 1 ? sortSplit[0] : sortSplit[1];
      sortDirection = sortSplit.length === 1 ? 'ascending' : 'descending';
    }
    return {
      sortDirection,
      columnName,
    };
  };

  useEffect(() => {
    dispatch(getAssignees(appraisalId, query));
  }, [appraisalId, query, dispatch]);

  const onPageUpdate = (paginationState: PaginationState): void => {
    if (paginationState.currentPage !== Number(query.page)) {
      setQuery({ ...query, page: paginationState.currentPage });
    }

    if (paginationState.currentItemsPerPage !== Number(query.limit)) {
      setQuery({ ...query, limit: paginationState.currentItemsPerPage });
    }
  };

  /**
   * Handle search box submission.
   *
   * @param {string} value The search value
   */
  const handleSearch = (searchValue?: string): void => {
    setQuery({ ...query, keyword: searchValue });
  };

  /**
   * Handle quick filter change.
   *
   * @param {string} phase The filtered phase
   */
  const handleFilter = (phase: string): void => {
    setQuery({ ...query, phase });
  };

  const onDebouncedSearch = useDebouncedCallback((searchValue?: string): void => {
    handleSearch(searchValue);
  }, 300);

  /**
   * Handle table sort change.
   *
   * @param {number} columnIndex
   * @param {string} direction
   */
  const handleSort = (columnSort: ColumnSort): void => {
    const sort =
      columnSort.sortDirection === 'descending'
        ? `-${columnSort.columnName}`
        : columnSort.columnName;
    setQuery({ ...query, sort });
  };

  /**
   * Handle status filter
   *
   * @param {DropdownOption[] | null} items
   */
  const onMultiPhaseFilterChange = (items: DropdownOption[] | null): void => {
    if (items && items.length > 0) {
      handleFilter(items.map((item: DropdownOption) => item.value).join(','));
      setPhaseItems(items);
    } else {
      handleFilter(STATUS_ALL);
      setPhaseItems([]);
    }
  };

  const [searchValue, setSearchValue] = useState<string>(query.keyword || '');

  const handleSearchClear = (): void => {
    setSearchValue('');
    onDebouncedSearch();
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value.trim();
    setSearchValue(value);
    /* istanbul ignore else */
    if (value && value.length >= 2) {
      onDebouncedSearch(value);
    }

    /* istanbul ignore else */
    if (value.length === 0) {
      handleSearchClear();
    }
  };

  /**
   * Render the assignee list data.
   */
  const AssigneeListData = (): React.ReactElement => (
    <Flex flexDirection="column" gap="medium">
      <Table tableBorder>
        <TableHead>
          <TableRow>
            <TableHeaderCell
              columnName="name"
              key="name"
              onSortChange={handleSort}
              sort={getColumnSort()}
              data-testid="name"
            >
              {t(messages.assignAppraisal.assigneeList.listHeader.name)}
            </TableHeaderCell>
            <TableHeaderCell
              columnName="status"
              key="status"
              onSortChange={handleSort}
              sort={getColumnSort()}
              data-testid="status"
            >
              {t(messages.assignAppraisal.assigneeList.listHeader.status)}
            </TableHeaderCell>
            <TableHeaderCell
              columnName="manager"
              key="manager"
              onSortChange={handleSort}
              sort={getColumnSort()}
              data-testid="manager"
            >
              {t(messages.assignAppraisal.assigneeList.listHeader.manager)}
            </TableHeaderCell>
            <TableHeaderCell
              columnName="startDate"
              key="startDate"
              onSortChange={handleSort}
              sort={getColumnSort()}
              data-testid="startDate"
            >
              {t(messages.assignAppraisal.assigneeList.listHeader.startDate)}
            </TableHeaderCell>
            <TableHeaderCell
              columnName="endDate"
              key="endDate"
              onSortChange={handleSort}
              sort={getColumnSort()}
              data-testid="endDate"
            >
              {t(messages.assignAppraisal.assigneeList.listHeader.endDate)}
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assignees.data.map((item: AssigneeItem) => (
            <TableRow key={item.id}>
              <TableCell>
                <Flex alignItems="center" gap="xxsmall">
                  {item.employee.firstName && item.employee.lastName && (
                    <Avatar
                      name={`${item.employee.firstName} ${item.employee.lastName}`}
                      src={item.employee.profileImage}
                    />
                  )}
                  {`${item.employee.firstName} ${item.employee.lastName}`}
                </Flex>
              </TableCell>
              <TableCell>
                <Badge
                  label={item.phase.text}
                  tone={getPhaseBadgeTone(item.phase.id.toString())}
                  weight={getPhaseBadgeWeight(item.phase.id.toString())}
                />
              </TableCell>
              <TableCell>
                <Flex alignItems="center" gap="xxsmall">
                  {item.manager.firstName && item.manager.lastName && (
                    <Avatar
                      src={item.manager.profileImage}
                      name={`${item.manager.firstName} ${item.manager.lastName}`}
                    />
                  )}
                  {`${item.manager.firstName} ${item.manager.lastName}`}
                </Flex>
              </TableCell>
              <TableCell>{item.startDate ? dateFormat(item.startDate * 1000) : '-'}</TableCell>
              <TableCell>{item.endDate ? dateFormat(item.endDate * 1000) : '-'}</TableCell>
            </TableRow>
          ))}
          {!assignees.data.length && (
            <TableRow>
              <TableCell colSpan="100%">
                <Box marginTop="large" marginBottom="large">
                  <EmptyState title={emptyState.title} description={emptyState.content} />
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        indexBased={1}
        itemsCount={assignees.total}
        currentPage={Number(query.page)}
        currentItemsPerPage={Number(query.limit)}
        itemsLabelSingular="item"
        itemsLabelPlural="items"
        onPageUpdate={onPageUpdate}
      />
    </Flex>
  );

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginTop="small"
        marginBottom="large"
        flexWrap="wrap"
        gap="small"
      >
        <FilterBar
          loading={isLoading}
          keywordFilter={{
            placeholder: t(messages.assignAppraisal.assigneeList.searchTitle),
            value: searchValue,
            onChange: handleSearchChange,
            onClear: handleSearchClear,
            width: '30rem',
          }}
        >
          <SelectDropdown
            isMulti
            clearButton
            hideSearchInput
            label="Status"
            value={phaseItems}
            onChangeMulti={onMultiPhaseFilterChange}
            items={AppraisalStatusOptions}
          />
        </FilterBar>
      </Flex>
      <AssigneeListData />
    </>
  );
};

export default AssigneeListView;
