import { ENV } from '../const';

/**
 * Some logic to get the env name
 */
export function getEnv(): string | undefined {
  return process.env.REACT_APP_BUILD_ENV;
}

/**
 *
 * @param env
 */
export function isDev(env?: string): boolean {
  return env ? env === ENV.names.development : getEnv() === ENV.names.development;
}

/**
 *
 * @param env
 */
export function isProd(env?: string): boolean {
  return env ? env === ENV.names.production : getEnv() === ENV.names.production;
}

export function isPendoEnabled(): boolean {
  return process.env.REACT_APP_PENDO_ENABLED === 'true';
}

export function pendoApiKey(): string | undefined {
  return process.env.REACT_APP_PENDO_API_KEY;
}
