/* eslint-disable max-len */
import React from 'react';
import './Spinner.scss';
var sizes = {
    xl: '50px',
    l: '40px',
    m: '30px',
    s: '20px',
};
function Spinner(_a) {
    var color = _a.color, size = _a.size, id = _a.id, role = _a.role, ariaLabel = _a.ariaLabel;
    var radius = (size && sizes[size]) || sizes.m;
    return (React.createElement("div", { role: role },
        React.createElement("span", { id: id, className: "elmo-spinner", "data-testid": "elmo-spinner-".concat(id || 'default'), "aria-label": ariaLabel },
            React.createElement("svg", { width: radius, height: radius, viewBox: "0 0 50 50", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("circle", { className: "elmo-spinner__path", cx: "25", cy: "25", r: "20", fill: "none", strokeWidth: "2" })))));
}
Spinner.defaultProps = {
    color: '#4285F4',
    role: 'status',
    ariaLabel: 'Loading...',
};
export default Spinner;
