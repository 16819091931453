import React, { ReactElement } from 'react';
import { Route } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function PrivateRouteComponent(props: any): ReactElement {
  return <Route {...props} />;
}

const PrivateRoute = PrivateRouteComponent;

export default PrivateRoute;
