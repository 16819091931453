import { getClass } from '../../../_lib/helper';
/**
 * Responsive properties
 */
export var responsiveProperties = [
    { property: 'span', prefix: 'elmo-col-' },
    { property: 'auto', prefix: 'elmo-col-' },
    { property: 'order', prefix: 'order-' },
    { property: 'offset', prefix: 'elmo-offset-' },
    { property: 'alignSelf', prefix: 'align-self-' },
    { property: 'shrink', prefix: 'flex-shrink-' },
    { property: 'grow', prefix: 'flex-grow-' },
    { property: 'fill', prefix: 'flex-' },
];
/**
 *
 * @param breakpoint
 * @param options
 */
export function mapResponsive(block, breakpoint, options) {
    if (options === null || options === undefined) {
        return '';
    }
    if (typeof options === 'number') {
        return "".concat(block, "-").concat(breakpoint, "-").concat(options);
    }
    return responsiveProperties
        .map(function (item) {
        if (options[item.property] !== undefined) {
            return "".concat(item.prefix).concat(breakpoint, "-").concat(options[item.property]);
        }
        return undefined;
    })
        .join(' ');
}
/**
 *
 * @param block
 * @param props
 */
export function mapPropsToClass(block, props) {
    var _a;
    var className = props.className, isAuto = props.isAuto, span = props.span, order = props.order, offset = props.offset, align = props.align, shrink = props.shrink, grow = props.grow, isFill = props.isFill, xs = props.xs, sm = props.sm, md = props.md, lg = props.lg, xl = props.xl, xxl = props.xxl, xxxl = props.xxxl;
    return getClass('elmo-col', (_a = {},
        _a["".concat(className)] = className,
        _a["".concat(block, "-auto")] = isAuto,
        _a["elmo-col-".concat(span)] = span,
        _a["order-".concat(order)] = order,
        _a["elmo-offset-".concat(offset)] = offset,
        _a["align-self-".concat(align)] = align,
        _a["flex-shrink-".concat(shrink)] = shrink !== undefined,
        _a["flex-grow-".concat(grow)] = grow !== undefined,
        _a["flex-fill"] = isFill,
        _a["".concat(mapResponsive(block, 'xs', xs))] = xs,
        _a["".concat(mapResponsive(block, 'sm', sm))] = sm,
        _a["".concat(mapResponsive(block, 'md', md))] = md,
        _a["".concat(mapResponsive(block, 'lg', lg))] = lg,
        _a["".concat(mapResponsive(block, 'xl', xl))] = xl,
        _a["".concat(mapResponsive(block, 'xxl', xxl))] = xxl,
        _a["".concat(mapResponsive(block, 'xxxl', xxxl))] = xxxl,
        _a));
}
