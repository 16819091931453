// eslint-disable-next-line consistent-return
((): void | boolean => {
  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(
    event: string,
    params: any = {
      bubbles: false,
      cancelable: false,
      detail: undefined,
    }
  ): CustomEvent {
    const evt: CustomEvent = document.createEvent('CustomEvent') as any;
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = Event.prototype;
  const win = window as Window;
  win.CustomEvent = CustomEvent as any;
})();
export {};
