var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Children, Component } from 'react';
import Row from '../Grid/Row/Row';
import Col from '../Grid/Col/Col';
import { getClass, isElementOfType } from '../_lib/helper';
import './DateRange.scss';
import DateRangeInput from './component/DateRangeInput';
import DateRangeDatepicker from './component/DateRangeDatepicker';
var DateRange = /** @class */ (function (_super) {
    __extends(DateRange, _super);
    function DateRange() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            startDate: null,
            endDate: null,
        };
        /**
         * Called when a date is selected in the datepicker
         * @param isStartDate
         * @param childProps
         */
        _this.onChange = function (isStartDate, childProps) { return function (d) {
            var name = childProps.name, onChange = childProps.onChange;
            var currentValue = {
                startDate: isStartDate ? d : _this.state.startDate,
                endDate: !isStartDate ? d : _this.state.endDate,
            };
            _this.setState(__assign({}, currentValue));
            onChange(d, name);
        }; };
        /**
         * Returns true if the date passed in is disabled
         * @param current
         */
        _this.disabledDateStart = function (current) {
            var endDate = _this.state.endDate;
            return current && endDate ? endDate < current : false;
        };
        /**
         * Returns true if the date passed in is disabled
         * @param current
         */
        _this.disabledDateEnd = function (current) {
            var startDate = _this.state.startDate;
            return current && startDate ? startDate > current : false;
        };
        /**
         * Checks if the date is disabled
         * @param isStartDate
         * @param childProps
         */
        _this.disabledDate = function (isStartDate, childProps) { return function (value) {
            var disabledDate = childProps.disabledDate;
            var isDisabledInvalidRange = isStartDate
                ? _this.disabledDateStart(value)
                : _this.disabledDateEnd(value);
            var isDisabled = disabledDate ? disabledDate(value) : false;
            return isDisabled || isDisabledInvalidRange;
        }; };
        /**
         * Called by child components (DateRangeDatepicker) to update the startDate and endDate in state,
         * this is required when the startDate and endDate are actually managed outside the component
         * @param isStartDate
         */
        _this.updateDateInState = function (isStartDate) { return function (value) {
            var nextState = _this.state;
            if (isStartDate) {
                nextState.startDate = value;
            }
            else {
                nextState.endDate = value;
            }
            _this.setState(nextState);
        }; };
        /**
         * Returns the placeholder for the datepicker
         * @param isStartDate
         */
        _this.getPlaceholder = function (isStartDate) {
            return isStartDate ? 'Start Date' : 'End Date';
        };
        _this.checkValidChildren = function () {
            var children = _this.props.children;
            if (Children.count(children) !== 2) {
                return false;
            }
            return Children.toArray(children).every(function (child) {
                return isElementOfType(child, DateRangeInput);
            });
        };
        return _this;
    }
    /**
     * Render a datepicker
     * @param childProps
     * @param isStartDate
     */
    DateRange.prototype.renderDatepicker = function (childProps, isStartDate) {
        var className = 'elmo-date-range__' + (isStartDate ? 'start' : 'end');
        var _a = this.props, format = _a.format, isClearable = _a.isClearable, isDisabled = _a.isDisabled, isInline = _a.isInline;
        var childPlaceholder = childProps.placeholder, label = childProps.label, value = childProps.value, ariaLabel = childProps.ariaLabel;
        var placeholder = childPlaceholder
            ? childPlaceholder
            : this.getPlaceholder(isStartDate);
        return (React.createElement(Col, { md: 12, role: "presentation" },
            React.createElement(DateRangeDatepicker, { className: className, onChange: this.onChange(isStartDate, childProps), disabledDate: this.disabledDate(isStartDate, childProps), format: format, placeholder: placeholder, label: label, ariaLabel: ariaLabel, value: value, updateParentDate: this.updateDateInState(isStartDate), isClearable: isClearable, isDisabled: isDisabled, isInline: isInline })));
    };
    DateRange.prototype.render = function () {
        var _this = this;
        var _a = this.props, id = _a.id, isVertical = _a.isVertical, children = _a.children, className = _a.className;
        var validChildren = this.checkValidChildren();
        if (!validChildren) {
            return null;
        }
        var classNames = getClass('elmo-date-range', className, {
            vertical: isVertical,
        });
        return (React.createElement("div", { id: id, "data-testid": "elmo-date-range-".concat(id || 'default'), className: classNames },
            React.createElement(Row, { role: "presentation" }, Children.map(children, function (child, index) {
                return _this.renderDatepicker(child.props, index === 0);
            }))));
    };
    DateRange.Start = DateRangeInput;
    DateRange.End = DateRangeInput;
    return DateRange;
}(Component));
export default DateRange;
