import Logger from './logger';
import { isDev } from '../env';

const logger: Logger = new Logger();
logger.setLevel('info');
if (isDev()) {
  logger.setLevel('debug');
}

/**
 * <Dev.Note>
 *   If you want to do some extra action after we log an Info, Error or Debug messages
 *   Then use the following setPost<x>Log calbacl functions
 * </Dev.Note>
 */
logger.setPostInfoLog((...msg: any[]) => {
  console.log(msg);
});

logger.setPostErrorLog((...msg: any[]) => {
  console.log(msg);
});

logger.setPostDebugLog((...msg: any[]) => {
  console.log(msg);
});

export default logger;
