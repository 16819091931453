var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Col from '../../Grid/Col';
import React from 'react';
import './ListTableTd.scss';
import { withListTableContext } from '../ListTableContextConsumer';
function ListTableTd(_a) {
    var data = _a.data, rowIndex = _a.rowIndex, colIndex = _a.colIndex, label = _a.label, property = _a.property, classNamesForCols = _a.classNamesForCols, colSpanForCols = _a.colSpanForCols, render = _a.render, children = _a.children, hideLabel = _a.hideLabel, truncateData = _a.truncateData;
    if (colIndex === undefined || rowIndex === undefined) {
        return null;
    }
    var classNamesForIndex = classNamesForCols
        ? classNamesForCols[colIndex]
        : '';
    var colSpanForIndex = colSpanForCols ? colSpanForCols[colIndex] : {};
    var labelClassNames = classNamesForIndex ? classNamesForIndex.label : '';
    return (React.createElement(Col, __assign({ key: colIndex, className: "elmo-listtable__col" }, colSpanForIndex),
        React.createElement(React.Fragment, null,
            !hideLabel && (React.createElement("div", { className: 'elmo-listtable__data-label ' + labelClassNames }, label)),
            React.createElement("div", { className: "elmo-listtable__".concat(truncateData ? 'data-with-truncate' : 'data') },
                children,
                render && render(data, rowIndex),
                !render && property && data[property]))));
}
export default withListTableContext(ListTableTd);
