import React from 'react';
import { Loader } from 'elmo-elements';

function PageLoading(): React.ReactElement {
  return (
    <div className="app-loading-element">
      <Loader type="spinner" />
    </div>
  );
}

export default PageLoading;
