var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component, createRef } from 'react';
import { getClass } from '../../_lib/helper';
var StickyElement = /** @class */ (function (_super) {
    __extends(StickyElement, _super);
    function StickyElement() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.elementRef = createRef();
        return _this;
    }
    StickyElement.prototype.componentDidMount = function () {
        var id = this.props.id;
        if (id) {
            if (window.ElmoSticky === undefined) {
                window.ElmoSticky = {};
            }
            if (window.ElmoSticky[id] === undefined) {
                window.ElmoSticky[id] = {};
            }
            window.ElmoSticky[id]['element'] = this.elementRef.current;
        }
    };
    StickyElement.prototype.componentWillUnmount = function () {
        var id = this.props.id;
        if (id && window.ElmoSticky && window.ElmoSticky[id]) {
            delete window.ElmoSticky[id];
        }
    };
    StickyElement.prototype.render = function () {
        var _a = this.props, id = _a.id, children = _a.children, zIndex = _a.zIndex, withoutSpacing = _a.withoutSpacing;
        return (id && (React.createElement("div", { id: id, "data-testid": "elmo-sticky-".concat(id || 'default'), className: getClass('elmo-sticky', null, {
                'without-spacing': withoutSpacing,
            }), ref: this.elementRef, style: {
                zIndex: zIndex,
            } }, children)));
    };
    return StickyElement;
}(Component));
export default StickyElement;
