// tslint:disable max-line-length
import React from 'react';

function Development(): React.ReactElement {
  return (
    <svg className="elmo-icon__svg" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
      <path d="M16.9959 17.4834C15.8888 17.32 14.843 17.4006 13.8545 17.6786C12.5886 18.033 11.3968 18.0521 10.1182 17.685C8.26169 17.1523 6.39038 17.4515 4.55718 17.963C3.7316 18.1943 2.91025 18.4342 2.0381 18.6846C2.02752 18.4872 2.00847 18.3217 2.00847 18.1561C2.00847 14.1535 2.01058 10.1488 2 6.14613C2 5.81506 2.0381 5.55614 2.39162 5.45002C2.77266 5.33542 2.92507 5.0765 2.84463 4.69237C2.73879 4.18727 3.0034 3.98353 3.43735 3.82436C6.23373 2.78656 9.01106 2.62951 11.7778 3.92835C11.8942 3.98353 12.0805 3.96655 12.2033 3.91137C14.9743 2.65073 17.7516 2.76746 20.5459 3.83496C20.9671 3.99626 21.1957 4.20849 21.1047 4.67539C21.0243 5.08924 21.2106 5.30995 21.581 5.4861C21.7482 5.56463 21.9282 5.8384 21.9282 6.02516C21.9472 10.17 21.943 14.3127 21.9409 18.4575C21.9409 18.5106 21.9282 18.5615 21.9176 18.6528C21.1174 18.4257 20.3215 18.2007 19.4578 17.9545V20.8323L19.3456 20.8833C18.9879 20.4121 18.6301 19.941 18.2321 19.4168C17.8299 19.9304 17.451 20.4142 16.9938 21V17.4897L16.9959 17.4834ZM11.1216 15.4014C11.147 15.2698 11.1639 15.2168 11.1639 15.1658C11.166 12.0291 11.1724 8.89025 11.1533 5.75351C11.1533 5.57736 10.984 5.32693 10.8252 5.23779C8.94756 4.19151 6.97887 4.36129 4.99113 4.84305C4.59528 4.93855 4.48097 5.15078 4.48309 5.5519C4.50002 8.65467 4.49155 11.7575 4.49367 14.8602C4.49367 15.0258 4.50849 15.1934 4.52119 15.429C6.75237 14.5758 8.92427 14.7456 11.1258 15.4014H11.1216ZM19.4006 15.4099C19.4324 15.2762 19.4557 15.2253 19.4557 15.1743C19.4578 11.9145 19.4557 8.65255 19.4663 5.39272C19.4663 5.08075 19.3329 4.9237 19.0556 4.88974C17.9633 4.74967 16.8731 4.56079 15.7766 4.50773C14.8769 4.46529 14.0132 4.73481 13.2046 5.16776C12.8807 5.34179 12.7727 5.54977 12.7748 5.9148C12.7897 8.89449 12.7833 11.8721 12.7833 14.8517C12.7833 15.0194 12.8003 15.1871 12.8151 15.4099C15.0335 14.7414 17.2055 14.5865 19.4006 15.412V15.4099Z" />
    </svg>
  );
}

export default Development;
