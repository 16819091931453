import { ApprovalPerformancesState, ApprovalPerformancesQuery } from '../type';

export const ApprovalPerformanceDefaultQuery: ApprovalPerformancesQuery = {
  page: 1,
  limit: 10,
  sort: '-startDate',
};

/**
 * Document about what this state is about
 */
export default {
  performances: {
    data: [],
    total: 0,
    page: 1,
    limit: 10,
  },
  isLoaded: false,
  isLoading: true,
  error: undefined,
  query: { ...ApprovalPerformanceDefaultQuery },
} as ApprovalPerformancesState;
