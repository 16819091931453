import React from 'react';
import './ListTableHeaderSelectAll.scss';
import { withListTableContext } from '../ListTableContextConsumer';
import { getClass } from '../../_lib/helper';
function ListTableHeaderSelectAllMessage(_a) {
    var children = _a.children, className = _a.className;
    return (React.createElement("div", { className: getClass('elmo-listtable__select-message', className) }, children));
}
function ListTableHeaderSelectAll(_a) {
    var countItemsAvailable = _a.countItemsAvailable, onSelectAllAvailable = _a.onSelectAllAvailable, isAllAvailableSelected = _a.isAllAvailableSelected, countSelectable = _a.countSelectable;
    if (isAllAvailableSelected) {
        return (React.createElement(ListTableHeaderSelectAllMessage, { className: "all-selected" },
            "All ",
            countItemsAvailable,
            " items are selected.\u00A0",
            React.createElement("span", { onClick: onSelectAllAvailable(false) }, "Clear Selection")));
    }
    return (React.createElement(ListTableHeaderSelectAllMessage, null,
        "All ",
        countSelectable,
        " items have been selected on this page."));
}
export default withListTableContext(ListTableHeaderSelectAll);
