import { createReducer } from 'redux-act';
import defaultState from './state';
import {
  getReview360Performance,
  getReview360PerformanceSuccess,
  getReview360PerformanceFailure,
} from './actions';
import {
  Review360PerformanceQuery,
  Review360PerformanceState,
  Review360PerformanceData,
} from '../type';
import { resetState } from '../../../state/App';

/**
 * Get performance review 360
 */
export function getReview360PerformanceReducer(
  state: Review360PerformanceState,
  review360PerformanceQuery: Review360PerformanceQuery
): Review360PerformanceState {
  return {
    ...state,
    query: review360PerformanceQuery,
    isDeleted: false,
    isLoaded: false,
    isLoading: true,
  };
}

export function getReview360PerformanceSuccessReducer(
  state: Review360PerformanceState,
  data: Review360PerformanceData
): Review360PerformanceState {
  return {
    ...state,
    myReview: { ...data },
    isLoading: false,
    isLoaded: true,
  };
}

export function getReview360PerformanceFailureReducer(
  state: Review360PerformanceState,
  error: Error
): Review360PerformanceState {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    isDeleted: false,
    error,
  };
}

export function resetStateReducer(state: Review360PerformanceState): Review360PerformanceState {
  return {
    ...state,
    isDeleted: false,
    isLoading: false,
    isLoaded: false,
    error: undefined,
  };
}

export default createReducer(
  {
    [`${getReview360Performance}`]: getReview360PerformanceReducer,
    [`${getReview360PerformanceSuccess}`]: getReview360PerformanceSuccessReducer,
    [`${getReview360PerformanceFailure}`]: getReview360PerformanceFailureReducer,
    [`${resetState}`]: resetStateReducer,
  },
  defaultState
);
