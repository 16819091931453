import React, { useEffect } from 'react';
import { ContentModal } from '@eds/modal';
import { Flex } from '@eds/flex';
import { Text } from '@eds/text';
import { Heading } from '@eds/heading';
import { LoadingSpinner } from '@eds/loading';
import { useCore } from '../../../../element/Core/CoreContext';

const AssignmentProgressModal = ({
  isModalOpen,
  onClose,
}: {
  isModalOpen: boolean;
  onClose: () => void;
}): React.ReactElement => {
  const { setIsModalOpen } = useCore();
  useEffect(() => {
    setIsModalOpen(isModalOpen);
  }, [isModalOpen, setIsModalOpen]);

  return (
    <ContentModal isOpen={isModalOpen} onClose={onClose} footer={<></>} maxWidth="27.5rem">
      <Flex
        flexDirection="column"
        gap="small"
        marginTop="small"
        marginBottom="small"
        alignItems="center"
      >
        <LoadingSpinner />
        <Heading level={4}>Assigning Appraisals ...</Heading>
        <Text color="neutralSubtle">This will take a moment. Do not refresh this page.</Text>
      </Flex>
    </ContentModal>
  );
};
export default AssignmentProgressModal;
