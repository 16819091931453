import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest, take, race, delay, takeEvery } from 'redux-saga/effects';
import {
  appInit,
  appInitSuccess,
  appInitFailure,
  startPoll,
  stopPoll,
  fetchNavigation,
  fetchNavigationSuccess,
  fetchNavigationFailure,
} from './actions';
import setup from '../../lib/setup';
import apis from './api';
import { Action } from 'redux-act';
import { POLLING_CONTINUE_POLL_STATUSES } from '../../lib/const';
import { StartPollPayloadType } from './type';

/**
 * Get appraisal
 */
export const appInitSaga = function* (): SagaIterator {
  try {
    // <Dev.Note>
    //  If the async activities you need to do is big or complex it is better to abstract your API
    //  in a separate service for example in this case 'lib/setup'. The advantage of this abstraction
    //  is to have 1) better test ability and control on your API 2) keep the size of
    //  this saga small
    // </Dev.Note>
    const data = yield call(setup);
    yield put(appInitSuccess(data));
  } catch (error) {
    yield put(appInitFailure(error));
  }
};

export const pollJobStatusSaga = function* ({ jobId }: StartPollPayloadType): SagaIterator {
  while (true) {
    try {
      const data = yield call(apis.getJobStatus, jobId as number);

      if (data.processStatus in POLLING_CONTINUE_POLL_STATUSES) {
        yield delay(5000);
      } else {
        yield put(stopPoll(jobId, data));
      }
    } catch (error) {
      yield put(stopPoll(jobId, undefined, error));
    }
  }
};

export const createPollingSaga = function* ({
  payload,
}: {
  payload: StartPollPayloadType;
}): SagaIterator {
  function getCancelAction(action: Action<any>): any {
    if (action.type === 'PERFORMANCE_STOP_POLL' || action.type === 'PERFORMANCE_PAUSE_POLLS') {
      return action;
    }
    return null;
  }
  yield race({
    task: call<any>(pollJobStatusSaga, payload),
    cancel: take(getCancelAction),
  });
};

export const fetchNavigationSaga = function* (): SagaIterator {
  try {
    const data = yield call<any>(apis.getData);
    yield put(fetchNavigationSuccess(data));
  } catch (error) {
    yield put(fetchNavigationFailure(error));
  }
};

/**
 * Apprisal Sagas
 */
export default function* root(): SagaIterator {
  yield all([
    takeLatest(appInit, appInitSaga),
    takeLatest(fetchNavigation, fetchNavigationSaga),
    takeEvery(startPoll, createPollingSaga),
  ]);
}
