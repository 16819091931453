import { Route } from 'react-router-dom';
// App layouts
import PrivateLayout from './layout/PrivateLayout';
import { setBasePath, setPerformanceBasePath } from './lib/route';
import NotFound from './page/NotFound';
import NotFoundRoutes from './page/NotFound/routes';
import ForbiddenRoutes from './page/Forbidden/routes';
import { ConversationListRoutes, ConversationDetailsRoutes } from './page/Conversations/routes';
import TeamPerformancesRoutes from './page/TeamPerformance/routes';
import SignOffPerformancesRoutes from './page/SignOffPerformance/routes';
import MyPerformancesRoutes from './page/MyPerformance/routes';
import ApprovalPerformancesRoutes from './page/ApprovalPerformance/routes';
import Review360PerformanceRoutes from './page/Review360Performance/routes';
import AssignAppraisalRoutes from './page/AssignAppraisal/routes';
import { PerformanceViewRoutes } from './page/Performance/routes';
import MyContributionsRoutes from './page/MyContributions/routes';

export const defaultRoutes: Record<string, any> = {
  rootRedirect: {
    path: '/',
    redirect: setPerformanceBasePath('/my-performance'),
  },
  baseRedirect: {
    path: setBasePath(''),
    redirect: setPerformanceBasePath('/my-performance'),
  },
  approvalPerformances: ApprovalPerformancesRoutes,
  myPerformances: MyPerformancesRoutes,
  teamPerformances: TeamPerformancesRoutes,
  myContributions: MyContributionsRoutes,
  signOffPerformances: SignOffPerformancesRoutes,
  review360Performance: Review360PerformanceRoutes,
  conversationList: ConversationListRoutes,
  conversationDetails: ConversationDetailsRoutes,
  assignAppraisal: AssignAppraisalRoutes,
  performanceView: PerformanceViewRoutes,
  forbidden: ForbiddenRoutes,
  notFound: {
    path: NotFoundRoutes.path,
    component: NotFound,
    routeComponent: Route,
    exact: true,
  },
  redirect: {
    path: '*',
    redirect: NotFoundRoutes.path,
  },
};

// AppRoutes defines the routings with app layout
export const appRoutes: any = {
  default: {
    path: '/',
    component: PrivateLayout,
    routeComponent: Route,
    routes: defaultRoutes,
  },
};

export default defaultRoutes;
