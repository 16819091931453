import { HttpClient } from './httpClient';
import config from '../config';
import logger from '../logger';
import ErrorApiResponseHandler from '../error/ErrorApiResponseHandler';
import { isAuthenticated } from '../auth/check';

// Setup a custom HttpClient
const httpClient = new HttpClient({
  baseURL: config.api.baseUrl,
  useMockData: process.env.REACT_APP_BUILD_ENV === 'development',
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
});

// Adding some custom request interceptor
httpClient.addRequestInterceptor(
  (requestConfig: any) => {
    if (requestConfig.url === '/auth/check-token') {
      return requestConfig;
    }

    return isAuthenticated().then(() => requestConfig);
  },
  (error: any) => {
    logger.error('Oh we have error in our http request');
    return Promise.reject(error);
  }
);

// Adding some custom response interceptor
httpClient.addResponseInterceptor(
  (response: any) => response,
  (error: any) => ErrorApiResponseHandler.handleError(error)
);

// Setup the default httpClient
export default httpClient;
