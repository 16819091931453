import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { isDev } from '../env';
import { LANGUAGES } from './messages';

export { messages, LANGUAGES } from './messages';

/**
 * Fetches translation for client and language
 */
export function fetchTranslations(language: string, client?: string): Promise<any> {
  /* If we need to support client from the frontend part
  if (client) {
    return fetchTranslationFile(`/translations-${client}-${language}.json`);
  }
  */
  return Promise.resolve({});
}

/**
 * Fetches translation file with filename
 */
export function fetchTranslationFile(filename: string): Promise<any> {
  return fetch(filename).then((response: Response) => response.json());
}

/**
 * Loads translations into localStorage
 * @param language
 * @param client
 */
export async function loadTranslations(language: string = LANGUAGES.en): Promise<any> {
  const storedTranslations = localStorage.getItem('translations');
  let translations = null;
  try {
    if (storedTranslations) {
      translations = JSON.parse(storedTranslations);
    }
  } catch (error) {
    return Promise.reject(error);
  }

  // store the base translations in the local storage if they haven't already been
  if (!storedTranslations) {
    translations = await fetchTranslations(language);
    localStorage.setItem('translations', JSON.stringify(translations));
  }

  i18n.addResourceBundle(LANGUAGES.en, 'translation', translations);
  return Promise.resolve();
}

/**
 * Wrapper function around the i18next translate function
 * Translates using the default value if translations have not been loaded yet
 *
 * @param translationData
 * @param options
 */
export function t(translationData: Record<any, any>, options?: Record<any, any>): string {
  return i18n.t(translationData.key, {
    ...options,
    defaultValue: translationData.default,
  });
}

i18n.use(initReactI18next).init({
  resources: {},
  fallbackLng: LANGUAGES.en,
  debug: isDev && isDev(),
  lng: LANGUAGES.en, // set default language to "en"
});

export default i18n;
