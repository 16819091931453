import { STATUS_ALL } from '../../../lib/const';
import httpClient from '../../../lib/api';
import { MyPerformancesQuery, MyPerformancesApiInterface } from '../type';
import { CancellableGetType } from '../../../lib/api/type';

/**
 * <Dev.Note>
 *  Define your Restfull API resources here in any format you like
 *  (It is manily based on your REST API). It is good to have a snapshot
 *  testing for this. If you find that you have to implement many api calls
 *  here then you can abstract them all in a file called api.ts in the
 *  current directory
 * </Dev.Note>
 */
export const resources: any = {
  performances: {
    url: '/performance-api/v1/my-performances',
  },
};

/**
 * Fetch the Data from Rest API endpoint
 * <Dev.Note>
 *  Here you fetch your data from the API endpoint, if you need to do any
 *  preprocessing or mapping or data massage you should do it here. You can
 *  also define each function individually if you like that way. The key is to
 *  have a unit testing for this part
 *  ```
 *    export function getPerformancesApi() {
 *      return httpClient
 *        .get('/users')
 *        .then((result) => result.data);
 *    }
 *  ```
 * </Dev.Note>
 */
export class MyPerformancesApi implements MyPerformancesApiInterface {
  getMyPerformances(myPerformancesQuery: MyPerformancesQuery): CancellableGetType {
    const { get, cancel } = httpClient.cancellableGet(resources.performances.url, {
      params: {
        // params mapping
        page: myPerformancesQuery.page,
        limit: myPerformancesQuery.limit,
        phase: myPerformancesQuery.phase !== STATUS_ALL ? myPerformancesQuery.phase : undefined,
        sort: myPerformancesQuery.sort ? myPerformancesQuery.sort : undefined,
        keyword: myPerformancesQuery.keyword ? myPerformancesQuery.keyword : undefined,
      },
    });
    const getPromise = get
      .then(result => {
        const total =
          typeof result.headers !== 'undefined' &&
          typeof result.headers['x-pagination-count'] !== 'undefined'
            ? result.headers['x-pagination-count']
            : 0;
        const pageNum =
          typeof result.headers !== 'undefined' &&
          typeof result.headers['x-pagination-page'] !== 'undefined'
            ? result.headers['x-pagination-page']
            : myPerformancesQuery.page;
        const limitNum =
          typeof result.headers !== 'undefined' &&
          typeof result.headers['x-pagination-limit'] !== 'undefined'
            ? result.headers['x-pagination-limit']
            : myPerformancesQuery.limit;
        return Promise.resolve({
          total,
          page: pageNum,
          limit: limitNum,
          data: typeof result.data !== 'undefined' ? result.data : [],
        });
      })
      .catch((error: Error) => {
        // if the error is cancelled request, we don't need to handle it
        if (error.message === 'Request cancelled') {
          return undefined;
        }
        return Promise.reject(error);
      });
    return {
      get: getPromise,
      cancel,
    };
  }
}

export default new MyPerformancesApi();
