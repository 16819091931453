import {
  AssignAppraisalState,
  AssigneeListState,
  AssignAppraisalQuery,
  EmployeeListState,
} from '../type';

export const AssignAppraisalDefaultQuery: AssignAppraisalQuery = {
  page: 1,
  limit: 10,
  sort: 'title',
  status: 0,
};

export const AssigneeListDefaultState: AssigneeListState = {
  assignees: {
    data: [],
    page: 1,
    limit: 10,
    total: 0,
  },
  isLoading: true,
  error: undefined,
};

export const EmployeeListDefaultState: EmployeeListState = {
  employees: {
    data: [],
    page: 1,
    limit: 10,
    total: 0,
  },
  isLoading: true,
  error: undefined,
};

export default {
  appraisals: {
    data: [],
    total: 0,
    page: 1,
    limit: 10,
  },
  appraisalId: undefined,
  selectedAppraisal: undefined,
  assigneeList: { ...AssigneeListDefaultState },
  isLoaded: false,
  isLoading: true,
  error: undefined,
  query: { ...AssignAppraisalDefaultQuery },
  employeeList: { ...EmployeeListDefaultState },
} as AssignAppraisalState;
