import React, { Suspense as ReactSuspense, ReactNode } from 'react';
import PageLoading from '../PageLoading';

export type SuspenseProps = {
  children: ReactNode;
  fallback?: any;
};

function Suspense({ children, fallback: Fallback }: SuspenseProps): React.ReactElement {
  return <ReactSuspense fallback={Fallback || <PageLoading />}>{children}</ReactSuspense>;
}

export default Suspense;
