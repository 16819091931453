/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getTeamPerformance,
  getTeamPerformanceSuccess,
  getTeamPerformanceFailure,
  deleteTeamPerformance,
  deleteTeamPerformanceSuccess,
  deleteTeamPerformanceFailure,
} from './actions';
import apis from './api';

export const getTeamPerformanceSaga = function* (action: any): SagaIterator {
  try {
    const data = yield call(apis.getTeamPerformance, action.payload);
    yield put(getTeamPerformanceSuccess(data));
  } catch (error) {
    yield put(getTeamPerformanceFailure(error));
  }
};

/**
 * This saga runs calls delete api
 */
export const deleteTeamPerformanceSaga = function* (action: any): SagaIterator {
  try {
    const result: any = yield call(apis.deleteTeamPerformance, action.payload.performanceId);
    //  If delete was successfull
    if (result.status) {
      yield put(deleteTeamPerformanceSuccess());
    }
  } catch (error) {
    yield put(deleteTeamPerformanceFailure(error));
  }
};

/**
 * Team Performances Sagas
 */
export default function* root(): SagaIterator {
  yield all([
    takeLatest(getTeamPerformance, getTeamPerformanceSaga),
    takeLatest(deleteTeamPerformance, deleteTeamPerformanceSaga),
  ]);
}
