import { Review360PerformanceState, Review360PerformanceQuery } from '../type';

export const Review360PerformanceDefaultQuery: Review360PerformanceQuery = {
  page: 1,
  limit: 10,
  sort: '-endDate',
};

/**
 * Document about what this state is about
 */
export default {
  myReview: {
    data: [],
    total: 0,
    page: 1,
    limit: 10,
  },
  isDeleted: false,
  isLoaded: false,
  isLoading: true,
  error: undefined,
  query: { ...Review360PerformanceDefaultQuery },
} as Review360PerformanceState;
