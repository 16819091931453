/* eslint-disable camelcase */
import { decodeToken, getToken } from '../token';

export type JwtObject = {
  branding: Record<string, unknown>;
  capabilities: Record<string, unknown>;
  exp: number;
  feature_flags: Record<string, unknown>;
  host: string;
  iat: number;
  industry_settings: Record<string, unknown>;
  navigation_bar: string;
  roles: any;
  user_id: number;
  user_menu: string;
  username: string;
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

class AuthAccess {
  /**
   * Get current user JWT decoded
   */
  get currentUser(): any {
    const token = getToken();

    if (!token) {
      return null;
    }

    return decodeToken(token);
  }

  /**
   * Check if current user is Super Admin
   */
  public isElmoSuperAdmin(): boolean {
    return this.currentUser && this.currentUser.roles.indexOf('ROLE_ELMO_SUPER') !== -1;
  }

  /**
   * Check if current user is Company Admin
   */
  public isCompanyAdmin(): boolean {
    return this.currentUser && this.currentUser.roles.indexOf('ROLE_SUPER') !== -1;
  }
}

export default new AuthAccess();
