import Forbidden from '.';
import { setBasePath } from '../../lib/route';

const ForbiddenRoutes: any = {
  path: setBasePath('forbidden'),
  component: Forbidden,
};

export type ForbiddenRoutesType = typeof ForbiddenRoutes;

export default ForbiddenRoutes;
