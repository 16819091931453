import React, { Component, ReactElement } from 'react';
import MessageLayout from '../element/MessageLayout';
import { messages, t } from '../lib/translation';
import Layout from '../element/Layout';
import { MenuWithRouter } from '../element/Menu';
import { Provider } from 'react-redux';
import store from '../state/store';

export type CatchProps = {
  children: React.ReactNode;
};

export type CatchState = {
  error?: Error;
  errorInfo?: React.ErrorInfo;
};

export default class Catch extends Component<CatchProps, CatchState> {
  state: CatchState = { error: undefined, errorInfo: undefined };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
  }

  render(): ReactElement | React.ReactNode {
    if (this.state.error && this.state.errorInfo) {
      return (
        <Provider store={store}>
          <Layout menu={<MenuWithRouter />}>
            <Layout.Content>
              <MessageLayout
                title={this.state.error.toString()}
                content={t(messages.app.catch.message)}
              />
            </Layout.Content>
          </Layout>
        </Provider>
      );
    }

    return this.props.children;
  }
}
