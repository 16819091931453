var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import SortButton from './component/SortButton';
import SortLabel from './component/SortLabel';
import Dropdown from '../Dropdown';
import { getClass } from '../_lib/helper';
import { CheckIcon, NullIcon } from '../Icon';
import './Sort.scss';
var Sort = /** @class */ (function (_super) {
    __extends(Sort, _super);
    function Sort(props) {
        var _this = _super.call(this, props) || this;
        _this.handleOnChange = function (value) {
            var onChange = _this.props.onChange;
            if (onChange) {
                onChange(value);
            }
        };
        _this.state = {
            item: props.defaultValue.item,
        };
        return _this;
    }
    Sort.prototype.render = function () {
        var _this = this;
        var _a = this.props, id = _a.id, className = _a.className, label = _a.label, items = _a.items, defaultValue = _a.defaultValue;
        var classes = getClass('elmo-sort', className);
        return (React.createElement(Dropdown, { icon: React.createElement(SortButton, null), className: classes, id: id, "data-testid": "elmo-sort-".concat(id || 'default'), isWidthAuto: true, ariaLabel: label, hasTooltip: true },
            React.createElement(Dropdown.Label, null,
                React.createElement(SortLabel, null, label)),
            items &&
                items.map(function (item, i) { return (React.createElement(Dropdown.Item, { key: "best-radio-button-ever-".concat(i), ariaLabel: "item.label", value: item.id, onClick: _this.handleOnChange },
                    defaultValue.item === item.id ? React.createElement(CheckIcon, null) : React.createElement(NullIcon, null),
                    item.label)); })));
    };
    Sort.defaultProps = {
        label: 'Sort By',
        defaultValue: {
            item: '',
        },
    };
    return Sort;
}(Component));
export default Sort;
