import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: false, // do not retry when error happening
      staleTime: 0,
      cacheTime: 0,
    },
  },
});

export default queryClient;
