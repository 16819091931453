/* eslint-disable max-classes-per-file */
import { ReactNode } from 'react';

export interface InterfaceStatus {
  location: InterfaceLocation;
}

export interface InterfaceLocation {
  key: string;
  title: string;
}

/**
 * State model
 */
export class Status implements InterfaceStatus {
  public location: InterfaceLocation = {
    key: '',
    title: '',
  };

  constructor() {
    Object.assign(this, {
      location: {
        key: '',
        title: '',
      },
    });
  }
}

export interface InterfaceTheme {
  container: {
    padding: string;
    width: string;
  };
  profile: {
    color: string;
    backgroundColor: string;
  };
  admin: {
    color: string;
    backgroundColor: string;
  };
  menu: {
    padding: string;
    font: string;
    fontColor: string;
    hoverFontColor: string;
    backgroundColor: string;
    backgroundColorHover: string;
  };
}

/**
 * Theme model
 */
export class Theme implements InterfaceTheme {
  public container: {
    padding: string;
    width: string;
  } = {
    padding: '1em',
    width: '256px',
  };

  public profile: {
    color: string;
    backgroundColor: string;
  } = {
    color: '#1b4da2',
    backgroundColor: '#fff',
  };

  public admin: {
    color: string;
    backgroundColor: string;
  } = {
    color: '#1b4da2',
    backgroundColor: '#fff',
  };

  public menu: {
    padding: string;
    font: string;
    fontColor: string;
    hoverFontColor: string;
    backgroundColor: string;
    backgroundColorHover: string;
  } = {
    padding: '1em',
    font: 'Verdana',
    fontColor: '#fff',
    hoverFontColor: '#fff',
    backgroundColor: '#1b4da2',
    backgroundColorHover: '#123f8d',
  };

  constructor() {
    Object.assign(this, {
      container: {
        padding: '1em',
        width: '256px',
      },
      profile: {
        color: '#1b4da2',
        backgroundColor: '#fff',
      },
      admin: {
        color: '#1b4da2',
        backgroundColor: '#fff',
      },
      menu: {
        padding: '1em',
        font: 'Verdana',
        fontColor: '#fff',
        hoverFontColor: '#fff',
        backgroundColor: '#1b4da2',
        backgroundColorHover: '#123f8d',
      },
    });
  }
}

export interface InterfaceBranding {
  logo: string;
  logoLink: string;
  smallLogo: string;
  companyName: string;
  version: string;
  theme: InterfaceTheme;
}

/**
 * Branding model
 */
export class Branding implements InterfaceBranding {
  public logo = '';

  public smallLogo = '';

  public logoLink = '';

  public companyName = '';

  public version = '';

  public theme: InterfaceTheme = new Theme();

  constructor() {
    Object.assign(this, {
      logo: '',
      smallLogo: '',
      logoLink: '',
      companyName: '',
      version: '',
      theme: new Theme(),
    });
  }
}

export interface InterfaceUser {
  id: string;
  name: string;
  img: string;
}

/**
 * User model
 */
export class User implements InterfaceUser {
  public id = '';

  public name = '';

  public img = '';

  constructor() {
    Object.assign(this, {
      id: '',
      name: '',
      img: '',
    });
  }
}

export interface InterfaceLink {
  description: string;
  icon: null | ReactNode;
  key: string;
  title: string;
  url: string;
  isExternal: boolean;
}

/**
 * Link model
 */
export class Link implements InterfaceLink {
  public description = '';

  public icon = null;

  public key = '';

  public title = '';

  public url = '';

  public isExternal = false;

  constructor() {
    Object.assign(this, {
      description: '',
      icon: null,
      key: '',
      title: '',
      url: '',
      isExternal: false,
    });
  }
}

export interface InterfaceMenu {
  user: InterfaceLink[];
  home: InterfaceLink;
  modules: InterfaceLink[];
}

/**
 * Navigation links model
 */
export class Menu implements InterfaceMenu {
  public user: InterfaceLink[] = [new Link()];

  public home: InterfaceLink = new Link();

  public modules: InterfaceLink[] = [new Link()];

  constructor() {
    Object.assign(this, {
      user: [new Link()],
      home: new Link(),
      modules: [new Link()],
    });
  }
}

export interface InterfaceSubMenu {
  modules: InterfaceLink[];
}

/**
 * Navigation links model
 */
export class SubMenu implements InterfaceSubMenu {
  public modules: InterfaceLink[] = [new Link()];

  constructor() {
    Object.assign(this, {
      modules: [new Link()],
    });
  }
}

export interface InterfaceNavigation {
  branding: InterfaceBranding;
  user: InterfaceUser;
  menu: InterfaceMenu;
  subMenu: InterfaceSubMenu;
  status: InterfaceStatus;
  footerMessage: string;
}

/**
 * Navigation model
 */
export class Navigation implements InterfaceNavigation {
  public branding: InterfaceBranding = new Branding();

  public user: InterfaceUser = new User();

  public menu: InterfaceMenu = new Menu();

  public status: InterfaceStatus = new Status();

  public subMenu: InterfaceSubMenu = new SubMenu();

  public footerMessage = '';

  constructor() {
    Object.assign(this, {
      branding: new Branding(),
      user: new User(),
      menu: new Menu(),
      status: new Status(),
      subMenu: new SubMenu(),
      footerMessage: '',
    });
  }
}

export interface InterfaceAppState {
  error?: Error;
  navigation: InterfaceNavigation;
  loaded: boolean;
}
