import React, { ReactElement, Suspense } from 'react';
import Layout from '../element/Layout';
import { MenuWithRouter } from '../element/Menu';
import { NestedRoutes } from '../lib/route';
import PageLoading from '../element/PageLoading';
import { SubmenuProvider } from '../element/Menu/submenuContext';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function PrivateLayout({ routes }: any): ReactElement {
  return (
    <Suspense fallback={<PageLoading />}>
      <SubmenuProvider>
        <Layout menu={<MenuWithRouter />}>{routes && <NestedRoutes routes={routes} />}</Layout>
      </SubmenuProvider>
    </Suspense>
  );
}

export default PrivateLayout;
