import Review360Performance from '.';
import { setPerformanceBasePath } from '../../lib/route';

export const Review360PerformancePath = setPerformanceBasePath('/review-360-performance');
const Review360PerformanceRoutes: any = {
  path: Review360PerformancePath,
  component: Review360Performance,
  exact: true,
};

export type Review360PerformanceRoutesType = typeof Review360PerformanceRoutes;

export default Review360PerformanceRoutes;
