import axios, { Canceler } from 'axios';
import { CancellableGetType } from './type';

export class HttpClient {
  private client: any;

  constructor(options?: any) {
    this.client = axios.create(options);
  }

  /**
   *
   * @param interceptors
   */
  addRequestInterceptor(...interceptors: any) {
    this.client.interceptors.request.use(...interceptors);
  }

  /**
   *
   * @param interceptors
   */
  addResponseInterceptor(...interceptors: any) {
    this.client.interceptors.response.use(...interceptors);
  }

  /**
   *
   * @param url
   * @param conf
   */
  get(url: string, conf: any = {}): Promise<any> {
    return this.client
      .get(url, conf)
      .then((response: any) => Promise.resolve(response))
      .catch((error: Error) => Promise.reject(error));
  }

  /**
   * return an object which contain the get promise, and a cancel function
   * @param url
   * @param conf
   */
  cancellableGet(url: string, conf: any = {}): CancellableGetType {
    const { CancelToken } = axios;
    let cancel: Canceler | undefined;
    const config = {
      ...conf,
      cancelToken: new CancelToken((cancelFn: Canceler) => {
        cancel = cancelFn;
      }),
    };
    return {
      get: this.get(url, config),
      cancel,
    };
  }

  /**
   *
   * @param url
   * @param conf
   */
  delete(url: string, conf: any = {}): Promise<any> {
    return this.client
      .delete(url, conf)
      .then((response: any) => Promise.resolve(response))
      .catch((error: Error) => Promise.reject(error));
  }

  /**
   *
   * @param url
   * @param conf
   */
  head(url: string, conf: any = {}): Promise<any> {
    return this.client
      .head(url, conf)
      .then((response: any) => Promise.resolve(response))
      .catch((error: Error) => Promise.reject(error));
  }

  /**
   *
   * @param url
   * @param conf
   */
  options(url: string, conf: any = {}): Promise<any> {
    return this.client
      .options(url, conf)
      .then((response: any) => Promise.resolve(response))
      .catch((error: Error) => Promise.reject(error));
  }

  /**
   *
   * @param url
   * @param data
   * @param conf
   */
  post(url: string, data: any = {}, conf: any = {}) {
    return this.client
      .post(url, data, conf)
      .then((response: any) => Promise.resolve(response))
      .catch((error: Error) => Promise.reject(error));
  }

  /**
   *
   * @param url
   * @param data
   * @param conf
   */
  put(url: string, data: any = {}, conf: any = {}): Promise<any> {
    return this.client
      .put(url, data, conf)
      .then((response: any) => Promise.resolve(response))
      .catch((error: Error) => Promise.reject(error));
  }

  /**
   *
   * @param url
   * @param data
   * @param conf
   */
  patch(url: string, data: any = {}, conf: any = {}): Promise<any> {
    return this.client
      .patch(url, data, conf)
      .then((response: any) => Promise.resolve(response))
      .catch((error: Error) => Promise.reject(error));
  }
}

const defaultHttpClient = new HttpClient();

/**
 * Base HTTP Client
 */
export default defaultHttpClient;
