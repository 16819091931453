import React, { Dispatch, FC, SetStateAction, createContext, useContext, useState } from 'react';

interface CoreContextType {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}
const CoreContext = createContext<CoreContextType | undefined>(undefined);

export const CoreContextProvider: FC = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const value = { isModalOpen, setIsModalOpen };

  return <CoreContext.Provider value={value}>{children}</CoreContext.Provider>;
};

export const useCore = () => {
  const context = useContext(CoreContext);

  if (context === undefined) {
    throw new Error('useCore must be used within a CoreContextProvider');
  }

  return context;
};
export default CoreContext;
