import httpClient from '../../../lib/api';
import {
  AssignAppraisalQuery,
  AssignAppraisalApiInterface,
  AssigneeQuery,
  AssignAppraisalPayload,
  EmployeeListQuery,
} from '../type';
import { CancellableGetType } from '../../../lib/api/type';

/**
 * Appraisals api url
 */
export const resources: any = {
  appraisals: {
    url: '/performance-api/v1/appraisals',
  },
  appraisal: {
    url: '/performance-api/v1/appraisal-assignments/:id',
  },
  appraisalPerformances: {
    url: '/performance-api/v1/appraisals/:id/performances',
  },
  assignAppraisal: {
    url: '/performance-api/v1/appraisals/assign',
  },
  employees: {
    url: '/performance-api/v1/appraisals/:id/pending-assignment-employees',
  },
};

export class AppraisalsApi implements AssignAppraisalApiInterface {
  getAppraisals(appraisalQuery: AssignAppraisalQuery): CancellableGetType {
    const { get, cancel } = httpClient.cancellableGet(resources.appraisals.url, {
      params: {
        page: appraisalQuery.page,
        limit: appraisalQuery.limit,
        sort: appraisalQuery.sort,
        status: appraisalQuery.status,
        keyword: appraisalQuery.keyword,
      },
    });
    const promise = get
      .then(result =>
        Promise.resolve({
          total: result.headers['x-pagination-count'] || 0,
          page: result.headers['x-pagination-page'] || appraisalQuery.page,
          limit: result.headers['x-pagination-limit'] || appraisalQuery.limit,
          data: result.data || [],
        })
      )
      .catch((error: Error) => {
        if (error.message === 'Request cancelled') {
          return undefined;
        }
        return Promise.reject(error);
      });
    return {
      get: promise,
      cancel,
    };
  }

  getAppraisal(id: number): CancellableGetType {
    const { get, cancel } = httpClient.cancellableGet(resources.appraisal.url.replace(':id', id));
    const promise = get
      .then(result => result.data)
      .catch((error: Error) => {
        if (error.message === 'Request cancelled') {
          return undefined;
        }
        return Promise.reject(error);
      });
    return {
      get: promise,
      cancel,
    };
  }

  /**
   * Get appraisal performance list from API /appraisals/:id/performances
   *
   * @param {number} id           Appraisal Id
   * @param {AssigneeQuery} query Query string's parameters
   */
  getAppraisalPerformances(id: number, query: AssigneeQuery): CancellableGetType {
    const { get, cancel } = httpClient.cancellableGet(
      resources.appraisalPerformances.url.replace(':id', id),
      {
        params: {
          page: query.page,
          limit: query.limit,
          sort: query.sort,
          phase: query.phase,
          keyword: query.keyword,
        },
      }
    );
    const promise = get
      .then(result =>
        Promise.resolve({
          total: result.headers['x-pagination-count'] || 0,
          page: result.headers['x-pagination-page'] || query.page,
          limit: result.headers['x-pagination-limit'] || query.limit,
          data: result.data || [],
        })
      )
      .catch((error: Error) => {
        if (error.message === 'Request cancelled') {
          return undefined;
        }
        return Promise.reject(error);
      });

    return { get: promise, cancel };
  }

  assign(payload: AssignAppraisalPayload): Promise<any> {
    return httpClient
      .post(resources.assignAppraisal.url, payload)
      .then((response: { data: any }) => response.data)
      .catch((error: any) => {
        let message = 'unknown errors';
        if (error.data && error.data.errors && error.data.errors.length > 0) {
          message = error.data.errors[0].message;
        } else if (error.message) {
          message = error.message;
        }
        const err = new Error(message);
        return Promise.reject(err);
      });
  }

  /**
   * Get appraisal pending assignment employee list from API /appraisals/:id/pending-assignment-employees
   *
   * @param {number} id               Appraisal Id
   * @param {EmployeeListQuery} query Query string's parameters
   */
  getEmployees(id: number, query: EmployeeListQuery): CancellableGetType {
    const { get, cancel } = httpClient.cancellableGet(resources.employees.url.replace(':id', id), {
      params: {
        page: query.page,
        limit: query.limit,
        sort: query.sort,
        keyword: query.keyword,
        startDate: query.startDate,
      },
    });
    const promise = get
      .then(result =>
        Promise.resolve({
          total: result.headers['x-pagination-count'] || 0,
          page: result.headers['x-pagination-page'] || query.page,
          limit: result.headers['x-pagination-limit'] || query.limit,
          data: result.data || [],
        })
      )
      .catch((error: Error) => {
        if (error.message === 'Request cancelled') {
          return undefined;
        }
        return Promise.reject(error);
      });

    return { get: promise, cancel };
  }
}

export default new AppraisalsApi();
