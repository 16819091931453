var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component, createRef } from 'react';
import ResizeObserver from '@juggle/resize-observer';
import { getClass, isScreenDesktop } from '../../_lib/helper';
import './NativeTableWrapper.scss';
var NativeTableWrapper = /** @class */ (function (_super) {
    __extends(NativeTableWrapper, _super);
    function NativeTableWrapper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.wrapperRef = createRef();
        _this.handleResize = function () {
            if (_this.wrapperRef &&
                _this.wrapperRef.current &&
                _this.layoutHeader &&
                isScreenDesktop() === false) {
                var headerHeight_1 = _this.layoutHeader.offsetHeight;
                var headColumns = _this.wrapperRef.current.querySelectorAll('.elmo-nativetable-head th, .elmo-nativetable-head td');
                headColumns.forEach(function (column) {
                    column.style.top = headerHeight_1 + 'px';
                });
            }
        };
        return _this;
    }
    NativeTableWrapper.prototype.componentDidMount = function () {
        var _this = this;
        var isHeaderSticky = this.props.isHeaderSticky;
        if (isHeaderSticky) {
            this.layoutHeader = document.querySelector('.elmo-layout__main-header');
            this.headerObserver = new ResizeObserver(function (entries) {
                _this.handleResize();
            });
            this.headerObserver.observe(this.layoutHeader);
        }
    };
    NativeTableWrapper.prototype.componentWillUnmount = function () {
        var isHeaderSticky = this.props.isHeaderSticky;
        if (isHeaderSticky) {
            this.headerObserver.disconnect();
        }
    };
    NativeTableWrapper.prototype.render = function () {
        var _a = this.props, id = _a.id, theme = _a.theme, children = _a.children, className = _a.className, isResponsive = _a.isResponsive, isFullWidth = _a.isFullWidth, isHeaderSticky = _a.isHeaderSticky, isFirstColumnSticky = _a.isFirstColumnSticky, isTableFixed = _a.isTableFixed;
        // TODO: change this theme to a modifer
        var themeClassName = theme ? theme : 'default';
        var classNames = getClass('elmo-nativetable', [className, themeClassName], {
            'full-width': isFullWidth,
            fixed: isTableFixed,
        });
        var wrapperClassNames = getClass('elmo-nativetable-wrapper', {
            'elmo-nativetable-responsive': isResponsive,
        }, {
            'is-header-sticky': isHeaderSticky,
            'is-first-column-sticky': isFirstColumnSticky,
        });
        return (React.createElement("div", { className: wrapperClassNames, ref: this.wrapperRef },
            React.createElement("table", { id: id, "data-testid": "elmo-nativetable-".concat(id || 'default'), className: classNames }, children)));
    };
    return NativeTableWrapper;
}(Component));
export default NativeTableWrapper;
