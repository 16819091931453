import { isPendoEnabled, pendoApiKey } from '../env';
import AuthAccess, { JwtObject } from '../auth/access';

declare const window: any;

export const init = (companySubDomain: string): void => {
  const apiKey = pendoApiKey();

  if (!isPendoEnabled() || apiKey === '' || !companySubDomain) {
    return;
  }

  const { pendo } = window;
  const jwtObject: JwtObject | null = AuthAccess.currentUser;

  if (jwtObject && jwtObject.id) {
    pendo.initialize({
      visitor: {
        id: jwtObject.id,
        email: jwtObject.email,
        fullName: `${jwtObject.firstName} ${jwtObject.lastName}`,
      },
      account: {
        id: companySubDomain,
      },
    });
  }
};
