import React from 'react';
import { getQuery, getRoute } from '../../util';
import history from '../../history';
import { AppraisalStatusOptions, ManagerType } from '../../../page/TeamPerformance/state/state';
import { DropdownOption } from '../../../page/TeamPerformance/type';
import { STATUS_ALL } from '../../const';
import { ColumnSort, SortDirection } from '@eds/table';

const usePerformanceListControl = (path: string, defaultQuery: Record<string, any>): any => {
  const [query, setQuery] = React.useState<Record<string, any>>({
    ...defaultQuery,
    ...getQuery(),
  });

  const updateQuery = (queryParams: Record<string, any>): void => {
    const route = getRoute(path, { query: queryParams });
    history.push(route);
    setQuery({
      ...query,
      ...queryParams,
    });
  };

  const onTableSort = ({ columnName, sortDirection }: ColumnSort): void => {
    switch (sortDirection) {
      case 'ascending': {
        updateQuery({
          sort: columnName,
          page: 1,
        });
        break;
      }
      case 'descending': {
        updateQuery({
          sort: `-${columnName}`,
          page: 1,
        });
        break;
      }
      default: {
        updateQuery({
          sort: '',
          page: 1,
        });
      }
    }
  };

  /**
   * A simple handller to just change the url with some
   * query parameters.
   *
   * Note, that we do not call a redux/action function here.
   * We pass the query parameters as a props to this component and
   * when component recives new props we will call the redux/action
   * to fetch data. This makes the logic simpler.
   */
  const onPageChange = (page: number): void => {
    updateQuery({ page });
  };

  /**
   * handle the page size change, we
   * change the page and page size to 1, and 10
   */
  const onPageSizeChange = (limit: number): void => {
    updateQuery({ limit, page: 1 });
  };

  /**
   * handle the appraisal phase change, when phase is changed, we
   * change the page and page size to 1, and 10
   */
  const onMultiPhaseFilterChange = (items?: DropdownOption[]): void => {
    if (items && items.length > 0) {
      updateQuery({
        phase: items.map((item: DropdownOption) => item.value).join(','),
        page: 1,
      });
    } else {
      updateQuery({
        phase: STATUS_ALL,
        page: 1,
      });
    }
  };

  const getColumnSort = (): ColumnSort => {
    let sortDirection: SortDirection = 'default';
    let columnName = '';
    if (query.sort) {
      const sortSplit = query.sort.split('-');
      columnName = sortSplit.length === 1 ? sortSplit[0] : sortSplit[1];
      sortDirection = sortSplit.length === 1 ? 'ascending' : 'descending';
    }

    return {
      sortDirection,
      columnName,
    };
  };

  const onReporteeChange = (enabled: boolean): void => {
    updateQuery({
      indirectManager: enabled ? ManagerType.directAndIndirect : ManagerType.directOnly,
      page: 1,
    });
  };

  const onSearch = (keyword?: string): void => {
    updateQuery({
      keyword: keyword || '',
      page: 1,
    });
  };

  const getPhaseItems = (phase: string): DropdownOption[] => {
    const value: DropdownOption[] = [];
    phase.split(',').forEach((i: string) => {
      const foundItem = AppraisalStatusOptions.find(item => item.value === i);
      if (foundItem) {
        value.push(foundItem);
      }
    });
    return value;
  };

  const isIndirectReporteeEnabled = (reportee: string): boolean =>
    reportee === ManagerType.directAndIndirect.toString();

  return {
    query,
    onTableSort,
    onSearch,
    onPageChange,
    onPageSizeChange,
    onMultiPhaseFilterChange,
    onReporteeChange,
    getColumnSort,
    getPhaseItems,
    isIndirectReporteeEnabled,
  };
};

export default usePerformanceListControl;
