import React, { useEffect } from 'react';
import { ContentModal } from '@eds/modal';
import { CheckCircleIcon, CloseIcon } from '@eds/icon';
import history from '../../../../lib/history';
import { getRoute } from '../../../../lib/util';
import { TeamPerformancePath } from '../../../TeamPerformance/routes';
import { Flex } from '@eds/flex';
import { Heading } from '@eds/heading';
import { Button } from '@eds/button';
import { useCore } from '../../../../element/Core/CoreContext';

const AssignmentCompletedModal = ({
  isModalOpen,
  appraisalTitle,
  onClose,
}: {
  isModalOpen: boolean;
  appraisalTitle: string;
  onClose: () => void;
}): React.ReactElement => {
  const { setIsModalOpen } = useCore();
  useEffect(() => {
    setIsModalOpen(isModalOpen);
  }, [isModalOpen, setIsModalOpen]);

  const AssignedModalHeader = (): React.ReactElement => (
    <Flex padding="large" justifyContent="space-between" alignItems="center">
      <Flex alignItems="center" gap="xxsmall">
        <CheckCircleIcon color="positive" />
        <Heading level={4}>Appraisals assigned</Heading>
      </Flex>
      <Button label="Close" iconOnly tone="ghost" icon={CloseIcon} onClick={onClose} size="small" />
    </Flex>
  );
  return (
    <ContentModal
      isOpen={isModalOpen}
      onClose={onClose}
      header={<AssignedModalHeader />}
      controls={{
        confirm: {
          label: 'Back to Team Performance',
          onClick: (): void => history.push(getRoute(TeamPerformancePath)),
        },
      }}
      maxWidth="27.5rem"
    >
      {`${appraisalTitle} has been assigned successfully`}
    </ContentModal>
  );
};
export default AssignmentCompletedModal;
