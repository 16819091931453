import httpClient from '../../lib/api';
import { POLLING_ERROR_POLL_STATUSES } from '../../lib/const';
import config from '../../lib/config';
import { messages, t } from '../../lib/translation';
import { Navigation } from '../../element/Menu/model';

export class AppApi {
  async getJobStatus(id: number): Promise<any> {
    const response = await httpClient.get(`/performance-api/v1/processes/${id}`);
    const { data } = response;

    if (data.processStatus in POLLING_ERROR_POLL_STATUSES) {
      throw new Error('Polling was unsuccesfull');
    }

    return data;
  }

  async getData(): Promise<any> {
    try {
      // remove the base URL, as this menuURL is using a totally different tms url
      const serverResponse: any = await httpClient
        .get(config.api.menuUrl)
        .then((result: any) => result);

      if (!serverResponse || !serverResponse.data) {
        return Promise.reject(t(messages.app.menu.receivedInvalidData));
      }

      const response = serverResponse.data;
      return Object.assign(new Navigation(), response);
    } catch (err) {
      return Promise.reject(t(messages.app.menu.receivedInvalidData));
    }
  }
}
export default new AppApi();
