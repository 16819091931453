var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import Row from '../Grid/Row';
import Col from '../Grid/Col';
import { getClass } from '../_lib/helper';
import AlertIcon from './component/AlertIcon';
import AlertCloseButton from './component/AlertCloseButton';
import AlertTitle from './component/AlertTitle';
import AlertMessage from './component/AlertMessage';
import { AlertTypes } from './type';
import './Alert.scss';
var Alert = /** @class */ (function (_super) {
    __extends(Alert, _super);
    function Alert() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isClosed: false,
        };
        _this.handleClose = function () {
            var onClose = _this.props.onClose;
            _this.setState({ isClosed: true }, function () {
                if (onClose) {
                    onClose();
                }
            });
        };
        return _this;
    }
    Alert.prototype.render = function () {
        var _a;
        if (this.state.isClosed) {
            return null;
        }
        var _b = this.props, type = _b.type, heading = _b.heading, children = _b.children, message = _b.message, isCloseable = _b.isCloseable, icon = _b.icon, id = _b.id, className = _b.className, role = _b.role;
        return (React.createElement("div", { id: id, className: getClass('elmo-alert', className, (_a = {},
                _a[type] = type,
                _a)), "data-testid": "elmo-alert-".concat(id || 'default'), role: role },
            React.createElement(Row, { isNoGutters: true, className: "flex-nowrap", role: "presentation" },
                React.createElement(Col, { isAuto: true, role: "presentation" },
                    React.createElement(AlertIcon, { icon: icon, type: type })),
                React.createElement(Col, { role: "presentation" },
                    isCloseable && (React.createElement(AlertCloseButton, { id: id, onClick: this.handleClose })),
                    heading && React.createElement(AlertTitle, { id: id, title: heading }),
                    React.createElement(AlertMessage, { id: id, message: message }, children)))));
    };
    Alert.defaultProps = {
        type: AlertTypes.info,
        isCloseable: true,
        role: 'alert',
    };
    return Alert;
}(Component));
export default Alert;
