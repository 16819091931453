export const modal = () => ({
  '.elmo-layout__main-menu, .elmo-layout__main-header': {
    zIndex: 0,
  },
});

export const content = () => ({
  '.elmo-layout__main-header': {
    zIndex: 1,
  },
});
