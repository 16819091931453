import React from 'react';
import { messages, t } from '../../../../lib/translation';
import { DateInput } from '@eds/date-input';
import { Text } from '@eds/text';
import { Field } from '@eds/field';
import { Box } from '@eds/box';
import { Heading } from '@eds/heading';
import { Flex } from '@eds/flex';

type DurationCommonProps = {
  startDate: Date | undefined;
  setStartDate: (date?: Date) => void;
  isFixedDuration: boolean;
};
type DurationConditionalProps =
  | {
      appStartDate?: Date;
      appEndDate?: Date;
      appStartPeriod?: never;
      appEndPeriod?: never;
      appPeriodScale?: never;
      isDisabled?: never;
    }
  | {
      appStartDate?: never;
      appEndDate?: never;
      appStartPeriod?: number;
      appEndPeriod?: number;
      appPeriodScale?: string;
      isDisabled: boolean;
    };

const AppraisalDuration = (
  props: DurationCommonProps & DurationConditionalProps
): React.ReactElement => (
  <Flex flexDirection="column">
    <Heading level={4}>{t(messages.assignAppraisal.assignmentDuration.title)}</Heading>

    {props.isFixedDuration ? (
      <>
        <Text color="neutralSubtle" fontSize="small">
          {t(messages.assignAppraisal.assignmentDuration.descriptionFixed)}
        </Text>
        <Flex flexDirection="row" gap="medium" flexGrow={1} flexWrap="wrap" marginTop="medium">
          <Box width="25rem">
            <Field label={t(messages.assignAppraisal.assignmentDuration.labels.startDate)}>
              <DateInput
                value={props.appStartDate}
                onChange={(): void => undefined}
                disabled={true}
              />
            </Field>
          </Box>
          <Box width="25rem">
            <Field label={t(messages.assignAppraisal.assignmentDuration.labels.endDate)}>
              <DateInput
                value={props.appEndDate}
                onChange={(): void => undefined}
                disabled={true}
              />
            </Field>
          </Box>
        </Flex>
      </>
    ) : (
      <>
        <Text color="neutralSubtle" fontSize="small">
          {t(messages.assignAppraisal.assignmentDuration.descriptionRelative)}{' '}
          <Text fontWeight="medium" color="neutralBold">
            {props.appEndPeriod !== undefined &&
              props.appStartPeriod !== undefined &&
              `${props.appEndPeriod - props.appStartPeriod}`}{' '}
            {props.appPeriodScale &&
              t(messages.assignAppraisal.assignmentDuration.labels[props.appPeriodScale])}
          </Text>
        </Text>
        <Flex flexDirection="row" gap="medium" flexGrow={1} flexWrap="wrap" marginTop="medium">
          <Box width="25rem">
            <Field label={t(messages.assignAppraisal.assignmentDuration.labels.startDate)}>
              <DateInput
                value={props.startDate}
                onChange={props.setStartDate}
                disabled={props.isDisabled}
              />
            </Field>
          </Box>
        </Flex>
      </>
    )}
  </Flex>
);

export default AppraisalDuration;
