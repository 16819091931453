import React, { useState } from 'react';

type SubmenuContextType = {
  submenu: string;
  setSubmenu: (key: string) => void;
};

export const SubmenuContext = React.createContext<Partial<SubmenuContextType>>({ submenu: '' });

export const SubmenuProvider = (props: Record<string, any>): React.ReactElement => {
  const [submenu, setSubmenu] = useState('');

  return (
    <SubmenuContext.Provider value={{ submenu, setSubmenu }}>
      {props.children}
    </SubmenuContext.Provider>
  );
};
