import { createAction } from 'redux-act';
import { StartPollPayloadType } from './type';

/**
 * Some short document about what this action is about
 * <Dev.Note>
 *  Action names should follow the following convention. Note that the sub section is optional
 *  [app abbreviation]_[section name]_[sub section name?]_[action]_[null|SUCCESS|FAILURE]
 * </Dev.Note>
 */
export const appInit: any = createAction('PMS_APP_INIT');
export const appInitSuccess: any = createAction('PMS_APP_INIT_SUCCESS');
export const appInitFailure: any = createAction('PMS_APP_INIT_FAILURE');
export const appClear: any = createAction('APP_CLEAR');
export const resetState: any = createAction('RESET_STATE');
export const startPoll: any = createAction(
  'PERFORMANCE_START_POLL',
  (job: StartPollPayloadType) => ({ ...job })
);
export const stopPoll: any = createAction(
  'PERFORMANCE_STOP_POLL',
  (jobId: number, data: any, error: Error) => ({ jobId, data, error })
);
export const pausePolls: any = createAction('PERFORMANCE_PAUSE_POLLS');
export const cleanNotification: any = createAction(
  'PERFORMANCE_CLEAN_NOTIFICATION',
  (id: number) => ({ id })
);
export const fetchNavigation: any = createAction('FETCH_NAVIGATION');
export const fetchNavigationSuccess: any = createAction(
  'FETCH_NAVIGATION_SUCCESS',
  (data: any) => data
);
export const fetchNavigationFailure: any = createAction(
  'FETCH_NAVIGATION_FAILURE',
  (error: any) => error
);
