import httpClient from '../api';
import config from '.';

/**
 * <Dev.Note>
 *  Anything that need to be loaded in a async way need to be implemented here.
 *  We use this function during app initialization process look at 'lib/setup'
 *
 *  And since the output of this function could be different from one instance
 *  of same running app (e.g. For example based on user capability or some user customizations)
 *  to another we keep its value in the state
 * </Dev.Note>
 */
export async function loadSystemConfiguration(): Promise<any> {
  try {
    const result = await httpClient.get(config.api.systemConfigurationUrl);
    const { data } = result;
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
}
