/**
 * Get the config based on the env and merge it with configs comming from server
 * <Dev.Note>
 *  This function should be in sync mode. If you have any async operation you should
 *  do it at app initialisation time and you need to keep it in app state. For example
 *  the 'loader.ts' is for this perpose.
 * </Dev.Note>
 */
export function getConfig(): Record<string, any> {
  return {
    api: {
      baseUrl: '/api/v1',
      menuUrl: 'navigation',
      themeUrl: 'theme',
      systemConfigurationUrl: 'system-configuration',
      oauthClientSecureRedirectionPath: '/oauth2-client/redirect-to-secure-frontend/',
      oauthClientCheckTokenPath: '/oauth2-client/auth/check-token',
    },
    baseName: '',
    performanceBaseName: 'performance',
    legacy: {
      appraisalUrl: '/dashboard/show-performance-detail',
    },
    hotjarId: 1696550,
    hotjarSV: 6,
    froalaLicense: 'OXC1lA3J4B12A10D9D7kOPVi1b1b2Le2e1G1AWMSGSAFDTGHWsE7D7D6E5A1I4E3C3A6D7==',
  };
}

export default getConfig();
