import { setBasePath } from '../../lib/route';
import ConversationListPage from './ListPage/index';
import ConversationDetailsPage from './DetailsPage/index';

export const ConversationListPath = setBasePath('conversations');
export const ConversationDetailsPath = setBasePath('conversations/:id');
export const ConversationListRoutes: any = {
  path: ConversationListPath,
  exact: true,
  component: ConversationListPage,
};
export const ConversationDetailsRoutes: any = {
  path: ConversationDetailsPath,
  paramRules: {
    id: '(\\d+)',
  },
  component: ConversationDetailsPage,
};
