import React from 'react';
import { MessageBlock } from 'elmo-elements';
import logger from '../../lib/logger';

export type NoResultsProps = {
  title: string;
  content: string;
};

function NoResults({ title, content }: NoResultsProps): React.ReactElement {
  logger.warn('MessageBlock needs to be updated to be aligned vertically');
  return (
    <div style={{ marginTop: '48px' }}>
      <MessageBlock>
        <MessageBlock.Header>{title}</MessageBlock.Header>
        <MessageBlock.Body>{content}</MessageBlock.Body>
      </MessageBlock>
    </div>
  );
}

export default NoResults;
