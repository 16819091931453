import StatusCodes from 'http-status-codes';

import auth from '../auth';
import { getRoute } from '../util';
import ForbiddenRoutes from '../../page/Forbidden/routes';
import NotFoundRoutes from '../../page/NotFound/routes';
import history from '../history';
import store from '../../state/store';
import { appInitFailure } from '../../state/App/actions';

export const UNAUTHORIZED_ERROR = 'Unauthorized error';

class ErrorApiResponseHandler {
  public async handleError(error: any): Promise<any> {
    switch (error.response.status) {
      case StatusCodes.UNAUTHORIZED:
        auth.removeJWTAndPromptForLogin();
        return Promise.reject(UNAUTHORIZED_ERROR);
      case StatusCodes.FORBIDDEN:
        history.push(getRoute(ForbiddenRoutes.path));
        break;
      case StatusCodes.NOT_FOUND:
        history.push(getRoute(NotFoundRoutes.path));
        break;
      case 502:
      case 504:
        store.dispatch(appInitFailure(error));
        break;
      default: {
        const responseError = {
          data: error.response.data,
          status: error.response.status,
          headers: error.response.headers,
        };

        return Promise.reject(responseError);
      }
    }
    return Promise.reject(error);
  }
}

export default new ErrorApiResponseHandler();
