export const LANGUAGES = {
  en: 'en',
};

export const messages: Record<string, any> = {
  app: {
    product: {
      key: 'app.product',
      default: 'Performance V2',
    },
    name: {
      key: 'app.name',
      default: 'Performance Frontend V2',
    },
    dashboard: {
      key: 'app.menu.dashboard',
      default: 'Dashboard Default',
    },
    language: {
      key: 'app.menu.language',
      default: 'Language Default',
    },
    notfound: {
      title: {
        key: 'app.notfound.title',
        default: 'Not Found - default',
      },
      content: {
        key: 'app.notfound.content',
        default:
          'The server returned a "404 Not Found". Try returning to your home page and try again. ',
      },
    },
    catch: {
      message: {
        key: 'app.catch.message',
        default:
          'Something went wrong, this error has been logged. Please try again later or refresh the page',
      },
    },
    noResultsfound: {
      title: {
        key: 'app.noresultsfound.title',
        default: 'No results found',
      },
      content: {
        key: 'app.noresultsfound.content',
        default: 'There are no results found. Try returning to your home page and try again. ',
      },
    },
    emptyState: {
      key: 'app.emptyState',
      default: 'Empty state',
    },
    forbidden: {
      title: {
        key: 'app.forbidden.title',
        default: 'Access Denied',
      },
      content: {
        key: 'app.forbidden.content',
        default: "You don't have permission to access this page.",
      },
    },
    error: {
      title: {
        key: 'app.error.title',
        default: 'Something went wrong',
      },
      content: {
        key: 'app.error.content',
        default:
          'Something went wrong, this error has been logged. Please try again later or go to your ',
      },
      dashboardLink: {
        key: 'app.error.dashboardLink',
        default: 'home',
      },
    },
    emptyStateTitle: {
      default: 'No results found.',
      search: 'Sorry, no results match your search.',
      myPerformance: 'There are no appraisals to display.',
      teamPerformance: 'There are no appraisals to display.',
      myContributions: 'There are no appraisals to display.',
      review360Performance: 'There are no appraisals to display.',
      approvalPerformance: 'There are no appraisals to display.',
      directIndirectReports: 'There are no appraisals to display.',
      assigneeList: 'There are no assignees to display.',
      employeeList: 'There are no employees to display.',
      workflowConfigs: 'No results found',
    },
    emptyStateContent: {
      default: 'There are no results found. Try returning to your home page and try again.',
      search: 'Please check the spelling or try a different word search.',
      myPerformance: 'There were no appraisals that matched this filter.',
      teamPerformance: 'There were no appraisals that matched this filter.',
      myContributions: 'There were no appraisals that matched this filter.',
      review360Performance:
        'There are no results found. Try returning to your home page and try again.',
      approvalPerformance:
        'There are no results found. Try returning to your home page and try again.',
      directIndirectReports: 'There may not be any reports under this filter.',
      assigneeList: 'No results found.',
      employeeList: 'No results found.',
      discussionPerformance: 'There are no appraisals to display.',
      sectionList: 'There are no section to display.',
      conversationList: 'There are no conversation to display.',
      closedConversations: 'No closed conversation.',
      workflowConfigs: 'Unable to retrieve phases configuration data',
    },
    menu: {
      receivedInvalidData: {
        key: 'app.menu.receivedInvalidData',
        default: 'Received data is not valid',
      },
      submenu: {
        assignAppraisal: {
          title: {
            key: 'app.menu.subMenu.assignAppraisal.title',
            default: 'Assign appraisal',
          },
        },
        myPerformances: {
          title: {
            key: 'app.menu.subMenu.myPerformances.title',
            default: 'My Performance',
          },
        },
        teamPerformances: {
          title: {
            key: 'app.menu.subMenu.teamPerformances.title',
            default: "Team's Performance",
          },
        },
        myContributionsPerformances: {
          title: {
            key: 'app.menu.submenu.myContributionsPerformances.title',
            default: 'Shared With Me',
          },
        },
        signOffPerformances: {
          title: {
            key: 'app.menu.subMenu.signOffPerformances.title',
            default: 'Sign-Offs',
          },
        },
        approvalPerformances: {
          title: {
            key: 'app.menu.subMenu.approvalPerformances.title',
            default: 'Approvals',
          },
        },
        review360Performances: {
          title: {
            key: 'app.menu.subMenu.review360Performances.title',
            default: '360 Reviews',
          },
        },
        conversations: {
          title: {
            key: 'app.menu.subMenu.conversations.title',
            default: 'Conversations',
          },
        },
      },
    },
    exportPdf: {
      key: 'app.exportPdf',
      default: 'Export to PDF',
    },
    openPerformance: {
      key: 'app.openPerformance',
      default: 'Open Performance Appraisal',
    },
    delete: {
      key: 'app.delete',
      default: 'Delete',
    },
    notifications: {
      appraisalAssignment: {
        error: {
          key: 'app.notifications.appraisalAssignment.error',
          default: 'Something went wrong during assignment, Please try again later.',
        },
        success: {
          key: 'app.notifications.appraisalAssignment.success',
          default: 'Appraisals successfully assigned.',
        },
        link: {
          key: 'app.notifications.appraisalAssignment.link',
          default: "Go to team's performance",
        },
      },
    },
    auth: {
      redirectingToLogin: {
        key: 'app.auth.redirectingToLogin',
        default: 'Redirecting to login page, please wait...',
      },
    },
    common: {
      save: {
        key: 'app.common.save',
        default: 'Save',
      },
      cancel: {
        key: 'app.common.cancel',
        default: 'Cancel',
      },
    },
  },
  userSelect: {
    loadMoreBtn: {
      key: 'userSelect.loadMoreBtn',
      default: 'Load More',
    },
    noResults: {
      key: 'userSelect.noResults',
      default: 'No users found!',
    },
    placeholder: {
      key: 'userSelect.placeholder',
      default: "Please input user's name",
    },
  },
  performance: {
    phaseFilter: {
      all: {
        key: 'performance.phaseFilter.all',
        default: 'All',
      },
      active: {
        key: 'performance.phaseFilter.active',
        default: 'Active',
      },
      planning: {
        key: 'performance.phaseFilter.planning',
        default: 'Planning',
      },
      inProgress: {
        key: 'performance.phaseFilter.inProgress',
        default: 'In Progress',
      },
      finalise: {
        key: 'performance.phaseFilter.finalise',
        default: 'Finalise',
      },
      completed: {
        key: 'performance.phaseFilter.completed',
        default: 'Completed',
      },
    },
  },
  myPerformances: {
    title: {
      key: 'myPerformances.title',
      default: 'My Performance',
    },
    notifications: {
      error: 'Something went wrong, this error has been logged.',
    },
    listHeader: {
      appraisal: {
        key: 'myPerformances.listHeader.appraisal',
        default: 'Appraisal',
      },
      phase: {
        key: 'myPerformances.listHeader.phase',
        default: 'Phase',
      },
      status: {
        key: 'myPerformances.listHeader.status',
        default: 'Status',
      },
      manager: {
        key: 'myPerformances.listHeader.manager',
        default: 'Manager',
      },
      startDate: {
        key: 'myPerformances.listHeader.startDate',
        default: 'Start date',
      },
      endDate: {
        key: 'myPerformances.listHeader.endDate',
        default: 'End date',
      },
    },
    listFilter: {
      all: {
        key: 'myPerformances.listFilter.all',
        default: 'All',
      },
      planning: {
        key: 'myPerformances.listFilter.planning',
        default: 'Planning',
      },
      inProgress: {
        key: 'myPerformances.listFilter.inProgress',
        default: 'In Progress',
      },
      finalise: {
        key: 'myPerformances.listFilter.finalise',
        default: 'Finalise',
      },
      completed: {
        key: 'myPerformances.listFilter.completed',
        default: 'Completed',
      },
    },
    header: {
      search: {
        title: {
          key: 'myPerformances.header.search.title',
          default: 'Search by employee or appraisal name...',
        },
      },
    },
  },
  teamPerformances: {
    title: {
      key: 'teamPerformances.title',
      default: "Team's Performance",
    },
    notifications: {
      error: 'Something went wrong, this error has been logged.',
      deleted: 'The appraisal has been successfully deleted.',
    },
    listHeader: {
      name: {
        key: 'teamPerformance.listHeader.name',
        default: 'Name',
      },
      appraisal: {
        key: 'teamPerformance.listHeader.appraisal',
        default: 'Appraisal',
      },
      phase: {
        key: 'teamPerformance.listHeader.phase',
        default: 'Phase',
      },
      status: {
        key: 'teamPerformance.listHeader.status',
        default: 'Status',
      },
      manager: {
        key: 'teamPerformance.listHeader.manager',
        default: 'Manager',
      },
      startDate: {
        key: 'teamPerformance.listHeader.startDate',
        default: 'Start date',
      },
      endDate: {
        key: 'teamPerformance.listHeader.endDate',
        default: 'End date',
      },
    },
    managerTypeFilter: {
      directOnly: {
        key: 'directonly',
        default: 'Direct only',
      },
      directAndIndirect: {
        key: 'directandindirect',
        default: 'Direct and indirect',
      },
    },
    listFilter: {
      all: {
        key: 'teamPerformance.listFilter.all',
        default: 'All',
      },
      planning: {
        key: 'teamPerformance.listFilter.planning',
        default: 'Planning',
      },
      inProgress: {
        key: 'teamPerformance.listHeader.inProgress',
        default: 'In Progress',
      },
      finalise: {
        key: 'teamPerformance.listHeader.finalise',
        default: 'Finalise',
      },
      completed: {
        key: 'teamPerformance.listHeader.completed',
        default: 'Completed',
      },
    },
    delete: {
      title: {
        key: 'teamPerformance.delete.title',
        default: 'Delete Appraisal',
      },
      message: {
        key: 'teamPerformance.delete.message',
        default: 'Appraisal will be removed from the list and any progress will be lost.',
      },
      iconTooltip: {
        key: 'teamPerformance.delete.iconTooltip',
        default: 'Delete',
      },
      okButton: {
        key: 'teamPerformance.delete.okButton',
        default: 'YES, DELETE',
      },
      cancelButton: {
        key: 'teamPerformance.delete.cancelButton',
        default: 'CANCEL',
      },
    },
    header: {
      search: {
        title: {
          key: 'teamPerformance.header.search.title',
          default: 'Search by employee or appraisal name...',
        },
      },
      assignButton: {
        label: {
          key: 'teamPerformance.header.assignButton.label',
          default: 'Assign',
        },
      },
    },
  },
  myContributionsPerformances: {
    title: {
      key: 'myContributionsPerformances.title',
      default: 'Shared With Me',
    },
    notifications: {
      error: 'Something went wrong, this error has been logged.',
      deleted: 'The appraisal has been successfully deleted.',
    },
    listHeader: {
      name: {
        key: 'myContributionsPerformances.listHeader.name',
        default: 'Name',
      },
      appraisal: {
        key: 'myContributionsPerformances.listHeader.appraisal',
        default: 'Appraisal',
      },
      phase: {
        key: 'myContributionsPerformances.listHeader.phase',
        default: 'Phase',
      },
      status: {
        key: 'myContributionsPerformances.listHeader.status',
        default: 'Status',
      },
      manager: {
        key: 'myContributionsPerformances.listHeader.manager',
        default: 'Manager',
      },
      startDate: {
        key: 'myContributionsPerformances.listHeader.startDate',
        default: 'Start date',
      },
      endDate: {
        key: 'myContributionsPerformances.listHeader.endDate',
        default: 'End date',
      },
    },
    listFilter: {
      all: {
        key: 'myContributionsPerformances.listFilter.all',
        default: 'All',
      },
      planning: {
        key: 'myContributionsPerformances.listFilter.planning',
        default: 'Planning',
      },
      inProgress: {
        key: 'myContributionsPerformances.listHeader.inProgress',
        default: 'In Progress',
      },
      finalise: {
        key: 'myContributionsPerformances.listHeader.finalise',
        default: 'Finalise',
      },
      completed: {
        key: 'myContributionsPerformances.listHeader.completed',
        default: 'Completed',
      },
    },
    header: {
      search: {
        title: {
          key: 'myContributionsPerformances.header.search.title',
          default: 'Search by employee or appraisal name...',
        },
      },
    },
  },
  review360Performance: {
    title: {
      key: 'review360Performance.title',
      default: '360 Reviews',
    },
    notifications: {
      error: 'Something went wrong, this error has been logged.',
    },
    listHeader: {
      name: {
        key: 'review360Performance.listHeader.name',
        default: 'Employee',
      },
      manager: {
        key: 'review360Performance.listHeader.manager',
        default: 'Manager',
      },
      appraisal: {
        key: 'review360Performance.listHeader.appraisal',
        default: 'Appraisal',
      },
      startDate: {
        key: 'review360Performance.listHeader.startDate',
        default: 'Start date',
      },
      endDate: {
        key: 'review360Performance.listHeader.endDate',
        default: 'End Date',
      },
    },
    header: {
      search: {
        title: {
          key: 'review360Performance.header.search.title',
          default: 'Search by employee or appraisal name...',
        },
      },
    },
  },
  signOffPerformances: {
    title: {
      key: 'signOffPerformances.title',
      default: 'Sign-Offs',
    },
    notifications: {
      error: 'Something went wrong, this error has been logged.',
    },
  },
  approvalPerformances: {
    title: {
      key: 'approvalPerformance.title',
      default: 'Approvals',
    },
    notifications: {
      error: 'Something went wrong, this error has been logged.',
    },
    header: {
      searchTitle: {
        key: 'approvalPerformace.header.searchTitle',
        default: 'Search by employee or appraisal name...',
      },
    },
    listHeader: {
      appraisal: {
        key: 'approvalPerformance.listHeader.appraisal',
        default: 'Appraisal',
      },
      name: {
        key: 'approvalPerformance.listHeader.name',
        default: 'Name',
      },
      manager: {
        key: 'approvalPerformance.listHeader.manager',
        default: 'Manager',
      },
      startDate: {
        key: 'approvalPerformance.listHeader.startDate',
        default: 'Start date',
      },
      endDate: {
        key: 'approvalPerformance.listHeader.endDate',
        default: 'End date',
      },
    },
  },
  assignAppraisal: {
    title: {
      key: 'assign.appraisal.title',
      default: 'Assign appraisal',
    },
    header: {
      backButton: {
        title: {
          key: 'assign.appraisal.header.backButton.title',
          default: 'Back',
        },
      },
      searchTitle: {
        key: 'assignAppraisal.header.searchTitle',
        default: 'Search by template name...',
      },
    },
    assign: {
      key: 'assign.appraisal',
      default: 'Assign',
    },
    error: {
      title: {
        key: 'assign.appraisal.error.title',
        default: 'An error occurred',
      },
    },
    notifications: {
      error: {
        key: 'assign.appraisalAssignment.notifications.error',
        default: 'Something went wrong, this error has been logged.',
      },
      assigning: {
        key: 'assign.appraisalAssignment.notifications.assigning',
        default: "Assigning appraisals. We'll let you know when they're ready.",
      },
      link: {
        key: 'assign.appraisalAssignment.notifications.link',
        default: "Go to team's performance",
      },
    },
    info: {
      message: 'Please choose the appraisal you would like to assign to your team member(s).',
    },
    listHeader: {
      appraisalTitle: {
        key: 'assignAppraisal.listHeader.title',
        default: 'Appraisal',
      },
      assignees: {
        key: 'assignAppraisal.listHeader.assignees',
        default: 'Assignees',
      },
    },
    assignButton: {
      key: 'assignAppraisal.assignButton',
      default: 'Assign',
    },
    backButton: {
      key: 'assignAppraisal.backButton',
      default: 'Back',
    },
    assignAppraisalConfirmationLabel: {
      noSelected: {
        key: 'assignAppraisal.assignAppraisalConfirmationLabel.noSelected',
        default: 'No team members have been selected.',
      },
      selected: {
        key: 'assignAppraisal.assignAppraisalConfirmationLabel.selected',
        default: '{{ title }} will be assigned to selected 1 team member.',
      },
      selectedMultiple: {
        key: 'assignAppraisal.assignAppraisalConfirmationLabel.selected',
        default: '{{ title }} will be assigned to selected {{ number }} team members.',
      },
      direct: {
        key: 'assignAppraisal.assignAppraisalConfirmationLabel.direct',
        default: '{{ title }} will be assigned to your direct reports only.',
      },
      all: {
        key: 'assignAppraisal.assignAppraisalConfirmationLabel.all',
        default: '{{ title }} will be assigned to your entire reporting line.',
      },
    },
    selectAssigneeType: {
      title: {
        key: 'assignAppraisal.assignment.selectAssigneeType.title',
        default: 'Select employees',
      },
      labels: {
        selected: {
          key: 'assignAppraisal.assignment.selectAssigneeType.label.selected',
          default: 'View and choose from your reporting line',
        },
        direct: {
          key: 'assignAppraisal.assignment.selectAssigneeType.label.direct',
          default: 'Direct reports',
        },
        all: {
          key: 'assignAppraisal.assignment.selectAssigneeType.label.all',
          default: 'Entire reporting line',
        },
      },
      description: {
        direct: {
          key: 'assignAppraisal.assignment.selectAssigneeType.description.direct',
          default: 'Assign all the employees that report directly to you',
        },
        all: {
          key: 'assignAppraisal.assignment.selectAssigneeType.description.all',
          default:
            'Assign all the employees that report directly to you and include their direct reports',
        },
      },
    },
    assignmentDuration: {
      title: {
        key: 'assignAppraisal.assignment.assignmentDuration.title',
        default: 'Duration',
      },
      descriptionFixed: {
        key: 'assignAppraisal.assignment.assignmentDuration.descriptionFixed',
        default: 'The appraisal will start and end on the pre-selected dates shown.',
      },
      descriptionRelative: {
        key: 'assignAppraisal.assignment.assignmentDuration.descriptionRelative',
        default: 'The appraisal will be effective for',
      },
      labels: {
        Months: {
          key: 'assignAppraisal.assignment.assignmentDuration.label.months',
          default: 'Months',
        },
        Weeks: {
          key: 'assignAppraisal.assignment.assignmentDuration.label.weeks',
          default: 'Weeks',
        },
        startDate: {
          key: 'assignAppraisal.assignment.assignmentDuration.label.startDate',
          default: 'Start',
        },
        endDate: {
          key: 'assignAppraisal.assignment.assignmentDuration.label.endDate',
          default: 'End',
        },
      },
    },
    assigneeList: {
      title: {
        key: 'appraisalAssignee.title',
        default: 'Assignees',
      },
      searchTitle: {
        key: 'appraisalAssignee.searchTitle',
        default: 'Search by employee name',
      },
      listHeader: {
        name: {
          key: 'appraisalAssignee.listHeader.name',
          default: 'Name',
        },
        status: {
          key: 'appraisalAssignee.listHeader.status',
          default: 'Status',
        },
        manager: {
          key: 'appraisalAssignee.listHeader.manager',
          default: 'Manager',
        },
        startDate: {
          key: 'appraisalAssignee.listHeader.startDate',
          default: 'Start date',
        },
        endDate: {
          key: 'appraisalAssignee.listHeader.endDate',
          default: 'End date',
        },
      },
      assignedEmployees: {
        key: 'appraisalAssignee.assignedEmployees',
        default: 'Below are the employees already assigned to this appraisal.',
      },
    },
    employeeList: {
      searchTitle: {
        key: 'assignAppraisal.employeeList.searchTitle',
        default: 'Search employees by name',
      },
      listHeader: {
        employeeName: {
          key: 'assignAppraisal.employeeList.header.employeeName',
          default: 'Employee Name',
        },
        managerName: {
          key: 'assignAppraisal.employeeList.header.managerName',
          default: 'Manager Name',
        },
        position: {
          key: 'assignAppraisal.employeeList.header.position',
          default: 'Position',
        },
        department: {
          key: 'assignAppraisal.employeeList.header.department',
          default: 'Department',
        },
      },
    },
  },
  performanceView: {
    noFound: {
      title: {
        key: 'performanceView.noFound.title',
        default: 'No Appraisal Found',
      },
      content: {
        key: 'performanceView.noFound.content',
        default: 'There is not appraisal data found.',
      },
    },
    body: {
      tabs: {
        overview: {
          title: {
            key: 'performanceView.body.tabs.overview.title',
            default: 'Overview',
          },
          subtitle: {
            key: 'performanceView.body.tabs.overview.subtitle',
            default: 'Appraisal overview & Workflow',
          },
        },
        goals: {
          key: 'performanceView.body.tabs.goals',
          default: 'Goals',
        },
      },
    },
    user: {
      roles: {
        assignee: {
          key: 'performanceView.user.titles.assignee',
          default: 'Employee',
        },
        reporter: {
          key: 'performanceView.user.titles.reporter',
          default: 'Manager',
        },
      },
    },
    description: {
      title: {
        key: 'performanceView.description.title',
        default: 'Overview and Workflow',
      },
      readMore: {
        key: 'performanceView.description.readMore',
        default: 'Read More',
      },
      showLess: {
        key: 'performanceView.description.showLess',
        default: 'Show Less',
      },
    },
    workflowChart: {
      title: {
        key: 'performanceView.workflowChart.title',
        default: 'Workflow',
      },
      tooltips: {
        connector: '<br/>',
        pastDue: {
          key: 'performanceView.workflowChart.tooltips.pastDue',
          default: '<b>%name%</b> should have been completed by %date%',
        },
        startDate: {
          key: 'performanceView.workflowChart.tooltips.startDate',
          default: '<b>Start date:</b> %date%',
        },
        endDate: {
          key: 'performanceView.workflowChart.tooltips.endDate',
          default: '<b>End date:</b> %date%',
        },
        definedStartDate: {
          key: 'performanceView.workflowChart.tooltips.definedStartDate',
          default: '<b>Defined start date:</b> %date%',
        },
        definedEndDate: {
          key: 'performanceView.workflowChart.tooltips.definedEndDate',
          default: '<b>Defined end date:</b> %date%',
        },
      },
    },
  },
  conversations: {
    title: {
      key: 'conversations.title',
      default: 'Conversations',
    },
    visibilityFilter: {
      active: {
        key: 'conversation.visibilityFilter.active',
        default: 'Active',
      },
      closed: {
        key: 'conversation.visibilityFilter.closed',
        default: 'Closed',
      },
    },
    startConversations: {
      text: {
        key: 'conversations.startConversations',
        default: 'Your conversations will appear here.',
      },
      button: {
        key: 'conversations.button',
        default: 'Get started',
      },
    },
    userSelect: {
      placeholder: {
        key: 'conversations.userSelect.placeholder',
        default: 'Who do you want to have a conversation with?',
      },
    },
    notifications: {
      error: {
        key: 'conversations.notifications.error',
        default: 'Something went wrong, this error has been logged.',
      },
    },
    noFound: {
      title: {
        key: 'conversations.noFound.title',
        default: 'No Conversation Found',
      },
      content: {
        key: 'conversations.noFound.content',
        default: 'There is not conversation data found.',
      },
    },
    discussionItemCreationInactiveWarning: {
      key: 'conversations.discussionItemCreationInactiveWarning',
      default:
        'New discussion items cannot be created as this conversation involves an inactive user.',
    },
    discussionItems: {
      text: {
        key: 'conversations.discussionItems.text',
        default: 'discussion items',
      },
      title: {
        key: 'conversations.discussionItems.title',
        default: 'Discussion Items',
      },
      create: {
        key: 'conversation.discussionItems.create',
        default: 'Create discussion',
      },
      delete: {
        key: 'conversations.discussionItems.delete',
        default: 'Delete Discussion Item?',
      },
      deleteContent: {
        key: 'conversations.discussionItems.deleteContent',
        default: 'Are you sure you want to delete this discussion item?',
      },
      startDiscussionTitle: {
        key: 'conversations.discussionItems.startDiscussionTitle',
        default: 'Start the discussion',
      },
      startDiscussion: {
        key: 'conversations.discussionItems.startDiscussion',
        default:
          'To start a new discussion tap the "Create New" button or you can select the agenda from your appraisal by clicking the arrow button',
      },
      noCompletedTitle: {
        key: 'conversations.discussionItems.noCompletedTitle',
        default: 'No completed discussion',
      },
      noCompleted: {
        key: 'conversations.discussionItems.noCompleted',
        default:
          "When you're finished discussing something you can mark it as complete and it'll move here for safe keeping.",
      },
    },
    completedItems: {
      text: {
        key: 'conversations.completedItems.text',
        default: 'completed items',
      },
      title: {
        key: 'conversations.completedItems.title',
        default: 'Completed Items',
      },
    },
    notes: {
      delete: {
        key: 'conversations.notes.delete',
        default: 'Delete Note?',
      },
      deleteContent: {
        key: 'conversations.notes.deleteContent',
        default: 'Are you sure you want to delete this note?',
      },
      addNote: {
        placeholder: {
          key: 'conversations.notes.addNote.placeholder',
          default: 'Add a note...',
        },
      },
      addNoteToAppraisal: {
        key: 'conversations.notes.addNoteToAppraisal',
        default: 'Also add note to appraisal',
      },
      noteToAppraisal: {
        key: 'conversations.notes.noteToAppraisal',
        default: 'This message is added to your performance appraisal',
      },
    },
    inactive: {
      description: {
        key: 'conversations.inactive.description',
        default: '(Inactive)',
      },
    },
    memberList: {
      key: 'conversation.memberList.text',
      default: 'Whose appraisal would you like to discuss?',
    },
  },
};
