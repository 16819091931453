import MyPerformances from '.';
import { setPerformanceBasePath } from '../../lib/route';

export const MyPerformancePath = setPerformanceBasePath('/my-performance');
const MyPerformancesRoutes: any = {
  path: MyPerformancePath,
  component: MyPerformances,
  exact: true,
};

export type MyPerformancesRoutesType = typeof MyPerformancesRoutes;

export default MyPerformancesRoutes;
