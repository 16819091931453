// tslint:disable max-line-length
import * as React from 'react';
import { withIcon } from '../Icon';
import FlashOn from './FlashOn';
import PaperPlane from './PaperPlane';
import Goal from './Goal';
import Development from './Development';
import Competency from './Competency';
import ChatOutlined from './ChatOutlined';
import MyContributions from './MyContributions';

export const FlashOnIcon = withIcon(<FlashOn />);
export const PaperPlaneIcon = withIcon(<PaperPlane />);
export const GoalIcon = withIcon(<Goal />);
export const DevelopmentIcon = withIcon(<Development />);
export const CompetencyIcon = withIcon(<Competency />);
export const ChatOutlinedIcon = withIcon(<ChatOutlined />);
export const MyContributionsIcon = withIcon(<MyContributions />);
