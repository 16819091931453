var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { getClass } from '../_lib/helper';
import './Select.scss';
function Select(_a) {
    var id = _a.id, name = _a.name, className = _a.className, options = _a.options, isMulti = _a.isMulti, isClearable = _a.isClearable, value = _a.value, defaultValue = _a.defaultValue, isDisabled = _a.isDisabled, isCreatable = _a.isCreatable, onBlur = _a.onBlur, onFocus = _a.onFocus, onChange = _a.onChange, onInputChange = _a.onInputChange, onMenuScrollToBottom = _a.onMenuScrollToBottom, hideSelectedOptions = _a.hideSelectedOptions, closeMenuOnSelect = _a.closeMenuOnSelect, placeholder = _a.placeholder, ariaLabel = _a.ariaLabel, ariaLabelledby = _a.ariaLabelledby, filterOption = _a.filterOption, isInline = _a.isInline;
    var returnCloseMenuOnSelect = closeMenuOnSelect
        ? closeMenuOnSelect
        : isMulti
            ? false
            : true;
    /* istanbul ignore next */
    var menuPortalStyles = {
        menuPortal: function (base) { return (__assign(__assign({}, base), { zIndex: 1001 })); },
    };
    var portalProps = {
        menuPortalTarget: document.querySelector('body'),
        styles: menuPortalStyles,
    };
    var props = __assign({ className: 'react-select-container', classNamePrefix: 'react-select', closeMenuOnSelect: returnCloseMenuOnSelect, name: name, options: options, isMulti: isMulti, isClearable: isClearable, value: value, defaultValue: defaultValue, onChange: onChange, onInputChange: onInputChange, onMenuScrollToBottom: onMenuScrollToBottom, onBlur: onBlur, onFocus: onFocus, isDisabled: isDisabled, hideSelectedOptions: hideSelectedOptions, placeholder: placeholder, 'aria-label': ariaLabel, 'aria-labelledby': ariaLabelledby, filterOption: filterOption }, (!(isInline || isInline === undefined) ? portalProps : {}));
    return (React.createElement("div", { id: id, "data-testid": "elmo-select-".concat(id || 'default'), className: getClass('elmo-select', className) }, isCreatable ? (React.createElement(CreatableSelect, __assign({}, props))) : (React.createElement(ReactSelect, __assign({}, props)))));
}
Select.defaultProps = {
    isMulti: false,
    isClearable: false,
    hideSelectedOptions: false,
};
export default Select;
