import { MyPerformancesState, MyPerformancesQuery, DropdownOption } from '../type';
import { t, messages } from '../../../lib/translation';
import {
  STATUS_PLANNING,
  STATUS_INPROGRESS,
  STATUS_FINALISE,
  STATUS_COMPLETED,
} from '../../../lib/const';

export const MyPerformancesDefaultQuery: MyPerformancesQuery = {
  page: 1,
  limit: 10,
  phase: `${STATUS_PLANNING},${STATUS_INPROGRESS},${STATUS_FINALISE}`,
  sort: '-startDate',
  keyword: '',
};

/**
 * Document about what this state is about
 */
export default {
  performances: {
    data: [],
    total: 0,
    page: 1,
    limit: 10,
  },
  isLoaded: false,
  isLoading: true,
  error: undefined,
  query: { ...MyPerformancesDefaultQuery },
} as MyPerformancesState;

export const AppraisalStatusOptions: DropdownOption[] = [
  {
    value: STATUS_PLANNING,
    label: t(messages.myPerformances.listFilter.planning),
  },
  {
    value: STATUS_INPROGRESS,
    label: t(messages.myPerformances.listFilter.inProgress),
  },
  {
    value: STATUS_FINALISE,
    label: t(messages.myPerformances.listFilter.finalise),
  },
  {
    value: STATUS_COMPLETED,
    label: t(messages.myPerformances.listFilter.completed),
  },
];
