import { setPerformanceBasePath } from '../../lib/route';
import ViewPage from './View';
import DiagnosticsContainer from './View/DiagnosticsContainer';
import ViewContainer from './View/ViewContainer';

export const PerformanceDiagnosticsPath = setPerformanceBasePath('/view/:id/diagnostics');
export const PerformanceViewPath = setPerformanceBasePath('/view/:id');

export const PerformanceViewRoutes = {
  path: PerformanceViewPath,
  paramRules: {
    id: '(\\d+)',
  },
  component: ViewPage,
  routes: {
    view: {
      path: PerformanceViewPath,
      paramRules: {
        id: '(\\d+)',
      },
      component: ViewContainer,
      exact: true,
    },
    diagnostics: {
      path: PerformanceDiagnosticsPath,
      component: DiagnosticsContainer,
      paramRules: {
        id: '(\\d+)',
      },
      exact: true,
    },
  },
};
