import { createAction } from 'redux-act';
import { MyPerformancesDefaultQuery } from './state';
import { MyPerformancesQuery, MyPerformancesData } from '../type';

/**
 * Some short document about what this action is about
 */
export const getMyPerformances: any = createAction(
  'PERFORMANCE_GET_MY_PERFORMANCES',
  (payload: MyPerformancesQuery) => ({
    ...MyPerformancesDefaultQuery,
    ...payload,
  })
);

export const getMyPerformancesSuccess: any = createAction(
  'PERFORMANCE_GET_MY_PERFORMANCES_SUCCESS',
  (data: MyPerformancesData) => data
);

export const getMyPerformancesFailure: any = createAction(
  'PERFORMANCE_GET_MY_PERFORMANCES_FAILURE',
  (error: any) => error
);
