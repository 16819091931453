import NotFound from '.';
import { setBasePath } from '../../lib/route';

const NotFoundRoutes: any = {
  path: setBasePath('not-found'),
  component: NotFound,
};

export type NotFoundRoutesType = typeof NotFoundRoutes;

export default NotFoundRoutes;
