import { createReducer } from 'redux-act';
import defaultState from './state';
import {
  getApprovalPerformances,
  getApprovalPerformancesSuccess,
  getApprovalPerformancesFailure,
} from './actions';
import {
  ApprovalPerformancesQuery,
  ApprovalPerformancesState,
  ApprovalPerformancesData,
} from '../type';
import { resetState } from '../../../state/App';

/**
 * Get performances
 */
export function getApprovalPerformanceReducer(
  state: ApprovalPerformancesState,
  approvalPerformanceQuery: ApprovalPerformancesQuery
): ApprovalPerformancesState {
  return {
    ...state,
    query: approvalPerformanceQuery,
    isLoaded: false,
    isLoading: true,
  };
}

export function getApprovalPerformanceSuccessReducer(
  state: ApprovalPerformancesState,
  data: ApprovalPerformancesData
): ApprovalPerformancesState {
  return {
    ...state,
    performances: { ...data },
    isLoading: false,
    isLoaded: true,
  };
}

export function getApprovalPerformanceFailureReducer(
  state: ApprovalPerformancesState,
  error: Error
): ApprovalPerformancesState {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error,
  };
}

export function resetStateReducer(state: ApprovalPerformancesState): ApprovalPerformancesState {
  return {
    ...state,
    isDeleted: false,
    isLoading: false,
    isLoaded: false,
    error: undefined,
  };
}

export default createReducer(
  {
    [`${getApprovalPerformances}`]: getApprovalPerformanceReducer,
    [`${getApprovalPerformancesSuccess}`]: getApprovalPerformanceSuccessReducer,
    [`${getApprovalPerformancesFailure}`]: getApprovalPerformanceFailureReducer,
    [`${resetState}`]: resetStateReducer,
  },
  defaultState
);
