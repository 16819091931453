import {
  createConversationNote,
  getDiscussionItemNotes,
  resetDiscussionItemNotes,
  updateDiscussionItemNote,
} from '../../page/Conversations/state/actions';
import {
  TYPE_DISCUSSION,
  TYPE_COMPLETED,
} from '../../page/Conversations/DetailsPage/components/ItemList/type';
import { DiscussionItemNoteUpdatePayload } from '../../page/Conversations/type';

export default class NoteControls {
  itemType: string; // Note is belong to which item type

  itemId: number | null; // the item id of this item type

  constructor({ itemType, itemId = null }: { itemType: string; itemId?: number | null }) {
    this.itemType = itemType;
    this.itemId = itemId;
  }

  public isDiscussionItem(): boolean {
    return this.itemType === TYPE_DISCUSSION;
  }

  public isCompletedItem(): boolean {
    return this.itemType === TYPE_COMPLETED;
  }

  public getItemType(): string {
    return this.itemType;
  }

  public getItemId(): number | null {
    return this.itemId;
  }

  public getListAction(): any {
    let type;
    if (NoteControls.isItemTypeSupported(this.itemType)) {
      type = getDiscussionItemNotes({ discussionItemId: this.itemId });
    }
    return type;
  }

  public getResetAction(): any {
    let type;
    if (NoteControls.isItemTypeSupported(this.itemType)) {
      type = resetDiscussionItemNotes(this.itemId);
    }
    return type;
  }

  public getItemNotesStateIndex(): string {
    let index = '';
    if (NoteControls.isItemTypeSupported(this.itemType)) {
      index = 'discussionItemsNotes';
    }
    return index;
  }

  public getConversationNoteCreationAction(
    discussionItem: string,
    text: string,
    itemType: string,
    isDisplayingInPerformance: boolean
  ): any {
    if (NoteControls.isItemTypeSupported(this.itemType)) {
      return createConversationNote({
        discussionItem,
        text,
        itemType,
        isDisplayingInPerformance,
      });
    }

    return null;
  }

  public getUpdateDiscussionItemNoteAction(
    id: number,
    payload: DiscussionItemNoteUpdatePayload
  ): any {
    return updateDiscussionItemNote(id, payload);
  }

  public static isItemTypeSupported(itemType: string): boolean {
    return [TYPE_DISCUSSION, TYPE_COMPLETED].includes(itemType);
  }
}
