import { createAction } from 'redux-act';
import { UsersPaginator } from '../../../lib/user/type';
import {
  ConversationsPaginator,
  ConversationsQuery,
  DiscussionItemPayload,
  DiscussionItemsQueryType,
  DiscussionItemType,
  NoteCreationError,
  NotePayload,
  NoteDeletionPayload,
  NotesPaginator,
  UserSearchQueryType,
  NoteQuery,
  DiscussionItemNoteUpdatePayload,
  ConversationVisibilityPayload,
} from '../type';
import {
  defaultConversationQuery,
  defaultConversationsState,
  defaultNoteQuery,
  defaultUserSearchQueryState,
} from './state';

export const getConversations: any = createAction(
  'GET_CONVERSATIONS',
  (payload: ConversationsQuery) => ({
    ...defaultConversationQuery,
    ...payload,
  })
);
export const getConversationsSuccess: any = createAction(
  'GET_CONVERSATIONS_SUCCESS',
  (data: ConversationsPaginator) => data
);
export const getConversationsFailure: any = createAction(
  'GET_CONVERSATIONS_FAILURE',
  (error: any) => error
);

export const searchUsers: any = createAction('SEARCH_USERS', (payload: UserSearchQueryType) => ({
  ...defaultUserSearchQueryState,
  ...payload,
}));
export const searchUsersSuccess: any = createAction(
  'SEARCH_USERS_SUCCESS',
  (data: UsersPaginator) => data
);
export const searchUsersFailure: any = createAction('SEARCH_USERS_FAILURE', (error: any) => error);

export const resetSearchUsers: any = createAction('RESET_SEARCH_USERS');

export const createConversation: any = createAction(
  'CREATE_CONVERSATION',
  (userId: string) => userId
);

export const createConversationSuccess: any = createAction(
  'CREATE_CONVERSATION_SUCCESS',
  (conversationId: number) => conversationId
);

export const createConversationFailure: any = createAction(
  'CREATE_CONVERSATION_FAILURE',
  (error: any) => error
);

export const updateConversationVisibility: any = createAction(
  'UPDATE_CONVERSATION_VISIBILITY',
  (id: number, payload: ConversationVisibilityPayload) => ({ id, payload })
);

export const updateConversationVisibilitySuccess: any = createAction(
  'UPDATE_CONVERSATION_VISIBILITY_SUCCESS'
);

export const updateConversationVisibilityFailure: any = createAction(
  'UPDATE_CONVERSATION_VISIBILITY_FAILURE',
  (error: any) => error
);

export const getConversationDetails: any = createAction(
  'GET_CONVERSATION_DETAIL',
  (id: number) => id
);

export const getConversationDetailsSuccess: any = createAction(
  'GET_CONVERSATION_DETAIL_SUCCESS',
  (data: any) => data
);

export const getConversationDetailsFailure: any = createAction(
  'GET_CONVERSATION_DETAIL_FAILURE',
  (error: any) => error
);

export const getDiscussionItems: any = createAction(
  'GET_DISSCUSSION_ITEMS',
  (query: DiscussionItemsQueryType): DiscussionItemsQueryType => ({
    ...defaultConversationsState.discussionItems.query,
    ...query,
    isCompleted: false,
  })
);

export const getDiscussionItemsSuccess: any = createAction(
  'GET_DISSCUSSION_ITEMS_SUCCESS',
  (data: ConversationsPaginator) => data
);

export const getDiscussionItemsFailure: any = createAction(
  'GET_DISSCUSSION_ITEMS_FAILURE',
  (error: any) => error
);

export const createDiscussionItem: any = createAction(
  'CREATE_DISCUSSION_ITEM',
  (payload: DiscussionItemPayload) => payload
);
export const createDiscussionItemSuccess: any = createAction(
  'CREATE_DISCUSSION_ITEM_SUCCESS',
  (payload: DiscussionItemPayload, data: DiscussionItemType) => ({ payload, data })
);
export const createDiscussionItemFailure: any = createAction(
  'CREATE_DISCUSSION_ITEM_FAILURE',
  (payload: DiscussionItemPayload, error: Error) => ({ payload, error })
);

export const updateDiscussionItem: any = createAction(
  'UPDATE_DISCUSSION_ITEM',
  (id: number, payload: DiscussionItemPayload) => ({ id, payload })
);
export const updateDiscussionItemSuccess: any = createAction('UPDATE_DISCUSSION_ITEM_SUCCESS');

export const updateDiscussionItemFailure: any = createAction(
  'UPDATE_DISCUSSION_ITEM_FAILURE',
  (payload: Error) => payload
);

export const completeDiscussionItem: any = createAction(
  'COMPLETE_DISCUSSION_ITEM',
  (itemId: number) => itemId
);
export const completeDiscussionItemSuccess: any = createAction('COMPLETE_DISCUSSION_ITEM_SUCCESS');
export const completeDiscussionItemFailure: any = createAction(
  'COMPLETE_DISCUSSION_ITEM_FAILURE',
  (error: Error) => error
);

export const restoreCompletedItem: any = createAction(
  'RESTORE_COMPLETED_ITEM',
  (itemId: number) => itemId
);
export const restoreCompletedItemSuccess: any = createAction('RESTORE_COMPLETED_ITEM_SUCCESS');
export const restoreCompletedItemFailure: any = createAction(
  'RESTORE_COMPLETED_ITEM_FAILURE',
  (error: Error) => error
);

export const deleteDiscussionItem: any = createAction('DELETE_DISCUSSION_ITEM', (id: number) => id);

export const deleteDiscussionItemSuccess: any = createAction('DELETE_DISCUSSION_ITEM_SUCCESS');

export const deleteDiscussionItemFailure: any = createAction(
  'DELETE_DISCUSSION_ITEM_FAILURE',
  (payload: Error) => payload
);

export const getDiscussionItemNotes: any = createAction(
  'GET_DISSCUSSION_ITEM_NOTES',
  (query: NoteQuery): NoteQuery => ({ ...defaultNoteQuery, ...query })
);

export const getDiscussionItemNotesSuccess: any = createAction(
  'GET_DISSCUSSION_ITEM_NOTES_SUCCESS',
  (data: NotesPaginator) => data
);

export const getDiscussionItemNotesFailure: any = createAction(
  'GET_DISSCUSSION_ITEM_NOTES_FAILURE',
  (error: any) => error
);

export const resetDiscussionItemNotes: any = createAction('RESET_DISCUSSION_ITEM_NOTES');

export const getCompletedItems: any = createAction(
  'GET_COMPLETED_ITEMS',
  (query: DiscussionItemsQueryType): DiscussionItemsQueryType => ({
    ...defaultConversationsState.completedItems.query,
    ...query,
  })
);
export const getCompletedItemsSuccess: any = createAction(
  'GET_COMPLETED_ITEMS_SUCCESS',
  (data: ConversationsPaginator) => data
);
export const getCompletedItemsFailure: any = createAction(
  'GET_COMPLETED_ITEMS_FAILURE',
  (error: any) => error
);

export const deleteCompletedItem: any = createAction('DELETE_COMPLETED_ITEM', (id: number) => id);

export const deleteCompletedItemSuccess: any = createAction('DELETE_COMPLETED_ITEM_SUCCESS');

export const deleteCompletedItemFailure: any = createAction(
  'DELETE_COMPLETED_ITEM_FAILURE',
  (payload: Error) => payload
);

export const deleteConversationNote: any = createAction(
  'DELETE_CONVERSATION_NOTE',
  (payload: NoteDeletionPayload) => payload
);

export const deleteConversationNoteFailure: any = createAction(
  'DELETE_CONVERSATION_NOTE_FAILURE',
  (payload: Error) => payload
);

export const createConversationNote: any = createAction(
  'CREATE_CONVERSATION_NOTE',
  (payload: NotePayload) => payload
);

export const createConversationNoteFailure: any = createAction(
  'CREATE_CONVERSATION_NOTE_FAILURE',
  (error: NoteCreationError) => error
);

export const updateDiscussionItemNote: any = createAction(
  'UPDATE_DISCUSSION_ITEM_NOTE',
  (id: number, payload: DiscussionItemNoteUpdatePayload) => ({ id, payload })
);

export const updateDiscussionItemNoteSuccess: any = createAction(
  'UPDATE_DISCUSSION_ITEM_NOTE_SUCCESS'
);

export const updateDiscussionItemNoteFailure: any = createAction(
  'UPDATE_DISCUSSION_ITEM_NOTE_FAILURE',
  (payload: Error) => payload
);

export const resetCompletedItems: any = createAction('RESET_COMPLETED_ITEMS');

export const resetCompletedItemDeletion: any = createAction('RESET_COMPLETED_ITEM_DELETION');

export const resetConversationCreation: any = createAction('RESET_CONVERSATION_CREATION');

export const resetConversationList: any = createAction('RESET_CONVERSATION_LIST');

export const resetConversationDetails: any = createAction('RESET_CONVERSATION_DETAILS');

export const resetDiscussionItems: any = createAction('RESET_DISCUSSION_ITEMS');

export const resetDiscussionItemCreation: any = createAction('RESET_DISCUSSION_ITEM_CREATION');

export const resetDiscussionItemUpdate: any = createAction('RESET_DISCUSSION_ITEM_UPDATE');

export const resetDiscussionItemCompletion: any = createAction('RESET_DISCUSSION_ITEM_COMPLETION');

export const resetDiscussionItemDeletion: any = createAction('RESET_DISCUSSION_ITEM_DELETION');

export const resetCompletedItemRestoring: any = createAction('RESET_COMPLETED_ITEM_RESTORING');

export const resetUpdateDiscussionItemNote: any = createAction('RESET_UPDATE_DISCUSSION_ITEM_NOTE');
