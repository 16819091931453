var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Pagination from '../Pagination';
import './List.scss';
var List = function (props) {
    var data = props.data, withPagination = props.withPagination, onPageSizeChange = props.onPageSizeChange, onPageChange = props.onPageChange, onClick = props.onClick, renderData = props.renderData, rest = __rest(props, ["data", "withPagination", "onPageSizeChange", "onPageChange", "onClick", "renderData"]);
    var handlePageChange = function (pageNumber, startItem, endItem) {
        if (onPageChange) {
            onPageChange(pageNumber, startItem, endItem);
        }
    };
    var handlePageSizeChange = function (pageSize) {
        if (onPageSizeChange) {
            onPageSizeChange(pageSize);
        }
    };
    var handleOnClick = function (info) {
        if (onClick) {
            onClick(info);
        }
    };
    if (!data || !data.length) {
        return null;
    }
    return (React.createElement("div", { className: "elmo-list" },
        data.map(function (items, index) { return (React.createElement("div", { className: "elmo-list__item", key: index, onClick: function () { return handleOnClick(items); } }, renderData(items))); }),
        withPagination && (React.createElement(Pagination, __assign({}, rest, { onPageChange: handlePageChange, onPageSizeChange: handlePageSizeChange })))));
};
export default List;
