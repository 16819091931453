import React, { useContext } from 'react';
import { SystemConfigurationContext } from '../../../../lib/systemConfiguration';
import {
  PERFORMANCE_EMPLOYEE_SEARCH_SCALE_DIRECT,
  ASSIGN_APPRAISAL_TO_ALL_REPORTS,
  ASSIGN_APPRAISAL_TO_SELECTED_REPORTS,
  ASSIGN_APPRAISAL_TO_DIRECT_REPORTS,
} from '../../../../lib/const';
import { t, messages } from '../../../../lib/translation';
import { Flex } from '@eds/flex';
import { Radio, RadioGroup } from '@eds/radio';
import { Text } from '@eds/text';
import { Heading } from '@eds/heading';

export type SelectAssigneeTypeProps = {
  selected: string;
  onSelect: (value: string) => void;
  isDisabled: boolean;
};

const SelectAssigneeType = ({
  selected,
  isDisabled,
  onSelect,
}: SelectAssigneeTypeProps): React.ReactElement => {
  const systemConfiguration = useContext(SystemConfigurationContext);

  const handleSelectChange = (value: string): void => {
    onSelect(value);
  };

  return (
    <Flex flexDirection="column" gap="medium">
      <Heading level={4}>Select employees</Heading>
      <RadioGroup value={selected} onInputChange={handleSelectChange} disabled={isDisabled}>
        <Radio value={ASSIGN_APPRAISAL_TO_SELECTED_REPORTS}>
          <Text fontSize="small">
            {t(messages.assignAppraisal.selectAssigneeType.labels.selected)}
          </Text>
        </Radio>

        <Radio value={ASSIGN_APPRAISAL_TO_DIRECT_REPORTS}>
          <Flex flexDirection="column" gap="xxsmall">
            <Text fontSize="small">
              {t(messages.assignAppraisal.selectAssigneeType.labels.direct)}
            </Text>
            <Text fontSize="xsmall" lineHeight="tight" color="neutralSubtle">
              {t(messages.assignAppraisal.selectAssigneeType.description.direct)}
            </Text>
          </Flex>
        </Radio>

        {systemConfiguration.employeeSearchScale !== PERFORMANCE_EMPLOYEE_SEARCH_SCALE_DIRECT && (
          <Radio value={ASSIGN_APPRAISAL_TO_ALL_REPORTS}>
            <Flex flexDirection="column" gap="xxsmall">
              <Text fontSize="small">
                {t(messages.assignAppraisal.selectAssigneeType.labels.all)}
              </Text>
              <Text fontSize="xsmall" lineHeight="tight" color="neutralSubtle">
                {t(messages.assignAppraisal.selectAssigneeType.description.all)}
              </Text>
            </Flex>
          </Radio>
        )}
      </RadioGroup>
    </Flex>
  );
};

export default SelectAssigneeType;
