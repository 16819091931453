import React, { lazy, useContext, useEffect } from 'react';
import { SubmenuContext } from '../../../element/Menu/submenuContext';
import SubMenuItems from '../../../element/Menu/SubMenuItems';
import Suspense from '../../../element/Suspense';
import { Route } from '../../../lib/route';

const ConversationListView = lazy(() => import('./view'));

const ConversationListPage = ({ routes }: { routes: Route[] }): React.ReactElement => {
  const { setSubmenu } = useContext(SubmenuContext);
  useEffect(() => {
    if (setSubmenu) {
      setSubmenu(SubMenuItems.conversations.key);
    }
  }, [setSubmenu]);

  return (
    <Suspense>
      <ConversationListView routes={routes} />
    </Suspense>
  );
};

export default ConversationListPage;
