import TeamPerformances from '.';
import { setPerformanceBasePath } from '../../lib/route';

export const TeamPerformancePath = setPerformanceBasePath('/team-performance');

const TeamPerformancesRoutes: any = {
  path: TeamPerformancePath,
  component: TeamPerformances,
  exact: true,
};

export type TeamPerformancesRoutesType = typeof TeamPerformancesRoutes;

export default TeamPerformancesRoutes;
