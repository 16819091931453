import { createReducer } from 'redux-act';
import defaultState from './state';
import {
  getTeamPerformance,
  getTeamPerformanceSuccess,
  getTeamPerformanceFailure,
  deleteTeamPerformance,
  deleteTeamPerformanceSuccess,
  deleteTeamPerformanceFailure,
} from './actions';
import { TeamPerformanceQuery, TeamPerformanceState, TeamPerformanceData } from '../type';
import { resetState } from '../../../state/App';

/**
 * Get team performances
 */
export function getTeamPerformanceReducer(
  state: TeamPerformanceState,
  teamPerformanceQuery: TeamPerformanceQuery
): any {
  const newState = {
    ...state,
    query: teamPerformanceQuery,
    isLoaded: false,
    isLoading: true,
  };

  if ('isDeleted' in newState && newState.isDeleted) {
    delete newState.isDeleted;
  }

  return newState;
}

export function getTeamPerformanceSuccessReducer(
  state: TeamPerformanceState,
  data: TeamPerformanceData
): any {
  return {
    ...state,
    teamAppraisals: { ...data },
    isLoading: false,
    isLoaded: true,
  };
}

export function getTeamPerformanceFailureReducer(state: TeamPerformanceState, error: Error): any {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error,
  };
}

export function deleteTeamPerformanceReducer(state: TeamPerformanceState): any {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
    isDeleted: undefined,
    error: undefined,
  };
}

export function deleteTeamPerformanceSuccessReducer(state: TeamPerformanceState): any {
  return {
    ...state,
    isDeleted: true,
  };
}

export function deleteTeamPerformanceFailureReducer(
  state: TeamPerformanceState,
  error: Error
): any {
  return {
    ...state,
    isDeleted: false,
    isLoading: false,
    isLoaded: true,
    error,
  };
}

export function resetStateReducer(state: TeamPerformanceState): any {
  return {
    ...state,
    isDeleted: undefined,
    isLoading: false,
    isLoaded: false,
    error: undefined,
  };
}

export default createReducer(
  {
    [`${getTeamPerformance}`]: getTeamPerformanceReducer,
    [`${getTeamPerformanceSuccess}`]: getTeamPerformanceSuccessReducer,
    [`${getTeamPerformanceFailure}`]: getTeamPerformanceFailureReducer,
    [`${deleteTeamPerformance}`]: deleteTeamPerformanceReducer,
    [`${deleteTeamPerformanceSuccess}`]: deleteTeamPerformanceSuccessReducer,
    [`${deleteTeamPerformanceFailure}`]: deleteTeamPerformanceFailureReducer,
    [`${resetState}`]: resetStateReducer,
  },
  defaultState
);
