// tslint:disable max-line-length
import React from 'react';

function FlashOn(): React.ReactElement {
  return (
    <svg className="elmo-icon__svg" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
      <path d="M7 2v11h3v9l7-12h-4l4-8z" />
    </svg>
  );
}

export default FlashOn;
