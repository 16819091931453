import React from 'react';
import { RouteProps } from 'react-router';
import getConfig from '../config';

export { default as NestedRoutes } from './NestedRoutes';
export { default as routeAware } from './RouteAware';
export * from './RouteUtil';

export interface DeafultRoute<T extends RouteProps = RouteProps> {
  path: string;
  component: React.ComponentType<any>;
  routes?: Route[];
  routeComponent?: React.ComponentType<T>;
  paramRules?: any;
}

export interface RedirectRoute {
  path: string;
  redirect: string;
  exact?: boolean;
  paramRules?: any;
}

export type Route = DeafultRoute | RedirectRoute;

export function setBasePath(path: string): string {
  return `/${getConfig.baseName}${path}`;
}

export function setPerformanceBasePath(path: string): string {
  return `/${getConfig.performanceBaseName}${path}`;
}
