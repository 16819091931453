import { defaultTheme } from 'elmo-elements';
import httpClient from '../api';
import config from '../config';

const fetchTheme = (): Promise<any> =>
  httpClient
    .get(config.api.themeUrl)
    .then(({ data }) => ({
      font: data.siteFont,
      link: {
        color: data.linkColor,
      },
      button: {
        color: data.buttonFontColor,
        background: data.buttonColor,
        hover: data.buttonHoverColor,
        active: data.buttonHoverColor,
        font: data.siteFont,
      },
    }))
    .catch(() => Promise.resolve({}));

export const loadTheme = async (): Promise<any> => {
  const loadedTheme = await fetchTheme();
  return {
    ...defaultTheme,
    ...loadedTheme,
  };
};
