import React, { lazy } from 'react';
import Suspense from '../../../../element/Suspense';

const DiagnosticsView = lazy(() => import('./view'));

const DiagnosticsContainer = ({ routes }: Record<string, any>): React.ReactElement => (
  <Suspense>
    <DiagnosticsView />
  </Suspense>
);

export default DiagnosticsContainer;
