import { t, messages } from '../../lib/translation';
import { ConversationListPath } from '../../page/Conversations/routes';
import {
  MyTeamIcon,
  LocalActivityOutlinedIcon,
  ApprovalIcon,
  SignOffIcon,
  Review360RequestIcon,
  AssignmentIndIcon,
} from 'elmo-elements';
import { InterfaceLink } from './model';
import { ApprovalPerformancePath } from '../../page/ApprovalPerformance/routes';
import { TeamPerformancePath } from '../../page/TeamPerformance/routes';
import { SignOffPerformancePath } from '../../page/SignOffPerformance/routes';
import { AppraisalAssignmentListPath } from '../../page/AssignAppraisal/routes';
import { MyPerformancePath } from '../../page/MyPerformance/routes';
import { Review360PerformancePath } from '../../page/Review360Performance/routes';
import { MyContributionsPath } from '../../page/MyContributions/routes';
import { ChatOutlinedIcon } from '../Icon';
import { MyContributionsIcon } from '../Icon';

const SubMenuItems: Record<string, InterfaceLink> = {
  assignAppraisal: {
    key: 'app.performance.submenu.assignAppraisal',
    title: t(messages.assignAppraisal.title),
    url: AppraisalAssignmentListPath,
    icon: AssignmentIndIcon,
    description: '',
    isExternal: false,
  },
  myPerformance: {
    key: 'app.performance.submenu.myPerformances',
    title: t(messages.myPerformances.title),
    url: MyPerformancePath,
    icon: LocalActivityOutlinedIcon,
    description: '',
    isExternal: false,
  },
  teamPerformance: {
    key: 'app.menu.submenu.teamPerformances',
    title: t(messages.teamPerformances.title),
    url: TeamPerformancePath,
    icon: MyTeamIcon,
    description: '',
    isExternal: false,
  },
  myContributions: {
    key: 'app.menu.submenu.myContributionsPerformances',
    title: t(messages.myContributionsPerformances.title),
    url: MyContributionsPath,
    icon: MyContributionsIcon,
    description: '',
    isExternal: false,
  },
  review360Performance: {
    key: 'app.performance.submenu.review360Performance',
    title: t(messages.review360Performance.title),
    url: Review360PerformancePath,
    icon: Review360RequestIcon,
    description: '',
    isExternal: false,
  },
  signOffPerformance: {
    key: 'app.performance.submenu.signOffPerformances',
    title: t(messages.signOffPerformances.title),
    url: SignOffPerformancePath,
    icon: SignOffIcon,
    description: '',
    isExternal: false,
  },
  approvalPerformance: {
    key: 'app.performance.submenu.approvalPerformance',
    title: t(messages.approvalPerformances.title),
    url: ApprovalPerformancePath,
    icon: ApprovalIcon,
    description: '',
    isExternal: false,
  },
  conversations: {
    key: 'app.menu.submenu.conversations',
    title: t(messages.app.menu.submenu.conversations.title),
    url: ConversationListPath,
    icon: ChatOutlinedIcon,
    description: '',
    isExternal: false,
  },
};

export default SubMenuItems;
