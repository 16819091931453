var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { getClass } from '../_lib/helper';
import CheckboxInput from './component/CheckboxInput';
import CheckboxLabel from './component/CheckboxLabel';
import './Checkbox.scss';
var Checkbox = /** @class */ (function (_super) {
    __extends(Checkbox, _super);
    function Checkbox(props) {
        var _this = _super.call(this, props) || this;
        _this.toggle = function () {
            if (_this.props.isChecked !== undefined) {
                return;
            }
            _this.setState({
                isChecked: !_this.state.isChecked,
            });
        };
        _this.onChange = function (e) {
            var _a = _this.props, isDisabled = _a.isDisabled, onChange = _a.onChange;
            if (isDisabled) {
                return;
            }
            _this.toggle();
            if (onChange) {
                onChange(e);
            }
        };
        var defaultChecked = !!(props.isChecked || props.isDefaultChecked);
        _this.state = {
            isChecked: defaultChecked ? defaultChecked : false,
        };
        return _this;
    }
    Checkbox.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.isChecked !== this.props.isChecked) {
            this.setState({
                isChecked: !!this.props.isChecked,
            });
        }
    };
    Checkbox.prototype.render = function () {
        var _a = this.props, id = _a.id, name = _a.name, label = _a.label, value = _a.value, className = _a.className, isDisabled = _a.isDisabled, isInvalid = _a.isInvalid, ariaLabel = _a.ariaLabel, isRequired = _a.isRequired, isIndeterminate = _a.isIndeterminate, tabIndex = _a.tabIndex;
        return (React.createElement("span", { id: id, className: getClass('elmo-checkbox', className, {
                checked: this.state.isChecked && !isIndeterminate,
                disabled: isDisabled,
                invalid: isInvalid,
                indeterminate: isIndeterminate,
            }), "data-testid": "elmo-checkbox-".concat(id || 'default') },
            React.createElement(CheckboxInput, { id: id, name: name, value: value, ariaLabel: ariaLabel, onChange: this.onChange, isChecked: this.state.isChecked, isDisabled: isDisabled, isRequired: isRequired, tabIndex: !!tabIndex ? tabIndex : 0 }),
            label && React.createElement(CheckboxLabel, { id: id, label: label })));
    };
    return Checkbox;
}(Component));
export default Checkbox;
