import { TeamPerformanceState, TeamPerformanceQuery, DropdownOption } from '../type';
import {
  STATUS_PLANNING,
  STATUS_INPROGRESS,
  STATUS_FINALISE,
  STATUS_COMPLETED,
} from '../../../lib/const';
import { messages, t } from '../../../lib/translation';

/**
 * It defines that the current manager is the direct or indirect manager for the performance
 */
export const ManagerType = {
  directAndIndirect: 1,
  directOnly: 0,
};

/**
 * determines the default value for the List
 */
export const TeamPerformanceDefaultQuery: TeamPerformanceQuery = {
  page: 1,
  limit: 10,
  phase: `${STATUS_PLANNING},${STATUS_INPROGRESS},${STATUS_FINALISE}`,
  sort: '-startDate',
  indirectManager: ManagerType.directOnly,
  isSignOff: false,
  keyword: '',
};

/**
 * Document about what this state is about
 */
export default {
  teamAppraisals: {
    data: [],
    total: 0,
    page: 1,
    limit: 10,
  },
  isLoaded: false,
  isLoading: true,
  error: undefined,
  query: { ...TeamPerformanceDefaultQuery },
} as TeamPerformanceState;

export const AppraisalStatusOptions: DropdownOption[] = [
  {
    value: STATUS_PLANNING,
    label: t(messages.myPerformances.listFilter.planning),
  },
  {
    value: STATUS_INPROGRESS,
    label: t(messages.myPerformances.listFilter.inProgress),
  },
  {
    value: STATUS_FINALISE,
    label: t(messages.myPerformances.listFilter.finalise),
  },
  {
    value: STATUS_COMPLETED,
    label: t(messages.myPerformances.listFilter.completed),
  },
];
