import React from 'react';
import { t, messages } from '../../lib/translation';
import { Layout, Link, Text, MessageBlock } from 'elmo-elements';

const ErrorContainer = ({ error }: { error?: Error }): React.ReactElement | null => {
  if (!error) {
    return null;
  }

  const DashboardLink = (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link url="/">
      <Text>{t(messages.app.error.dashboardLink)}</Text>
    </Link>
  );

  return (
    <Layout>
      <Layout.Content>
        <MessageBlock>
          <MessageBlock.Header>{error.toString()}</MessageBlock.Header>
          <MessageBlock.Body>
            <>
              {t(messages.app.error.content)}
              {DashboardLink}
            </>
          </MessageBlock.Body>
        </MessageBlock>
      </Layout.Content>
    </Layout>
  );
};

export default ErrorContainer;
