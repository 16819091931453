var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component, Children, createRef } from 'react';
import { getClass, isElementOfType, noop, isScreenDesktop, } from '../_lib/helper';
import ResizeObserver from '@juggle/resize-observer';
import DataTableContext from './DataTableContext';
import DataTableHeader from './component/DataTableHeader';
import DataTableBody from './component/DataTableBody';
import DataTableTd from './component/DataTableTd';
import DataTableTr from './component/DataTableTr';
import DataTableWrapper from './component/DataTableWrapper';
import DataTableEmptyMessage from './component/DataTableEmptyMessage';
import DataTableCustomiseModal from './component/DataTableCustomiseModal';
import DataTableEmptyColumnsMessage from './component/DataTableEmptyColumnsMessage';
import DataTablePagination from './component/DataTablePagination';
import './DataTable.scss';
var DataTable = /** @class */ (function (_super) {
    __extends(DataTable, _super);
    function DataTable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.wrapperRef = createRef();
        /**
         * Exposes setState to DataTableContext consumers.
         *
         * @param state
         */
        _this.setContextState = function (state) {
            _this.setState(state);
        };
        _this.handleResize = function () {
            if (_this.wrapperRef &&
                _this.wrapperRef.current &&
                _this.layoutHeader &&
                isScreenDesktop() === false) {
                var headerHeight_1 = _this.layoutHeader.offsetHeight;
                var headColumns = _this.wrapperRef.current.querySelectorAll('.elmo-datatable__head th, .elmo-datatable__head td');
                headColumns.forEach(function (column) {
                    column.style.top = headerHeight_1 + 'px';
                });
            }
        };
        /**
         * Called when a checkbox is toggled to update the status of the Select All checkbox and the "Select all
         * available items" component in the header.
         *
         * @param isSelect
         */
        _this.updateSelectAllCheckboxState = function (isSelect) {
            if (!_this.subcomponents.Body) {
                return;
            }
            var countVisibleSelected = 0;
            if (isSelect !== undefined) {
                countVisibleSelected = isSelect ? 1 : -1;
            }
            var countVisibleSelectable = 0;
            var bodyProps = _this.subcomponents.Body.props;
            var rows = Children.toArray(bodyProps.children);
            rows.forEach(function (row) {
                if (!React.isValidElement(row) || !isElementOfType(row, DataTableTr)) {
                    return;
                }
                var rowProps = row.props;
                countVisibleSelected += rowProps.isSelected ? 1 : 0;
                countVisibleSelectable +=
                    !rowProps.isDisabled || rowProps.isSelected ? 1 : 0;
            });
            var isIndeterminate = !(countVisibleSelected === countVisibleSelectable) &&
                countVisibleSelected !== 0;
            var isChecked = countVisibleSelected !== 0;
            _this.setContextState({
                isSelectAllChecked: isChecked,
                isSelectAllIndeterminate: isIndeterminate,
                showSelectAllComponent: isChecked && !isIndeterminate,
            });
        };
        /**
         * Clears the select all checkbox
         */
        _this.clearSelectAllCheckbox = function () {
            _this.setContextState({
                showSelectAllComponent: false,
                isSelectAllChecked: false,
                isSelectAllIndeterminate: false,
            });
        };
        /**
         * The initial state of the DataTable context
         */
        _this.state = {
            showSelectAllComponent: false,
            isSelectAllChecked: false,
            isSelectAllIndeterminate: false,
            hasBulkActions: false,
            countSelectedItems: 0,
            hasRowActions: false,
            countItemsAvailable: 0,
            onSelectAllAvailableToggle: noop,
            isAllAvailableItemsSelected: false,
            countSelectable: 0,
            setContextState: _this.setContextState,
            isCustomiseModalOpen: false,
            headerColumns: [],
            showCustomiseModal: false,
            rowCount: 0,
            isResponsive: false,
            updateSelectAllCheckboxState: _this.updateSelectAllCheckboxState,
        };
        /**
         * Update the data in the context from the props that come through.
         */
        _this.updateContext = function (prevProps) {
            var props = _this.props;
            var propsToUpdate = [
                'hasBulkActions',
                'countItemsAvailable',
                'onSelectAllAvailableToggle',
                'isAllAvailableItemsSelected',
                'countSelectable',
                'countSelectedItems',
                'isResponsive',
            ];
            var newState = {};
            // Check if the props have changed, if they have, add it to the new state to be updated
            propsToUpdate.forEach(function (key) {
                if (prevProps[key] !== props[key]) {
                    newState[key] = props[key];
                }
            });
            if (Object.keys(newState).length !== 0) {
                _this.setContextState(newState);
            }
        };
        _this.showEmptyColumnsMessage = function () {
            var headerColumns = _this.state.headerColumns;
            var countVisibleColumns = headerColumns.filter(function (c) {
                return !c.isHidden;
            }).length;
            return countVisibleColumns === 0;
        };
        /**
         * Called when the page is changed or page size is changed.
         */
        _this.onPageUpdate = function () {
            _this.clearSelectAllCheckbox();
        };
        return _this;
    }
    /**
     * Initialises the DataTable Context with the initial props.
     */
    DataTable.prototype.componentDidMount = function () {
        var _this = this;
        var isHeaderSticky = this.props.isHeaderSticky;
        // No previous props so pass in empty object.
        this.updateContext({});
        if (isHeaderSticky) {
            this.layoutHeader = document.querySelector('.elmo-layout__main-header');
            this.headerObserver = new ResizeObserver(function (entries) {
                _this.handleResize();
            });
            this.headerObserver.observe(this.layoutHeader);
        }
    };
    DataTable.prototype.componentWillUnmount = function () {
        var isHeaderSticky = this.props.isHeaderSticky;
        if (isHeaderSticky) {
            this.headerObserver.disconnect();
        }
    };
    DataTable.prototype.componentDidUpdate = function (prevProps) {
        var _a = this.props, countSelectedItems = _a.countSelectedItems, toggleBulkActionDisabled = _a.toggleBulkActionDisabled, isBulkActionOpen = _a.isBulkActionOpen;
        /**
         * Update the data in the context with the props that come through
         */
        this.updateContext(prevProps);
        /**
         * Update the bulk actions disable state when the number of items selected has changed.
         * When the first item has been selected, then bulk actions should be enabled.
         * When the last item has been deselected, disable bulk actions.
         */
        if (toggleBulkActionDisabled &&
            this.shouldToggleBulkActionsDisabled(prevProps.countSelectedItems, countSelectedItems)) {
            toggleBulkActionDisabled();
        }
        /**
         * If the bulk actions has been closed, hide the select all component in the header, and uncheck the Select All
         * checkbox.
         */
        if (!isBulkActionOpen && isBulkActionOpen !== prevProps.isBulkActionOpen) {
            this.clearSelectAllCheckbox();
        }
    };
    DataTable.prototype.shouldToggleBulkActionsDisabled = function (prevCountSelectedItems, currCountSelectedItems) {
        var prevSelected = prevCountSelectedItems
            ? prevCountSelectedItems
            : 0;
        var currSelected = currCountSelectedItems
            ? currCountSelectedItems
            : 0;
        // no change in number of selected items - return false
        if (prevSelected === currSelected) {
            return false;
        }
        var firstSelected = currSelected > 0 && prevSelected === 0;
        var lastDeselected = currSelected === 0 && prevSelected > 0;
        // if either first item selected, or last item selected, toggle the bulk actions disable state.
        return firstSelected || lastDeselected;
    };
    DataTable.prototype.getSubComponents = function () {
        var children = this.props.children;
        var subComponents = {};
        React.Children.map(children, function (child) {
            if (!React.isValidElement(child)) {
                return;
            }
            if (isElementOfType(child, DataTablePagination)) {
                subComponents.Pagination = child;
            }
            else if (isElementOfType(child, DataTableHeader)) {
                subComponents.Header = child;
            }
            else if (isElementOfType(child, DataTableBody)) {
                subComponents.Body = child;
            }
        });
        return subComponents;
    };
    DataTable.prototype.render = function () {
        var _a = this.props, id = _a.id, className = _a.className, isFullWidth = _a.isFullWidth, isResponsive = _a.isResponsive, isHeaderSticky = _a.isHeaderSticky, isFirstColumnSticky = _a.isFirstColumnSticky;
        this.subcomponents = this.getSubComponents();
        return (React.createElement(DataTableContext.Provider, { value: this.state },
            React.createElement("div", { id: id, className: getClass('elmo-datatable', className, {
                    scroll: isResponsive,
                    'is-header-sticky': isHeaderSticky,
                    'is-first-column-sticky': isFirstColumnSticky,
                }), "data-testid": "elmo-datatable-".concat(id || 'default'), ref: this.wrapperRef },
                React.createElement(DataTableWrapper, { isFullWidth: isFullWidth },
                    this.subcomponents.Header,
                    this.subcomponents.Body),
                this.showEmptyColumnsMessage() && React.createElement(DataTableEmptyColumnsMessage, null),
                this.state.rowCount === 0 && React.createElement(DataTableEmptyMessage, null),
                this.subcomponents.Pagination &&
                    React.cloneElement(this.subcomponents.Pagination, {
                        onPageUpdate: this.onPageUpdate,
                    }),
                React.createElement(DataTableCustomiseModal, null))));
    };
    // Compound Components
    DataTable.Body = DataTableBody;
    DataTable.Header = DataTableHeader;
    DataTable.Td = DataTableTd;
    DataTable.Tr = DataTableTr;
    DataTable.Pagination = DataTablePagination;
    return DataTable;
}(Component));
export default DataTable;
