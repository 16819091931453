import { getEnv } from '../env';

/**
 * If you need to load some assets before you can render the app then do it here
 */
export function loadAssets(): Promise<any> {
  // Workaround for https://github.com/facebook/create-react-app/issues/6399
  // until it gets fixed upstream
  if (getEnv() !== 'production') {
    return new Promise((r: any) =>
      setTimeout(() => {
        r();
      }, 500)
    );
  }

  return Promise.resolve();
}
