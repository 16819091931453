import { setPerformanceBasePath } from '../../lib/route';
import ApprovalPerformance from '.';

export const ApprovalPerformancePath = setPerformanceBasePath('/approval-performance');

const ApprovalPerformancesRoutes: any = {
  path: ApprovalPerformancePath,
  component: ApprovalPerformance,
  exact: true,
};

export default ApprovalPerformancesRoutes;
