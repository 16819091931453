import React from 'react';
/**
 * Hook to support control value to be both controlled
 * as well as uncontrolled, but one at a time.
 *
 * @param object {
 * controlled: is the value passed in,
 * default: is the value that it should be in case controlled value is not passed in,
 * name: is the name of component in which this is being used,
 * state: is the field value name,
 * }
 */
export function useControlled(_a) {
    var controlled = _a.controlled, defaultProp = _a.default, name = _a.name, _b = _a.state, state = _b === void 0 ? 'value' : _b;
    var isControlled = React.useRef(controlled !== undefined).current;
    var _c = React.useState(defaultProp), stateValue = _c[0], setStateValue = _c[1];
    var value = (isControlled ? controlled : stateValue);
    if (process.env.NODE_ENV !== 'production') {
        React.useEffect(function () {
            if (isControlled !== (controlled !== undefined)) {
                // eslint-disable-next-line no-console
                console.error("".concat(state, " of component ").concat(name, " changed from controlled to uncontrolled or vice versa."));
            }
        }, [controlled]);
        var defaultValue_1 = React.useRef(defaultProp).current;
        React.useEffect(function () {
            if (!isControlled && defaultValue_1 !== defaultProp) {
                // eslint-disable-next-line no-console
                console.error("Default ".concat(state, " state of component ").concat(name, " changed after initial render"));
            }
        }, [JSON.stringify(defaultProp)]);
    }
    var setValueIfUncontrolled = React.useCallback(function (newValue) {
        if (!isControlled) {
            setStateValue(newValue);
        }
    }, []);
    return [value, setValueIfUncontrolled];
}
