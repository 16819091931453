import React from 'react';
import { NestedRoutes } from '../lib/route';
import { appRoutes } from '../routes';
import QueryClientComponent from '../lib/query';
import './App.scss';

/**
 * For Suspense please see: https://reactjs.org/docs/code-splitting.html
 * Also plase see src/lib/lazy/LazyLoading.tsx If you dont use withLazyLoading
 * then we will fullback on the AppLayout Suspense component.
 */
function AppLayout(): React.ReactElement {
  return (
    <QueryClientComponent>
      <NestedRoutes routes={appRoutes} />
    </QueryClientComponent>
  );
}

export default AppLayout;
