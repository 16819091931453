import * as actions from './actions';
import { SagaIterator } from 'redux-saga';
import { takeEvery, all, call, put, takeLatest } from 'redux-saga/effects';
import api from './api';

export const getUserPerformancesSaga = function* (action: Record<string, any>): SagaIterator {
  const { employeeId } = action.payload;

  try {
    const data = yield call(api.getUserPerformances, action.payload);
    yield put(actions.getUserPerformancesSuccess(data, employeeId));
  } catch (error) {
    yield put(actions.getUserPerformancesFailure(error, employeeId));
  }
};

export const getSectionListSaga = function* (action: Record<string, any>): SagaIterator {
  try {
    const data = yield call(api.getSectionList, action.payload);
    yield put(actions.getSectionListSuccess(data));
  } catch (error) {
    yield put(actions.getSectionListFailure(error));
  }
};

export const getSectionItemListSaga = function* (action: Record<string, any>): SagaIterator {
  try {
    const performanceSection = yield call(api.getSection, action.payload.id, action.payload.module);
    yield put(actions.getSectionItemListSuccess(performanceSection.items));
  } catch (error) {
    yield put(actions.getSectionItemListFailure(error));
  }
};

export default function* root(): SagaIterator {
  yield all([
    takeEvery(actions.getUserPerformances, getUserPerformancesSaga),
    takeLatest(actions.getSectionList, getSectionListSaga),
    takeLatest(actions.getSectionItemList, getSectionItemListSaga),
  ]);
}
