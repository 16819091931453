var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { getClass } from '../_lib/helper';
import { ChevronRightIcon } from '../Icon';
import './ButtonCard.scss';
var ButtonCard = /** @class */ (function (_super) {
    __extends(ButtonCard, _super);
    function ButtonCard() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onClick = function (event) {
            if (!_this.props.isDisabled && _this.props.onClick) {
                event.preventDefault();
                _this.props.onClick(event);
            }
        };
        return _this;
    }
    ButtonCard.prototype.render = function () {
        var _a = this.props, id = _a.id, icon = _a.icon, title = _a.title, description = _a.description, className = _a.className, isDisabled = _a.isDisabled, href = _a.href, tabIndex = _a.tabIndex;
        return (React.createElement("a", __assign({ id: id, className: getClass('elmo-buttoncard', [
                className,
                {
                    'is-disabled': isDisabled,
                },
            ]) }, (href ? { href: href } : {}), { onClick: this.onClick, title: title, "data-testid": "elmo-buttoncard-".concat(id || 'default'), tabIndex: tabIndex || 0 }),
            icon && React.createElement("div", { className: "elmo-buttoncard__icon" }, icon),
            React.createElement("div", { className: "elmo-buttoncard__content" },
                React.createElement("div", { className: "elmo-buttoncard__title" }, title),
                description && (React.createElement("div", { className: "elmo-buttoncard__description" }, description))),
            React.createElement("div", { className: "elmo-buttoncard__arrow" },
                React.createElement(ChevronRightIcon, null))));
    };
    return ButtonCard;
}(Component));
export default ButtonCard;
