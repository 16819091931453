var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Checkbox from '../../Checkbox';
import './ListTableHeaderWrapper.scss';
import ListTableHeader from './ListTableHeader';
import ListTableHeaderSelectAll from './ListTableHeaderSelectAll';
import { withListTableContext } from '../ListTableContextConsumer';
import Sticky from '../../Sticky';
var ListTableHeaderWrapper = /** @class */ (function (_super) {
    __extends(ListTableHeaderWrapper, _super);
    function ListTableHeaderWrapper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onSelectAllAvailable = function (isSelect) { return function () {
            var _a = _this.props, setContextState = _a.setContextState, onSelectAllAvailableToggle = _a.onSelectAllAvailableToggle;
            if (!isSelect && setContextState) {
                setContextState({
                    showSelectAllAvailableHeader: false,
                    selectAllIsChecked: false,
                });
            }
            if (onSelectAllAvailableToggle) {
                onSelectAllAvailableToggle(isSelect);
            }
        }; };
        _this.onSelectAllClick = function () {
            var _a = _this.props, onSelectAllToggle = _a.onSelectAllToggle, selectAllIsChecked = _a.selectAllIsChecked, setContextState = _a.setContextState;
            var nextSelectAllIsChecked = !selectAllIsChecked;
            if (setContextState) {
                setContextState({
                    selectAllIsChecked: nextSelectAllIsChecked,
                    selectAllIsIndeterminate: false,
                    showSelectAllAvailableHeader: nextSelectAllIsChecked,
                });
            }
            if (onSelectAllToggle) {
                onSelectAllToggle(nextSelectAllIsChecked);
            }
        };
        return _this;
    }
    ListTableHeaderWrapper.prototype.render = function () {
        var _a = this.props, selectAllIsChecked = _a.selectAllIsChecked, selectAllIsIndeterminate = _a.selectAllIsIndeterminate, showSelectAllAvailableHeader = _a.showSelectAllAvailableHeader, id = _a.id, isSticky = _a.isSticky, useCompactLayout = _a.useCompactLayout, hasActions = _a.hasActions, isSelectable = _a.isSelectable, countSelectedItems = _a.countSelectedItems;
        var renderListTableHeaderWrapper = (React.createElement("div", { className: "elmo-".concat(useCompactLayout ? 'compact' : '', "listtable__").concat(useCompactLayout ? 'compact-' : '', "header-wrapper"), role: "rowgroup" },
            isSelectable && (React.createElement("div", { className: "elmo-".concat(useCompactLayout ? 'compact' : '', "listtable__row-checkbox") },
                React.createElement(Checkbox, { id: "selectAll", onChange: this.onSelectAllClick, isChecked: selectAllIsChecked, isIndeterminate: selectAllIsIndeterminate, ariaLabel: "Select all" }))),
            !showSelectAllAvailableHeader && React.createElement(ListTableHeader, null),
            showSelectAllAvailableHeader && (React.createElement(ListTableHeaderSelectAll, { onSelectAllAvailable: this.onSelectAllAvailable, countSelectable: countSelectedItems }))));
        if (id && isSticky) {
            return (React.createElement(Sticky.Element, { id: id + '-Sticky' }, renderListTableHeaderWrapper));
        }
        return renderListTableHeaderWrapper;
    };
    return ListTableHeaderWrapper;
}(React.Component));
export { ListTableHeaderWrapper as ListTableHeaderWrapperNoContext };
export default withListTableContext(ListTableHeaderWrapper);
